import { User } from '../../core/models/user.model';
import { searchState } from '../../shared/models/search/searchState.model';
import { SearchActionTypes, SearchActions } from '../actions/search.action';
import { searchResulteDTO } from 'src/app/shared/models/search/openSearch.model';

export interface State {  
  selectedSearch :searchState ;   
} 
export const initialState : State = {
  selectedSearch : null
};
 
export function searchReducer(state = initialState, action: SearchActions){
  switch (action.type) {
    case SearchActionTypes.SETSEARCH: {
      //debugger;
      //console.log('insideSearchRed');
      return {
        ...state,
        //selectedSearch: [...state.selectedSearch,action.payload]
        selectedSearch: action.payload        
        // category: [...state.category,action],
        // subCategory: [...state.subCategory,action],       
      };      
    }            
    default: {
      return state;
    }
  }
}