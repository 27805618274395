<style>
    .dealImages {
        max-height: 200px;
        max-width: 200px;
    }
    
    .featuredAdsImageClass {
        max-height: 120px;
        min-height: 120px
    }
    main{
        background-color: #f5f5f5;
        
    }
</style>
<div id="tg-homebanner" class="tg-homebanner tg-haslayout ">
    <app-header-outer></app-header-outer>
</div>

<!--*******Home Slider End*******-->
<!--****** 👀 Main Start 👀******-->
<main id="tg-main" class="tg-main tg-haslayout">
    <!--*****Categories Search Start******-->

    <article>       
        <section class="tg-haslayout">
            <div class="container">
                <div class="row" style="z-index: 997;">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-push-1 col-lg-10">
                        <div class="tg-categoriessearch">
                            <div class="tg-title">
                                <h2><span>Boost your search with</span> Trending Categories</h2>
                            </div>
                            <div id="tg-categoriesslider" class="tg-categoriesslider tg-categories">
                                <owl-carousel [options]="mySlideOptions" [carouselClasses]="['owl-theme', 'sliding']">
                                    <div class=" item tg-category" *ngFor="let image of mySlideImages;let i = index">
                                        <div class="tg-categoryholder" (click)="ClassifiedsearchSend()">
                                            <figure>
                                                <img alt="{{image.category}}" class="imageClass" src={{image.url}}/>
                                            </figure>
                                            <h3>{{image.category}}</h3>
                                        </div>
                                    </div>
                                </owl-carousel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--***** Categories Search End *****-->

        <!--******Latest Posted Deals End******-->
        <div class="container">
            <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet" />
            <div class="container bootdey">
                <div class="col-md-3" style="margin-top: 20px;">
                    <section class="panel">
                        <div class="panel-body">
                            <img src="assets/images/beechtree season end sale 1 sep 2021.jpg" (click)="ClassifiedsearchSend()" >
                        </div>
                    </section>
                    <section class="panel">
                        <header class="panel-heading">
                            More Ads
                        </header>
                        <div class="panel-body sliders">
                            <div id="slider-range" class="slider"></div>
                            <div class="slider-info">
                                <span id="slider-range-amount">
                                    <div *ngFor="let topAdd of topFifteenAdds; let i = index" class="col-xs-6 col-sm-6 col-md-12 col-lg-12">
                                        <div class="tg-ad tg-verifiedad" style="background-color: #f9f9f9;">
                                            <figure>
                                                <span class="tg-themetag tg-featuretag">featured</span>
                                                <a style="cursor: pointer;" (click)="ongetDetails(topAdd)" target="_blank">
                                                    <img *ngIf="topAdd.addImageBase == null || topAdd.addImageBase == ''" src="/assets/images/no_image.jpg" alt="image description" class="featuredAdsImageClass">
                                                    <img *ngIf="topAdd.addImageBase != null && topAdd.addImageBase != ''" [src]="topAdd.addImageBase" alt="image description" class="featuredAdsImageClass"></a>
                                                <!-- <img src="{{topAdd.mainImagePath}}{{topAdd.imageName}}" alt="image description" class="featuredAdsImageClass"></a> -->
                                                <span class="tg-photocount">See All Photos</span>
                                            </figure>
                                            <div class="tg-adcontent">
                                                <ul class="tg-productcagegories">
                                                    <li><a href="javascript:void(0);">{{topAdd.cat}}</a></li>
                                                </ul>
                                                <div class="tg-adtitle">
                                                    <h3><a href="javascript:void(0);">{{topAdd.title}}</a></h3>
                                                </div>
                                                <time datetime="2017-06-06">Date: {{topAdd.datePosted}} </time>
                                                <div class="tg-adprice">
                                                    <h4>{{topAdd.currencyName}}:{{topAdd.price}}</h4>
                                                </div>
                                                <!-- <address>44-46 abc Road, Manchester</address> -->
                                                <div class="tg-phonelike">
                                                    <a class="tg-btnphone" [ngClass]="showPhones == topAdd.add_ID ? 'highLight' : 'clearfix'" href="javascript:void(0);">
                                                        <i class="fa fa-phone"></i>
                                                        <span data-toggle="tooltip" data-placement="top" title="Show Phone No." data-last="0800 - 1234 - 562 - 6">
                                                            <em *ngIf="showPhones == topAdd.add_ID">{{topAdd.add_Phone}}</em>
                                                            <em (click)="showPhoneNumber(topAdd.add_ID)" [ngClass]="showPhones != topAdd.add_ID ? 'view' : 'hidden'">Show Phone No.</em>
                                                        </span>
                                                    </a>
                                                    <span class="tg-like tg-liked" (click)="addFavourite(topAdd)" style="font-size: 18px;">
                                                    <fa-icon [icon]="faHeart"></fa-icon>                                        
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </section>
                    <section class="panel">
                        <div class="panel-body">
                            <img src="assets/images/3ce709113389695.60269c221352f.jpg" (click)="ClassifiedsearchSend()" >
                        </div>
                    </section>
                    <section class="panel">
                        <header class="panel-heading">
                            Top Products
                        </header>
                        <div class="panel-body sliders">
                            <div id="slider-range" class="slider"></div>
                            <div class="slider-info">
                                <span id="slider-range-amount">
                                    <div *ngFor="let product of ProductList" class="col-xs-6 col-sm-6 col-md-12 col-lg-12">
                                        <div class="tg-ad tg-verifiedad" style="background-color: #f9f9f9;">
                                            <figure>
                                                <span  *ngIf="product.discountPrice == 0 || product.discountCheck == false" class="tg-themetag tg-featuretag">featured</span>
                                                <span  *ngIf="product.discountPrice != 0 && product.discountCheck == true" class="tg-themetag tg-featuretag">discount: {{product.discountPrice}}%</span>
                                                <a style="cursor: pointer;" [routerLink]="['/product/details', product.proID]" target="_blank">
                                                    <img *ngIf="product.proImageBase == null || product.proImageBase == ''" src="/assets/images/no_image.jpg" alt="image description" class="featuredAdsImageClass">
                                                    <img *ngIf="product.proImageBase != null && product.proImageBase != ''" [src]="product.proImageBase" alt="image description" class="featuredAdsImageClass"></a>
                                                <!-- <img src="{{product.mainImagePath}}{{product.imageName}}" alt="image description" class="featuredAdsImageClass"></a> -->
                                                <span class="tg-photocount">See All Photos</span>
                                            </figure>
                                            <div class="tg-adcontent">
                                                <ul class="tg-productcagegories">
                                                    <li><a href="javascript:void(0);">Product</a></li>
                                                    <span style="float: right;" *ngIf="product.shipping == 1">
                                                     <a (click)="addToCart(product)"><span style="font-size: 12px;cursor: pointer;"></span> <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                                                    </a></span>
                                                </ul>
                                                <div class="tg-adtitle">
                                                    <h3><a href="javascript:void(0);">{{product.proName}}</a></h3>
                                                </div>
                                                <time>date: {{product.proAddedDate}} </time>
                                                <div class="tg-adprice">
                                                    <h4>{{product.proPriceUnitName}}:{{product.proPrice}} 
                                                        <!-- <span *ngIf="product.discountPrice != 0 && product.discountCheck == true"
                                                         style="float: right;color: rgb(236, 18, 18);font-size: small;">discount: {{product.discountPrice}}%</span> -->
                                                        </h4>
                                                </div>
                                                <!-- <address>44-46 abc Road, Manchester</address> -->
                                                <div class="tg-phonelike">
                                                    <a class="tg-btnphone" [ngClass]="showPhones == product.proID ? 'highLight' : 'clearfix'" href="javascript:void(0);">
                                                        <i class="fa fa-phone"></i>
                                                        <span data-toggle="tooltip" data-placement="top" title="Show Phone No." data-last="0800 - 1234 - 562 - 6">
                                                            <em *ngIf="showPhones == product.proID">{{product.proPhoneNumber}}</em>
                                                            <em (click)="showPhoneNumber(product.proID)" [ngClass]="showPhones != product.proID ? 'view' : 'hidden'">Show Phone No.</em>
                                                        </span>
                                                    </a>
                                                    <span class="tg-like tg-liked" (click)="productFavourite(product)" style="font-size: 18px;">
                                                    <fa-icon [icon]="faHeart"></fa-icon>                                        
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </section>
                    <section class="panel">
                        <div class="panel-body">
                            <img src="assets/images/home-banner2-2.jpg" (click)="ClassifiedsearchSend()" >
                        </div>
                    </section>
                </div>
                <div class="col-md-9"  style="margin-top: 20px;">
                    <section class="panel">
                        <div class="panel-body">
                        <img src="assets/images/360_F_316376413_nYL2jpLONPQPOsy31DE86n7FPpSxPIi3.jpg" (click)="ClassifiedsearchSend()" >
                    </div>
                    </section>
                    <section class="tg-sectionspace tg-haslayout">
                        <div class="">
                            <div class="row">
                                <div class="tg-ads" style="background:white;padding:10px;border: radius 15px;"> 
                                    <div class="tg-sectionhead">
                                        <div class="tg-title">
                                            <h2>Leatest Deals</h2>
                                            <p>Over 10,56,432 Featured Deals
                                               <span style="float: right;"><button class="btn btn-success" (click)="DealsearchSend()" target="_blank">View All</button></span>
                                            </p>
                                        </div></div>
                                        <div *ngFor="let topDeal of topDeals; let i = index" class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                                            <div class="tg-ad tg-verifiedad">
                                                <figure>
                                                    <span class="tg-themetag tg-featuretag">featured</span>
                                                    <a style="cursor: pointer;" [routerLink]="['/deal/details', topDeal.addID]" target="_blank">
                                                            <img *ngIf="topDeal.addImageBase == null || topDeal.addImageBase == ''" src="/assets/images/no_image.jpg" alt="image description" class="featuredAdsImageClass">
                                                            <img *ngIf="topDeal.addImageBase != null && topDeal.addImageBase != ''" [src]="topDeal.addImageBase" alt="image description" class="featuredAdsImageClass"></a>
                                                    <!-- <img src="{{item.mainImagePath}}" alt="{{item.title}}" class="dealImages"></a> -->
                                                    <span class="tg-photocount">See Photos</span>
                                                </figure>
                                                <div class="tg-adcontent">
                                                    <ul class="tg-productcagegories">
                                                        <li><a href="javascript:void(0);">Deal</a></li>
                                                    </ul>
                                                    <div class="tg-adtitle">
                                                        <h3><a href="javascript:void(0);">{{topDeal.title}}</a></h3>
                                                    </div>
                                                    <time datetime="2017-06-06">End Date: {{topDeal.dealEnd}}</time>
                                                    <div class="tg-adprice">
                                                        <h4>{{topDeal.currency}}:{{topDeal.price}}</h4>
                                                    </div>
                                                    <!-- <address>44-46 abc Road, Manchester</address> -->
                                                    <div class="tg-phonelike">
                                                        <a class="tg-btnphone" [ngClass]="showPhones == topDeal.addID ? 'highLight' : 'clearfix'" href="javascript:void(0);">
                                                            <i class="fa fa-phone"></i>
                                                            <span data-toggle="tooltip" data-placement="top" title="Show Phone No." data-last="0800 - 1234 - 562 - 6">
                                                                <em *ngIf="showPhones == topDeal.addID">{{topDeal.mobileNumber}}</em>
                                                                <em (click)="showPhoneNumber(topDeal.addID)" [ngClass]="showPhones != topDeal.addID ? 'view' : 'hidden'">Show Phone No.</em>
                                                            </span>
                                                        </a>
                                                        <span class="tg-like tg-liked" (click)="DealFavourite(topDeal)" style="font-size: 18px;">
                                                        <fa-icon [icon]="faHeart"></fa-icon>                                        
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                                
                            </div>
                        </div>
                    </section>
                    <div class="row">
                        <div class="col-md-6">
                            <img src="assets/images/b8f57e0aee6a4d4e5fd14c4cc5ddcd67.w600.h300.jpg" (click)="ClassifiedsearchSend()" >
                        </div>
                        <div class="col-md-6">
                            <img src="assets/images/b8f57e0aee6a4d4e5fd14c4cc5ddcd67.w600.h300.jpg" (click)="ClassifiedsearchSend()" >
                        </div>
                    </div>
                    <section class="tg-sectionspace tg-haslayout">
                        <div class="">
                            <div class="row">
                                <div class="tg-ads" style="background:white;padding:10px;border: radius 15px;"> 
                                    <div class="tg-sectionhead">
                                        <div class="tg-title">
                                            <h2>Leatest Autos</h2>
                                            <p>Over 10,56,432 Featured Autos
                                                <span style="float: right;"><button class="btn btn-success" (click)="AutosearchSend()" target="_blank">View All</button></span>
                                            </p>
                                        </div></div>
                                        <div *ngFor="let items of PostList" class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                                            <div class="tg-ad tg-verifiedad">
                                                <figure>
                                                    <span class="tg-themetag tg-featuretag">featured</span>
                                                    <a style="cursor: pointer;" (click)="ongetDetails(items)">
                                                        <img *ngIf="items.addImageBase == null || items.addImageBase == ''" src="/assets/images/no_image.jpg" alt="image description" class="featuredAdsImageClass">
                                                        <img *ngIf="items.addImageBase != null && items.addImageBase != ''" [src]="items.addImageBase" alt="image description" class="featuredAdsImageClass"></a>
                                                    <!-- <img src="{{product.mainImagePath}}{{product.imageName}}" alt="image description" class="featuredAdsImageClass"></a> -->
                                                    <span class="tg-photocount">See All Photos</span>
                                                </figure>
                                                <div class="tg-adcontent">
                                                    <ul class="tg-productcagegories">
                                                        <li><a href="javascript:void(0);">{{items.cat}}</a></li>
                                                    </ul>
                                                    <div class="tg-adtitle">
                                                        <h3><a href="javascript:void(0);">{{items.title}}</a></h3>
                                                    </div>
                                                    <time datetime="2017-06-06">Date: {{items.datePosted}} </time>
                                                    <div class="tg-adprice">
                                                        <h4>{{items.currencyName}}:{{items.price}}</h4>
                                                    </div>
                                                    <!-- <address>44-46 abc Road, Manchester</address> -->
                                                    <div class="tg-phonelike">
                                                        <a class="tg-btnphone" [ngClass]="showPhones == items.add_ID ? 'highLight' : 'clearfix'" href="javascript:void(0);">
                                                            <i class="fa fa-phone"></i>
                                                            <span data-toggle="tooltip" data-placement="top" title="Show Phone No." data-last="0800 - 1234 - 562 - 6">
                                                                <em *ngIf="showPhones == items.add_ID">{{items.add_Phone}}</em>
                                                                <em (click)="showPhoneNumber(items.add_ID)" [ngClass]="showPhones != items.add_ID ? 'view' : 'hidden'">Show Phone No.</em>
                                                                <!-- <span (click)="showPhoneNumber()">Click To Show Number</span> -->
                                                               
                                                            </span>
                                                            <!-- <p [ngClass]="toggleStyle ? 'show':'hide'">
                                                               {{items.add_Phone}}
                                                              </p>
                                                              <button (click)="toggle()">Show/Hide</button> -->
                                                        </a>
                                                        <span class="tg-like tg-liked" (click)="addFavourite(items)" style="font-size: 18px;">
                                                        <fa-icon [icon]="faHeart"></fa-icon>                                        
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                                
                            </div>
                        </div>
                    </section>
                    <div class="">
                        <img src="assets/images/Screenshot-2022-05-20-162747.png" (click)="ClassifiedsearchSend()" >
                    </div>
                    <section class="tg-sectionspace tg-haslayout">
                        <div class="">
                            <div class="row">
                                <div class="tg-ads" style="background:white;padding:10px;border: radius 15px;"> 
                                    <div class="tg-sectionhead">
                                        <div class="tg-title">
                                            <h2>Leatest Property For Rent</h2>
                                            <p>Over 10,56,432 Featured Property
                                                <span style="float: right;"><button class="btn btn-success" (click)="PropertyRentsearchSend()" target="_blank">View All</button></span>
                                            </p>
                                        </div></div>
                                        <div *ngFor="let items of PropertyList | paginate: { itemsPerPage: 4, currentPage: page,totalItems:TotalLength }" class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                                            <div class="tg-ad tg-verifiedad">
                                                <figure>
                                                    <span class="tg-themetag tg-featuretag">featured</span>
                                                    <a style="cursor: pointer;" (click)="ongetDetails(items)">
                                                        <img *ngIf="items.addImageBase == null|| items.addImageBase == ''" src="/assets/images/no_image.jpg" alt="image description" class="featuredAdsImageClass">
                                                        <img *ngIf="items.addImageBase != null && items.addImageBase != ''" [src]="items.addImageBase" alt="image description" class="featuredAdsImageClass"></a>
                                                    <!-- <img src="{{product.mainImagePath}}{{product.imageName}}" alt="image description" class="featuredAdsImageClass"></a> -->
                                                    <span class="tg-photocount">See All Photos</span>
                                                </figure>
                                                <div class="tg-adcontent">
                                                    <ul class="tg-productcagegories">
                                                        <li><a href="javascript:void(0);">{{items.cat}}</a></li>
                                                    </ul>
                                                    <div class="tg-adtitle">
                                                        <h3><a href="javascript:void(0);">{{items.title}}</a></h3>
                                                    </div>
                                                    <time datetime="2017-06-06">Date: {{items.datePosted}} </time>
                                                    <div class="tg-adprice">
                                                        <h4>{{items.currencyName}}:{{items.price}}</h4>
                                                    </div>
                                                    <!-- <address>44-46 abc Road, Manchester</address> -->
                                                    <div class="tg-phonelike">
                                                        <a class="tg-btnphone" [ngClass]="showPhones == items.add_ID ? 'highLight' : 'clearfix'" href="javascript:void(0);">
                                                            <i class="fa fa-phone"></i>
                                                            <span data-toggle="tooltip" data-placement="top" title="Show Phone No." data-last="0800 - 1234 - 562 - 6">
                                                                <em *ngIf="showPhones == items.add_ID">{{items.add_Phone}}</em>
                                                                <em (click)="showPhoneNumber(items.add_ID)"  [ngClass]="showPhones != items.add_ID ? 'view' : 'hidden'">Show Phone No.</em>
                                                            </span>
                                                        </a>
                                                        <span class="tg-like tg-liked"  (click)="addFavourite(items)" style="font-size: 18px;">
                                                        <fa-icon [icon]="faHeart"></fa-icon>                                        
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                                
                            </div>
                        </div>
                    </section>
                    <div class="row">
                        <div class="col-md-6">
                            <img src="assets/images/home-banner1-1.jpg" (click)="ClassifiedsearchSend()" >
                        </div>
                        <div class="col-md-6">
                        <img src="assets/images/home-banner2-2.jpg" (click)="ClassifiedsearchSend()" >
                        </div>
                    </div>
                    <section class="tg-sectionspace tg-haslayout">
                        <div class="">
                            <div class="row">
                                <div class="tg-ads" style="background:white;padding:10px;border: radius 15px;"> 
                                    <div class="tg-sectionhead">
                                        <div class="tg-title">
                                            <h2>Leatest Property For Rent</h2>
                                            <p>Over 10,56,432 Featured Property
                                                <span style="float: right;"><button class="btn btn-success" (click)="PropertySalesearchSend()" target="_blank">View All</button></span>
                                            </p>
                                        </div></div>
                                        <div *ngFor="let items of PropertySaleList | paginate: { itemsPerPage: 4, currentPage: page,totalItems:TotalLength }" class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                                            <div class="tg-ad tg-verifiedad">
                                                <figure>
                                                    <span class="tg-themetag tg-featuretag">featured</span>
                                                    <a style="cursor: pointer;" (click)="ongetDetails(items)">
                                                        <img *ngIf="items.addImageBase == null || items.addImageBase == ''" src="/assets/images/no_image.jpg" alt="image description" class="featuredAdsImageClass">
                                                        <img *ngIf="items.addImageBase != null && items.addImageBase != ''" [src]="items.addImageBase" alt="image description" class="featuredAdsImageClass"></a>
                                                    <!-- <img src="{{product.mainImagePath}}{{product.imageName}}" alt="image description" class="featuredAdsImageClass"></a> -->
                                                    <span class="tg-photocount">See All Photos</span>
                                                </figure>
                                                <div class="tg-adcontent">
                                                    <ul class="tg-productcagegories">
                                                        <li><a href="javascript:void(0);">{{items.cat}}</a></li>
                                                    </ul>
                                                    <div class="tg-adtitle">
                                                        <h3><a href="javascript:void(0);">{{items.title}}</a></h3>
                                                    </div>
                                                    <time datetime="2017-06-06">Date: {{items.datePosted}} </time>
                                                    <div class="tg-adprice">
                                                        <h4>{{items.currencyName}}:{{items.price}}</h4>
                                                    </div>
                                                    <!-- <address>44-46 abc Road, Manchester</address> -->
                                                    <div class="tg-phonelike">
                                                        <a class="tg-btnphone" [ngClass]="showPhones == items.add_ID ? 'highLight' : 'clearfix'" href="javascript:void(0);">
                                                            <i class="fa fa-phone"></i>
                                                            <span data-toggle="tooltip" data-placement="top" title="Show Phone No." data-last="0800 - 1234 - 562 - 6">
                                                                <em *ngIf="showPhones == items.add_ID">{{items.add_Phone}}</em>
                                                                <em (click)="showPhoneNumber(items.add_ID)" [ngClass]="showPhones != items.add_ID ? 'view' : 'hidden'">Show Phone No.</em>
                                                            </span>
                                                        </a>
                                                        <span class="tg-like tg-liked"  (click)="addFavourite(items)" style="font-size: 18px;">
                                                        <fa-icon [icon]="faHeart"></fa-icon>                                        
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                                
                            </div>
                        </div>
                    </section>
                    <div class="row">
                        <div class="col-md-6">
                            <img src="assets/images/handbag-fashion01-1.jpg" (click)="ClassifiedsearchSend()" >
                        </div>
                        <div class="col-md-6">
                        <img src="assets/images/luxury-treading-1.jpg" (click)="ClassifiedsearchSend()" >
                        </div>
                    </div>
                    <section class="tg-sectionspace tg-haslayout">
                        <div class="">
                            <div class="row">
                                <div class="tg-ads" style="background:white;padding:10px;border: radius 15px;"> 
                                    <div class="tg-sectionhead">
                                        <div class="tg-title">
                                            <h2>Leatest Want Job</h2>
                                            <p>Over 10,56,432 Featured Jobs
                                                <span style="float: right;"><button class="btn btn-success" (click)="JobWantsearchSend()" target="_blank">View All</button></span>
                                            </p>
                                        </div></div>
                                        <div  *ngFor="let items of JobList | paginate: { itemsPerPage:4, currentPage: page,totalItems:TotalLength }" class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                                            <div class="tg-ad tg-verifiedad">
                                                <figure>
                                                    <span class="tg-themetag tg-featuretag">featured</span>
                                                    <a style="cursor: pointer;" (click)="ongetDetails(items)">
                                                        <img *ngIf="items.addImageBase == null || items.addImageBase == ''" src="/assets/images/no_image.jpg" alt="image description" class="featuredAdsImageClass">
                                                        <img *ngIf="items.addImageBase != null && items.addImageBase != ''" [src]="items.addImageBase" alt="image description" class="featuredAdsImageClass"></a>
                                                    <!-- <img src="{{product.mainImagePath}}{{product.imageName}}" alt="image description" class="featuredAdsImageClass"></a> -->
                                                    <span class="tg-photocount">See All Photos</span>
                                                </figure>
                                                <div class="tg-adcontent">
                                                    <ul class="tg-productcagegories">
                                                        <li><a href="javascript:void(0);">{{items.subCat}}</a></li>
                                                    </ul>
                                                    <div class="tg-adtitle">
                                                        <h3><a href="javascript:void(0);">{{items.title}}</a></h3>
                                                    </div>
                                                    <time datetime="2017-06-06">Date: {{items.datePosted}} </time>
                                                    <div class="tg-adprice">
                                                        <h4>{{items.currencyName}}:{{items.price}}</h4>
                                                    </div>
                                                    <!-- <address>44-46 abc Road, Manchester</address> -->
                                                    <div class="tg-phonelike">
                                                        <a class="tg-btnphone" [ngClass]="showPhones == items.add_ID ? 'highLight' : 'clearfix'" href="javascript:void(0);">
                                                            <i class="fa fa-phone"></i>
                                                            <span data-toggle="tooltip" data-placement="top" title="Show Phone No." data-last="0800 - 1234 - 562 - 6">
                                                                <em *ngIf="showPhones == items.add_ID">{{items.add_Phone}}</em>
                                                                <em (click)="showPhoneNumber(items.add_ID)" [ngClass]="showPhones != items.add_ID ? 'view' : 'hidden'">Show Phone No.</em>
                                                            </span>
                                                        </a>
                                                        <span class="tg-like tg-liked"  (click)="addFavourite(items)" style="font-size: 18px;">
                                                        <fa-icon [icon]="faHeart"></fa-icon>                                        
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                                
                            </div>
                        </div>
                    </section>
                    <section class="tg-sectionspace tg-haslayout">
                        <div class="">
                            <div class="row">
                                <div class="tg-ads" style="background:white;padding:10px;border: radius 15px;"> 
                                    <div class="tg-sectionhead">
                                        <div class="tg-title">
                                            <h2>Leatest Hire Job</h2>
                                            <p>Over 10,56,432 Featured Jobs
                                                <span style="float: right;"><button class="btn btn-success" (click)="JobHiresearchSend()" target="_blank">View All</button></span>
                                            </p>
                                        </div></div>
                                        <div  *ngFor="let items of JobHiringList | paginate: { itemsPerPage:4, currentPage: page,totalItems:TotalLength }" class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                                            <div class="tg-ad tg-verifiedad">
                                                <figure>
                                                    <span class="tg-themetag tg-featuretag">featured</span>
                                                    <a style="cursor: pointer;" (click)="ongetDetails(items)">
                                                        <img *ngIf="items.addImageBase == null || items.addImageBase == ''" src="/assets/images/no_image.jpg" alt="image description" class="featuredAdsImageClass">
                                                        <img *ngIf="items.addImageBase != null  && items.addImageBase != ''" [src]="items.addImageBase" alt="image description" class="featuredAdsImageClass"></a>
                                                    <!-- <img src="{{product.mainImagePath}}{{product.imageName}}" alt="image description" class="featuredAdsImageClass"></a> -->
                                                    <span class="tg-photocount">See All Photos</span>
                                                </figure>
                                                <div class="tg-adcontent">
                                                    <ul class="tg-productcagegories">
                                                        <li><a href="javascript:void(0);">{{items.subCat}}</a></li>
                                                    </ul>
                                                    <div class="tg-adtitle">
                                                        <h3><a href="javascript:void(0);">{{items.title}}</a></h3>
                                                    </div>
                                                    <time datetime="2017-06-06">Date: {{items.datePosted}} </time>
                                                    <div class="tg-adprice">
                                                        <h4>{{items.currencyName}}:{{items.price}}</h4>
                                                    </div>
                                                    <!-- <address>44-46 abc Road, Manchester</address> -->
                                                    <div class="tg-phonelike">
                                                        <a class="tg-btnphone" [ngClass]="showPhones == items.add_ID ? 'highLight' : 'clearfix'" href="javascript:void(0);">
                                                            <i class="fa fa-phone"></i>
                                                            <span data-toggle="tooltip" data-placement="top" title="Show Phone No." data-last="0800 - 1234 - 562 - 6">
                                                                <em *ngIf="showPhones == items.add_ID">{{items.add_Phone}}</em>
                                                                <em (click)="showPhoneNumber(items.add_ID)" [ngClass]="showPhones != items.add_ID ? 'view' : 'hidden'">Show Phone No.</em>
                                                            </span>
                                                        </a>
                                                        <span class="tg-like tg-liked"  (click)="addFavourite(items)" style="font-size: 18px;">
                                                        <fa-icon [icon]="faHeart"></fa-icon>                                        
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                                
                            </div>
                        </div>
                    </section>
                </div>
            </div>
    </div>
        <!--************************************ -->
    </article>
</main>
<!--******👀 Main End 👀******-->



