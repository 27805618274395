import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { openSearchService } from '../shared/services/search/openSearch.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CategoryDto } from '../shared/models/category/categoryDTO.model';
import { dataLibraries } from '../shared/models/category/dataLibraries/dataLibraries.model';
import { Options } from 'ng5-slider';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdPostingService } from '../add-posting/adPosting.service';
import { addPhotos } from '../shared/models/addPhotos.model';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { NgForm } from '@angular/forms';
import { NotificationService } from '../shared/services/notification.service';
import { productDetails } from '../shared/models/Company/productDetail';
import { adProductService } from '../shared/services/compnies/ProductServices';
import { productRequest } from '../shared/models/Company/productRequest';
import { NgOption } from '@ng-select/ng-select';
import { AdcompanyService } from '../shared/services/compnies/CompanyServices';
import { adDealService } from '../shared/services/deal/deal-services.service';
import { DealRequest } from '../shared/models/deal/deal';
import { DealProductRequest } from '../shared/models/deal/dealProductRequest';
import { Product } from '../product/show-product/Product';
import { LocalStorageService } from 'angular-web-storage';
import { NgxUiLoaderService } from 'ngx-ui-loader';
//import { productDetails } from '../../models/Company/productDetails';


@Component({
  selector: 'app-user-deal',
  templateUrl: './user-deal.component.html',
  styleUrls: ['./user-deal.component.css']
})
export class UserDealComponent implements OnInit {


  selectedPositionAlert: boolean;
  selectedGenderAlert: boolean;
  companyID: any;
  ActualPriceAlert: boolean;
  StartDateAlert: boolean;
  EndDateAlert: boolean;
  checkedIDs: any[];
  checkboxesDataList: any;
  ID: number;
  proAddedBy: any;
  // dealService: any;


  constructor(private userInterface: openSearchService,private ngxLoader: NgxUiLoaderService,
    private adcompanyService: AdcompanyService,
    private dealService: adDealService,
    private router: Router,
    private route: ActivatedRoute,
    private mScrollbarService: MalihuScrollbarService,
    private fb: FormBuilder,
    private adproductService: adProductService,
    private notifyService: NotificationService, public localStor: LocalStorageService,
    private ngZone: NgZone) {
    if (this.localStor.get('currentCountry')) {
      this.selectedCountry = this.localStor.get('currentCountry');
    }
    if (this.localStor.get('currentCity')) {
      this.selectedCity = this.localStor.get('currentCity');
    }
  }
  postDetailForm: FormGroup;

  faArrowRight = faArrowRight;

  selectedCategory = '0';
  selectedSubCategory = '0';
  selectedSubCatFirstChild = '0';
  selectedSubCatSecondChild = '0';
  selectedSearchWord = '';
  subCategories: CategoryDto[];
  subCatFirstChildList: CategoryDto[];
  subCatSecondChildList: CategoryDto[];


  categoryTitle = "";
  subCategoryTitle = "";
  subToFirstTitle = "";
  subToSecondTitle = "";
  finalSelection = "";

  descriptionArea = "";
  ProNameAlert = false;
  ProPriceAlert = false;
  addCategoryAlert = false;
  addSubCatAlert = false;

  Name = "";
  BrandName = "";
  //AddedBy = 1225;
  AddedBy;
  AddedDate;
  EditedBy;
  EditedDateTime;
  UserID;
  ComID;
  EndCatID = "";
  Price;
  TermsArea = "";
  EndDate;
  StartDate;
  ActualPrice;

  CurrienciesList: NgOption[] = [
    { id: 0, title: "Select Curriencies" },
    { id: 1, title: "PKR" },
    { id: 2, title: "INR" },
    { id: 3, title: "DOLLER" },
    { id: 4, title: "OR" },
    { id: 5, title: "AED" },
    { id: 6, title: "SR" },
    { id: 7, title: "Other" }
  ]

  selectedCurrencyID: number;
  //CurrienciesList: dataLibraries[];
  //selectedCurrency: dataLibraries;
  selectedCountry = '1';
  selectedCity = '0';

  allselected: number = 0;
  levelOfSearch: number = 0;
  levelToShow: number = 0;

  imageDeleteFrom: FormGroup;
  videoDeleteFrom: FormGroup;
  postImages: addPhotos[];
  imageurls = [];
  imageProduct = [];
  videoUrls = [];
  imageurlsNew = [];
  base64String: string;
  name: string;
  imagePath: string;
  minkmValue: number = 0;
  maxkmValue: number = 100;
  optionsKM: Options = {
    floor: 1,
    ceil: 500
  };

  ngOnInit(): void {
    this.fillDropDowns();
    this.LoadCompanyData();
    this.LoadDealList();
    this.fetchCheckedIDs()
  }
  companyList: any;
  LoadCompanyData() {
    //debugger;
    this.adcompanyService.getCompanyDetails().subscribe(data => {
      this.companyList = data;
    });
  }

  DealList: any = [];
  LoadDealList() {
    ////debugger;
    // this.dealService.getDealList().subscribe((data:any[]) => {
    //   this.DealList = data;
    //   //console.log(this.DealList);
    // });
    this.adproductService.getProductDetails().subscribe((data: any[]) => {
      this.DealList = data;
      //console.log(this.DealList);
    });
  }

  fillDropDowns() {
    this.CurrienciesList = [
      { id: 0, title: "Select Curriencies" },
      { id: 1, title: "PKR" },
      { id: 2, title: "INR" },
      { id: 3, title: "DOLLER" },
      { id: 4, title: "OR" },
      { id: 5, title: "AED" },
      { id: 6, title: "SR" },
      { id: 7, title: "Other" }
    ]
  }

  backtoMain() {
    this.userInterface.setInterface("0");
    this.router.navigate(['../home'], { relativeTo: this.route });
  }

  getSubCategories(selectedLevel: number, event: any) {
    //debugger;
    let catToSearch: string = "";
    if (selectedLevel == 0) {
      catToSearch = this.selectedCategory;
      this.categoryTitle = event.target.options[event.target.options.selectedIndex].text;
      this.selectedSubCategory = '0';
      this.selectedSubCatFirstChild = '0';
      this.selectedSubCatSecondChild = '0';
    }
    else if (selectedLevel == 1) {
      catToSearch = this.selectedSubCategory;
      this.subCategoryTitle = event.target.options[event.target.options.selectedIndex].text;
      this.subToFirstTitle = "";
      this.subToSecondTitle = "";
    }
    else if (selectedLevel == 2) {
      catToSearch = this.selectedSubCatFirstChild;
      this.subToFirstTitle = event.target.options[event.target.options.selectedIndex].text;
      this.subToSecondTitle = "";
    }
    else if (selectedLevel == 3) {
      catToSearch = this.selectedSubCatSecondChild;
      this.subToSecondTitle = event.target.options[event.target.options.selectedIndex].text;
    }

    this.userInterface.getSubCategories(catToSearch)
      .subscribe((data: CategoryDto[]) => {
        //debugger;
        if (selectedLevel == 0) {
          this.subCategories = data;
          this.levelOfSearch = 1;
          this.levelToShow = 1;
        }
        else if (selectedLevel == 1) {
          this.subCatFirstChildList = data;
          this.levelOfSearch = 2;
          if (this.subCatFirstChildList.length > 0) {
            this.levelToShow = 2;
          }
          else {
            this.levelToShow = 1;
            this.allselected = 1;
          }

        }
        else if (selectedLevel == 2) {
          this.subCatSecondChildList = data;
          this.levelOfSearch = 3;
          if (this.subCatSecondChildList.length > 0) {
            this.levelToShow = 3;
          }
          else {
            this.levelToShow = 2;
            this.allselected = 1;
          }

        }
        else if (selectedLevel == 3) {
          this.levelOfSearch = 4;
          if (this.subCatSecondChildList.length > 0) {
            this.levelToShow = 4;
          }
          else {
            this.levelToShow = 3;
            this.allselected = 1;
          }
        }
        ////console.log(data);
      });

    // if(this.subCategoryIDFromSearch)
    // {
    //   this.selectedSubCategory = this.subCategoryIDFromSearch;
    // }
  }

  onSubmit(form: NgForm) {
    ////debugger; 
    //first do the validation
    var result = this.validateForm();
    if (result === true) {

      const DealDetails = new DealRequest();
      DealDetails.LI_Name = this.Name;
      DealDetails.LI_Description = this.descriptionArea;
      DealDetails.LI_Terms = this.TermsArea;
      DealDetails.LI_CountryID = parseInt(this.selectedCountry);
      DealDetails.LI_AddedBy = this.AddedBy;
      // DealDetails.LI_AddedDate = this.AddedDate; 
      DealDetails.LI_EditedBy = this.EditedBy;
      // DealDetails.LI_EditedDateTime= this.EditedDateTime;
      DealDetails.LI_StartDate = this.StartDate;
      DealDetails.LI_EndDate = this.EndDate;
      DealDetails.LI_UserID = this.UserID;
      DealDetails.LI_ComID = this.ComID;
      DealDetails.LI_CatID = this.selectedCategory; // 1
      DealDetails.LI_SubCatID = this.selectedSubCategory;  // 2
      DealDetails.LI_EndCatID = this.selectedSubCatFirstChild;  // 3
      DealDetails.LI_EndSubCatID = this.selectedSubCatSecondChild;  // 4
      DealDetails.LI_Price = parseInt(this.Price);
      DealDetails.LI_PriceActual = parseInt(this.ActualPrice);
      DealDetails.LI_PriceUnit = this.selectedCurrencyID;
      DealDetails.LI_IsActive = false;
      DealDetails.LI_IsDeleted = false;


      // const dealProduct = new DealProductRequest();
      // dealProduct.DealID = 0;
      // dealProduct.ProductID = 0;
      // dealProduct.AddedBy = 0;
      // dealProduct.AddedDate = "";
      // dealProduct.isActive = false;
      // dealProduct.isDeleted = false;
    //  //debugger;
      var ProductDeal = [];
      var counter = 1;
      if (this.selectedItemsList.length > 0) {
        for (let ProID of this.selectedItemsList) {
          this.proID = ProID.proID;
          this.proAddedBy = ProID.proAddedBy;
          var deal = {};
          deal["DealID"] = counter,
            deal["ProductID"] = this.proID,
            deal["AddedBy"] = this.proAddedBy,
            deal["AddedDate"] = "04/14/2022",
            deal["isActive"] = false,
            deal["isDeleted"] = false
          ProductDeal.push(deal);
          counter++;
        }
      }

      var photos = [];
      var counter = 1;
      if (this.imageurls.length > 0) {
        this.imageurls.forEach(element => {
          var photo = {}; // here's your object
          photo["dealPhotoID"] = counter,
            photo["dealPhotoPath"] = element,
            photo["dealPhotoIsActive"] = true,
            photo["dealPHotoIsDeleted"] = false,
            photo["dealPhotoAddedBy"] = 0,
            photo["dealPhotoAddedDate"] = "04/15/2022",
            photo["dealPhotoEditedBy"] = 0,
            photo["dealPhotoEditedDate"] = "",
            photo["dealIsThumbnail"] = false
          photos.push(photo);
          counter++;
        });
      }


      DealDetails.postPhotos = photos;
      // DealDetails.postProduct = ProductDeal;
      DealDetails.postProduct = ProductDeal;

      //debugger;
      ////console.log(productDetails);
      if(this.selectedItemsList.length != 0){
      if (DealDetails.LI_Price < DealDetails.LI_PriceActual) {
        if (DealDetails.LI_StartDate < DealDetails.LI_EndDate) {
          this.ngxLoader.start();  
          this.dealService.submitDealDetails(DealDetails).subscribe(data => {
            // this.reloadComponent();
            this.clearForm();
            setTimeout(() => {
              this.ngxLoader.stop();
             }, 0); 
            this.successNotification();
          });
        } else {
          this.notifyService.showError('StartDate is smaller then EndDate !!', 'Worng Data');
        }
      } else {
        this.notifyService.showError('Provide Actual price is greater then price !!', 'Worng Data');
      }
    }else {
      this.notifyService.showError('Please Select Products !!', 'Worng Action');
    }
    }
    else {
      this.notifyService.showError('Provide the missing data !', 'Missing Data');
    }
  }


  successNotification() {
    Swal.fire({
      position: 'top-center',
      icon: 'success',
      title: 'Your work has been saved',
      showConfirmButton: false,
      timer: 2000
    })
  }


  validateForm() {
    //debugger;
    var result = true;
    if (typeof this.selectedCategory == 'undefined' || !this.selectedCategory || this.selectedCategory == "0") {
      this.addCategoryAlert = true;
      result = false;
    }
    else if (typeof this.selectedSubCategory == 'undefined' || !this.selectedSubCategory || this.selectedSubCategory == "0") {
      this.addCategoryAlert = false;
      this.addSubCatAlert = true;
      result = false;
    }
    else if (typeof this.Name == 'undefined' || !this.Name) {
      this.addCategoryAlert = false;
      this.addSubCatAlert = false;
      this.ProNameAlert = true;
      result = false;
    }
    else if (typeof this.Price == 'undefined' || !this.Price) {
      this.ProNameAlert = false;
      this.ProPriceAlert = true;
      result = false;
    }
    else if (typeof this.ActualPrice == 'undefined' || !this.ActualPrice) {
      this.ProNameAlert = false;
      this.ProPriceAlert = false;
      this.ActualPriceAlert = true;
      result = false;
    }
    else if (typeof this.StartDate == 'undefined' || !this.StartDate) {
      this.ProNameAlert = false;
      this.ProPriceAlert = false;
      this.ActualPriceAlert = false;
      this.StartDateAlert = true;
      result = false;
    }
    else if (typeof this.EndDate == 'undefined' || !this.EndDate) {
      this.ProNameAlert = false;
      this.ProPriceAlert = false;
      this.ActualPriceAlert = false;
      this.StartDateAlert = false;
      this.EndDateAlert = true;
      result = false;
    }
    else {
      this.ProNameAlert = false;
      this.ProPriceAlert = false;
      this.ActualPriceAlert = false;
      this.StartDateAlert = false;
      this.EndDateAlert = false;
    }
    return result;
  }

  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  removeImageEdit(i, imagepath) {
    this.imageDeleteFrom.value.id = i;
    this.imageDeleteFrom.value.ImagePath = imagepath;
  }

  removeImage(i) {
    this.imageurls.splice(i, 1);
    // this.videoUrls.splice(i, 1);
    this.imageurlsNew.splice(i, 1);
  }
  removeProduct(i) {
    //debugger;
    this.selectedItemsList.splice(i, 1);
    // this.imageDeleteFrom.value.isActive = i;
  }

  fetchCheckedIDs() {
    //debugger;
    this.checkedIDs = []
    this.checkboxesDataList.forEach((value, index) => {
      if (value.proIsActive) {
        this.checkedIDs.push(value.proID);
      }
    });
  }

  proID: any;
  dealDetails: Product;
  selectedItemsList = [];
  // data = [];
  onClickADD() {
    //debugger;
    this.selectedItemsList = this.DealList.filter((value, index) => {
      this.ID = value.proIsActive && value.proID;
      //this.ID = value.proID;
      //console.log(this.ID);
      return value.proIsActive

    });
    var ProductDeal = [];
    var counter = 1;
    if (this.selectedItemsList.length > 0) {
      for (let ProID of this.selectedItemsList) {
        this.proID = ProID.proID;
        this.proAddedBy = ProID.proAddedBy;
        var deal = {};
        deal["DealID"] = counter,
          deal["ProductID"] = this.proID,
          deal["AddedBy"] = this.proAddedBy,
          deal["AddedDate"] = "04/14/2022",
          deal["isActive"] = false,
          deal["isDeleted"] = false
        ProductDeal.push(deal);
        counter++;
      }
    }

    //console.log(this.selectedItemsList);
    // var data = this.DealList.filter(x=>x.isActive==true).map(x=>x.dealID).join(",").toString();
    //this.SelectProducts = [ 
    // this.test = this.DealList.filter(x=>x.isActive).map(x=>x.name),
    // this.test = this.DealList.filter(x=>x.isActive).map(x=>x.dealID),
    //this.DealList.filter(x=>x.isActive).map(x=>x.price) 
    // ]

  }
  onChange($event) {
    // const dealID = $event.target.value;
    // //console.log(dealID);

    ////console.log(this.DealList);
  }

  onSelectFile(event) {
    //debugger;
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      var totalFiles = this.imageurls.length + filesAmount;

      if (totalFiles > 5) {
        alert("file limit execede!");
      }
      else {
        for (let i = 0; i < filesAmount; i++) {
          var reader = new FileReader();
          reader.onload = (event: any) => {
            this.imageurls.push(event.target.result);
          }
          reader.readAsDataURL(event.target.files[i]);
        }
      }
    }
  }

  clearForm() {
    this.Name = null;
    this.descriptionArea = null;
    this.selectedCategory = '0';
    this.selectedSubCategory = '0';
    this.selectedSubCatFirstChild = '0';
    this.selectedSubCatSecondChild = '0';
    this.categoryTitle = ''
    this.subCategoryTitle = '';
    this.subToFirstTitle = '';
    this.subToSecondTitle = '';
    this.Price = null;
    this.selectedCurrencyID = null;
    this.TermsArea = null;
    this.StartDate = null;
    this.EndDate = null;
    this.ActualPrice = null;
    this.selectedCurrencyID = null;
    this.selectedItemsList = null;
    this.imageurls.splice(0);
    this.imageurls.splice(1);
    this.imageurls.splice(2);
    this.imageurls.splice(3);
    this.imageurls.splice(4);
    this.imageurls.splice(5);
    this.videoUrls.splice(0);
   // this.imageurls = null;
  }
}
