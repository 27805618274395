<div class="tg-wrapper tg-haslayout">
    <div class="tg-dashboardbanner">
        <h1>Connections</h1>
        <ol class="tg-breadcrumb">
            <li><a (click)="backtoMain()">Go to Home</a></li>
            <li class="tg-active">Friend</li>
        </ol>
    </div>

    <ul>

    </ul>

    <main id="tg-main" class="tg-main tg-haslayout">
        <!--************************************
					Section Start
			*************************************-->
        <section class="tg-dbsectionspace tg-haslayout">
            <div class="row">
                <!-- <form class="tg-formtheme tg-formdashboard" [formGroup]="postDetailForm" (ngSubmit)="onSubmit()"> -->
                <form class="tg-formtheme tg-formdashboard">
                    <fieldset>
                        <div class="tg-postanad">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                                <div class="tg-dashboardbox">
                                    <div class="tg-dashboardboxtitle">
                                        <h2>Friends</h2>
                                    </div>
                                    <div class="tg-dashboardholder">
                                        <div class="form-group row">
                                            <div class="col-md-9">
                                                <div class="form-floating">
                                                    <label for="floatingInput">My Friend</label>
                                                    <input type="text" class="form-control" id="floatingInput"
                                                        name="searchnow" placeholder="Search Friend"
                                                        [(ngModel)]="this.SearchFrnd">
                                                </div>
                                            </div>
                                            <div class="col-md-3"><br />
                                                <button class="tg-btn" (click)="searchnow()">Search</button>
                                            </div>
                                        </div>
                                        <div class="container">
                                            <div class="line">
                                                <mat-divider></mat-divider>
                                            </div>
                                            <div class="text mat-typography">My Friends</div>
                                            <div class="line">
                                                <mat-divider></mat-divider>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="modal-body d-flex justify-content-center" data-spy="scroll"
                                                data-offset="0">
                                                <div class="row">
                                                    <div class="col-md-3 col-sm-4" *ngFor="let items of MyFriends">
                                                        <div class="card mb-30 hover10">
                                                            <a class="card-img-tiles " data-abc="true">
                                                                <div class="inner" (click)="profileview(items.userProfileID)">
                                                                    <div class="main-img">
                                                                        <figure><img *ngIf="items.imageBase64 != null"
                                                                                [src]="items.imageBase64"
                                                                                class="img-fluid circular_image" />
                                                                            <img *ngIf="items.imageBase64 == null"
                                                                                class="img-fluid circular_image"
                                                                                src="../../assets/images/ads/dummyimage.png" />
                                                                        </figure>
                                                                        <h5>{{items.userName}}</h5>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            <div style="text-align:center;">
                                                                <button class="btn btn-light" style="width: 100%;"
                                                                    (click)="UnFriend(items.userProfileID)">UnFriend</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5">
                                <div class="tg-dashboardbox tg-contactdetail">
                                    <div class="tg-dashboardboxtitle">
                                        <h2>Friend Request</h2>
                                    </div>
                                    <div class="col-md-12 col-sm-12">
                                        <div class="row form-group">
                                            <br />
                                            <div class="col-md-9">
                                                <div class="form-floating">
                                                    <label for="floatingInputs">Friend Request</label>
                                                    <input type="text" class="form-control" id="floatingInputs"
                                                        name="searchnowrequest" placeholder="Search Friend" disabled>
                                                </div>
                                            </div>
                                            <div class="col-md-3"><br />
                                                <button class="btn btn-success" disabled>Search</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="container">
                                        <div class="line">
                                            <mat-divider></mat-divider>
                                        </div>
                                        <div class="text mat-typography">Friends Request</div>
                                        <div class="line">
                                            <mat-divider></mat-divider>
                                        </div>
                                    </div>
                                    <div class="tg-dashboardholder modal-body d-flex justify-content-center"
                                        data-spy="scroll" style="height: 150vh;" data-offset="0">
                                        <div class="form-group">
                                            <div class="row">
                                                <!-- <div class="col-md-6 overflow-auto" style="text-align:center;" *ngFor="let items of FrndRequests">
                                                    <div id="wraper" class=" row col-md-12">
                                                        <div id="Profile">
                                                            <img *ngIf="items.imageBase64 != null" [src]="items.imageBase64" class="img-fluid circular_image" />
                                                                            <img *ngIf="items.imageBase64 == null" class="img-fluid circular_image" src="../../assets/images/ads/dummyimage.png" />
                                                            <span>{{items.userName}}</span><br />
                                                            <div class="btn-group" role="group" aria-label="Basic example">
                                                                <button type="button" class="btn btn-light" (click)="AcceptReq(items.userProfileID)">Accept</button>
                                                                <button type="button" class="btn btn-light" (click)="RejectReq(items.userProfileID)">Reject</button>
                                                              </div>
                                                        </div>
                                                    </div>
                                                </div> -->
                                                <div class="col-md-4 col-sm-6" *ngFor="let items of FrndRequests">
                                                    <div class="card mb-30 hover10">
                                                        <a class="card-img-tiles " data-abc="true">
                                                            <div class="inner">
                                                                <div class="main-img">
                                                                    <figure><img *ngIf="items.imageBase64 != null"
                                                                            [src]="items.imageBase64"
                                                                            class="img-fluid circular_image" />
                                                                        <img *ngIf="items.imageBase64 == null"
                                                                            class="img-fluid circular_image"
                                                                            src="../../assets/images/ads/dummyimage.png" />
                                                                    </figure>
                                                                    <h5>{{items.userName}}</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                        <div style="text-align:center;">
                                                            <button type="button" class="btn btn-light"
                                                                (click)="AcceptReq(items.userProfileID)"><i
                                                                    class="fa fa-check" aria-hidden="true"></i></button>
                                                            &nbsp;
                                                            <button type="button" class="btn btn-light"
                                                                (click)="RejectReq(items.userProfileID)"><i
                                                                    class="fa fa-times" aria-hidden="true"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </section>
        <section class="tg-dbsectionspace tg-haslayout">
            <div class="row">
                <form class="tg-formtheme tg-formdashboard">
                    <fieldset>
                        <div class="tg-postanad">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div class="tg-dashboardbox">
                                    <div class="tg-dashboardboxtitle">
                                        <div class="col-md-3">
                                            <h2>Your Request</h2>
                                        </div>
                                    </div>
                                    <div class="modal-body d-flex justify-content-center" data-spy="scroll"
                                        data-offset="0">
                                        <div class="row">
                                            <div class="col-md-2 col-sm-3" *ngFor="let items of SendFrndRequests">
                                                <div class="card mb-30 hover10">
                                                    <a class="card-img-tiles " data-abc="true">
                                                        <div class="inner">
                                                            <div class="main-img">
                                                                <figure><img *ngIf="items.imageBase64 != null"
                                                                        [src]="items.imageBase64"
                                                                        class="img-fluid circular_image" />
                                                                    <img *ngIf="items.imageBase64 == null"
                                                                        class="img-fluid circular_image"
                                                                        src="../../assets/images/ads/dummyimage.png" />
                                                                </figure>
                                                                <h5>{{items.userName}}</h5>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <div style="text-align:center;">
                                                        <button class="btn btn-light" style="width: 100%;"
                                                            (click)="CancelRequest(items.userProfileID)">Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </section>

        <section class="tg-dbsectionspace tg-haslayout">
            <div class="row">
                <form class="tg-formtheme tg-formdashboard">
                    <fieldset>
                        <div class="tg-postanad">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div class="tg-dashboardbox">
                                    <div class="tg-dashboardboxtitle">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <h2>Suggested Friends :</h2>
                                            </div>
                                            <div class="col-md-6">
                                                <!-- <label for="floatingInput">My Friend</label> -->
                                                <input type="text" class="form-control" id="floatingInput"
                                                    name="searchnow" placeholder="Search Friend"
                                                    [(ngModel)]="this.SearchUser">
                                            </div>
                                            <div class="col-md-3">
                                                <button class="btn-new" (click)="Usersearchnow()">Search</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-body d-flex justify-content-center" data-spy="scroll"
                                        data-offset="0">
                                        <div class="row">
                                            <div class="col-md-2 col-sm-3" *ngFor="let items of SuggestedFrnd; let i = index">
                                                <div class="card mb-30 hover10">
                                                    <a class="card-img-tiles " data-abc="true">
                                                        <div class="inner">
                                                            <div class="main-img">
                                                                <figure><img *ngIf="items.imageBase64 != null"
                                                                        [src]="items.imageBase64"
                                                                        class="img-fluid circular_image" />
                                                                    <img *ngIf="items.imageBase64 == null"
                                                                        class="img-fluid circular_image"
                                                                        src="../../assets/images/ads/dummyimage.png" />
                                                                </figure>
                                                                <h5>{{items.userName}}</h5>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <div style="text-align:center;">
                                                        <button class="btn btn-light" style="width: 100%;"
                                                        (click)="addfriend(items.userProfileID)">AddFriend</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </section>

    </main>

</div>