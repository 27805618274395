import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { openSearchService } from '../shared/services/search/openSearch.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CategoryDto } from '../shared/models/category/categoryDTO.model';
import { dataLibraries } from '../shared/models/category/dataLibraries/dataLibraries.model';
import { Options } from 'ng5-slider';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdPostingService } from '../add-posting/adPosting.service';
import { addPhotos } from '../shared/models/addPhotos.model';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { NgForm } from '@angular/forms';
import { NotificationService } from '../shared/services/notification.service';
import { productDetails } from '../shared/models/Company/productDetail';
import { adProductService } from '../shared/services/compnies/ProductServices';
import { productRequest } from '../shared/models/Company/productRequest';
import { NgOption } from '@ng-select/ng-select';
import { AdcompanyService } from '../shared/services/compnies/CompanyServices';
import { LocalStorageService } from 'angular-web-storage';
import { NgxUiLoaderService } from 'ngx-ui-loader';
//import { productDetails } from '../../models/Company/productDetails';


@Component({
  selector: 'app-user-product',
  templateUrl: './user-product.component.html',
  styleUrls: ['./user-product.component.css']
})
export class UserProductComponent implements OnInit {

  selectedPositionAlert: boolean;
  selectedGenderAlert: boolean;
  selectedCountry = '1';
  selectedCity = '0';
  btnDisabled = false;

  constructor(private userInterface: openSearchService,private ngxLoader: NgxUiLoaderService,
    private adcompanyService: AdcompanyService,
    private router: Router,
    private route: ActivatedRoute,
    private mScrollbarService: MalihuScrollbarService,
    private fb: FormBuilder,
    private adproductService: adProductService,
    private notifyService: NotificationService,public localStor: LocalStorageService,
    private ngZone: NgZone) { 
      if(this.localStor.get('currentCountry'))
      {
        this.selectedCountry =this.localStor.get('currentCountry');
      }
      if(this.localStor.get('currentCity'))
      {
        this.selectedCity =this.localStor.get('currentCity');
      }
    }
  postDetailForm: FormGroup;

  faArrowRight = faArrowRight;

  selectedCategory = '0';
  selectedSubCategory = '0';
  selectedSubCatFirstChild = '0';
  selectedSubCatSecondChild = '0';
  selectedSearchWord = '';
  subCategories: CategoryDto[];
  subCatFirstChildList: CategoryDto[];
  subCatSecondChildList: CategoryDto[];


  categoryTitle = "";
  subCategoryTitle = "";
  subToFirstTitle = "";
  subToSecondTitle = "";
  finalSelection = "";

  descriptionArea = "";
  ProNameAlert = false;
  ProPriceAlert = false;
  addCategoryAlert = false;
  addSubCatAlert = false;
  ProBrandAlert = false;
  ProMinAlert= false;
  ProMaxAlert=false;
  
  Name = "";
  BrandName = "";
  //AddedBy = 1225;
  AddedBy;
  AddedDate;
  EditedBy;
  EditedDateTime;
  UserID;
  ComID;
  EndCatID = "";
  Price;
  DiscountPrice:number=0;
  discountChk :boolean=false;
  //Price = 0;
 // PriceUnit = 1;
  MinQty:string;
  MaxSupply:string;
  MainImage = "";
  
  CurrienciesList: NgOption[]=[
    { id: 0, title: "Select Curriencies" },
    { id: 1, title: "PKR" },
    { id: 2, title: "INR" },
    { id: 3, title: "DOLLER" },
    { id: 4, title: "OR" },
    { id: 5, title: "AED" },
    { id: 6, title: "SR" },
    { id: 7, title: "Other" }
  ]

  selectedCurrencyID: number;
  //CurrienciesList: dataLibraries[];
  //selectedCurrency: dataLibraries;

  allselected: number = 0;
  levelOfSearch: number = 0;
  levelToShow: number = 0;

  imageDeleteFrom: FormGroup;
  videoDeleteFrom: FormGroup;
  postImages: addPhotos[];
  imageurls = [];
  videoUrls = [];
  imageurlsNew = [];
  base64String: string;
  name: string;
  imagePath: string;
  minkmValue: number = 0;
  maxkmValue: number = 100;
  optionsKM: Options = {
    floor: 1,
    ceil: 500
  };

  ngOnInit(): void {
    this.fillDropDowns();
    this.LoadCompanyData();
  }
  companyList:any;
  LoadCompanyData() {
    //debugger;
    this.adcompanyService.getCompanyDetails().subscribe(data => {
      this.companyList = data;
    });
  }
  fillDropDowns() {
    this.CurrienciesList = [
      { id: 0, title: "Select Curriencies" },
      { id: 1, title: "PKR" },
      { id: 2, title: "INR" },
      { id: 3, title: "DOLLER" },
      { id: 4, title: "OR" },
      { id: 5, title: "AED" },
      { id: 6, title: "SR" },
      { id: 7, title: "Other" }
    ]
  }
 

  backtoMain() {
    this.userInterface.setInterface("0");
    this.router.navigate(['../home'], { relativeTo: this.route });
  }

  getSubCategories(selectedLevel: number, event: any) {
    //debugger;
    let catToSearch: string = "";
    if (selectedLevel == 0) {
      catToSearch = this.selectedCategory;
      this.categoryTitle = event.target.options[event.target.options.selectedIndex].text;
      //var e = document.getElementById("ddpCat");
      //var text=e.options[e.selectedIndex].text;
      ////console.log(this.selectedCategory +"-"+this.categoryTitle);
      this.selectedSubCategory = '0';
      this.selectedSubCatFirstChild = '0';
      this.selectedSubCatSecondChild = '0';
    }
    else if (selectedLevel == 1) {
      catToSearch = this.selectedSubCategory;
      this.subCategoryTitle = event.target.options[event.target.options.selectedIndex].text;
      this.subToFirstTitle = "";
      this.subToSecondTitle = "";
    }
    else if (selectedLevel == 2) {
      catToSearch = this.selectedSubCatFirstChild;
      this.subToFirstTitle = event.target.options[event.target.options.selectedIndex].text;
      this.subToSecondTitle = "";
    }
    else if (selectedLevel == 3) {
      catToSearch = this.selectedSubCatSecondChild;
      this.subToSecondTitle = event.target.options[event.target.options.selectedIndex].text;
    }

    this.userInterface.getSubCategories(catToSearch)
      .subscribe((data: CategoryDto[]) => {
        //debugger;
        if (selectedLevel == 0) {
          this.subCategories = data;
          this.levelOfSearch = 1;
          this.levelToShow = 1;
        }
        else if (selectedLevel == 1) {
          this.subCatFirstChildList = data;
          this.levelOfSearch = 2;
          if (this.subCatFirstChildList.length > 0) {
            this.levelToShow = 2;
          }
          else {
            this.levelToShow = 1;
            this.allselected = 1;
          }

        }
        else if (selectedLevel == 2) {
          this.subCatSecondChildList = data;
          this.levelOfSearch = 3;
          if (this.subCatSecondChildList.length > 0) {
            this.levelToShow = 3;
          }
          else {
            this.levelToShow = 2;
            this.allselected = 1;
          }

        }
        else if (selectedLevel == 3) {
          this.levelOfSearch = 4;
          if (this.subCatSecondChildList.length > 0) {
            this.levelToShow = 4;
          }
          else {
            this.levelToShow = 3;
            this.allselected = 1;
          }
        }
        ////console.log(data);
      });

    // if(this.subCategoryIDFromSearch)
    // {
    //   this.selectedSubCategory = this.subCategoryIDFromSearch;
    // }
  }

  onSubmit(form: NgForm) {
    ////debugger; 
    //first do the validation
    var result = this.validateForm();
    if (result === true) {
      debugger
      const productDetails = new productRequest();
      productDetails.ProName = this.Name;
      productDetails.ProBrandName = this.BrandName;
      productDetails.ProDescription = this.descriptionArea; 
      productDetails.ProAddedBy = this.AddedBy;
      productDetails.CountryID = parseInt(this.selectedCountry);
     // productDetails.ProAddedDate = this.AddedDate; 
      productDetails.ProEditedBy = this.EditedBy;
     // productDetails.ProEditedDateTime= this.EditedDateTime;
      productDetails.ProUserID = this.UserID;
      productDetails.ProComID = this.ComID;
      productDetails.ProCatID = this.selectedCategory;
      productDetails.ProSubCatID= this.selectedSubCategory;
      productDetails.ProEndCatID= this.selectedSubCatFirstChild; 
      productDetails.ProEndSubCatID= this.selectedSubCatSecondChild;
      productDetails.ProPrice = parseInt(this.Price);
      productDetails.DiscountCheck = this.discountChk;
      productDetails.DiscountPrice = this.DiscountPrice;
      productDetails.ProPriceUnit= this.selectedCurrencyID;
      productDetails.ProMinQty= parseInt(this.MinQty);
      productDetails.ProMaxSupply= parseInt(this.MaxSupply); 
      productDetails.ProMainImage= "";
      productDetails.VIDEO_PATH= "";
      productDetails.ProIsActive = false;
      productDetails.ProIsDeleted= false;
      var photos = [];

      var counter = 1;
      if (this.imageurls.length > 0) {
        this.imageurls.forEach(element => {
          var photo = {}; // here's your object
          photo["ProID"] = counter,
            photo["proPhotoPath"] = element,
            photo["proPhotoIsActive"] = true,
            photo["proPHotoIsDeleted"] = false,
            photo["proPhotoAddedBy"] = 0,
            photo["proPhotoAddedDate"] = "07/02/2022",
            photo["proPhotoEditedBy"] = 0,
            photo["proPhotoEditedDate"] = "",
            photo["proIsThumbnail"] = false
          photos.push(photo);
          counter++;
        });
      }

      if (this.videoUrls.length > 0) {
        this.videoUrls.forEach(element => {
          productDetails.VIDEO_PATH = element;
        })
      }

      // //debugger;
      // var Videos = [];
      // var counter = 1;
      // if (this.videoUrls.length > 0) {
      //   this.videoUrls.forEach(element => {
      //     var video = {}; // here's your object
      //     video["ProID"] = counter,
      //       video["proVideoPath"] = element,
      //       video["proVideoIsActive"] = true,
      //       video["proVideoIsDeleted"] = false,
      //       video["proVideoAddedBy"] = 0,
      //       video["proVideoAddedDate"] = "24/03/2022",
      //       video["proVideoEditedBy"] = 0,
      //       video["proVideoEditedDate"] = "",
      //       video["proIsThumbnail"] = false
      //       Videos.push(video);
      //     counter++;
      //   });
      // }

      productDetails.postPhotos = photos;
      //productDetails.addVideo = Videos;
      
      //debugger;
      //console.log(productDetails);
      this.ngxLoader.start();       
      this.adproductService.submitProductDetails(productDetails).subscribe(data => {
        this.clearForm();        
        setTimeout(() => {
          this.ngxLoader.stop();
         }, 0); 
         this.successNotification();
      });        
    }
    else {
      this.notifyService.showError('Provide the missing data !', 'Missing Data');
    }
  }

  successNotification() {
    Swal.fire({
      position: 'top-center',
      icon: 'success',
      title: 'Your work has been saved',
      showConfirmButton: false,
      timer: 2000
    })
  }
  

  validateForm() {
    //debugger;
    var result = true;
    if (typeof this.selectedCategory == 'undefined' || !this.selectedCategory || this.selectedCategory == "0") {
      this.addCategoryAlert = true;
      result = false;
    }
    else if (typeof this.selectedSubCategory == 'undefined' || !this.selectedSubCategory || this.selectedSubCategory == "0") {
      this.addCategoryAlert = false;
      this.addSubCatAlert = true;
      result = false;
    }
    else if (typeof this.Name == 'undefined' || !this.Name) {
      this.addCategoryAlert = false;
      this.addSubCatAlert = false;
      this.ProNameAlert = true;
      result = false;
    }
    else if (typeof this.Price == 'undefined' || !this.Price) {
      this.ProNameAlert = false;
      this.ProPriceAlert = true;
      result = false;
    }
    else if (typeof this.BrandName == 'undefined' || !this.BrandName) {
      this.ProNameAlert = false;
      this.ProPriceAlert = false;
      this.ProBrandAlert = true;
      result = false;
    }
    else if (typeof this.MinQty == 'undefined' || !this.MinQty) {
      this.ProNameAlert = false;
      this.ProPriceAlert = false;
      this.ProBrandAlert = false;
      this.ProMinAlert = true;
      result = false;
    }
    else if (typeof this.MaxSupply == 'undefined' || !this.MaxSupply) {
      this.ProNameAlert = false;
      this.ProPriceAlert = false;
      this.ProBrandAlert = false;
      this.ProMinAlert = false;
      this.ProMaxAlert = true
      result = false;
    }
    else {
      this.ProNameAlert = false;
      this.ProPriceAlert = false;
      this.ProBrandAlert = false;
      this.ProMinAlert = false;
      this.ProMaxAlert = false;
    }
    return result;
  }

  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  removeImageEdit(i, imagepath) {
    this.imageDeleteFrom.value.id = i;
    this.imageDeleteFrom.value.ImagePath = imagepath;
  }

  removeImage(i) {
    this.imageurls.splice(i, 1);
    // this.videoUrls.splice(i, 1);
    this.imageurlsNew.splice(i, 1);
  }

  removeVideoEdit(i, videopath) {
    this.videoDeleteFrom.value.id = i;
    this.videoDeleteFrom.value.Videopath = videopath;
  }
  removeVideo(i) {
   this.videoUrls.splice(i, 1);
  }

  onSelectFile(event) {
    //debugger;
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      var totalFiles =  this.imageurls.length + filesAmount ;

      if(totalFiles > 5)
      {
        alert("file limit execede!");
      }            
      else {
        for (let i = 0; i < filesAmount; i++) {
          var reader = new FileReader();
          reader.onload = (event: any) => {
            this.imageurls.push(event.target.result);
          }
          reader.readAsDataURL(event.target.files[i]);
        }
      }
    }
  }

  // onSelectVideo(event) {
  //   //debugger;
  //   if (event.target.files && event.target.files[0]) {
  //     var filesAmount = event.target.files.length;
  //     var totalFiles =  this.videoUrls.length + filesAmount ;

  //     if(totalFiles > 1)
  //     {
  //       alert("file limit execede!");
  //     }            
  //     else {
  //       for (let i = 0; i < filesAmount; i++) {
  //         var reader = new FileReader();
  //         reader.onload = (event: any) => {
  //           this.videoUrls.push(event.target.result);
  //         }
  //         reader.readAsDataURL(event.target.files[i]);
  //       }
  //     }
  //   }
  // }
  onSelectVideo(event) {
    //debugger;
    let files = event.target.files;
    if (!this.validateFile(files[0].name)) {
     // this.errorNotification();
      this.notifyService.showError('Please Select Video .mp4 !!', 'Somthing Wrong');
      return false;
    }
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;     
    
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.videoUrls[0] = event.target.result;
        }
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'mp4') {
        return true;
    }
    else {
        return false;
    }
  }


  clearForm(){
    this.Name = null;
    this.BrandName = null;
    this.descriptionArea = null; 
    this.selectedCategory = '0';
    this.selectedSubCategory = '0';
    this.selectedSubCatFirstChild = '0'; 
    this.selectedSubCatSecondChild = '0';
    this.categoryTitle = ''
    this.subCategoryTitle = '';
    this.subToFirstTitle = '';
    this.subToSecondTitle= '';
    this.Price= null;
    this.selectedCurrencyID = null;
    this.MinQty= null;
    this.MaxSupply = null;
  //  this.imageurls=null;
    //this.videoUrls=null
    this.imageurls.splice(0);
    this.imageurls.splice(1);
    this.imageurls.splice(2);
    this.imageurls.splice(3);
    this.imageurls.splice(4);
    this.imageurls.splice(5);
    this.videoUrls.splice(0);
  }

}
