import { Component, OnInit } from '@angular/core';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { faCrosshairs } from '@fortawesome/free-solid-svg-icons';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { searchService } from 'src/app/shared/services/searchService';
import { categories } from 'src/app/shared/models/category/category.model';
import { EventEmitter } from 'protractor';
import { allSearchFilters } from 'src/app/shared/models/requestsModel/search/searchInput.model';
import { Subject } from 'rxjs';
import { openSearchService } from 'src/app/shared/services/search/openSearch.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  faBullhorn=faBullhorn;
  faSearch=faSearch;
  faLayerGroup=faLayerGroup;
  faLocationArrow=faLocationArrow;
  faCrosshairs=faCrosshairs;
  data: allSearchFilters;
  itemList = [];
  selectedItems = [];
  settings = {};
  //itemList: any = [];
  allCategories :  categories[];

  constructor(private http: HttpClient, private searchService: searchService,private userInterface: openSearchService, private operSearch: openSearchService,
    private router: Router, private route: ActivatedRoute) { }
  ngOnInit(): void {

    //this.getALLCategories();

    this.allCategories = [
      {
        "id": 1,
        "catID": "042F8B36-2F6E-4A05-8DF0-2BC49710D67C",
        "catName": "Boats",
        "istParentCatID": "5BF243B1-2BE4-4DDA-93A0-CF9E06209E0F",
        "firstParentCatName": "Autos",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Autos",
        "titleForShow": "Boats -in- Autos"
      },
      {
        "id": 2,
        "catID": "07BEA9F7-6E9D-4C10-8B3C-B5F10755CD3B",
        "catName": "Tickets & Vouchers",
        "istParentCatID": "D7B411FB-1C21-4F0A-88F7-34D6B789F67D",
        "firstParentCatName": "Classified",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Classified",
        "titleForShow": "Tickets & Vouchers -in- Classified"
      },
      {
        "id": 3,
        "catID": "1155E7CE-0844-4ED1-BB81-9DD982F8912E",
        "catName": "Business",
        "istParentCatID": "D7B411FB-1C21-4F0A-88F7-34D6B789F67D",
        "firstParentCatName": "Classified",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Classified",
        "titleForShow": "Business -in- Classified"
      },
      {
        "id": 4,
        "catID": "135FF770-A3D5-4C6A-97B1-9A8C6A45E132",
        "catName": "Industries",
        "istParentCatID": "D7B411FB-1C21-4F0A-88F7-34D6B789F67D",
        "firstParentCatName": "Classified",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Classified",
        "titleForShow": "Industries -in- Classified"
      },
      {
        "id": 5,
        "catID": "13629BDB-9E2D-4C9F-8135-C8E09A33F7CD",
        "catName": "Financial Services",
        "istParentCatID": "C4E1F606-8105-4666-A8BC-C6657730A0DF",
        "firstParentCatName": "Deals",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Deals",
        "titleForShow": "Financial Services -in- Deals"
      },
      {
        "id": 7,
        "catID": "15627EF7-96EC-4C8D-A52B-86DAF5EC77A6",
        "catName": "Cameras",
        "istParentCatID": "C4E1F606-8105-4666-A8BC-C6657730A0DF",
        "firstParentCatName": "Cameras",
        "secondParentCatId": "C4E1F606-8105-4666-A8BC-C6657730A0DF",
        "secondParentCatName": "Deals",
        "thirdParentCatiD": "0",
        "thirdParentCatName": null,
        "categoryToShow": "Deals",
        "titleForShow": "Cameras -in- Cameras"
      },
      {
        "id": 9,
        "catID": "1D36E7EB-89AC-4AB6-9A4C-94C24CDDDAB7",
        "catName": "Toys",
        "istParentCatID": "D7B411FB-1C21-4F0A-88F7-34D6B789F67D",
        "firstParentCatName": "Classified",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Classified",
        "titleForShow": "Toys -in- Classified"
      },
      {
        "id": 10,
        "catID": "2CC94F9F-117E-4096-8B44-5E163E8B2E78",
        "catName": "Baby Items",
        "istParentCatID": "D7B411FB-1C21-4F0A-88F7-34D6B789F67D",
        "firstParentCatName": "Classified",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Classified",
        "titleForShow": "Baby Items -in- Classified"
      },
      {
        "id": 11,
        "catID": "2FB3F7FB-3D0F-459D-A5B9-037EAE300567",
        "catName": "Misselenious",
        "istParentCatID": "D7B411FB-1C21-4F0A-88F7-34D6B789F67D",
        "firstParentCatName": "Classified",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Classified",
        "titleForShow": "Misselenious -in- Classified"
      },
      {
        "id": 12,
        "catID": "309D0411-4A49-449F-9981-25DADB863CC0",
        "catName": "Commercial For Rent",
        "istParentCatID": "0372DC00-F9CB-4CB5-8B95-901EA8A02EFA",
        "firstParentCatName": "Commercial For Rent",
        "secondParentCatId": "0372DC00-F9CB-4CB5-8B95-901EA8A02EFA",
        "secondParentCatName": "Property For Rent",
        "thirdParentCatiD": "0",
        "thirdParentCatName": null,
        "categoryToShow": "Property For Rent",
        "titleForShow": "Commercial For Rent -in- Commercial For Rent"
      },
      {
        "id": 16,
        "catID": "34B647D5-76BC-4B05-A4DF-E216216B063A",
        "catName": "TVs",
        "istParentCatID": "C4E1F606-8105-4666-A8BC-C6657730A0DF",
        "firstParentCatName": "Deals",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Deals",
        "titleForShow": "TVs -in- Deals"
      },
      {
        "id": 18,
        "catID": "3B3A5EC7-4CB1-4FFB-85C8-13E849427C90",
        "catName": "Books",
        "istParentCatID": "D7B411FB-1C21-4F0A-88F7-34D6B789F67D",
        "firstParentCatName": "Classified",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Classified",
        "titleForShow": "Books -in- Classified"
      },
      {
        "id": 19,
        "catID": "3C71D311-2022-494E-8851-6CFFF6692E2C",
        "catName": "Networking",
        "istParentCatID": "D7B411FB-1C21-4F0A-88F7-34D6B789F67D",
        "firstParentCatName": "Classified",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Classified",
        "titleForShow": "Networking -in- Classified"
      },
      {
        "id": 20,
        "catID": "3CB0BCFC-1399-4E18-BA43-A8AFBA13230F",
        "catName": "Home & Garden",
        "istParentCatID": "D7B411FB-1C21-4F0A-88F7-34D6B789F67D",
        "firstParentCatName": "Classified",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Classified",
        "titleForShow": "Home & Garden -in- Classified"
      },
      {
        "id": 21,
        "catID": "3E2D768E-4559-49D7-8842-2078AF4733D6",
        "catName": "Gaming",
        "istParentCatID": "D7B411FB-1C21-4F0A-88F7-34D6B789F67D",
        "firstParentCatName": "Classified",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Classified",
        "titleForShow": "Gaming -in- Classified"
      },
      {
        "id": 22,
        "catID": "553A6045-D552-4E91-BEDE-FC3D2FC1D487",
        "catName": "Wanted",
        "istParentCatID": "D7B411FB-1C21-4F0A-88F7-34D6B789F67D",
        "firstParentCatName": "Classified",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Classified",
        "titleForShow": "Wanted -in- Classified"
      },
      {
        "id": 23,
        "catID": "55F2C559-9DD7-4F48-B64C-5510CB088EB6",
        "catName": "Accessories",
        "istParentCatID": "D7B411FB-1C21-4F0A-88F7-34D6B789F67D",
        "firstParentCatName": "Classified",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Classified",
        "titleForShow": "Accessories -in- Classified"
      },
      {
        "id": 24,
        "catID": "58F1AC77-DF13-469B-8457-38DD29AF3266",
        "catName": "Land For Sale",
        "istParentCatID": "27BBB528-5666-4429-B153-7D6441C0EFA1",
        "firstParentCatName": "Property For Sale",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Property For Sale",
        "titleForShow": "Land For Sale -in- Property For Sale"
      },
      {
        "id": 25,
        "catID": "5F29FF53-457B-4BA3-9CD0-A0A02BFBB5CD",
        "catName": "Computers",
        "istParentCatID": "D7B411FB-1C21-4F0A-88F7-34D6B789F67D",
        "firstParentCatName": "Classified",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Classified",
        "titleForShow": "Computers -in- Classified"
      },
      {
        "id": 26,
        "catID": "6240A3B2-15B4-49AC-BF96-B30311BAEC60",
        "catName": "Motorcycles",
        "istParentCatID": "5BF243B1-2BE4-4DDA-93A0-CF9E06209E0F",
        "firstParentCatName": "Autos",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Autos",
        "titleForShow": "Motorcycles -in- Autos"
      },
      {
        "id": 28,
        "catID": "6264349E-6876-4B91-8A7C-8352FA3AC8E8",
        "catName": "Short Term(Monthly)",
        "istParentCatID": "0372DC00-F9CB-4CB5-8B95-901EA8A02EFA",
        "firstParentCatName": "Short Term(Monthly)",
        "secondParentCatId": "0372DC00-F9CB-4CB5-8B95-901EA8A02EFA",
        "secondParentCatName": "Property For Rent",
        "thirdParentCatiD": "0",
        "thirdParentCatName": null,
        "categoryToShow": "Property For Rent",
        "titleForShow": "Short Term(Monthly) -in- Short Term(Monthly)"
      },
      {
        "id": 29,
        "catID": "69F94326-9BB7-4802-A965-30E88D024147",
        "catName": "Multiple For Sale",
        "istParentCatID": "27BBB528-5666-4429-B153-7D6441C0EFA1",
        "firstParentCatName": "Multiple For Sale",
        "secondParentCatId": "27BBB528-5666-4429-B153-7D6441C0EFA1",
        "secondParentCatName": "Property For Sale",
        "thirdParentCatiD": "0",
        "thirdParentCatName": null,
        "categoryToShow": "Property For Sale",
        "titleForShow": "Multiple For Sale -in- Multiple For Sale"
      },
      {
        "id": 31,
        "catID": "6B3A9F5D-7B0D-4778-AECB-8640C98BC0C0",
        "catName": "Toys,Bady Products",
        "istParentCatID": "C4E1F606-8105-4666-A8BC-C6657730A0DF",
        "firstParentCatName": "Deals",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Deals",
        "titleForShow": "Toys,Bady Products -in- Deals"
      },
      {
        "id": 33,
        "catID": "6EA486D7-D4EF-41E2-B7BF-360C92C35EE1",
        "catName": "Sports",
        "istParentCatID": "D7B411FB-1C21-4F0A-88F7-34D6B789F67D",
        "firstParentCatName": "Classified",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Classified",
        "titleForShow": "Sports -in- Classified"
      },
      {
        "id": 46,
        "catID": "79504CCA-41F7-4936-ACF3-6757E9AA7E29",
        "catName": "Cars",
        "istParentCatID": "5BF243B1-2BE4-4DDA-93A0-CF9E06209E0F",
        "firstParentCatName": "Cars",
        "secondParentCatId": "5BF243B1-2BE4-4DDA-93A0-CF9E06209E0F",
        "secondParentCatName": "Autos",
        "thirdParentCatiD": "0",
        "thirdParentCatName": null,
        "categoryToShow": "Autos",
        "titleForShow": "Cars -in- Cars"
      },                     
      {
        "id": 114,
        "catID": "7B25ECF5-0167-4610-8446-B40030FC2899",
        "catName": "Pets",
        "istParentCatID": "D7B411FB-1C21-4F0A-88F7-34D6B789F67D",
        "firstParentCatName": "Classified",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Classified",
        "titleForShow": "Pets -in- Classified"
      },
      {
        "id": 115,
        "catID": "7E66B874-FFFA-4390-BF05-D1DB1A7FCEB9",
        "catName": "DVDS & Movies",
        "istParentCatID": "D7B411FB-1C21-4F0A-88F7-34D6B789F67D",
        "firstParentCatName": "Classified",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Classified",
        "titleForShow": "DVDS & Movies -in- Classified"
      },
      {
        "id": 118,
        "catID": "8F4C6A4A-35FE-4CB4-86AB-B0FEB751435D",
        "catName": "PDAs",
        "istParentCatID": "D7B411FB-1C21-4F0A-88F7-34D6B789F67D",
        "firstParentCatName": "Classified",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Classified",
        "titleForShow": "PDAs -in- Classified"
      },
      {
        "id": 120,
        "catID": "967CEED7-54C0-488E-8AA8-69E6045E6C05",
        "catName": "I am Hiring",
        "istParentCatID": "3C064386-A300-4F90-8E18-F6DA9F83AA1C",
        "firstParentCatName": "Jobs",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Jobs",
        "titleForShow": "I am Hiring -in- Jobs"
      },
      {
        "id": 121,
        "catID": "97508C80-4BA0-4147-8643-6924A7207B0F",
        "catName": "Music",
        "istParentCatID": "D7B411FB-1C21-4F0A-88F7-34D6B789F67D",
        "firstParentCatName": "Classified",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Classified",
        "titleForShow": "Music -in- Classified"
      },
      {
        "id": 122,
        "catID": "9C89C78F-B925-4129-A95B-D4D750954AA3",
        "catName": "Accessories & Parts",
        "istParentCatID": "5BF243B1-2BE4-4DDA-93A0-CF9E06209E0F",
        "firstParentCatName": "Autos",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Autos",
        "titleForShow": "Accessories & Parts -in- Autos"
      },
      {
        "id": 123,
        "catID": "A07D5BE2-BC59-4086-82F5-1E1DF97EC87A",
        "catName": "I want Job",
        "istParentCatID": "3C064386-A300-4F90-8E18-F6DA9F83AA1C",
        "firstParentCatName": "I want Job",
        "secondParentCatId": "3C064386-A300-4F90-8E18-F6DA9F83AA1C",
        "secondParentCatName": "Jobs",
        "thirdParentCatiD": "0",
        "thirdParentCatName": null,
        "categoryToShow": "Jobs",
        "titleForShow": "I want Job -in- I want Job"
      },
      {
        "id": 125,
        "catID": "A2730643-3991-40D0-902C-DCDB62D90CE1",
        "catName": "Collecables",
        "istParentCatID": "D7B411FB-1C21-4F0A-88F7-34D6B789F67D",
        "firstParentCatName": "Classified",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Classified",
        "titleForShow": "Collecables -in- Classified"
      },
      {
        "id": 126,
        "catID": "AD708A18-5BEA-40BC-966B-C22383A8ADAA",
        "catName": "Commercial For Sale",
        "istParentCatID": "27BBB528-5666-4429-B153-7D6441C0EFA1",
        "firstParentCatName": "Commercial For Sale",
        "secondParentCatId": "27BBB528-5666-4429-B153-7D6441C0EFA1",
        "secondParentCatName": "Property For Sale",
        "thirdParentCatiD": "0",
        "thirdParentCatName": null,
        "categoryToShow": "Property For Sale",
        "titleForShow": "Commercial For Sale -in- Commercial For Sale"
      },
      {
        "id": 128,
        "catID": "ADEEE04D-F451-4641-B93B-33AE22EA48AC",
        "catName": "Rooms Wanted",
        "istParentCatID": "0372DC00-F9CB-4CB5-8B95-901EA8A02EFA",
        "firstParentCatName": "Property For Rent",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Property For Rent",
        "titleForShow": "Rooms Wanted -in- Property For Rent"
      },
      {
        "id": 129,
        "catID": "B13692C2-9AAC-4CD0-8748-D5741B923013",
        "catName": "Electronics",
        "istParentCatID": "D7B411FB-1C21-4F0A-88F7-34D6B789F67D",
        "firstParentCatName": "Classified",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Classified",
        "titleForShow": "Electronics -in- Classified"
      },
      {
        "id": 130,
        "catID": "B96964E1-6D39-40E7-AE2E-256559520497",
        "catName": "Lost/Found",
        "istParentCatID": "D7B411FB-1C21-4F0A-88F7-34D6B789F67D",
        "firstParentCatName": "Classified",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Classified",
        "titleForShow": "Lost/Found -in- Classified"
      },
      {
        "id": 131,
        "catID": "BADC9697-01E1-483E-91D6-DF61BEBCB3A3",
        "catName": "Mens Fashion",
        "istParentCatID": "C4E1F606-8105-4666-A8BC-C6657730A0DF",
        "firstParentCatName": "Accessories",
        "secondParentCatId": "BADC9697-01E1-483E-91D6-DF61BEBCB3A3",
        "secondParentCatName": "Mens Fashion",
        "thirdParentCatiD": "C4E1F606-8105-4666-A8BC-C6657730A0DF",
        "thirdParentCatName": "Deals",
        "categoryToShow": "Mens Fashion",
        "titleForShow": "Mens Fashion -in- Accessories"
      },
      {
        "id": 133,
        "catID": "BADC9697-01E1-483E-91D6-DF61BEBCB3A3",
        "catName": "Mens Fashion",
        "istParentCatID": "C4E1F606-8105-4666-A8BC-C6657730A0DF",
        "firstParentCatName": "Deals",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Deals",
        "titleForShow": "Mens Fashion -in- Deals"
      },
      {
        "id": 140,
        "catID": "BB8D9DFA-63E0-47AD-AF6D-1575E4A57217",
        "catName": "Textile & Leather",
        "istParentCatID": "C4E1F606-8105-4666-A8BC-C6657730A0DF",
        "firstParentCatName": "Deals",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Deals",
        "titleForShow": "Textile & Leather -in- Deals"
      },
      {
        "id": 142,
        "catID": "CBE61317-40BB-483B-BF40-F2A781BE701D",
        "catName": "Furniture",
        "istParentCatID": "D7B411FB-1C21-4F0A-88F7-34D6B789F67D",
        "firstParentCatName": "Classified",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Classified",
        "titleForShow": "Furniture -in- Classified"
      },
      {
        "id": 143,
        "catID": "CEFC0FA3-EF19-41AC-9892-7D6A6925E9AA",
        "catName": "Women Fashion",
        "istParentCatID": "C4E1F606-8105-4666-A8BC-C6657730A0DF",
        "firstParentCatName": "Assessories",
        "secondParentCatId": "CEFC0FA3-EF19-41AC-9892-7D6A6925E9AA",
        "secondParentCatName": "Women Fashion",
        "thirdParentCatiD": "C4E1F606-8105-4666-A8BC-C6657730A0DF",
        "thirdParentCatName": "Deals",
        "categoryToShow": "Women Fashion",
        "titleForShow": "Women Fashion -in- Assessories"
      },
      {
        "id": 154,
        "catID": "D0242819-FB2B-47C5-B76A-F8B6AE1A0ABE",
        "catName": "Clothing",
        "istParentCatID": "D7B411FB-1C21-4F0A-88F7-34D6B789F67D",
        "firstParentCatName": "Classified",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Classified",
        "titleForShow": "Clothing -in- Classified"
      },
      {
        "id": 155,
        "catID": "D0B7A17A-30BB-40CE-BFD5-33C04BA1D6DB",
        "catName": "Home Appliances",
        "istParentCatID": "D7B411FB-1C21-4F0A-88F7-34D6B789F67D",
        "firstParentCatName": "Classified",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Classified",
        "titleForShow": "Home Appliances -in- Classified"
      },
      {
        "id": 161,
        "catID": "D1C6D949-447A-40C8-BF1A-BF3A6DA7E4D7",
        "catName": "Appliances",
        "istParentCatID": "C4E1F606-8105-4666-A8BC-C6657730A0DF",
        "firstParentCatName": "Large Appliances",
        "secondParentCatId": "D1C6D949-447A-40C8-BF1A-BF3A6DA7E4D7",
        "secondParentCatName": "Appliances",
        "thirdParentCatiD": "C4E1F606-8105-4666-A8BC-C6657730A0DF",
        "thirdParentCatName": "Deals",
        "categoryToShow": "Appliances",
        "titleForShow": "Appliances -in- Large Appliances"
      },
      {
        "id": 162,
        "catID": "D1FAF65C-41D6-46DF-A27E-D610447F3E1B",
        "catName": "jewelry",
        "istParentCatID": "D7B411FB-1C21-4F0A-88F7-34D6B789F67D",
        "firstParentCatName": "Classified",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Classified",
        "titleForShow": "jewelry -in- Classified"
      },
      {
        "id": 163,
        "catID": "D20CF420-26AF-4AA1-BBF2-A95858EE3BF4",
        "catName": "Machinary & Tools",
        "istParentCatID": "C4E1F606-8105-4666-A8BC-C6657730A0DF",
        "firstParentCatName": "Deals",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Deals",
        "titleForShow": "Machinary & Tools -in- Deals"
      },
      {
        "id": 165,
        "catID": "D8613F11-C947-4E1A-A9FB-2E1CB17C6039",
        "catName": "Sports, Health & Hobbies",
        "istParentCatID": "C4E1F606-8105-4666-A8BC-C6657730A0DF",
        "firstParentCatName": "Deals",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Deals",
        "titleForShow": "Sports, Health & Hobbies -in- Deals"
      },
      {
        "id": 171,
        "catID": "D8613F11-C947-4E1A-A9FB-2E1CB17C6039",
        "catName": "Sports, Health & Hobbies",
        "istParentCatID": "C4E1F606-8105-4666-A8BC-C6657730A0DF",
        "firstParentCatName": "Sports, Health & Hobbies",
        "secondParentCatId": "C4E1F606-8105-4666-A8BC-C6657730A0DF",
        "secondParentCatName": "Deals",
        "thirdParentCatiD": "0",
        "thirdParentCatName": null,
        "categoryToShow": "Deals",
        "titleForShow": "Sports, Health & Hobbies -in- Sports, Health & Hobbies"
      },
      {
        "id": 172,
        "catID": "DB70A015-7A8C-4ADB-87FD-5DE91852CD6C",
        "catName": "Mobiles",
        "istParentCatID": "D7B411FB-1C21-4F0A-88F7-34D6B789F67D",
        "firstParentCatName": "Classified",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Classified",
        "titleForShow": "Mobiles -in- Classified"
      },
      {
        "id": 173,
        "catID": "DE474B6E-1983-4C04-9EE6-1E67DA0436E9",
        "catName": "Residential For Rent",
        "istParentCatID": "0372DC00-F9CB-4CB5-8B95-901EA8A02EFA",
        "firstParentCatName": "Property For Rent",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Property For Rent",
        "titleForShow": "Residential For Rent -in- Property For Rent"
      },
      {
        "id": 175,
        "catID": "E0254826-3A26-48C9-891A-9526664C7518",
        "catName": "Short Term (Daily)",
        "istParentCatID": "0372DC00-F9CB-4CB5-8B95-901EA8A02EFA",
        "firstParentCatName": "Property For Rent",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Property For Rent",
        "titleForShow": "Short Term (Daily) -in- Property For Rent"
      },
      {
        "id": 178,
        "catID": "E0B72997-A58A-44DE-B6D6-AB325036A276",
        "catName": "Rental Wanted",
        "istParentCatID": "0372DC00-F9CB-4CB5-8B95-901EA8A02EFA",
        "firstParentCatName": "Property For Rent",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Property For Rent",
        "titleForShow": "Rental Wanted -in- Property For Rent"
      },
      {
        "id": 180,
        "catID": "E49D23FB-F5E0-43A7-9489-FC3D52F1D9EC",
        "catName": "Rooms For Rent",
        "istParentCatID": "0372DC00-F9CB-4CB5-8B95-901EA8A02EFA",
        "firstParentCatName": "Property For Rent",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Property For Rent",
        "titleForShow": "Rooms For Rent -in- Property For Rent"
      },
      {
        "id": 182,
        "catID": "E4E3813A-E469-4FA7-AA8D-C45F1733691D",
        "catName": "Watches",
        "istParentCatID": "D7B411FB-1C21-4F0A-88F7-34D6B789F67D",
        "firstParentCatName": "Classified",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Classified",
        "titleForShow": "Watches -in- Classified"
      },
      {
        "id": 183,
        "catID": "F2C5E5A5-3A6E-4948-8C4D-9AD32C17F5DE",
        "catName": "Mobile & Tablets",
        "istParentCatID": "C4E1F606-8105-4666-A8BC-C6657730A0DF",
        "firstParentCatName": "Deals",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Deals",
        "titleForShow": "Mobile & Tablets -in- Deals"
      },
      {
        "id": 185,
        "catID": "F3F3582D-2BF4-4D92-90D3-0F93C0426F1F",
        "catName": "Home & Kitchen",
        "istParentCatID": "C4E1F606-8105-4666-A8BC-C6657730A0DF",
        "firstParentCatName": "Catering",
        "secondParentCatId": "F3F3582D-2BF4-4D92-90D3-0F93C0426F1F",
        "secondParentCatName": "Home & Kitchen",
        "thirdParentCatiD": "C4E1F606-8105-4666-A8BC-C6657730A0DF",
        "thirdParentCatName": "Deals",
        "categoryToShow": "Home & Kitchen",
        "titleForShow": "Home & Kitchen -in- Catering"
      },
      {
        "id": 194,
        "catID": "F4006F3F-BFAC-46AA-8D92-69F3578835F4",
        "catName": "Automotives",
        "istParentCatID": "C4E1F606-8105-4666-A8BC-C6657730A0DF",
        "firstParentCatName": "Automotives",
        "secondParentCatId": "C4E1F606-8105-4666-A8BC-C6657730A0DF",
        "secondParentCatName": "Deals",
        "thirdParentCatiD": "0",
        "thirdParentCatName": null,
        "categoryToShow": "Deals",
        "titleForShow": "Automotives -in- Automotives"
      },
      {
        "id": 196,
        "catID": "FE1C13DE-9342-4EFB-824B-806DA0B8E3FE",
        "catName": "Heavy Vehycles",
        "istParentCatID": "5BF243B1-2BE4-4DDA-93A0-CF9E06209E0F",
        "firstParentCatName": "Autos",
        "secondParentCatId": "0",
        "secondParentCatName": null,
        "thirdParentCatiD": null,
        "thirdParentCatName": null,
        "categoryToShow": "Autos",
        "titleForShow": "Heavy Vehycles -in- Autos"
      }
    ];
    this.itemList = [
      { "id": 1, "itemName": "India", "category": "asia" },
      { "id": 2, "itemName": "Singapore", "category": "asia pacific" },
      { "id": 3, "itemName": "Germany", "category": "Europe" },
      { "id": 4, "itemName": "France", "category": "Europe" },
      { "id": 5, "itemName": "South Korea", "category": "asia" },
      { "id": 6, "itemName": "Sweden", "category": "Europe" }
    ];

    this.selectedItems = [
      {
        "id": 34,
        "catID": "79504CCA-41F7-4936-ACF3-6757E9AA7E29",
        "catName": "Cars",
        "istParentCatID": "5BF243B1-2BE4-4DDA-93A0-CF9E06209E0F",
        "firstParentCatName": "Acura",
        "secondParentCatId": "79504CCA-41F7-4936-ACF3-6757E9AA7E29",
        "secondParentCatName": "Cars",
        "thirdParentCatiD": "5BF243B1-2BE4-4DDA-93A0-CF9E06209E0F",
        "thirdParentCatName": "Autos",
        "categoryToShow": "Cars",
        "titleForShow": "Cars -in- Acura"
      }
      // { "id": 1, "itemName": "India" }
      
    ];

//     this.itemList = [
//       {"id":1,"itemName":"India","capital":"Delhi","image":"http://www.sciencekids.co.nz/images/pictures/flags96/India.jpg"},
//       {"id":2,"itemName":"Singapore", "capital":"Singapore","image":"http://www.sciencekids.co.nz/images/pictures/flags96/Singapore.jpg"},
//       {"id":3,"itemName":"United Kingdom", "capital":"London","image":"http://www.sciencekids.co.nz/images/pictures/flags96/United_Kingdom.jpg"},
//       {"id":4,"itemName":"Canada","capital":"Ottawa","image":"http://www.sciencekids.co.nz/images/pictures/flags96/Canada.jpg"},
//       {"id":5,"itemName":"South Korea","capital":"Seoul","image":"http://www.sciencekids.co.nz/images/pictures/flags96/South_Korea.jpg"},    
//       {"id":6,"itemName":"Brazil","capital":"Brasilia","image":"http://www.sciencekids.co.nz/images/pictures/flags96/Brazil.jpg"}                      
//     ];

// this.selectedItems = [
//       {"id":1,"itemName":"India","capital":"Delhi","image":"http://www.sciencekids.co.nz/images/pictures/flags96/India.jpg"},
//       {"id":2,"itemName":"Singapore", "capital":"Singapore","image":"http://www.sciencekids.co.nz/images/pictures/flags96/Singapore.jpg"},
//       {"id":3,"itemName":"United Kingdom", "capital":"London","image":"http://www.sciencekids.co.nz/images/pictures/flags96/United_Kingdom.jpg"},
//       {"id":4,"itemName":"Canada","capital":"Ottawa","image":"http://www.sciencekids.co.nz/images/pictures/flags96/Canada.jpg"}]
    // this.settings = {
    //   singleSelection: false,
    //   text: "Select Fields",
    //   selectAllText: 'Select All',
    //   unSelectAllText: 'UnSelect All',
    //   searchPlaceholderText: 'Search Fields',
    //   enableSearchFilter: true,
    //   badgeShowLimit: 5,
    //   groupBy: "category"
    //   //,      classes: "myclass custom-class-example"      
    // };
    // this.settings = { 
    //   text:"Select Countries",
    //   selectAllText:'Select All',
    //   unSelectAllText:'UnSelect All',
    //   enableSearchFilter: true,
    //   classes:"myclass custom-class",
    //   showCheckbox: true
    // };
    this.settings = {
      singleSelection: true,      
      text: "Select Categories",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      searchPlaceholderText: 'Search Fields',
      enableSearchFilter: true,
      badgeShowLimit: 5,      
      labelKey:"catName",
      groupBy: "categoryToShow"
    };
  }


  getALLCategories() {
    this.searchService.getALLCategories()
    .subscribe((data:categories[]) => {     
      this.allCategories = data;
      //console.log(this.allCategories);      
    });
  }  

  onItemSelect(item:any){
    //console.log(item);
    //console.log(this.selectedItems);
    const requestData = new allSearchFilters();
    
    let catName : string;
    let subCatName : string ;
    let catID: string ;
    let subCatID: string ;
    if(this.selectedItems[0].thirdParentCatName !="" && this.selectedItems[0].thirdParentCatName !=null )
    {
      catName =this.selectedItems[0].thirdParentCatName;
      catID = this.selectedItems[0].thirdParentCatiD;
    }
    else if(this.selectedItems[0].secondParentCatName !="" && this.selectedItems[0].secondParentCatName !=null)
    {
      catName =this.selectedItems[0].secondParentCatName;
      catID = this.selectedItems[0].secondParentCatId;
      
    }
    else
    {
      catName =this.selectedItems[0].firstParentCatName; 
      catID = this.selectedItems[0].istParentCatID;     
    }
    subCatName =this.selectedItems[0].catName;
    subCatID= this.selectedItems[0].catID;     

    requestData.CatID =catID;
    requestData.subCatID =subCatID;
    requestData.levelOfSearch = "2";
    requestData.searchWord = "";
    var newCatName = catName.replace(/ /g,'-');
    var newSubCatName = subCatName.replace(/ /g,'-'); 
   // //console.log(this.selectedItems);
   // this.data.CatID = this.selectedItems[0].istParentCatID.toString();
   // this.data.subCatID=this.selectedItems[0].catID.toString();
    this.operSearch.setData(requestData);
  }
  OnItemDeSelect(item:any){
    //console.log(item);
    //console.log(this.selectedItems);
    //debugger;
    
  }
  onSelectAll(items: any){
    //console.log(items);
  }
   onDeSelectAll(items: any){
    //console.log(items);
  }

  searchSend()
  {
    //debugger;
    const requestData = new allSearchFilters();
    
    let catName : string;
    let subCatName : string ;
    let catID: string ;
    let subCatID: string ;
    if(this.selectedItems[0].thirdParentCatName !="" && this.selectedItems[0].thirdParentCatName !=null )
    {
      catName =this.selectedItems[0].thirdParentCatName;
      catID = this.selectedItems[0].thirdParentCatiD;
    }
    else if(this.selectedItems[0].secondParentCatName !="" && this.selectedItems[0].secondParentCatName !=null)
    {
      catName =this.selectedItems[0].secondParentCatName;
      catID = this.selectedItems[0].secondParentCatId;
      
    }
    else
    {
      catName =this.selectedItems[0].firstParentCatName; 
      catID = this.selectedItems[0].istParentCatID;     
    }
    subCatName =this.selectedItems[0].catName;
    subCatID= this.selectedItems[0].catID;     

    requestData.CatID =catID;
    requestData.subCatID =subCatID;
    requestData.levelOfSearch = "2";
    requestData.searchWord = "";
    var newCatName = catName.replace(/ /g,'-');
    var newSubCatName = subCatName.replace(/ /g,'-'); 
   // //console.log(this.selectedItems);
   // this.data.CatID = this.selectedItems[0].istParentCatID.toString();
   // this.data.subCatID=this.selectedItems[0].catID.toString();
    this.operSearch.setData(requestData);
    this.router.navigate(['/search',newCatName,newSubCatName]);
  }

//   onSearch(evt: any) {
//     //console.log(evt.target.value);
//     this.itemList = [];
//     this.http.get('https://restcountries.eu/rest/v2/name/'+evt.target.value+'?fulltext=true')
//         .subscribe(res => {
//             //console.log(res);
//             this.itemList = res;
//         }, error => {

//         });
// }

backtoMain() {
  this.userInterface.setInterface("0");
  this.router.navigate(['/home'], { relativeTo: this.route });
}
}
