import { Component, OnInit } from '@angular/core';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { openSearchService } from 'src/app/shared/services/search/openSearch.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Route } from '@angular/compiler/src/core';
import { LocalStorageService } from 'angular-web-storage';
import { AdcompanyService } from 'src/app/shared/services/compnies/CompanyServices';
import { AuthenticationService } from 'src/app/auth/auth.service';
import { Observable } from 'rxjs';
import * as fromAuth from '../../store/reducers/auth.reducers';
import { Store } from '@ngrx/store';
import * as fromApp from '../../store/app.state';
import jwt_decode from 'jwt-decode';
import { postnotify } from 'src/app/shared/models/postnotify.model';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { notificationService } from 'src/app/shared/liveUpdate/Notification.service';
import { ActionserviceService } from 'src/app/shared/services/compnies/actionservice.service';
import { Actionbtn } from 'src/app/shared/models/Company/User-About/Action_btn';
import { adProductService } from 'src/app/shared/services/compnies/ProductServices';
import { Product } from 'src/app/product/show-product/Product';
import { adds } from 'src/app/shared/models/Adds/adds.model';

@Component({
  selector: 'app-header-inner',
  templateUrl: './header-inner.component.html',
  styleUrls: ['./header-inner.component.css']
})
export class HeaderInnerComponent implements OnInit {
  [x: string]: any;
  public scrollbarOptions = { axis: 'y', theme: 'light' };
  status: boolean = false;
  myAdds: boolean = false;
  myPro: boolean = false;
  myDeal: boolean = false;
  myCom: boolean = false;
  authState: Observable<fromAuth.State>;
  userInnerSideBarMode: string = "0"; // means normal
  ImageName: any;
  imageBase64: any;
  FrndRequests: any;
  SendFrndRequests: any;
  friendmessages: any[];
  messageslength: number;
  frndrequestlenght: any;

  clickEvent() {
    ////debugger;
    if (this.status == true) {
      this.status = false;
      this.userInterface.setInnerSideBar("1");
      //this.userInterface.currentInnerSideBarInterface = "1";  

    }
    else {
      this.status = true;
      this.userInterface.setInnerSideBar("0");
    }
    //this.status = !this.status;       
  }
  selectMyCom() {
    //debugger;
    if (this.myCom == false) {
      this.myCom = true;
    }
    else {
      this.myCom = false;
    }
  }
  selectMyAdds() {
    //debugger;
    if (this.myAdds == false) {
      this.myAdds = true;
    }
    else {
      this.myAdds = false;
    }
  }
  selectMyPro() {
    //debugger;
    if (this.myPro == false) {
      this.myPro = true;
    }
    else {
      this.myPro = false;
    }
  }
  selectMyDeal() {
    //debugger;
    if (this.myDeal == false) {
      this.myDeal = true;
    }
    else {
      this.myDeal = false;
    }
  }
  constructor(private authSer: AuthenticationService, private adcompanyService: AdcompanyService, private actions: ActionserviceService,
    private router: Router, private store: Store<fromApp.AppState>, private userInterface: openSearchService,
    private mScrollbarService: MalihuScrollbarService,private liveNotification: notificationService,public adproductService: adProductService
    , private route: ActivatedRoute, public localStor: LocalStorageService,private notifyService: NotificationService,) {
    if (this.localStor.get('currentUser')) {
    }
    else {
      this.userInterface.setInterface("0");
      this.router.navigate(['../home'], { relativeTo: this.route });
    }
  }
  userName: string;
  token: string;
  email: string;
  userId: string;
  UserImage: any;
  companyList: any;

  LoadCompanyData() {
    //debugger;
    this.adcompanyService.getCompanyDetails().subscribe(data => {
      this.companyList = data;
    });
  }
  onLogOut() {
    this.authSer.LogedOut();
    this.userInterface.setInterface("0");
    this.router.navigate(['../home'], { relativeTo: this.route });
  }
  ngOnInit(): void {

    //debugger;
    this.liveNotification.currentnotification.subscribe((adds: postnotify) => {
      //debugger;
      if(adds.userName !== null && adds.userName !== undefined){
      this.showNotifyAdd(adds);
    }
    });
    
    this.LoadCompanyData();
    this.LoadFriendRequests();
    //this.LoadSendFriendRequests();
    this.LoadMessageNotify();

    this.LoadUserData();
    this.authState = this.store.select('auth');

    this.store.select('auth').subscribe(data =>
      this.token = data.token
    )
    //debugger;
    
      const tokenInfo = this.getDecodedAccessToken(this.token); // decode token
      if (tokenInfo != null) {
      this.email = tokenInfo.email; // get token expiration dateTime
      this.userName = tokenInfo.given_name;
      this.userId = tokenInfo.nameid;
      this.ImageName = tokenInfo.unique_name;
      // //console.log(this.ImageName);
      // //console.log(this.userName);
      // //console.log(this.email);
      // //console.log(tokenInfo);

      }
      else{
        
      }
      ////debugger;
      this.userInterface.currentInnerSideBarInterface.subscribe(data => {
        //debugger;
        //console.log(data);
        if (data.toString() == "1") {
          this.status = false;
          //this.userInnerSideBarMode  = data.toString();
        }
        else if (data.toString() == "0") {
          this.status = true;
          //this.userInnerSideBarMode  = '0';
        }
      });

      if (this.localStor.get('currentUser')) {
      }
      else {
        this.userInterface.setInterface("0");
        this.router.navigate(['../home'], { relativeTo: this.route });
      }
    }  
    LoadFriendRequests(){
      this.actions.GetFrndsRequest().subscribe(data => {
        this.FrndRequests = data;
        ////console.log(this.FrndRequests)
      });
    }
    LoadSendFriendRequests(){
      this.actions.GetSENDFrndsRequest().subscribe(data => {
        this.SendFrndRequests = data;
        if(data.length == 0){
          this.SendFrndRequests = 1;
        }else{
          this.SendFrndRequests =data.length;
        }
      });
    }

    AcceptReq(frndID){
      // //debugger;
       const data = new Actionbtn();
       data.mode = "Accept"
       data.userID = frndID;
       this.actions.AcceptFriendRequest(data).subscribe(data => {
         this.LoadFriendRequests();
         this.notifyService.showSuccess('Baravo !!', 'Accept Successfully');
       });     
     }
     RejectReq(frndID){
       var result = confirm("want to Reject ??")
       if (result) {
       const data = new Actionbtn();
       data.mode = "Reject"
       data.userID = frndID;
       this.actions.AcceptFriendRequest(data).subscribe(data => {
         this.LoadFriendRequests();
         this.notifyService.showSuccess('Baravo !!', 'Reject Successfully');
       });        
       }
     }

    LoadMessageNotify(){
      var userProfileID = 0;
      this.actions.From_FriendMessages(userProfileID).subscribe((result) => {
        this.friendmessages = result;
        this.messageslength = result.length;
        // this.todayWithPipe = this.pipe.transform(Date.now(), 'dd/MM/yyyy');
      });
    }

    showNotifyAdd(adds: postnotify) {
      //debugger;
      this.notifyService.showSuccessWithHtml(
        '<div style="width:100%; float:left;">' +
          '<div> ' +
            '<a href="/' + adds.URL + '" target="_blank"><img src="' + adds.userImage + '"  class="img-fluid circular_image" style="width: 15px;height: 15px;border-radius: 50%;"  alt="..."></a>' +
            //'<span> Ali Ahamd </span>'+
          '</div>' +
          '<div style="width:75%">' +
            '<div style="width:100%; float:left;">' +
            '<div style="width:50%"><span>' + adds.userName + '</span></div>' +
            '<div style="width:50%"> <span>' + adds.datetime + '</span></div>' +
          '</div>' +
          '<div style="width:100%; float:left;"><span>' + adds.addTitle + '</span></div>' +
        ' </div>', '')
  
    }
  goUserNewPost() {
    this.userInterface.setInterface("1");
    this.router.navigate(['../user/adsposting'], { relativeTo: this.route });
  }

  refreshComponent() {
    this.router.navigate(['../userPosting'], { relativeTo: this.route });
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }
  LoadUserData() {
    //debugger;
    this.adcompanyService.getProfileDetails().subscribe(data => {
      this.UserImage = data;
      this.imageBase64 = data.imageBase64;
      ////console.log(this.imageBase64);
      //console.log(this.UserImage);
    });
  }
  backtoMain() {
    this.userInterface.setInterface("0");
    this.router.navigate(['../home'], { relativeTo: this.route });
  }

  myuserview(){
    //debugger;
    var userProfileID = this.userId;
    this.router.navigate(['/userprofile/detail',userProfileID], { relativeTo: this.route });
  }

  removeCartProduct(product: Product) {
    this.adproductService.removeLocalCartProduct(product);
  }
  goUserCart() {
    this.userInterface.setInterface("1");
    this.router.navigate(['../usercart'], { relativeTo: this.route });
  }

  goUserCartFavorite() {
    this.userInterface.setInterface("1");
    this.router.navigate(['../user/favorite/products'], { relativeTo: this.route });
  }

  removeLocalAds(items:adds){
    this.adproductService.removeLocalAds(items);
  }
}

