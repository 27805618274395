import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { SigninComponent } from './auth/signin/signin.component';
import { SignupComponent } from './auth/signup/signup.component';
import { HomeComponent } from './core/home/home.component';
import { ChatBotComponent } from './chat-bot/chat-bot.component';
import { BooksComponent } from './books/books.component';
import { AddPostingComponent } from './add-posting/add-posting.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { AddDetailsComponent } from './add-details/add-details.component';
import { AddListingComponent } from './add-listing/add-listing.component';
import { UserHomeComponent } from './core/user-home/user-home.component';
import { UserNewPostComponent } from './user-new-post/user-new-post.component';
import {UserCompanyComponent} from './user-company/user-company.component';
import {UserProductComponent} from './user-product/user-product.component';
import {UserNewCompanyComponent} from './user-new-company/user-new-company.component';
import {ShowProductComponent} from './product/show-product/show-product.component';
import {ProductComponent} from './product/product.component';
import {AddEditProductComponent} from './product/add-edit-product/add-edit-product.component';
import {AdsComponent} from './ads/ads.component';
import {AddEditPostComponent} from './ads/add-edit-post/add-edit-post.component';
import { ComDetailsComponent } from './pro-details/com-details.component';
import { UserDealComponent } from './user-deal/user-deal.component';
import { DealComponent } from './deal/deal.component';
import { AddEditDealComponent } from './deal/add-edit-deal/add-edit-deal.component';
import { DealDetailsComponent } from './deal-details/deal-details.component';
import { ContectUsComponent } from './contect-us/contect-us.component';
import { UserValidationComponent } from './user-validation/user-validation.component';
import { UpdateProfileComponent } from './update-profile/update-profile.component';
import { UserProfileDetailComponent } from './user-profile-detail/user-profile-detail.component';
import { UserProfileAboutComponent } from './user-profile-about/user-profile-about.component';
import { UserFriendComponent } from './user-friend/user-friend.component';
import { UserChatComponent } from './user-chat/user-chat.component';
import { AddToCartComponent } from './add-to-cart/add-to-cart.component';
import { TestHomeComponent } from './home/home.component';
import { ShoppingDetailsComponent } from './shopping-details/shopping-details.component';
import { AddToFavoriteComponent } from './add-to-favorite/add-to-favorite.component';
import { PaymentComponent } from './payment/payment.component';
import { RecepitComponent } from './recepit/recepit.component';

const routes: Routes = [
  
  {path: '',component: HomeComponent},
  {path: 'home',component: HomeComponent},
  {path: 'usercompany',component: UserCompanyComponent},
  {path: 'userprofile/friend',component: UserFriendComponent},
  {path: 'userprofile',component: UpdateProfileComponent},
  // ADD-TO-CART WITH FAVORITE
  {path: 'usercart',component: AddToCartComponent},
  {path: 'usershopping',component: ShoppingDetailsComponent},
  {path: 'user/favorite/products',component: AddToFavoriteComponent},
  {path: 'user/payment',component: PaymentComponent},
  {path: 'user/recepit',component: RecepitComponent},
  // 
  {path: 'userprofile/detail',component: UserProfileDetailComponent},
  {path: 'userprofile/detail/:userProfileID',component: UserProfileDetailComponent},
  {path: 'userprofile/about',component: UserProfileAboutComponent},
  {path: 'verify/:userID/:validationKey',component: UserValidationComponent},
  {path: 'user/productlist',component: ProductComponent},
  {path: 'user/edit/:proID',component: AddEditProductComponent},
  {path: 'user/addproduct',component: UserProductComponent},
  {path: 'user/adddeal',component: UserDealComponent},
  {path: 'user/deallist',component: DealComponent},
  {path: 'user/edit/deal/:dealID',component: AddEditDealComponent},
  {path: 'user/adcompany',component: UserNewCompanyComponent},
  {path: 'user/adslist',component: AdsComponent},
  {path: 'useredit/:cat/:addID',component: AddEditPostComponent},
  {path: 'aboutUs',component: AboutUsComponent},
  {path: 'contactUs',component: ContectUsComponent},
  {path: 'books',component: BooksComponent},
  {path: 'chat',component: ChatBotComponent},
  {path: 'userchat',component: UserChatComponent},
  {path: 'adPosting',component: AddPostingComponent},
  {path: 'user/adsposting',component: UserNewPostComponent},
  {path: 'userPostingTest',component: UserNewPostComponent},
  {path: 'search/:category/:subCategory',component: AddListingComponent},  
  {path: 'login',component: SigninComponent},
  {path: 'userHome',component: UserHomeComponent},  
  {path: 'signup',component: SignupComponent},
  {path: 'details/:category/:id',component: AddDetailsComponent},
  {path: 'product/details/:proID',component: ComDetailsComponent},
  {path: 'deal/details/:dealID',component: DealDetailsComponent},
  {path: 'notfound',component: NotFoundComponent},
  //{path: 'update/:proID',component: AddEditProductComponent},
  //{path: 'showproduct',component: ShowProductComponent},
  {path:'test',component:TestHomeComponent},
  {path: '**',redirectTo: '/404'}

];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes),BrowserModule,CommonModule,],
  exports: [RouterModule]
})
export class AppRoutingModule { }
