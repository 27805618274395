<style>

</style>
<div class="clearfix"></div>
<footer id="tg-footer" class="tg-footer tg-haslayout">
    <div class="tg-footerbar">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-push-1 col-lg-10">
                    <div class="tg-newsletter">
                        <!-- <span class="caret"></span> -->
                        <fa-icon [icon]="faBullhorn" style="top: 15px;
                        right: 100%;
                        color: #fff;
                        height: 100%;
                        opacity: 0.60;                        
                        font-size: 54px;
                        line-height: 100px;
                        position: absolute;
                        font-family: 'icomoon';
                        -webkit-transform: rotate(-50deg);
                        -ms-transform: rotate(-50deg);
                        transform: rotate(-50deg);"></fa-icon>
                        <h2>Signup For Newsletter:</h2>
                        <div class="tg-formtheme tg-formnewsletter">
                            <fieldset>
                                <i class="icon-envelope"></i>
                                <input type="email" name="email" class="form-control" placeholder="Enter your email here">
                                <button type="button" (click)="shownotify()">Signup Now</button>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="clearfix"></div>
    <div class="container">
        <div class="row">
            <div class="tg-footerinfo">
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 pull-right">
                    <div class="tg-widget tg-widgetsearchbylocations">
                        <div class="tg-widgettitle">
                            <h3>Search By Locations:</h3>
                        </div>
                        <div class="tg-widgetcontent">
                            <ul *ngFor="let items of citySelection | paginate: { itemsPerPage: 23, currentPage: page,totalItems:TotalLength }">
                                <li><a href="javascript:void(0);">- {{items.name}}</a></li>
                                <!-- <li><a href="javascript:void(0);">-  Armagh</a></li>
                                <li><a href="javascript:void(0);">-  Bangor</a></li>
                                <li><a href="javascript:void(0);">-  Bath</a></li>
                                <li><a href="javascript:void(0);">-  Belfast</a></li>
                                <li><a href="javascript:void(0);">-  Birmingham</a></li>
                                <li><a href="javascript:void(0);">-  Bradford</a></li>
                                <li><a href="javascript:void(0);">-  Brighton and Hove</a></li>
                                <li><a href="javascript:void(0);">-  Bristol</a></li>
                                <li><a href="javascript:void(0);">-  Cambridge</a></li> -->
                            </ul>
                            <ul><li><a href="javascript:void(0);" style="color: #55acee;">View All</a></li></ul>
                            <!-- <ul>
                                <li><a href="javascript:void(0);">-  Canterbury</a></li>
                                <li><a href="javascript:void(0);">-  Cardiff</a></li>
                                <li><a href="javascript:void(0);">-  Carlisle</a></li>
                                <li><a href="javascript:void(0);">-  Chester</a></li>
                                <li><a href="javascript:void(0);">-  Chichester</a></li>
                                <li><a href="javascript:void(0);">-  City of London</a></li>
                                <li><a href="javascript:void(0);">-  Coventry</a></li>
                                <li><a href="javascript:void(0);">-  Derby</a></li>
                                <li><a href="javascript:void(0);">-  Dundee</a></li>
                                <li><a href="javascript:void(0);">View All</a></li>
                            </ul> -->
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                    <div class="tg-widget tg-widgettext">
                        <div class="tg-widgetcontent">
                            <strong class="tg-logo"><a href="javascript:void(0);"><img src="assets/images/wihoutborder.png" width="100px" alt="image description"></a></strong>
                            <div class="tg-description">
                                <p>Select from the Largest Online Marketplace in Dubai
                                    With over 15 million products to select from, MyKnocks offers its customers the most comprehensive listing of products in the country. Whether you’re looking for electronics, apparel, appliances, or groceries – there is something for everyone.</p>
                            </div>
                            <div class="tg-followus">
                                <strong>Follow Us:</strong>
                                <ul class="tg-socialicons">
                                    <li class="tg-facebook"><a href="https://www.facebook.com/MyKnocks/?ref=aymt_homepage_panel" target="_blank"><i class="fa fa-facebook"></i></a></li>
                                    <li class="tg-instagram"><a href="https://www.instagram.com/myknocks2015/" target="_blank"><i class="fa fa-instagram"></i></a></li>
                                    <li class="tg-twitter"><a href="https://twitter.com/myknocksdeals" target="_blank"><i class="fa fa-twitter"></i></a></li>
                                   <li class="tg-linkedin"><a href="https://www.linkedin.com/in/my-knocks-922077105/" target="_blank"><i class="fa fa-linkedin"></i></a></li>
                                    <!-- <li class="tg-googleplus"><a href="javascript:void(0);"><i class="fa fa-google-plus" target="_blank"></i></a></li>
                                    <li class="tg-rss"><a href="javascript:void(0);"><i class="fa fa-rss" target="_blank"></i></a></li> -->
                                </ul>
                                <ul class="tg-appsnav">
                                    <li>
                                        <a href="javascript:void(0);"><img src="assets/images/apps-01.png" alt="image description"></a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);"><img src="assets/images/apps-02.png" alt="image description"></a>
                                    </li>
                                </ul>
                            </div>
                            <nav class="tg-footernav">
                                <ul>
                                    <!-- <li><a href="javascript:void(0);">Listing Policy</a></li> -->
                                    <li><a routerLink ="/contactUs">Terms of Use</a></li>
                                    <li><a routerLink ="/contactUs">Privacy Policy</a></li>
                                    <li><a routerLink ="/contactUs">Mobiles Policy</a></li>
                                    <li><a routerLink ="/contactUs">Terms & Conditions</a></li>
                                    <li><a routerLink ="/contactUs">Privacy Agreement</a></li>
                                    <li><a routerLink ="/contactUs">Contact Us</a></li>
                                </ul>
                            </nav>
                            <span class="tg-copyright">2022 All Rights Reserved © MyKnocks</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<!--************************************
			Theme Modal Box Start
	*************************************-->
<div id="modalselectcurrency" class="modal fade tg-thememodal tg-modalselectcurrency" style="z-index: 1000000!important;" tabindex="-1" role="dialog">
    <div class="modal-dialog tg-thememodaldialog" role="document">
        <button type="button" class="tg-close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
        <div class="modal-content tg-thememodalcontent">
            <div class="tg-title">
                <strong>Change Country</strong>
            </div>
            <div class="tg-formtheme tg-formselectcurency">
                <fieldset>
                    <select style="width: 100%;margin-bottom: 15px;" [(ngModel)]="selectedCountry" (change)="getCities()">                        
                        <option value="0" selected="selected">All</option>
                            <option value="1">United Arab Emirates</option>
                            <option value="2">Pakistan</option>
                            <option value="3">Saudi Arabia</option>
                            <option value="4">KUWAIT</option>
                            <option value="5">Oman</option>
                            <option value="7">india</option>
                            <option value="8">United Kingdom</option>
                            <option value="9">United States</option>
                            <option value="10">China</option>
                            <option value="11">Japan</option>
                            <option value="12">Canada</option>                    
                    </select>

                    <select *ngIf="this.citySelection.length > 0" style="width: 100%;margin-bottom: 15px;" [(ngModel)]="selectedCity">
                        <option value="0">All Cities</option>
                        <option *ngFor="let subCat of citySelection" value={{subCat.id}}>{{subCat.name}}</option>
                    </select>

                    <div class="form-group">
                        <button class="tg-btn" style="margin-bottom: 15px;" data-dismiss="modal" (click)="setLocation()" type=" button">Change Now</button>
                        <button class="tg-btn" style="margin-bottom: 15px;" data-dismiss="modal" (click)="clearLocation()" type=" button">Clear Selection</button>
                    </div>
                </fieldset>
            </div>
        </div>
    </div>
</div>
<div id="tg-modalpriceconverter " class="modal fade tg-thememodal tg-modalpriceconverter " style="z-index: 1000000!important; " tabindex="-1 " role="dialog ">
    <div class="modal-dialog tg-thememodaldialog " role="document ">
        <button type="button " class="tg-close " data-dismiss="modal " aria-label="Close "><span aria-hidden="true ">×</span></button>
        <div class="modal-content tg-thememodalcontent ">
            <div class="tg-title ">
                <strong>Currency Converter</strong>
            </div>
            <div class="tg-formtheme tg-formcurencyconverter ">
                <fieldset>
                    <div class="form-group ">
                        <div id="tg-flagstraptwo " class="tg-flagstrap " data-input-name="country "></div>
                        <div class="tg-curencyrateetc ">
                            <span>120<sup>$</sup></span>
                            <p>1 USD = 0.784769 GBP</p>
                        </div>
                    </div>
                    <div class="form-group ">
                        <span class="tg-iconseprator "><i><img src="assets/images/icons/img-36.png " alt="image description "></i></span>
                    </div>
                    <div class="form-group ">
                        <div id="tg-flagstrapthree " class="tg-flagstrap " data-input-name="country "></div>
                        <div class="tg-curencyrateetc ">
                            <span>94.1723<sup>£</sup></span>
                            <p>1 GBP = 1.27426 USD</p>
                        </div>
                    </div>
                    <div class="form-group ">
                        <span class="tg-lastupdate ">Last update on <time datetime="2017-08-08 ">2017-06-12 12:35 local time</time></span>
                    </div>
                    <div class="form-group ">
                        <button class="tg-btn " type="button ">Convert Now</button>
                    </div>
                </fieldset>
            </div>
        </div>
    </div>
</div>
<!--************************************
                Theme Modal Box End
        *************************************-->

<!-- Offline bot instance -->

<!-- <script>
    $(function() {
        $("#addClass ").click(function() {
            $('#qnimate').addClass('popup-box-on');
        });

        $("#removeClass ").click(function() {
            $('#qnimate').removeClass('popup-box-on');
        });
    })
</script>
<div class="container text-center ">
    <p>footer works!</p>
    <div class="row ">
        <h4>Click Here</h4>
        <div class="round hollow text-center ">
            <a href="# " id="addClass "><span class="glyphicon glyphicon-comment "></span> Open in chat </a>
        </div>
    </div>
</div>
<div class="popup-box chat-popup popup-box-on " id="qnimate ">
    <div class="popup-head ">
        <div class="popup-head-left pull-left "><img src="http://bootsnipp.com/img/avatars/bcf1c0d13e5500875fdd5a7e8ad9752ee16e7462.jpg " alt="iamgurdeeposahan "> Gurdeep Osahan</div>
        <div class="popup-head-right pull-right ">
            <div class="btn-group ">
                <button class="chat-header-button " data-toggle="dropdown " type="button " aria-expanded="false ">
                             <i class="glyphicon glyphicon-cog "></i> </button>
                <ul role="menu " class="dropdown-menu pull-right ">
                    <li><a href="# ">Media</a></li>
                    <li><a href="# ">Block</a></li>
                    <li><a href="# ">Clear Chat</a></li>
                    <li><a href="# ">Email Chat</a></li>
                </ul>
            </div>

            <button data-widget="remove " id="removeClass " class="chat-header-button pull-right " type="button "><i class="glyphicon glyphicon-off "></i></button>
        </div>
    </div>
    <div class="popup-messages ">




        <div class="direct-chat-messages ">


            <div class="chat-box-single-line ">
                <abbr class="timestamp ">October 8th, 2015</abbr>
            </div>            
            <div class="direct-chat-msg doted-border ">
                <div class="direct-chat-info clearfix ">
                    <span class="direct-chat-name pull-left ">Osahan</span>
                </div>
                
                <img alt="message user image " src="http://bootsnipp.com/img/avatars/bcf1c0d13e5500875fdd5a7e8ad9752ee16e7462.jpg " class="direct-chat-img ">
                
                <div class="direct-chat-text ">
                    Hey bro, how’s everything going ?
                </div>
                <div class="direct-chat-info clearfix ">
                    <span class="direct-chat-timestamp pull-right ">3.36 PM</span>
                </div>
                <div class="direct-chat-info clearfix ">
                    <span class="direct-chat-img-reply-small pull-left ">
                  
              </span>
                    <span class="direct-chat-reply-name ">Singh</span>
                </div>
                
            </div>            

            <div class="chat-box-single-line ">
                <abbr class="timestamp ">October 9th, 2015</abbr>
            </div>            
            <div class="direct-chat-msg doted-border ">
                <div class="direct-chat-info clearfix ">
                    <span class="direct-chat-name pull-left ">Osahan</span>
                </div>
                
                <img alt="iamgurdeeposahan " src="http://bootsnipp.com/img/avatars/bcf1c0d13e5500875fdd5a7e8ad9752ee16e7462.jpg " class="direct-chat-img ">
                
                <div class="direct-chat-text ">
                    Hey bro, how’s everything going ?
                </div>
                <div class="direct-chat-info clearfix ">
                    <span class="direct-chat-timestamp pull-right ">3.36 PM</span>
                </div>
                <div class="direct-chat-info clearfix ">
                    <img alt="iamgurdeeposahan " src="http://bootsnipp.com/img/avatars/bcf1c0d13e5500875fdd5a7e8ad9752ee16e7462.jpg " class="direct-chat-img big-round ">
                    <span class="direct-chat-reply-name ">Singh</span>
                </div>
                
            </div>            
        </div>
    </div>
    <div class="popup-messages-footer ">
        <textarea id="status_message " placeholder="Type a message... " rows="10 " cols="40 " name="message "></textarea>
        <div class="btn-footer ">
            <button class="bg_none "><i class="glyphicon glyphicon-film "></i> </button>
            <button class="bg_none "><i class="glyphicon glyphicon-camera "></i> </button>
            <button class="bg_none "><i class="glyphicon glyphicon-paperclip "></i> </button>
            <button class="bg_none pull-right "><i class="glyphicon glyphicon-thumbs-up "></i> </button>
        </div>
    </div>
</div> -->