import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../auth.service';
import { Store } from '@ngrx/store';
import * as fromApp from '../../store/app.state';
import * as authActions from '../../store/actions/auth.actions';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { LocalStorageService } from 'angular-web-storage';
import { AdcompanyService } from 'src/app/shared/services/compnies/CompanyServices';
import { RregisterDTO } from './Register';
import { RegisterserviceService } from 'src/app/shared/registerservice.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { openSearchService } from 'src/app/shared/services/search/openSearch.service';
import { NgOption } from '@ng-select/ng-select';

;
//import { fas } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {
  token: string;
  sendemail:string;
  faFacebook = faFacebook;
  faGoogle = faGoogle;
  faLinkedin = faLinkedin;
  faTwitter = faTwitter;
  faEnvelope = faEnvelope;
  faLock = faLock;
  logInMessage: string;
  UserImage: any;
  userName: "";
  email: "";
  Country;
  phoneNumber: "";
  userPassword: "";
  retypePassword: "";
  NameAlert=false;

  constructor(private adcompanyService: AdcompanyService, private authSer: AuthenticationService, public localStor: LocalStorageService,
    private router: Router, private userInterface: openSearchService, private route: ActivatedRoute, private notifyService: NotificationService, private registerservice: RegisterserviceService
    , private store: Store<fromApp.AppState>) { }

  ngOnInit() {
    //this.logInMessage = "";
    this.store.select('auth').subscribe(data =>
      this.logInMessage = data.errorMessage
    )
  }
  onSignIn(form: NgForm) {
    debugger;
    const email = form.value.email;
    const pass = form.value.pass;
    this.store.dispatch(new authActions.trySignIn({ username: email, password: pass }));

    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
    
   // window.location.href = 'home';
    //this.router.navigate(['../header'], { relativeTo: this.route });
  //   debugger
  //   this.router.navigateByUrl('/header', { skipLocationChange: true }).then(() => {
  //     this.router.navigate(['/login']);
  // }); 

  }

  onSubmit(form: NgForm) {
    debugger;
    var result = this.validateForm();
    //if (result === true) {
      if (this.userName != undefined && this.email != undefined && this.phoneNumber != undefined && this.userPassword != undefined && this.retypePassword != undefined && this.Country != undefined) {
        if (this.userPassword == this.retypePassword) {
          const reg = new RregisterDTO();
          reg.userName = this.userName;
          reg.PrimaryEmail = this.email;
          reg.Country = this.Country;
          reg.MobileNumber = this.phoneNumber;
          reg.UserPassword = this.userPassword;
          reg.ConfirmPassword = this.retypePassword;
          this.registerservice.submitRegistration(reg).subscribe(data => {
            if (data != 0) {
              this.notifyService.showSuccess('Successfully Register !!', 'Wellcome');
              this.backtoMain();
            }
            else {
              this.notifyService.showError('Please Provide new Email', 'Somting Wrong');
            }
          });      
        }
        else {
          this.notifyService.showError('Provide Same Password !', 'Missing Data');
        }
      }
      else {
        this.notifyService.showError('Provide Complete Information!', 'Missing Data');
      }
    // }else {
    //   this.notifyService.showError('Provide Complete Information!', 'Missing Data');
    // }
  }

  onEmailSubmit() {
    this.registerservice.submitEmail(this.sendemail).subscribe((result) => {
      if (result != 0 && result != null) {
        this.sendemail = '';
        this.notifyService.showSuccess('Password send on your Email !!', 'Successfully');
      } else { this.notifyService.showError('Please provide true Email', 'Email is Worng'); }
    });
    ////console.log(this.sendemail)
  }

  validateForm() {
    //debugger;
    var result = true;
    if (typeof this.userName == 'undefined' || !this.userName || this.userName == "0") {
      this.NameAlert = true;
      result = false;
    }
    else{
      this.NameAlert = false;
      result = false;
    }
    return result;
  }

  backtoMain() {
    this.userInterface.setInterface("0");
    this.router.navigate(['/home'], { relativeTo: this.route });
  }

  CountryList: NgOption[] = [
    { id: 0, title: "Select Country" },
    { id: 1, title: "United Arab" },
    { id: 2, title: "Pakistan" },
    { id: 3, title: "Saudi Arabia" },
    { id: 4, title: "KUWAIT" },
    { id: 5, title: "Oman" },
    { id: 7, title: "india" },
    { id: 8, title: "United Kingdom" },
    { id: 9, title: "United States" },
    { id: 10, title: "China" },
    { id: 11, title: "Japan" },
    { id: 12, title: "Canada" }
  ]
}
