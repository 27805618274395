<app-search></app-search>
<div class="tg-breadcrumbarea ">
    <div class="container ">
        <div class="row ">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
                <ol class="tg-breadcrumb ">
                    <li><a (click)="backtoMain()">Home</a></li>
                    <li class="tg-active">Product <i class="fa fa-angle-right" aria-hidden="true"></i>  Details</li>
                </ol>
            </div>
        </div>
    </div>
</div>
<main id="tg-main" class="tg-main tg-haslayout" style="background: #fcfcfc;">
    <div class="container">
        <div class="row">
            <div id="tg-twocolumns" class="tg-twocolumns">
                <div class="col-xs-12 col-sm-5 col-md-4 col-lg-4">
                    <aside id="tg-sidebar" class="tg-sidebar">
                        <div class="tg-pricebox">
                            <div id="tg-flagstrapfour" class="tg-flagstrap" data-input-name="country"></div>
                            <div class="tg-priceandlastupdate">
                                <span>{{productDetails[0].proPrice}}<b>:</b>{{productDetails[0].proPriceUnitName}} <span *ngIf="productDetails[0].proDiscount != 0 && productDetails[0].discountCheck == true" style="float: right;color: white;font-size: medium;">discount: {{productDetails[0].proDiscount}}%</span></span>
                                <span>Last Updated: {{productDetails[0].proAddedDate}}</span>
                            </div>
                        </div>
                        <div class="tg-sellercontactdetail">
                            <div class="tg-sellertitle">
                                <h1>Seller Contact Detail</h1>
                            </div>
                            <div class="tg-sellercontact">
                                <div class="tg-memberinfobox">
                                    <figure>
                                        <a href="javascript:void(0);">
                                            <img  *ngIf="productDetails[0].imageBase64 != null" [src]="productDetails[0].imageBase64" alt="image description" style="    width: 70px;height: 70px;">
                                            <img *ngIf="productDetails[0].imageBase64 == null || productDetails[0].imageBase64 == 0" src="../../assets/images/ads/dummyimage.png" alt="image description" style="    width: 70px;height: 70px;">
                                            <!-- <img src="{{productDetails[0].userImageName}}"
                                                alt="image description" style="    width: 70px;height: 70px;"> -->
                                            </a>
                                    </figure>
                                    <div class="tg-memberinfo">
                                        <h3><a (click)="ProfileView(productDetails[0].proUserID)">{{productDetails[0].proUserName}}</a></h3>
                                        <span>Member Since {{productDetails[0].memberSince}}</span>
                                        <a class="tg-btnseeallads" href="javascript:void(0);">See All Ads</a>
                                    </div>
                                </div>
                                <a class="tg-btnphone" href="javascript:void(0);">
                                    <i class="fa fa-phone"></i>
                                    <span data-toggle="tooltip" data-placement="top" title="Show Phone No."
                                        data-last="123456">
                                        <em (click)="showPhoneNumber()" *ngIf="showPhone == false">Show Phone No.</em>
                                        <em *ngIf="showPhone == true && productDetails[0].phone != null">{{productDetails[0].phone}}</em>
                                        <em *ngIf="showPhone == true && productDetails[0].phone == null">Hidden ***********</em>
                                        <span (click)="showPhoneNumber()">Click To Show Number</span>
                                    </span>
                                </a>
                                <a class="tg-btnmakeanoffer" href="#" data-toggle="modal"
                                    data-target="#tg-modalmakeanoffer">
                                    <i class="fa fa-briefcase"></i>
                                    <span>
                                        <em>Make An Offer</em>
                                        <span>Place Your Comments to offer</span>
                                    </span>
                                </a>
                                <span class="tg-like tg-liked"><i class="fa fa-heart">Add To Favourite</i></span>
                            </div>
                            <div class="tg-sellerlocation">

                                <div id="tg-locationmap" class="tg-locationmap">
                                    <google-map height="100%" width="100%"> </google-map>
                                </div>
                            </div>
                        </div>
                        <div class="tg-reportthisadbox">
                            <div class="tg-reportthisadtitle">
                                <h2>Report This Ad</h2>
                            </div>
                            <div class="tg-formtheme tg-formreportthisad">
                                <h3>Select Reason:</h3>
                                <fieldset>
                                    <div class="tg-radio">
                                        <input id="tg-radioone" type="radio" name="repotadd"
                                            value="This is illegal/fraudulent" checked>
                                        <label for="tg-radioone">This is illegal/fraudulent</label>
                                    </div>
                                    <div class="tg-radio">
                                        <input id="tg-radiotwo" type="radio" name="repotadd" value="This ad is spam">
                                        <label for="tg-radiotwo">This ad is spam</label>
                                    </div>
                                    <div class="tg-radio">
                                        <input id="tg-radiothree" type="radio" name="repotadd"
                                            value="This ad is a duplicate">
                                        <label for="tg-radiothree">This ad is a duplicate</label>
                                    </div>
                                    <div class="tg-radio">
                                        <input id="tg-radiofour" type="radio" name="repotadd"
                                            value="This ad is in the wrong category">
                                        <label for="tg-radiofour">This ad is in the wrong category</label>
                                    </div>
                                    <div class="tg-radio">
                                        <input id="tg-radiofive" type="radio" name="repotadd"
                                            value="The ad goes against posting rules">
                                        <label for="tg-radiofive">The ad goes against <span
                                                class="tg-themecolor">posting rules</span></label>
                                    </div>
                                    <div class="form-group tg-inputwithicon">
                                        <i class="icon-bubble"></i>
                                        <textarea class="form-control"
                                            placeholder="Provide More Information"></textarea>
                                    </div>
                                    <div class="tg-btns">
                                        <button class="tg-btn" type="button">Send Report</button>
                                        <button class="tg-btn" type="button">Cancel</button>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </aside>
                </div>
                <div class="col-xs-12 col-sm-7 col-md-8 col-lg-8">
                    <div id="tg-content" class="tg-content">
                        <div class="tg-ad tg-verifiedad tg-detail tg-addetail" style="background: #fcfcfc;">
                            <div class="tg-adcontent">
                                <ul class="tg-pagesequence">
                                    Product <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                    {{productDetails[0].subCat}}
                                    <!-- <li><a href="javascript:void(0);">{{productDetails[0].cat}}</a> > </li>
                                    <li><a href="javascript:void(0);">{{productDetails[0].subCat}}</a></li> -->
                                    <span style="float: right;" *ngIf="productDetails[0].shipping == 1"> <a (click)="addToCart(proDetails)">Add to cart <i class="fa fa-shopping-cart" aria-hidden="true"></i></a></span>
                                </ul>
                                <div class="tg-adtitle">
                                    <h2>{{productDetails[0].proName}}</h2>
                                </div>
                                <ul class="tg-admetadata">
                                    <li>Comapny Name: <a href="javascript:void(0);" style="background-color: #00cc67;padding: 5px; border-radius: 10px;color: white;margin-top:-9px; text-decoration :none">
                                        {{productDetails[0].company}}</a></li>
                                    <!-- <li>Ad Id: <a href="javascript:void(0);">248GCa57</a></li> -->
                                    <li><i class="fa fa-globe">
                                            <!-- <fa-icon [icon]="faGlobe " size="1x"></fa-icon> -->
                                        </i>
                                        <address>{{productDetails[0].country}}<b> / </b>{{productDetails[0].city}}
                                        </address>
                                    </li>
                                    <!-- [disabled]="(authState | async).athenticated === true" -->

                                    <i class="icon-thumbs-up" style="padding: 5px;">
                                        <button [disabled]="(authState | async).athenticated === false"
                                            (click)="likeDislikePost(1)" class="backTransparent">
                                            <ejs-tooltip id="tooltip" content="Like the post">
                                                <fa-icon [icon]="faThumbsUp" size="1x" [styles]="{'color': '#00cc67'}"
                                                    class="likeButton"></fa-icon>
                                            </ejs-tooltip>
                                        </button>
                                    </i>
                                    <!-- {{(addLikes !=null)?addLikes:'0'}} -->
                                    <span>{{addLikes}}</span>

                                    <i class="icon-thumbs-down" style="padding: 5px;">
                                        <button [disabled]="(authState | async).athenticated === false"
                                            (click)="likeDislikePost(2)" class="backTransparent">
                                            <ejs-tooltip id="tooltip1" content="Dislike the post">
                                                <fa-icon [icon]="faThumbsDown " size="1x"
                                                    [styles]="{'color': '#00cc67'}" class="likeButton"></fa-icon>
                                            </ejs-tooltip>
                                        </button>

                                    </i>

                                    <span>{{addDisLikes}}</span>
                                    <li><i class="icon-eye">
                                            <fa-icon [icon]="faEye " size="1x"></fa-icon>
                                        </i><span>{{productDetails[0].viewCount}}</span></li>
                                </ul>

                                <!-- <ul>
                                    <li *ngFor="let link of shareLinks">
                                        <a id="sharebtns" [href]="link?.link" target="_blank" rel="noreferrer">
                                            {{ link?.title }}
                                        </a>
                                    </li>
                                </ul> -->

                                <div class="tg-share">
                                    <!-- <share-button [theme]="'modern-dark'" [button]="'facebook'" [url]="'https://twitter.com/'" [autoSetMeta]="false"></share-button> -->
                                    <!-- <button mat-fab shareButton="facebook" [style.backgroundColor]="share.prop.facebook.color">
                                        <fa-icon [icon]="share.prop.facebook.icon" size="lg"></fa-icon>
                                      </button> -->
                                    <strong>share:</strong>
                                    <ul class="tg-socialicons">
                                        <li class="tg-facebook" *ngFor="let link of Facebook">
                                            <a [href]="link?.link" target="_blank"><i>
                                                    <fa-icon [icon]="faFacebook " size="1x"></fa-icon>
                                                </i></a></li>
                                        <li class="tg-twitter"  *ngFor="let link of Twitter">
                                            <a [href]="link?.link"  target="_blank"><i>
                                                    <fa-icon [icon]="faTwitter " size="1x"></fa-icon>
                                                </i></a></li>
                                        <li class="tg-pinterestp" *ngFor="let link of Pinterest">
                                            <a  [href]="link?.link"  target="_blank"><i>
                                                    <fa-icon [icon]="faPinterest " size="1x"></fa-icon>
                                                </i></a></li>
                                        <li class="tg-whatsapp" *ngFor="let link of Whatsapp">
                                            <a  [href]="link?.link"  target="_blank"><i>
                                                    <fa-icon [icon]="faWhatsapp " size="1x"></fa-icon>
                                                </i></a></li>
                                        <!-- <li class="tg-rss"><a href="javascript:void(0);"><i class="fa fa-rss">
                                                </i></a></li> -->
                                    </ul>
                                    <div class="tg-adadded">
                                        <i style="margin-right: 5px;">
                                            <fa-icon [icon]="faMobile " size="1x"></fa-icon>
                                        </i>
                                        <span>Added via PC on {{productDetails[0].datePosted}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row" id="divClass" style="margin-bottom: 15px;background: #fcfcfc;">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="float: left;">
                                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3" style="float: left;">
                                        <div class="col-md-8" style="float: left;">
                                            <h5>BrandName:</h5>
                                        </div>
                                        <div class="col-md-4" style="float: left;">
                                            <i class="fa fa-check"></i>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4" style="float: left;">
                                        <div class="col-md-12" style="float: left;">
                                            <h6>{{(productDetails[0].proBrandName !=null)?productDetails[0].proBrandName:'N/A'}}</h6>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2" style="float: left;">
                                        <div class="col-md-8" style="float: left;">
                                            <h5>Quantity:</h5>
                                        </div>
                                        <div class="col-md-4" style="float: left;">
                                            <i class="fa fa-check"></i>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3" style="float: left;">
                                        <div class="col-md-12" style="float: left;">
                                            <h6>{{(productDetails[0].proMinQty !=null)?productDetails[0].proMinQty:'N/A'}}</h6>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="float: left;">
                                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3" style="float: left;">
                                        <div class="col-md-8" style="float: left;">
                                            <h5>Supplyer:</h5>
                                        </div>
                                        <div class="col-md-4" style="float: left;">
                                            <i class="fa fa-check"></i>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                        <div class="col-md-12" style="float: left;">
                                            <h6>{{(productDetails[0].proMaxSupply !=null)?productDetails[0].proMaxSupply:'N/A'}}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- <h1>Share Button With Square Shape</h1>
                            <div style="text-align: center;">
                                <share-buttons theme="material-dark" show="11" image="https://loremflickr.com/320/240"></share-buttons>
                            </div> -->

                            <div *ngIf="galleryImagesList.length > 0  && this.productDetails[0].proImagesData[0].imageBase64 != ''" class="gallery-wrapper">
                                <!-- <ngx-gallery [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery"></ngx-gallery> -->
                                <ngx-gallery [options]="galleryOptions" [images]="galleryImagesList"
                                    class="ngx-gallery">
                                </ngx-gallery>
                                
                            </div>
                            <img *ngIf="galleryImagesList.length == 0 || this.productDetails[0].proImagesData[0].imageBase64 == ''"  class="img-fluid circular_image" src="../../../assets/images/no_image.jpg" alt="product image" />
                            <!-- <div class="col-sm-10">
                                <video width="370" [src]="productDetails[0].videoBase64" height="220" controls disabled="true" #videoPlayer>
                                    <source [src]="productDetails[0].videoBase64" type="video/mp4" maxFileSize="10">                                                
                                </video>
                                <img [src]="productDetails[0].imageBase64" width="200px" height="100px" />
                            </div> -->
                            <div class="tg-share">
                                <strong>Description:</strong>
                                <p>{{productDetails[0].description}}</p>
                            </div>
                            <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8 tg-lgcolwidthfull"
                                style="padding-left: 0px!important;padding-right:0px!important">
                                <div class="tg-dashboardbox">
                                    <div class="tg-dashboardboxtitle" style="background-color: #00cc67;">
                                        <h2 style="color: white;">Comments</h2>
                                    </div>
                                    <div class="tg-dashboardholder tg-offersmessages">
                                        <ul>
                                            <li style="list-style: none;">
                                                <div class="tg-chatarea">
                                                    <!-- <div class="tg-messages tg-verticalscrollbar tg-dashboardscrollbar"> -->
                                                    <div class=" tg-dashboardscrollbar" malihu-scrollbar
                                                        [scrollbarOptions]="scrollbarOptions">

                                                        <!-- <div *ngFor="let item of addsNewComments[0].adComments" class="tg-offerermessage"></div> -->
                                                        <div *ngFor="let item of addsNewComments" 
                                                            class="tg-offerermessage">
                                                            <figure>
                                                                <img [src]="item.imageBase64" alt="image description">
                                                                <!-- <img *ngIf="item.imageBase64 == null || item.imageBase64 == 0" src="../../assets/images/ads/dummyimage.png" alt="image description"> -->
                                                            </figure>
                                                            <div class="tg-description">
                                                                <h6>{{item.addedByName}}</h6>
                                                                <p>{{item.description}}</p>
                                                                <!-- <span ng-bind-html="{{item.description}}"></span> -->
                                                                <div class="clearfix"></div>
                                                                <time
                                                                    datetime="2017-08-08">{{item.timeCommented}}</time>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="tg-replaybox">
                                                        <textarea
                                                            [disabled]="(authState | async).athenticated === false"
                                                            class="form-control" name="reply"
                                                            [(ngModel)]="commentDescription"
                                                            ng-keyup="$event.keyCode == 13 && submitComment()"
                                                            (keyup.enter)="submitComment()"
                                                            placeholder="Type Here &amp; Press Enter"></textarea>
                                                        <!-- <button type="button" class="btn btn-success" (click)="submitComment()">Send</button> -->
                                                        <!-- <div class="tg-iconbox">
                                                            <i class="icon-thumbs-up">

                                                            </i>
                                                            <i class="icon-thumbs-down"></i>
                                                            <i class="icon-smile"></i>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <!-- <div>
                                        <div light malihu-scrollbar [scrollbarOptions]="darkScrollbarOptions">
                                            <h2>Oooh Fency Scrollbar!</h2>
                                            <p *ngFor="let i of [1,2,3]">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nisl rhoncus mattis rhoncus urna neque viverra. Odio euismod lacinia at quis risus. Magna sit amet purus gravida quis blandit turpis
                                                cursus in. Molestie nunc non blandit massa enim. Dui sapien eget mi proin. Arcu risus quis varius quam. Mauris rhoncus aenean vel elit scelerisque mauris pellentesque pulvinar pellentesque. Eget nunc scelerisque
                                                viverra mauris in aliquam sem fringilla ut. Velit egestas dui id ornare arcu odio ut. Quis eleifend quam adipiscing vitae proin sagittis nisl rhoncus mattis. Pulvinar neque laoreet suspendisse interdum consectetur
                                                libero id faucibus nisl. Erat velit scelerisque in dictum. Turpis tincidunt id aliquet risus feugiat in ante. Cursus sit amet dictum sit amet. Nec ullamcorper sit amet risus nullam eget. Consequat mauris
                                                nunc congue nisi vitae suscipit tellus mauris a. Felis imperdiet proin fermentum leo. Sit amet nisl purus in mollis nunc sed id.
                                            </p>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                            <!--************************************
                        Offers/Messages End
                *************************************-->
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="tg-youmayalsolike">
                        <div class="tg-sectionhead">
                            <div class="tg-title">
                                <h2>More Ads By {{productDetails[0].ProUserName}}</h2>
                            </div>
                            <div class="tg-description">
                                <p>Total 432 Ads Posted</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="tg-ads">
                                <div *ngFor="let item of MoreProductsByUser; let i= index" class="col-xs-6 col-sm-6 col-md-4 col-lg-3">
                                    <div class="tg-ad tg-verifiedad" *ngIf="i < 4">
                                        <figure (click)="onMoreProducts(item.proID)">
                                            <span  *ngIf="item.proDiscount == 0 || item.discountCheck == false" class="tg-themetag tg-featuretag">featured</span>
                                            <span  *ngIf="item.proDiscount != 0 && item.discountCheck == true" class="tg-themetag tg-featuretag">discount: {{item.proDiscount}}%</span>
                                            <!-- <a href="javascript:void(0);"><img src="{{item.mainImagePath}}{{item.imageName}}" alt="image description" class="featuredAdsImageClass"></a> -->
                                            <a href="javascript:void(0);">
                                                <img *ngIf="item.proImageBase != null && item.proImageBase != ''" [src]="item.proImageBase" alt="image description" class="featuredAdsImageClass">
                                                <img *ngIf="item.proImageBase == null || item.proImageBase == ''" src="/assets/images/no_image.jpg" alt="image description" class="featuredAdsImageClass">
                                            </a>
                                            <span class="tg-photocount">See All Photos</span>
                                        </figure>
                                        <div class="tg-adcontent">
                                            <ul class="tg-productcagegories">
                                                <li><a href="javascript:void(0);">{{item.cat}}</a></li>
                                            </ul>
                                            <div class="tg-adtitle">
                                                <h3><a href="javascript:void(0);">{{item.proName}}</a></h3>
                                            </div>
                                            <time datetime="2017-06-06">Last Updated: {{item.proAddedDate}}</time>
                                            <div class="tg-adprice">
                                                <h4>{{item.proPriceUnitName}}:{{item.proPrice}}</h4>
                                            </div>
                                            <!-- <address>44-46 abc Road, Manchester</address> -->
                                            <div class="tg-phonelike">
                                                <a class="tg-btnphone" [ngClass]="showPhones == item.proID ? 'highLight' : 'clearfix'" href="javascript:void(0);">
                                                    <i class="fa fa-phone"></i>
                                                    <span data-toggle="tooltip" data-placement="top" title="Show Phone No." data-last="0800 - 1234 - 562 - 6">
                                                        <em *ngIf="showPhones == item.proID">{{item.phone}}</em>
                                                        <em (click)="showPhoneNumber2(item.proID)" [ngClass]="showPhones != item.proID ? 'view' : 'hidden'">Show Phone No.</em>
                                                    </span>
                                                </a>
                                                <span class="tg-like tg-liked"><i class="fa fa-heart"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tg-viewallbox">
                                <a class="tg-btn" href="javascript:void(0)">View All</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="tg-youmayalsolike">
                        <div class="tg-sectionhead">
                            <div class="tg-title">
                                <h2>You may also like by Category!</h2>
                            </div>
                            <div class="tg-description">
                                <p>Total 5000 Ads Posted</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="tg-ads">
                                <div *ngFor="let item of topAddsByCategorys;let i=index" class="col-xs-6 col-sm-6 col-md-4 col-lg-3">
                                    <div class="tg-ad tg-verifiedad" *ngIf="i < 4">
                                        <figure (click)="onMoreProducts(item.proID)">
                                            <span  *ngIf="item.proDiscount == 0 || item.discountCheck == false" class="tg-themetag tg-featuretag">featured</span>
                                            <span  *ngIf="item.proDiscount != 0 && item.discountCheck == true" class="tg-themetag tg-featuretag">discount: {{item.proDiscount}}%</span>
                                            <!-- <a href="javascript:void(0);"><img src="{{item.mainImagePath}}{{item.imageName}}" alt="image description" class="featuredAdsImageClass"></a> -->
                                            <a href="javascript:void(0);">
                                                <img *ngIf="item.proImageBase != null && item.proImageBase != ''" [src]="item.proImageBase" alt="image description" class="featuredAdsImageClass">
                                                <img *ngIf="item.proImageBase == null || item.proImageBase == ''" src="/assets/images/no_image.jpg" alt="image description" class="featuredAdsImageClass">
                                            </a>
                                            <span class="tg-photocount">See All Photos</span>
                                        </figure>
                                        <div class="tg-adcontent">
                                            <ul class="tg-productcagegories">
                                                <li><a href="javascript:void(0);">Product</a></li>
                                            </ul>
                                            <div class="tg-adtitle">
                                                <h3><a href="javascript:void(0);">{{item.proName}}</a></h3>
                                            </div>
                                            <time datetime="2017-06-06">Last Updated: {{item.proAddedDate}} </time>
                                            <div class="tg-adprice">
                                                <h4>{{item.proPriceUnitName}}:{{item.proPrice}}</h4>
                                            </div>
                                            <!-- <address>44-46 abc Road, Manchester</address> -->
                                            <div class="tg-phonelike">
                                                <a class="tg-btnphone" [ngClass]="showPhones == item.proID ? 'highLight' : 'clearfix'" href="javascript:void(0);">
                                                    <i class="fa fa-phone"></i>
                                                    <span data-toggle="tooltip" data-placement="top" title="Show Phone No." data-last="0800 - 1234 - 562 - 6">
                                                        <em *ngIf="showPhones == item.proID">{{item.phone}}</em>
                                                        <em (click)="showPhoneNumber2(item.proID)" [ngClass]="showPhones != item.proID ? 'view' : 'hidden'">Show Phone No.</em>
                                                    </span>
                                                </a>
                                                <span class="tg-like tg-liked"><i class="fa fa-heart"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tg-viewallbox">
                                <a class="tg-btn" href="javascript:void(0)">View All</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>