<div class="tg-wrapper tg-haslayout">
    <div class="tg-dashboardbanner">
        <h1>Post An Company</h1>
        <ol class="tg-breadcrumb">
            <li><a (click)="backtoMain()">Go to Home</a></li>
            <li class="tg-active">Add Details</li>
        </ol>
    </div>

    <ul>

    </ul>
    <main id="tg-main" class="tg-main tg-haslayout">
        <!--************************************
					Section Start
			*************************************-->
        <section class="tg-dbsectionspace tg-haslayout">
            <div class="row">
                <!-- <form class="tg-formtheme tg-formdashboard" #f="ngForm" (ngSubmit)="onSubmit(f)"> -->
                <fieldset>
                    <div class="tg-postanad container ">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div class="tg-dashboardbox">
                                <form class="tg-formtheme tg-formdashboard" #f="ngForm" (ngSubmit)="onSubmit(f)">
                                    <div class="tg-dashboardboxtitle">
                                        <h2>Update Company</h2>
                                    </div>
                                    <div class="tg-dashboardholder">
                                        <div class="form-group">
                                            <label for="input_id_1">Name</label>
                                            <input type="text" required class="form-control" [(ngModel)]="companyList.comName" placeholder="Company Name" id="selectedCompany" name="selectedCompany">
                                            <div class="form-group">
                                                <div *ngIf="comNameAlert == true" class="alert alert-danger">
                                                    <div *ngIf="comNameAlert == true">
                                                        Company Name is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="input_id_1">Industry Name</label>
                                            <input type="text" required class="form-control" [(ngModel)]="companyList.comIndustry" placeholder="Enter Industry" id="selectedIndustry" name="selectedIndustry">
                                            <div class="form-group">
                                                <div *ngIf="comIndustryAlert == true" class="alert alert-danger">
                                                    <div *ngIf="comIndustryAlert == true">
                                                        Industry is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="input_id_1">Company Size</label>
                                            <input type="number" numbersOnly required class="form-control" [(ngModel)]="companyList.comSize" placeholder="Enter Size" id="selectedSize" name="selectedSize">
                                            <div class="form-group">
                                                <div *ngIf="comSizeAlert == true" class="alert alert-danger">
                                                    <div *ngIf="comSizeAlert == true">
                                                        Size is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group " id="divCountry">
                                            <label for="input_id_1">Country List</label>
                                            <ng-select [items]="CountryList" bindLabel="title" bindValue="id" placeholder="Select Country"
                                                appendTo="body" [(ngModel)]="companyList.comCountry" id="selectedCountry" name="selectedCountry">
                                            </ng-select>
                                            <!-- <div class="form-group">
                                                <div *ngIf="selectedCountryAlert == true" class="alert alert-danger">
                                                    <div *ngIf="selectedCountryAlert == true">
                                                        Country is required !
                                                    </div>
                                                </div>
                                            </div> -->
                                        </div>
                                        <div class="form-group">
                                            <label for="input_id_1">Email</label>
                                            <input type="email" required class="form-control" [(ngModel)]="companyList.comEmail" placeholder="Enter Email" id="selectedEmail" name="selectedEmail">
                                            <div class="form-group">
                                                <div *ngIf="comEmailAlert == true" class="alert alert-danger">
                                                    <div *ngIf="comEmailAlert == true">
                                                        Email is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="input_id_1">Phone Number</label>
                                            <input type="text" required class="form-control" [(ngModel)]="companyList.comNumber" placeholder="Enter Phone" id="selectedPhone" name="selectedPhone">
                                            <div class="form-group">
                                                <div *ngIf="comNumberAlert == true" class="alert alert-danger">
                                                    <div *ngIf="comNumberAlert == true">
                                                        Phone Number is required !
                                                    </div>
                                                </div>
                                            </div>
                                         </div>
                                        <div class="form-group">
                                            <label for="input_id_1">Licence</label>
                                            <input type="text" #licence="ngModel" required class="form-control" [(ngModel)]="companyList.comLicence" placeholder="Enter Licence" id="selectedLicence" name="selectedLicence">
                                            <!-- <span class="error" *ngIf="licence.invalid && licence.touched">licence is required</span> -->
                                            <div class="form-group">
                                                <div *ngIf="comLicenceAlert == true" class="alert alert-danger">
                                                    <div *ngIf="comLicenceAlert == true">
                                                        Licence is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="input_id_1">Website</label>
                                            <input type="text" required class="form-control" [(ngModel)]="companyList.comWebsite" placeholder="Enter Website" id="selectedWebsite" name="addWebsite">
                                            </div>
                                        <div class="form-group">
                                            <label for="input_id_1">Address</label>
                                            <input type="text" required class="form-control" [(ngModel)]="companyList.comAddress" placeholder="Enter Address" id="selectedAddress" name="selectedAddress">
                                        </div>
                                        <div class="form-group">
                                            <label for="input_id_1">Description</label>
                                            <!-- <input type="text" required class="form-control" [(ngModel)]="companyList.comDescription" placeholder="Enter Description" id="selectedDescription" name="selectedDescription">
                                             -->
                                            <div class="form-group">
                                                <editor [init]="{
                                                            height: 500,
                                                            menubar: true,
                                                            plugins: [
                                                            'advlist autolink lists link image charmap print preview anchor',
                                                            'searchreplace visualblocks code fullscreen',
                                                            'insertdatetime media table paste code help wordcount'
                                                            ],
                                                            toolbar:
                                                            'undo redo | formatselect | bold italic backcolor | \
                                                            alignleft aligncenter alignright alignjustify | \
                                                            bullist numlist outdent indent | removeformat | help'
                                                        }" id="selectedDescription" name="selectedDescription" [(ngModel)]="companyList.comDescription"></editor>
                                            </div>
                                        </div>
                                        <button class="tg-btn" type="submit">Update</button>
                                    </div>
                                </form>
                                <hr style="height:2px;border-width:0;color:gray;background-color:gray" />
                                <!-- <hr /> -->
                                <form class="tg-formtheme tg-formdashboard" #f="ngForm" (ngSubmit)="onSubmit(f)">
                                    <div class="tg-dashboardholder">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="tg-horizontalthemescrollbar tg-profilephotogallery">
                                                    <div class="col-md-8 productAddfromImages" *ngFor='let url of imageurls; let i = index'>
                                                        <img class="img-fluid" [src]="url">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-8">
                                                <label class="tg-fileuploadlabel" for="tg-photogallery">
                                                <span>Drop files anywhere to upload</span>
                                                <span>Or</span>
                                                <span class="tg-btn">Select Files</span>
                                                <span>Maximum upload file size: 500 KB</span>
                                                <input id="tg-photogallery" class="tg-fileinput"
                                                    (change)="onSelectFile($event)" type="file" name="file" multiple
                                                    accept="image/jpeg,image/jpg">
                                            </label></div>
                                        </div>
                                        <button class="tg-btn img-btn mt-3" (click)="onImagesUpdate()">Update
                                            Images</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <!-- </form> -->
            </div>
        </section>
    </main>
</div>


<!-- <div class="ml-3 bd-highlight" *ngFor='let url of imageurls;let i = index' style="width: 200px;">
    <img [src]="url" height="250px;" width="200px;"></div>
    <input type="file" (change)="onSelectFile($event)" class="mt-2" name="file" accept="image/jpeg,image/jpg" />
 -->