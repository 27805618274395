import { ChatAdapter, IChatGroupAdapter, User, Group, Message, ChatParticipantStatus, ParticipantResponse, ParticipantMetadata, ChatParticipantType, IChatParticipant } from 'ng-chat';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {  HttpHeaders } from '@angular/common/http';

import * as signalR from "@microsoft/signalr";
import { LocalStorageService } from 'angular-web-storage';
import * as jwt_decode from "jwt-decode";
import { commentNotify } from '../models/Notifications/commentNotification.model';
import { EventEmitter, Injectable } from '@angular/core';
import { notifyMessage } from '../models/notifyMessage';

@Injectable() 
export class chattingService {
  public userId: string;
  localStorage : LocalStorageService;
  private hubConnection: signalR.HubConnection;
 // public static serverBaseUrl: string = 'https://localhost:44373/chat'; // Set this to 'https://localhost:5001/' if running locally  
  public static serverBaseUrl: string = 'https://api.myknocks.com/chat'; 
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  }; 
  CommentNotificationReceived = new EventEmitter<commentNotify>(); 
  ChatNotificationReceived = new EventEmitter<notifyMessage>(); 
  MessageSeenNotificationReceived = new EventEmitter<notifyMessage>();
  chatConnectionEstablished$ = new BehaviorSubject<boolean>(false);        
    constructor(private http: HttpClient) {
      this.createConnection();
      // this.registerOnServerEvents();
       this.receiveServerEvents();
       this.startConnection(); 
  }

  private createConnection() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(chattingService.serverBaseUrl)     
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();
  }

  private startConnection() {
    if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
      return;
    }
    this.hubConnection.start().then(
      () => {
        //console.log('Chat Hub connection started!');
        this.chatConnectionEstablished$.next(true);
      },
      error => console.error(error)
    );
  }

  // public initializeConnection(): void {
  //     ////debugger;
  //   this.hubConnection = new signalR.HubConnectionBuilder()
  //     .withUrl(chattingService.serverBaseUrl)
  //     .build();

  //   this.hubConnection
  //     .start()
  //     .then(() => {
  //       //this.joinUser();

  //       this.initializeListeners();
  //     })
  //     .catch(err => //console.log(`Error while starting SignalR connection: ${err}`));
  // }

  // private initializeListeners(): void {
  //   this.hubConnection.on("generatedUserId", (userId) => {
  //     // With the userId set the chat will be rendered
  //     this.userId = userId;
  //   });

  //   this.hubConnection.on("messageReceived", (participant, message) => {
  //     // Handle the received message to ng-chat
  //     //this.onMessageReceived(participant, message);
  //   });
   
  // }

  public receiveServerEvents(): void {    
    this.hubConnection.on('ReceiveCommentNotification', (data: any) => {
      //debugger;
      // alert(data +"Hy Hello 1");
      //console.log(data.addTitle);   
      this.CommentNotificationReceived.emit(data);  
    });    

    this.hubConnection.on('notifyMessage', (data: any) => {
      debugger;
      this.ChatNotificationReceived.emit(data); 
      //alert("Work") 
    });  
    this.hubConnection.on('notifyMessageSeen', (data: any) => {
      //debugger;
      this.MessageSeenNotificationReceived.emit(data); 
      //alert("Work") 
    });  
    
  }

  // public receiveNotifyMessage(): void {    
  //   this.hubConnection.on('notifyMessage', (data: any) => {
  //     //debugger;
  //     this.ChatNotificationReceived.emit(data); 
  //     alert("Work") 
  //   });    
  // }

  joinUser(username: string): void {
    if (this.hubConnection && this.hubConnection.state == signalR.HubConnectionState.Connected) {
      //debugger;     
        //console.log('Inside Chat Service ');        
        this.hubConnection.send("RegisterNewUser", username);           
    }
  }
  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }

//   listFriends(): Observable<ParticipantResponse[]> {
      
//     // List connected users to show in the friends list
//     // Sending the userId from the request body as this is just a demo 
//     return this.httpclient
//       .post('https://localhost:44373/api/Chat/getFriendList',JSON.stringify({"currentUserId": this.userId}),this.httpOptions)
//       //.post('https://api.myknocks.com/api/Chat/getFriendList',JSON.stringify({"currentUserId": this.userId}),this.httpOptions)
//       .pipe(map((res: any) => res),
//         catchError((error: any) => Observable.throw(error.error || 'Server error'))
//       );
//   }

//   getMessageHistory(destinataryId: any): Observable<Message[]> {
//     // This could be an API call to your web application that would go to the database
//     // and retrieve a N amount of history messages between the users.
//     return of([]);
//   }

//   sendMessage(message: Message): void {
//     if (this.hubConnection && this.hubConnection.state == signalR.HubConnectionState.Connected)
//       this.hubConnection.send("sendMessage", message);
//   }

//   groupCreated(group: Group): void {
//     this.hubConnection.send("groupCreated", group);
//   }
}