<div class="tg-wrapper tg-haslayout">
    <div class="tg-dashboardbanner">
        <h1>Profile Setting</h1> &nbsp;
         <!-- <a style="border: 1px solid gray; padding: 5px; border: radius 10px;" routerLink="/userprofile/about">About YourSelf</a>&nbsp;
         <a style="border: 1px solid gray; padding: 5px; border: radius 10px;" routerLink="/userprofile/friend">Friends</a>&nbsp;
         <a style="border: 1px solid gray; padding: 5px; border: radius 10px;" routerLink="/userchat">Message's</a>&nbsp;
         <a style="border: 1px solid gray; padding: 5px; border: radius 10px;" (click)="myuserview()">Preview</a> -->
        <ol class="tg-breadcrumb">
            <li><a (click)="backtoMain()">Go to Home</a></li>
            <li class="tg-active">User Profile</li>
        </ol>
    </div>

    <ul>

    </ul>

    <main id="tg-main" class="tg-main tg-haslayout">
        <!--************************************
					Section Start
			*************************************-->
        <section class="tg-dbsectionspace tg-haslayout">
            <div class="row">
                <!-- <form class="tg-formtheme tg-formdashboard" [formGroup]="postDetailForm" (ngSubmit)="onSubmit()"> -->
                <form class="tg-formtheme tg-formdashboard" #f="ngForm" (ngSubmit)="onSubmit(f)">
                    <fieldset>
                        <div class="tg-postanad">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                                <div class="tg-dashboardbox">
                                    <div class="tg-dashboardboxtitle">
                                        <h2>Profile Information</h2>
                                    </div>
                                    <div class="tg-dashboardholder">
                                        <div class="form-group">
                                            <div style="width:40%; float:left">
                                                <label for="input_id_1">First Name</label>
                                                <input type="text" readonly class="form-control" placeholder="Name"
                                                    id="userName" name="userName" [(ngModel)]="profileList.userName">
                                            </div>
                                            <div  style="width:50%;float:left; margin-left: 5px">
                                                <label for="input_id_1">Email</label>
                                                <input type="text" readonly class="form-control" placeholder="Email"
                                                    id="Email" name="Email" [(ngModel)]="profileList.primaryEmail">
                                            </div>     
                                        </div>
                                        <div class="form-group">
                                            <div class="form-group" style="width:40%; float:left">
                                                <label for="input_id_1">Last Name</label>
                                                <input type="text" class="form-control" placeholder="Last Name"
                                                    id="lastName" [(ngModel)]="profileList.userLastName" name="lastName">
                                            </div>
                                            <div style="width:50%;float:left; margin-left: 5px">
                                                <label for="input_id_1">Middle Name</label>
                                                <input type="text" class="form-control" placeholder="Middle Name"
                                                    id="middleName" [(ngModel)]="profileList.userMiddleName" name="middleName">
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="form-group" style="width:40%; float:left">
                                                <label for="input_id_1">Father Name</label>
                                                <input type="text" class="form-control" placeholder="Father Name"
                                                    id="fatherName"  [(ngModel)]="profileList.userFatherName"  name="fatherName">
                                            </div>
                                            <div class="form-group" style="width:50%;float:left; margin-left: 5px">
                                                <label for="input_id_1">Phone Number</label>
                                                <input type="text" required class="form-control" placeholder="Phone Number"
                                                    id="Number" name="Number" [(ngModel)]="profileList.mobileNumber">
                                            </div>
                                        </div>
                                        <div class="form-group">                                            
                                            <div class="form-group" style="width:60%; float:left">
                                                <label for="input_id_1">Date Of Birth</label>
                                                <input type="date" id="selecteddoB" class="form-control"
                                                 [(ngModel)]="profileList.dob" [ngModel]="profileList.dob | date:'yyyy-MM-dd'" name="txtDate">
                                            </div>
                                            <!-- <div class="form-group" style="width:30%;float:left; margin-left: 5px">
                                                <div class="tg-checkbox">
                                                    <input id="tg-enablemessages" type="checkbox" name="enablemessages"
                                                        value="on">
                                                    <label for="tg-enablemessages">Make Private</label>
                                                </div>
                                            </div> -->
                                        </div>
                                        <div class="form-group">
                                            <div class="form-group" style="width:40%; float:left">
                                                <label for="input_id_1">Current Company</label>
                                                <input type="text" class="form-control" placeholder="Current Company"
                                                    id="company" [(ngModel)]="profileList.currentCompany" name="company">
                                            </div>
                                            <div style="width:50%;float:left; margin-left: 5px">
                                                <label for="input_id_1">Current Position</label>
                                                <input type="text" class="form-control" placeholder="Current Position"
                                                    id="position" [(ngModel)]="profileList.currentCarrierLevel" name="position">
                                            </div>                                           
                                        </div>
                                        <div class="form-group">
                                            <div class="form-group" style="width:30%;float:left; margin-left: 5px">
                                                <div class="tg-checkbox">
                                                    <input id="tg-enablemessages1" type="checkbox" name="birth"
                                                    [(ngModel)]="profileList.dobPrivate" value="0">
                                                    <label for="tg-enablemessages1">Dat Of Birth Private</label>
                                                </div>
                                            </div> 
                                            <div class="form-group" style="width:30%;float:left; margin-left: 5px">
                                                <div class="tg-checkbox">
                                                    <input id="tg-enablemessages2" type="checkbox" name="position"
                                                        value="on">
                                                    <label for="tg-enablemessages2">Position Private</label>
                                                </div>
                                            </div> 
                                            <div class="form-group" style="width:30%;float:left; margin-left: 5px">
                                                <div class="tg-checkbox">
                                                    <input id="tg-enablemessages3" type="checkbox" name="company"
                                                        value="on">
                                                    <label for="tg-enablemessages3">Comapny Private</label>
                                                </div>
                                            </div> 
                                        </div>
                                        <div class="form-group" >
                                            <label for="input_id_1">Address</label>
                                            <input type="text" class="form-control" placeholder="Address"
                                                id="address" [(ngModel)]="profileList.address" name="address">
                                        </div>
                                        <!-- <div class="form-group" >
                                            <label for="input_id_1">Upload CV</label>
                                            <div  *ngFor='let url of fileurls; let i = index'>
                                                <input type="file" class="form-control" [(ngModel)]="profileList.fileurls"
                                                (change)="onSelectFile($event)" accept=".pdf,.xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel">
                                            </div>
                                        </div> -->
                                        <!-- <label class="tg-fileuploadlabel" for="tg-photogallery">
                                            <span>Drop files anywhere to upload</span>
                                            <span>Or</span>
                                            <span class="tg-btn">Select File</span>
                                            <span>Maximum upload file size: 500 KB</span>
                                            <input id="tg-photogallery" class="tg-fileinput"
                                                (change)="onSelectCV($event)" type="file" name="files"
                                                accept="application/doc,.pdf,.xls,.xlsx,application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel">
                                        </label><br /> -->
                                        <label class="tg-fileuploadlabel" for="tg-photogallery">
                                            <span>Drop files anywhere to upload</span>
                                            <span>Or</span>
                                            <span class="tg-btn">Select Image</span>
                                            <span>Maximum upload file size: 500 KB</span>
                                            <input id="tg-photogallery" class="tg-fileinput"
                                                (change)="onSelectImagess($event)" type="file" name="images" multiple
                                                accept="image/jpeg,image/jpg">
                                        </label>
                                        <div class="tg-horizontalthemescrollbar tg-profilephotogallery">
                                            <div class="col-md-6 productAddfromImages"
                                                *ngFor='let url of imageurls; let i = index'>
                                                <img class="img-fluid" [src]="url">
                                                <!-- <a (click)="removeImage(i)"
                                                    class="btn btn-xs btn-danger mt-2">Remove</a> -->
                                            </div>
                                        </div>
                                        <button class="tg-btn img-btn mt-3" (click)="onImagesUpdate()">Update
                                            Images</button>

                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5">
                                <div class="tg-dashboardbox tg-contactdetail">
                                    <!-- <div class="tg-dashboardboxtitle">
                                       <h2>Details</h2>
                                    </div> -->
                                    <div class="tg-dashboardholder" id="divCommon">
                                        <div class="form-group">
                                        <button type="button"  class="tg-btn" data-toggle="modal" data-target="#exampleModal">
                                            Change Your Password
                                        </button>      
                                            <div class="tg-checkbox">
                                                <input id="tg-enablemessages" type="checkbox" name="enablemessages"
                                                [(ngModel)]="profileList.makePrivate" value="0">
                                                <label for="tg-enablemessages">Profile Private</label>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="input_id_1">Ggender</label>
                                            <ng-select [items]="GenderList" bindLabel="title" bindValue="id"
                                                placeholder="Gender" appendTo="body" id="selectedGender"
                                                name="selectedGgender" [(ngModel)]="profileList.genderID">
                                            </ng-select>
                                        </div>
                                        <div class="form-group">
                                            <label for="input_id_1">Nationality</label>
                                            <ng-select [items]="Nationality" bindLabel="title" bindValue="id"
                                                placeholder="Nationality" appendTo="body" id="selectedNationality"
                                                name="selectedNationality" [(ngModel)]="profileList.nationality">
                                            </ng-select>
                                        </div>
                                        <div class="form-group">
                                            <label for="input_id_1">Status</label>
                                            <ng-select [items]="Status" bindLabel="title" bindValue="id"
                                                placeholder="Status" appendTo="body" id="selectedStatus"
                                                name="selectedStatus" [(ngModel)]="profileList.status">
                                            </ng-select>
                                        </div>
                                        <div class="form-group">
                                            <label for="input_id_1">Religion</label>
                                            <ng-select [items]="Religion" bindLabel="title" bindValue="id"
                                                placeholder="Religion" appendTo="body" id="selectedReligion"
                                                name="selectedReligion" [(ngModel)]="profileList.religionID">
                                            </ng-select>
                                        </div>
                                        <div class="form-group">
                                            <label for="input_id_1">Carrier</label>
                                            <ng-select [items]="Carrier" bindLabel="title" bindValue="id"
                                                placeholder="Carrier" appendTo="body" id="selectedCarrier"
                                                name="selectedCarrier" [(ngModel)]="profileList.carrierLevel">
                                            </ng-select>
                                        </div>
                                        
                                        <div class="form-group">
                                            <label for="input_id_1">About YourSelf</label>
                                            <editor [init]="{
                                                    height: 250,
                                                    menubar: true,
                                                    plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar:
                                                    'undo redo | formatselect | bold italic backcolor | \
                                                    alignleft aligncenter alignright alignjustify | \
                                                    bullist numlist outdent indent | removeformat | help'
                                                }" id="descriptionArea" name="descriptionArea"
                                                [(ngModel)]="profileList.currentCoverLetter"></editor>

                                        </div>
                                        <br />
                                        <button class="tg-btn mt-3" type="submit">Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </section>
        <!--************************************
					Section End
			*************************************-->
    </main>

</div>
<!-- Button trigger modal -->

  
  <!-- Modal -->
  <div class="modal fade" data-backdrop="static" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Change Your Password</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="container" style="width: auto;">
                <div class="form-group">
                    <label for="input_id_1">Old Password</label>
                    <input type="text" class="form-control" placeholder="Old Password ♣"
                        id="oldPassword" name="oldPassword" [(ngModel)]="this.oldPassword">
                </div>
                <div class="form-group inner-addon right-addon">
                    <label for="input_id_1">New Password</label>
                    <div class="input-group col-md-12 mx-auto">
                        <input name="password" [type]="showPassword ? 'text' : 'password'"
                            class="form-control pwd-control" id="password-field"
                            placeholder="New Password" [(ngModel)]="this.userPassword">
                        <div>
                            <i alt="show" class="far fa-eye eye-show"
                                (click)="showPassword = !showPassword"
                                [class.hide]="showPassword"></i>
                            <i alt="hide" class="far fa-eye-slash eye-hide"
                                (click)="showPassword = !showPassword"
                                [class.hide]="!showPassword"></i>
                        </div>
                    </div>
                </div>
                <div class="form-group inner-addon right-addon">
                    <label for="input_id_1">Confirm Password</label>
                    <div class="input-group col-md-12 mx-auto">
                        <input name="confirmpassword"
                            [type]="showPassword ? 'text' : 'password'"
                            class="form-control pwd-control" id="password-field"
                            placeholder="Confirm Password" [(ngModel)]="this.confirmPassword">
                        <div>
                            <i alt="show" class="far fa-eye eye-show"
                                (click)="showPassword = !showPassword"
                                [class.hide]="showPassword"></i>
                            <i alt="hide" class="far fa-eye-slash eye-hide"
                                (click)="showPassword = !showPassword"
                                [class.hide]="!showPassword"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" (click)="changepassword()" class="btn btn-success">Change</button>
        </div>
      </div>
    </div>
  </div>