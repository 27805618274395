import { Component, NgZone, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActionserviceService } from '../shared/services/compnies/actionservice.service';
import { adDealService } from '../shared/services/deal/deal-services.service';
import { addDetailsDTO } from '../shared/models/Adds/addDetails.model';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faLaptop } from '@fortawesome/free-solid-svg-icons';
// import {   } from '@fortawesome/free-regular-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

import { faFacebook,faPinterest,faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';

import { NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { NgxGalleryImage } from '@kolkov/ngx-gallery';
import { NgxGalleryAnimation } from '@kolkov/ngx-gallery';
import { GoogleMap, MapInfoWindow, MapMarker } from "@angular/google-maps";
import { adds } from '../shared/models/Adds/adds.model';
import { addsService } from '../shared/services/addServices';
import { AdComments } from '../shared/models/comments/comments.model';
import { Comments } from '../shared/models/comments/comment.model';
import { Subject, Subscription, BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromApp from '../store/app.state';
import * as fromAuth from '../store/reducers/auth.reducers';
import { Observable } from 'rxjs';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { NotificationService } from '../shared/services/notification.service';
import { ToastrService } from 'ngx-toastr';
import { likeDislikeDetails } from '../shared/models/Adds/likeDislikeDetails.model';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { galleryImagesDTO } from '../shared/models/Adds/galleryImage.model';
import { liveUpdateService } from '../shared/liveUpdate/listUpdate.service';
import * as jwt_decode from "jwt-decode";
import { LocalStorageService } from 'angular-web-storage';
import { VideoObject } from '../shared/models/Adds/VideoObject.model';
import { ProComments } from '../shared/models/comments/Pro-comments.model';
import { ProlikeDislikeDetails } from '../shared/models/Company/likeDislikeDetails.model';
import { ActionserviceServices } from '../shared/services/deal/actionservice.service';
import { ProViewCount } from '../shared/models/likesDislikes/ViewCount-pro';
import { DealViewCount } from '../shared/models/likesDislikes/ViewCount-Deal';
import { openSearchService } from '../shared/services/search/openSearch.service';

@Component({
  selector: 'app-deal-details',
  templateUrl: './deal-details.component.html',
  styleUrls: ['./deal-details.component.css']
})
export class DealDetailsComponent implements OnInit {

  addId: string;
  DealID:number;
  category: string;
  addDetails: addDetailsDTO;
  topAddsByUser: adds[];
  //Array<String> = [];
  galleryImagesList: Array<galleryImagesDTO> = [];
  ProductgalleryImagesList: Array<galleryImagesDTO> = [];
  imageObject: Array<VideoObject> = [];
  topAdds: adds[];
  topAddsByCategorys:any;
  private result: number;
  commentDescription = '';
  addsNewComments: Comments[];

  // fbIcon = faFacebookSquare;
  addLikes: number;
  addDisLikes: number;
  showPhone: boolean = false;
  update = new BehaviorSubject<boolean>(false);
  updateLike = new BehaviorSubject<boolean>(false);
  updateDislike = new BehaviorSubject<boolean>(false);

  //commentsChanged = new Subject<Comments[]>();
  //private readonly commentsChangedNew = new Subject();

  faThumbsUp = faThumbsUp;
  faThumbsDown = faThumbsDown;
  faEye = faEye;
  faGlobe = faGlobe;
  faMobile = faLaptop;

  faFacebook = faFacebook;
  faGoogle = faGoogle;
  faLinkedin = faLinkedin;
  faTwitter = faTwitter;
  faPinterest = faPinterest;
  faWhatsapp=faWhatsapp;

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  subscription: Subscription;

  // public scrollbarOptions = { axis: 'yx', theme: 'minimal-dark' };
  public scrollbarOptions = { axis: 'y', theme: 'light' };
  // public darkScrollbarOptions = { axis: 'y', theme: 'dark' };
  authState: Observable<fromAuth.State>;
  commentsObject: Comments;
  //dealDetails: Deal;
  dealDetails: any;
  imageurls = [];
  videoUrls = [];
  productMainID: number = 0;
  categoryTitle = "";
  subCategoryTitle = "";
  ProductList: any;
  MoreDealsLlist: any;
  loginuserID: string;

  constructor(private dealService: adDealService,private ActionService: ActionserviceServices, private ngxLoader: NgxUiLoaderService, private addService: addsService
    , private store: Store<fromApp.AppState>, private mScrollbarService: MalihuScrollbarService, private notifyService: NotificationService,
    private toasterService: ToastrService, private route: ActivatedRoute,private userInterface: openSearchService,
    private liveUpdate: liveUpdateService, private _ngZone: NgZone, private storage: LocalStorageService,private router: Router,) { }

  ngOnInit(): void {
    this.authState = this.store.select('auth');

    let token = this.storage.get('currentUser');
    let tokenInfo = this.getDecodedAccessToken(token); // decode token   
    if (tokenInfo != null) {
      this.loginuserID = tokenInfo.nameid;
    }
    
    this.route.params.subscribe((params: Params) => {
      this.addId = params['id'];
      this.category = params['category'];
      ////console.log(this.catId);        
    })
   
     this.getCommentsByAdd();
     this.update.subscribe(update => update === true ? this.getCommentsByAdd() : '');
     this.updateLike.subscribe(update => update === true ? this.getAddLikeDislikeCount(1, "direct") : '');
     this.updateDislike.subscribe(update => update === true ? this.getAddLikeDislikeCount(2, "direct") : '');

    this.liveUpdate.commentReceived.subscribe((comment: Comments) => {
      this.commentsObject = comment;
      // //debugger;     
      this.addsNewComments.splice(0, 0, this.commentsObject);
    });
    //debugger;
    this.liveUpdate.likeReceiveds.subscribe((likeDetails: ProlikeDislikeDetails) => {
      //debugger;
      if (likeDetails.actionType == 1) {
        this.getAddLikeDislikeCount(1, "update");
      }
      else if (likeDetails.actionType == 2) {
        this.getAddLikeDislikeCount(2, "update");
      }
    });
  
    // this.subscription =this.commentsChanged.subscribe((
    //   newComments : Comments[])=>{
    //     //debugger;
    //     this.addDetails.adComments = newComments;
    //   }
    // )

    this.LoadProductData();
    this.LoadDealsMore();
    this.getTopDealsByCategory();

    this.galleryOptions = [
      {
        width: '766px',
        height: '470px',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide,
        zoomInIcon: 'fa fa-search-plus',
        zoomOutIcon: 'fa fa-search-minus',
        rotateLeftIcon: 'fa fa-undo',
        rotateRightIcon: 'fa fa-repeat',
        imageSwipe: true
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 10,
        thumbnailsMargin: 10,
        thumbnailMargin: 10,
        imageSwipe: true
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: true,
        previewZoom: true,
        imageSwipe: true,
        previewRotate: true
      }
    ];

   this.counters();
  }
  counters() {
    //debugger;
    const viewDetail = new DealViewCount();
    viewDetail.DealID = parseInt(this.route.snapshot.params.dealID);
    viewDetail.userID = 0;
    
    this.ActionService.submitview(viewDetail)
  }
  
  ProfileView(userID){
    this.userInterface.setInterface("1");
    this.router.navigate(['/userprofile/detail/',userID], { relativeTo: this.route });
    // let url = this.router.createUrlTree(['/userprofile/detail/',userID])
    // window.open(url.toString(), '_blank')
  }
  backtoMain() {
    this.userInterface.setInterface("0");
    this.router.navigate(['/home'], { relativeTo: this.route });
  }
  showPhones:any
  showPhoneNumber2(items) {
    //debugger;
    if(items){
      if (this.showPhone == false) {
        this.showPhones = items;
      }
      else {
        this.showPhone = true;
      }
  }
  }
  
  LoadProductData() {
    //debugger;
    this.ngxLoader.start();
    //console.log(this.route.snapshot.params.dealID);
    this.dealService.GetDealbyID(this.route.snapshot.params.dealID).subscribe((result) => {
      //console.log(result);
     // this.dealDetails = result;
      this.dealDetails = result;
      this.addLikes = this.dealDetails[0].likeCount;
        this.addDisLikes = this.dealDetails[0].dislikeCount;
      //debugger;
      this.ProductList = this.dealDetails[0].dealProductData;
      // if (this.dealDetails[0].dealProductData != null) {
      //   //debugger;
      //   for (let ingrediant of this.dealDetails[0].dealProductData) {
      //     var p = { small: ingrediant.proImageBase, medium: ingrediant.proImageBase, big: ingrediant.proImageBase };
      //     this.ProductgalleryImagesList.push(p);             
      // }
      // }
      if (this.dealDetails[0].dealImagesData != null) {
        //debugger;
        for (let ingrediant of this.dealDetails[0].dealImagesData) {
          var d = { small: ingrediant.imageBase64, medium: ingrediant.imageBase64, big: ingrediant.imageBase64 };
          this.galleryImagesList.push(d);
        }
      }  
      setTimeout(() => {
        this.ngxLoader.stop();

      }, 1000);

    });
    
  
  }
  LoadDealsMore() {
    //console.log(this.route.snapshot.params.dealID);
    this.dealService.GetMmoreDealbyUserID(this.route.snapshot.params.dealID).subscribe((result) => {
      this.MoreDealsLlist = result;
    });
  }
  
  onMoreDeals(dealID)
  {
   // this.userInterface.setInterface("0");
   // this.router.navigate(['/deal/details/',dealID], { relativeTo: this.route });

    let url = this.router.createUrlTree(['/deal/details/',dealID])
    window.open(url.toString(), '_blank')
  }

  getTopDealsByCategory() {
    //var category ="C4E1F606-8105-4666-A8BC-C6657730A0DF";

    this.addService.getTopDealsByCategory(this.route.snapshot.params.dealID)
      .subscribe((data) => {
        //this.topAdds = data;
        this.topAddsByCategorys = data;
      });
  }

  submitComment() {
     //debugger; 
    //first do the validation   
    const commentDetails = new ProComments();
    commentDetails.postID = this.dealDetails[0].dealID;
    
    commentDetails.commentDesc = this.commentDescription;
    let token = this.storage.get('currentUser');
    let tokenInfo = this.getDecodedAccessToken(token); // decode token
    //debugger;
    let abc: number;
    const x = this.ActionService.submitAddComment(commentDetails)
      .subscribe((value: number) => {
        abc = value;
        this.update.next(true);
      });  
    //console.log("abc");
    this.commentDescription = "";
    commentDetails.addedBy = parseInt(tokenInfo.nameid);
    //this.liveUpdate.sendChatMessage(commentDetails);
    this.toasterService.success('Comment submitted successfully', 'Comment', {
      closeButton: true,
      progressBar: true,
      tapToDismiss: true
    });
  }

  likeDislikePost(action: number) {
    const likeDislike = new ProlikeDislikeDetails();
    likeDislike.actionType = action;
    likeDislike.postLikePostID = this.dealDetails[0].dealID;
    likeDislike.userID = parseInt(this.loginuserID);
    //likeDislike.postLikePostID = "B7A1A6D3-D488-4281-8801-D8E890D99787";     

    const x = this.ActionService.submitLikeDislike(likeDislike);
    //this.ActionService.submitLikeDislike(likeDislike); 
    //x.subscribe(()=>this.updateLike.next(true));
    if (action == 1) {
      // //debugger;
      x.subscribe(() => this.updateLike.next(true));
    }
    else if (action == 2) {
      // //debugger;
      x.subscribe(() => this.updateDislike.next(true));
    }
    this.liveUpdate.sendLikeDislieks(likeDislike);
  }

  getCommentsByAdd() {
    //debugger;
    //this.proID=176;
    this.ActionService.getAddComments(this.route.snapshot.params.dealID).subscribe((data: Comments[]) => {
    this.addsNewComments = data;
    });
  }


  getAddLikeDislikeCount(action: number, callingPlace: string) {
    ////debugger;
    const likeDislike = new ProlikeDislikeDetails();
    likeDislike.actionType = action;
    likeDislike.postLikePostID = this.dealDetails[0].dealID;
    //likeDislike.postLikePostID = "B7A1A6D3-D488-4281-8801-D8E890D99787"; 

    this.ActionService.getAddLikeDislikeCount(likeDislike)
      .subscribe((data: number) => {
        // //debugger;
        if (action == 1) {
          if (callingPlace == "direct") {
            if (this.addLikes < data) {
              this.toasterService.success('Your like submitted successfully', 'Like', {
                closeButton: true,
                progressBar: true,
                tapToDismiss: true
              });
            }
            else {
              this.toasterService.success('Your like removed successfully', 'Like', {
                closeButton: true,
                progressBar: true,
                tapToDismiss: true
              });

            }
          }
          setTimeout(() => {
            this.addLikes = data;
          }, 1000);
        }
        else if (action == 2) {
          if (callingPlace == "direct") {
            if (this.addDisLikes < data) {
              this.toasterService.success('Your dislike submitted successfully', 'Like', {
                closeButton: true,
                progressBar: true,
                tapToDismiss: true
              });
            }
            else {
              this.toasterService.success('Your dislike removed successfully', 'Like', {
                closeButton: true,
                progressBar: true,
                tapToDismiss: true
              });
            }
          }
          setTimeout(() => {
            this.addDisLikes = data;
          }, 1000);
        }

      });
  }


  setComments(commentsRecieved: Comments[]) {
    ////debugger;
    // this.addDetails.adComments = this.addsNewComments;
    // this.commentsChanged.next(this.addDetails.adComments.slice());
    ////console.log(this.recipes);
  }
  showPhoneNumber() {
    if (this.showPhone == false) {
      this.showPhone = true;
    }
    else {
      this.showPhone = false;
    }

  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }


  shareData: {
    url: string;
    description?: string;
    tags?: string;
  } = {
   // url: 'https://www.linkedin.com/in/sultan-ahmad-a653a0203/', 
    url: 'https://knocks.myknocks.com/deal/details/' + this.route.snapshot.params.dealID,
    description: 'https://knocks.myknocks.com',
    tags: 'myknocks',
  };

  Facebook: { title: string; link: string }[] = [
    {
      title: 'fb',
      link: `https://www.facebook.com/sharer.php?u=${this.shareData?.url}`,
    }
  ]
  Twitter: { title: string; link: string }[] = [
    {
      title: 'twitter',
      link: `https://twitter.com/intent/tweet?url=${this.shareData?.url}&text=${this.shareData?.description}&hashtags=${this.shareData?.tags}`,
    }
  ]
  Pinterest: { title: string; link: string }[] = [
    {
      title: 'pinterest',
      link: `http://pinterest.com/pin/create/link/?url=${this.shareData?.url}`,
    }
  ]
  Whatsapp: { title: string; link: string }[] = [
    {
      title: 'whatsapp',
      link: `https://wa.me?text=${this.shareData?.url}`,
    }
  ]
}
