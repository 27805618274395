import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Product } from '../product/show-product/Product';
import { adProductService } from '../shared/services/compnies/ProductServices';
import { openSearchService } from '../shared/services/search/openSearch.service';

@Component({
  selector: 'app-add-to-cart',
  templateUrl: './add-to-cart.component.html',
  styleUrls: ['./add-to-cart.component.css']
})
export class AddToCartComponent implements OnInit {
  cartProducts: Product[];
  constructor(public adproductService: adProductService,private userInterface: openSearchService,private router: Router,private route:ActivatedRoute) { }

  totalValue = 0;
  Total=0;
  name:number;
  grandTotal=0;
  proQuantity=1;
  discountTotal=0;
  TotalLength:any;
  page:number=1;

  ngOnInit(): void {
    //debugger
   // this.getCartProduct();
    this.cartProducts =  JSON.parse(localStorage.getItem("active_item"))
    this.TotalLength = this.cartProducts.length;
    this.calculateCartProduct();
    //this.adproductService.ordersChanged.subscribe(items => this.cartProducts = items)
    // this.adproductService.CartItemReceived.subscribe((adds:Product) => {
    //   debugger;
    //   if(adds != null )
    //   {
    //     this.cartProducts.push(adds) ;
    //   }      
    // });
  }
  

changeQuantity(selectedProduct: Product, quantity: number): void {  
  debugger
  console.log(selectedProduct)

if(quantity > 0)
{

this.cartProducts = this.adproductService.getLocalCartProducts();

let updateItem = this.cartProducts.find(f=>f.proID == selectedProduct.proID);

  let index = this.cartProducts.indexOf(updateItem);

selectedProduct.proQuantity = quantity;

  this.cartProducts[index].proQuantity =  quantity;

  //this.cartProducts[index].proTotal += parseInt(selectedProduct.proPrice);
  //console.log(this.cartProducts[index].proTotal)
  //this.totalValue += parseInt(selectedProduct.proPrice);
}
else	
{	
  this.cartProducts = this.adproductService.getLocalCartProducts();
  
  let updateItem = this.cartProducts.find(f=>f.proID == selectedProduct.proID);

  let index = this.cartProducts.indexOf(updateItem);

  this.cartProducts.splice(index, 1);
}

  this.adproductService.setLocalCartProducts(this.cartProducts);
  this.calculateCartProduct();
}  

calculateCartProduct(): void {  
  debugger;
  this.totalValue =0;
  this.grandTotal=0;
  let productTotal =0;
  this.discountTotal =0;
  this.cartProducts = this.adproductService.getLocalCartProducts();
  this.cartProducts.forEach((product) => { 
    this.totalValue  += parseInt(product.proPrice) * product.proQuantity ;
    this.discountTotal +=  parseInt(product.proPrice) - product.proDiscount;
    
  });
  if(this.totalValue >0)
  {
    this.grandTotal =  this.totalValue - 0;
  }
  else 
  {
    
  }

}




  removeCartProduct(product: Product) {
    this.adproductService.removeLocalCartProduct(product);
    this.cartProducts =  JSON.parse(localStorage.getItem("active_item"))
    this.calculateCartProduct();
  }

  addFavourite(product: Product) {
    this.adproductService.FavouriteProduct(product);
  }
  
  backtoMain()
  {
    this.userInterface.setInterface("0");
    this.router.navigate(['../home'],{relativeTo : this.route });
  }

}
