import { HttpInterceptor, HttpRequest, HttpHeaders, HttpEvent, HttpHandler, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthenticationService } from "../auth/auth.service";
import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';
import * as fromApp from '../store/app.state';
import * as fromAuth from '../store/reducers/auth.reducers';
import { map, take, catchError } from "rxjs/operators";
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Injectable()
export class authInterceptor implements HttpInterceptor{    
    constructor(private store:Store<fromApp.AppState>, private ngxLoader: NgxUiLoaderService){
       // //debugger;
    }
    
    intercept(req:HttpRequest<any> , next :HttpHandler) : Observable<HttpEvent<any>>
    {        
     ////debugger;
       // //console.log('intercepted', req);
        //this.ngxLoader.start();
        //this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));

       return this.store.select('auth')
       .pipe(take(1))
       .pipe(switchMap((authState : fromAuth.State)=>
       {
        const copiedReq = req.clone({
            setHeaders: {
                'Authorization': 'Bearer '+ authState.token,
                'Content-Type': 'application/json'
              }}
            // {params : req.params.set('auth', authState.token)}
            );
           // this.ngxLoader.stop();
        return next.handle(copiedReq);                    
       }))        
    }
}