import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { adds } from 'src/app/shared/models/Adds/adds.model';
import { HttpClient } from '@angular/common/http';
import { addsService } from 'src/app/shared/services/addServices';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { adProductService } from 'src/app/shared/services/compnies/ProductServices';
import { addDetailsService } from 'src/app/shared/services/addDetails.service';
import { searchResulteDTO } from 'src/app/shared/models/search/openSearch.model';
import { Router } from '@angular/router';
import { allSearchFilters } from 'src/app/shared/models/requestsModel/search/searchInput.model';
import { openSearchService } from 'src/app/shared/services/search/openSearch.service';
import { LocalStorageService } from 'angular-web-storage';
import { dataLibrary } from 'src/app/shared/models/category/dataLibraries/dataLibrary.model';
import { dataLibraryService } from 'src/app/shared/services/search/DataLibraries.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Product } from 'src/app/product/show-product/Product';

//declare function CountryAlert():any;
declare const CountryAlert:any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  //main:HTMLScriptElement;
 //@ViewChild('modalselectcurrency', {static : true}) modalselectcurrency:ElementRef;
  topFifteenAdds: any[];
  topDeals:adds[];
  faCoffee = faCoffee;
  faHeart = faHeart;
  faBookmark=faBookmark;
  selectedCountry = '0';
  selectedCity = '0';
  citySelection : dataLibrary[]=[];
  ProductList: any=[];
  TotalLength:any;
  page:number=1;
  PostList: any[];
  PropertyList: any[];
  JobList: any[];
  PropertySaleList: any[];
  JobHiringList: any[];
  showPhone: boolean = false;
  constructor(private adServices: addDetailsService,private dlService : dataLibraryService, private ngxLoader: NgxUiLoaderService,
    public localStor: LocalStorageService,private operSearch: openSearchService,private router: Router,
    private adproductService: adProductService,private http: HttpClient,private addService: addsService) {
    if(this.localStor.get('currentCountry'))
    {
      this.selectedCountry =this.localStor.get('currentCountry');
    }
    if(this.localStor.get('currentCity'))
    {
      this.selectedCity =this.localStor.get('currentCity');
    }
    //this.getCities();
    if(this.selectedCountry == null || this.selectedCountry == '0')
    {
      CountryAlert();
    }
   }

  ngOnInit(): void {    
   // this.modalselectcurrency.nativeElement.click();
    
    this.getTopAdds();
    this.getTopDeals();
    this.LoadAutosData();
    this.LoadPropertyforRentData();
    this.LoadPropertyforSaleData();
    this.LoadJobData();
    this.LoadJobforHiring();
    this.LoadProductData();
  }

  addToCart(product: Product) {
    debugger
    product.proQuantity = 1;
    this.adproductService.addToCart(product);
  }
  productFavourite(product: Product) {
    this.adproductService.FavouriteProduct(product);
  }
  addFavourite(ads: adds) {
    this.adproductService.FavouriteAds(ads);
  }
  DealFavourite(ads: adds) {
    this.adproductService.FavouriteDeals(ads);
  }
  
  getCities(){
   // //debugger;
    this.dlService.getCitiesByCountry(this.selectedCountry)
        .subscribe((data:dataLibrary[]) => {
          this.citySelection = data;
          this.TotalLength = data.length;
          //console.log(this.selectedCountry + "conuntry");
        });
  }
  LoadProductData() {
    ////debugger;
    var con  = parseInt(this.selectedCountry);
    this.adServices.gettopProductaDetails(con).subscribe((data:any[]) => {
      this.ProductList = data;
      this.TotalLength = data.length;
      //console.log(this.ProductList);
    });
  }
  stringJson: any;
  stringObject: any;
  LoadAutosData() {
    var con  = parseInt(this.selectedCountry);
    this.adServices.gettopAutosAdsDetails(con).subscribe((data: any[]) => {
      this.PostList = data;
      //console.log(this.PostList)
    });
  }
  LoadPropertyforRentData() {
    var con  = parseInt(this.selectedCountry);
    this.adServices.gettopPropertyRentDetails(con).subscribe((data:any[]) => {
      this.PropertyList = data;
      this.TotalLength = data.length;
      ////console.log(this.PropertyList);
    });
  }
  LoadPropertyforSaleData() {
    var con  = parseInt(this.selectedCountry);
    this.adServices.gettopPropertySaleDetails(con).subscribe((data:any[]) => {
      this.PropertySaleList = data;
      this.TotalLength = data.length;
    //  //console.log(this.PropertySaleList);
    });
  }
  LoadJobData() {
    var con  = parseInt(this.selectedCountry);
    this.adServices.gettopJobforWanted(con).subscribe((data:any[]) => {
      this.JobList = data;
      this.TotalLength = data.length;
     // //console.log(this.JobList);
    });
  }
  LoadJobforHiring() {
    var con  = parseInt(this.selectedCountry);
    this.adServices.gettopJobforHiring(con).subscribe((data:any[]) => {
      this.JobHiringList = data;
      this.TotalLength = data.length;
     // //console.log(this.JobHiringList);
    });
  }
  itemList = [];
  selectedItems = [];
  settings = {};

  ClassifiedsearchSend(){
    //debugger;
    const requestData = new allSearchFilters();
    
    let catName : string;
    let subCatName : string ;
    let catID: string ;
    let subCatID: string ;     

    catName = "Items"; 
    catID = "d7b411fb-1c21-4f0a-88f7-34d6b789f67d";
    subCatName ="?q=sad&_keyori=ss&from=input&spm=a2a0e.pdp.search.go";
    subCatID= "0";

    requestData.CatID =catID;
    requestData.subCatID =subCatID;
    requestData.levelOfSearch = "1";
    requestData.searchWord = "";
    var newCatName = catName.replace(/ /g,'-');
    var newSubCatName = subCatName.replace(/ /g,'-');
    this.operSearch.setData(requestData); 
   // this.router.navigate(['/search',newCatName,newSubCatName]);

    let url = this.router.createUrlTree(['/search',newCatName,newSubCatName])
    window.open(url.toString(), '_blank')
  }
  AutosearchSend()
  {
    //debugger;
    const requestData = new allSearchFilters();
    
    let catName : string;
    let subCatName : string ;
    let catID: string ;
    let subCatID: string ;
     // catName =this.PostList[0].cat; 
   //   catID = this.PostList[0].add_CatID; 
   // subCatName =this.PostList[0].subCat;
   // subCatID= this.PostList[0].add_SubCat_ID;     

    catName = "Items"; 
    catID = "5bf243b1-2be4-4dda-93a0-cf9e06209e0f";
    subCatName ="?q=sad&_keyori=ss&from=input&spm=a2a0e.pdp.search.go";
    subCatID= "0";

    requestData.CatID =catID;
    requestData.subCatID =subCatID;
    requestData.levelOfSearch = "1";
    requestData.searchWord = "";
    var newCatName = catName.replace(/ /g,'-');
    var newSubCatName = subCatName.replace(/ /g,'-');
    this.operSearch.setData(requestData); 
    this.router.navigate(['/search',newCatName,newSubCatName]);
  }
  PropertyRentsearchSend()
  {
    //debugger;
    const requestData = new allSearchFilters();
    
    let catName : string;
    let subCatName : string ;
    let catID: string ;
    let subCatID: string ;
    catName = "Items"; 
    catID = "0372dc00-f9cb-4cb5-8b95-901ea8a02efa";
    subCatName ="?q=sad&_keyori=ss&from=input&spm=a2a0e.pdp.search.go";
    subCatID= "0";
    requestData.CatID =catID;
    requestData.subCatID =subCatID;
    requestData.levelOfSearch = "1";
    requestData.searchWord = "";
    var newCatName = catName.replace(/ /g,'-');
    var newSubCatName = subCatName.replace(/ /g,'-'); 
    this.operSearch.setData(requestData);
    this.router.navigate(['/search',newCatName,newSubCatName]);
  }
  PropertySalesearchSend()
  {
    //debugger;
    const requestData = new allSearchFilters();
    
    let catName : string;
    let subCatName : string ;
    let catID: string ;
    let subCatID: string ;
    catName = "Items"; 
    catID = "27bbb528-5666-4429-b153-7d6441c0efa1";
    subCatName ="?q=sad&_keyori=ss&from=input&spm=a2a0e.pdp.search.go";
    subCatID= "0";
    requestData.CatID =catID;
    requestData.subCatID =subCatID;
    requestData.levelOfSearch = "1";
    requestData.searchWord = "";
    var newCatName = catName.replace(/ /g,'-');
    var newSubCatName = subCatName.replace(/ /g,'-'); 
    this.operSearch.setData(requestData);
    this.router.navigate(['/search',newCatName,newSubCatName]);
  }
  JobWantsearchSend()
  {
    //debugger;
    const requestData = new allSearchFilters();
    
    let catName : string;
    let subCatName : string ;
    let catID: string ;
    let subCatID: string ;
    catName = "Items"; 
    catID = "3c064386-a300-4f90-8e18-f6da9f83aa1c";
    subCatName ="?q=sad&_keyori=ss&from=input&spm=a2a0e.pdp.search.go";
    subCatID= "A07D5BE2-BC59-4086-82F5-1E1DF97EC87A";     

    requestData.CatID =catID;
    requestData.subCatID =subCatID;
    requestData.levelOfSearch = "2";
    requestData.searchWord = "";
    var newCatName = catName.replace(/ /g,'-');
    var newSubCatName = subCatName.replace(/ /g,'-'); 
    this.operSearch.setData(requestData);
    this.router.navigate(['/search',newCatName,newSubCatName]);
  }
  JobHiresearchSend()
  {
    //debugger;
    const requestData = new allSearchFilters();
    
    let catName : string;
    let subCatName : string ;
    let catID: string ;
    let subCatID: string ;
    catName = "Items"; 
    catID = "3c064386-a300-4f90-8e18-f6da9f83aa1c";
    subCatName ="?q=sad&_keyori=ss&from=input&spm=a2a0e.pdp.search.go";
    subCatID= "967CEED7-54C0-488E-8AA8-69E6045E6C05";        

    requestData.CatID =catID;
    requestData.subCatID =subCatID;
    requestData.levelOfSearch = "2";
    requestData.searchWord = "";
    var newCatName = catName.replace(/ /g,'-');
    var newSubCatName = subCatName.replace(/ /g,'-'); 
    this.operSearch.setData(requestData);
    this.router.navigate(['/search',newCatName,newSubCatName]);
  }
  ProductsearchSend(){
    //debugger;
    const requestData = new allSearchFilters();
    
    let catName : string;
    let subCatName : string ;
    let catID: string ;
    let subCatID: string ;
      catName = "Items"; 
      catID = "C4E1F606-8105-4666-A8BC-C6657730A0DF"; 
      subCatName = "?q=sad&_keyori=ss&from=input&spm=a2a0e.pdp.search.go";
      subCatID= "0";   
      
    requestData.CatID =catID;
    requestData.subCatID =subCatID;
    requestData.levelOfSearch = "1";
    requestData.searchWord = "";
    requestData.typeIdentity ="2";
    var newCatName = catName.replace(/ /g,'-');
    var newSubCatName = subCatName.replace(/ /g,'-'); 
    this.operSearch.setData(requestData);
    this.router.navigate(['/search',newCatName,newSubCatName]);
  }
  DealsearchSend(){
    //debugger;
    const requestData = new allSearchFilters();
    
    let catName : string;
    let subCatName : string ;
    let catID: string ;
    let subCatID: string ;
    catName = "Items";  
    catID = "c4e1f606-8105-4666-a8bc-c6657730a0df"; 
    subCatName = "?q=sad&_keyori=ss&from=input&spm=a2a0e.pdp.search.go";
    subCatID= "0"; 

    requestData.CatID =catID;
    requestData.subCatID =subCatID;
    requestData.levelOfSearch = "1";
    requestData.searchWord = "";
    requestData.typeIdentity ="1";
    var newCatName = catName.replace(/ /g,'-');
    var newSubCatName = subCatName.replace(/ /g,'-'); 
    this.operSearch.setData(requestData);
    this.router.navigate(['/search',newCatName,newSubCatName]);
  }

  getTopAdds() {
   // this.ngxLoader.start();
    var con  = parseInt(this.selectedCountry);
    this.addService.getTopAdds(con)
    .subscribe((data:adds[]) => {
      ////console.log(data);
      this.topFifteenAdds = data;
      //console.log(this.topFifteenAdds + "Classified");
      // setTimeout(() => {
      //   this.ngxLoader.stop();

      // }, 1000);    
    });
  }
  getTopDeals() {
    //debugger;
    var con  = parseInt(this.selectedCountry);
    this.addService.getTopDeals(con)
    .subscribe((data:adds[]) => {
      ////console.log(data);
      this.topDeals = data;
      //console.log(this.topDeals);     
    });
  }
 
  toggleStyle: boolean = false;

  toggle(){
    //console.log(this.toggleStyle);
    this.toggleStyle = !this.toggleStyle;
  }
  ongetDetails(itemDetails :searchResulteDTO)
  {
    let url = this.router.createUrlTree(['/details',itemDetails.cat,itemDetails.add_ID])
    window.open(url.toString(), '_blank')
  }
  
  showPhones:any
  showPhoneNumber(items) {
    //debugger;
    if(items){
      if (this.showPhone == false) {
        this.showPhones = items;
      }
      else {
        this.showPhone = true;
      }
  }
  }

  mySlideImages = [{url:'../assets/images/catImages/151-1512944_red-headphone-png-image-background-beats-by-dr-removebg-preview.png',category:'Accessories'},
  {url:'../assets/images/catImages/png-transparent-toy-box-graphy-taobao-electricity-supplier-baby-products-child-baby-people-removebg-preview.png',category:'Baby Items'},
  {url: '../assets/images/catImages/purepng.com-booksbookillustratedwrittenprintedliteratureclipart-14215264516954siyt.png',category:'Books'},
  {url:'../assets/images/catImages/6-66583_canon-camera-png-image-transparent-camera-canon-png-removebg-preview.png',category:'Camera'},
  {url:'../assets/images/catImages/istockphoto-1257563298-612x612-removebg-preview.png',category:'Clothing'},
  {url:'../assets/images/catImages/45-450952_transparent-laptop-png-hp-laptops-images-png-png-removebg-preview.png',category:'Computers'},
  {url:'../assets/images/catImages/521-5210266_electronic-home-appliance-png-transparent-png-removebg-preview.png',category:'Electronics'},
  {url:'../assets/images/catImages/png-transparent-brown-wooden-bedroom-furniture-set-art-bedside-tables-metal-furniture-couch-furniture-angle-furniture-drawer-removebg-preview.png',category:'Furniture'},
  {url: '../assets/images/catImages/png-clipart-video-game-overwatch-cities-skylines-business-gamer-icon-game-video-game-removebg-preview.png',category:'Gaming'},
  {url:'../assets/images/catImages/file-sports-and-games-svg-wikimedia-commons-1.png',category:'Sports'},
  {url:'../assets/images/catImages/152-1521280_gold-jewellery-png-transparent-image-gold-bangle-designs-removebg-preview.png',category:'Jewellery'},
  {url: '../assets/images/catImages/dog-tag-cat-pet-png-favpng-SGU7uuEpw2pyeevBGVUsUAA49-removebg-preview.png',category:'Pets'}
 ];
  
//   SlideOptions = { items: 1, dots: true, nav: false };  
//   CarouselOptions = { items: 3, dots: true, nav: true };

  //mySlideImages = [1,2,3].map((i)=> `https://picsum.photos/640/480?image=${i}`);
  //myCarouselImages =[1,2,3,4,5,6].map((i)=>`https://picsum.photos/640/480?image=${i}`);
 // mySlideOptions={items: 1, dots: true, nav: false};


  mySlideOptions= {
          items: 1,
         nav: true,
         dots:false,
          loop: true,
            margin: 0,
            rtl: true,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                    nav: true
                },
                700: {
                    items: 4,
                    nav: true
                },
                1000: {
                    items: 6,
                    nav: true,
                    loop: false
                }
  }
};

  //myCarouselOptions={items: 3, dots: true, nav: true};

  

}
