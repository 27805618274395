export class allSearchFilters{
    public searchWord: string;
    public levelOfSearch : string ;      
     //common filters  
    public CatID: string;
    public CatName: string;
    public subCatID : string ;  
    public firstChild: string;
    public secondChild : string ;  
    public thirdChild: string;
    public StartPrice : string ;  
    public EndPrice: string;
    public City : string ;  
    public Country: string;    
    //autos
    public Usage: string;
    public StartKilo : string ;  
    public EndKilo: string;
    public StartYear : string ;  
    public EndYear: string;
    public BodyCondition : string ;  
    public MacCondition: string;
    public BodyType : string ;  
    public Doors: string;
    public Cylender : string ;     
    public Color: string;
    public Transmission : string ;  
    public HP: string;
    public FT : string ;  
    public Warrenty: string;

    // property
    public Building: string;
    public Size : string ;     
    public StartBath: string;
    public ToBath : string ;  
    public StartBed: string;
    public ToBed : string ;  
    public Furnished: string;

     //jobs  
     public Exp: string;
     public Edu : string ;     
     public Commitment: string;
     public FromSalary : string ;  
     public ToSalary: string;
     public Shift : string ;  
     public Positions: string;
     public DisplaySalary : string ;  
     public Degree: string;
     public TravelRequired : string ;  
     public Gender: string;
     public FromAge : string ;  
     public ToAge: string;
     
    //Deals          
    public Supplier: string;
    
    //Indexing
    public start: string;
    public end: string;

    public typeIdentity: string; // 1= deal, 2= product, 3 =supplier

}    



