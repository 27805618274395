import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { dataLibraryService } from 'src/app/shared/services/search/DataLibraries.service';
import { dataLibrary } from 'src/app/shared/models/category/dataLibraries/dataLibrary.model';
import { LocalStorageService } from 'angular-web-storage';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
 // @ViewChild('modalselectcurrency', {static : true}) modalselectcurrency:ElementRef;
  faBullhorn = faBullhorn;
  selectedCountry = '0';
  selectedCity = '0';
  citySelection : dataLibrary[]=[];
  cityName:any;
  TotalLength:any;
  page:number=1;
  constructor(private notifyService: NotificationService,private dlService : dataLibraryService,private router: Router, public localStor: LocalStorageService, private notify: ToastrService) {
    if(this.localStor.get('currentCountry'))
    {
      this.selectedCountry =this.localStor.get('currentCountry');
    }
    if(this.localStor.get('currentCity'))
    {
      this.selectedCity =this.localStor.get('currentCity');
    }
    this.getCities();
  }

  // ngAfterViewInit() {
  //   this.content.nativeElement.click();
  // }
  
  ngOnInit(): void {
  //  this.modalselectcurrency.nativeElement.click();
  }

  getCities(){
    //debugger;
    this.dlService.getCitiesByCountry(this.selectedCountry)
        .subscribe((data:dataLibrary[]) => {
          this.citySelection = data;
          this.cityName = data;
          this.TotalLength = data.length;
          //console.log(this.selectedCountry);
        });
  }
  setLocation()
  {
    //debugger;
    this.localStor.set('currentCountry', this.selectedCountry);
    this.localStor.set('currentCity', this.selectedCity);

    this.notify.success('Your location is set successfully', 'location', {
      closeButton: true,
      progressBar:true,
      tapToDismiss:true
    });
   // window.location.reload();
   //this.reloadComponent();
   this.reloadCurrentRoute();

  }
  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
}

  clearLocation()
  {
    this.localStor.remove('currentCountry');
    this.localStor.remove('currentCity');

    this.selectedCountry = '0';
    this.selectedCity = '0';
    this.localStor.set('currentCountry', this.selectedCountry);
    this.localStor.set('currentCity', this.selectedCity);
    this.notify.success('Your location is removed successfully', 'location', {
      closeButton: true,
      progressBar:true,
      tapToDismiss:true
    });

  }

  shownotify(){
    this.notifyService.showSuccess('Successfully Signup !!', 'Thank you');
  }
}
