import { Component, OnInit } from '@angular/core';
import { Product } from '../product/show-product/Product';
import { adds } from '../shared/models/Adds/adds.model';
import { adProductService } from '../shared/services/compnies/ProductServices';

@Component({
  selector: 'app-add-to-favorite',
  templateUrl: './add-to-favorite.component.html',
  styleUrls: ['./add-to-favorite.component.css']
})
export class AddToFavoriteComponent implements OnInit {
  showPhone: boolean = false;
  constructor(public adproductService: adProductService) { }

  ngOnInit(): void {
  }

  
  showPhones:any
  showPhoneNumber(items) {
    //debugger;
    if(items){
      if (this.showPhone == false) {
        this.showPhones = items;
      }
      else {
        this.showPhone = true;
      }
  }
  }

  removeFavProducts(items: Product){
      this.adproductService.removeLocalProduct(items);
  }

  removeFavAds(items: adds){
    this.adproductService.removeLocalAds(items);
  }

  removeFavDeals(items: adds){
    this.adproductService.removeLocalDeals(items);
  }

}
