<h3 class="text-center">Checking Out Products</h3>

<div class="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2 board-inner" id="status-buttons">
  <ul class="nav nav-tabs" id="myTab">
    <div class="liner"></div>

    <!-- circular user icon -->
    <li id="productsTab" style="margin: auto;">
      <a [routerLink]="['/checkouts', { outlets: { checkOutlet: ['/'] } }]" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }" data-toggle="tab" title="Product Summary">
        <span class="round-tabs one">
          <i class="fa fa-shopping-cart" aria-hidden="true"></i>
        </span>
      </a>
    </li>

    <!-- circular tasks icon -->
    <li id="shippingTab" style="margin: auto;">
      <a [routerLink]="[
          '/checkouts',
          { outlets: { checkOutlet: ['shipping-details'] } }
        ]" routerLinkActive="active" data-toggle="tab" title="Shipping Details">
        <span class="round-tabs two">
          <i class="fa fa-truck" aria-hidden="true"></i>
        </span>
      </a>
    </li>

    <!-- circular home icon -->
    <li id="billingTab" style="margin: auto;">
      <a [routerLink]="[
          '/checkouts',
          { outlets: { checkOutlet: ['billing-details'] } }
        ]" routerLinkActive="active" data-toggle="tab" title="Confirmation">
        <span class="round-tabs three">
          <i class="fa fa-check-circle" aria-hidden="true"></i>
        </span>
      </a>
    </li>

    <!-- circular ok icon -->
    <li id="resultTab" style="margin: auto;">
      <a [routerLink]="['/checkouts', { outlets: { checkOutlet: ['result'] } }]" routerLinkActive="active"
        data-toggle="tab" title="Payment">
        <span class="round-tabs four">
          <i class="fa fa-credit-card" aria-hidden="true"></i>
        </span>
      </a>
    </li>
  </ul>
  <div class="clearfix"></div>
</div>

<div class="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2 ">
  <div class="tg-alert alert alert-success fade in" *ngIf="AddressList1 != null">
    <p><strong>Home Address:</strong>Your Home address is already saved, you can use it.</p>
    <div class="tg-anchors">
      <a class="tg-btndoaction" href="javascript:void(0)" (click)="HomeShippingAddress()">Apply</a>
      <a href="javascript:void(0)" class="close" data-dismiss="alert">&times;</a>
    </div>
  </div>
  <div class="tg-alert alert alert-success fade in" *ngIf="AddressList2 != null">
    <p><strong>Office Address:</strong>Your Office address is already saved, you can use it.</p>
    <div class="tg-anchors">
      <a class="tg-btndoaction" href="javascript:void(0)" (click)="OfficeShippingAddress()">Apply</a>
      <a href="javascript:void(0)" class="close" data-dismiss="alert">&times;</a>
    </div>
  </div>
  <div class="col-md order-md-1">
    <h4 class="mb-3">Shipping Address</h4>
    <form class="needs-validation" #f="ngForm" (ngSubmit)="onSubmit(f)">
      <div class="row">
        <div class="col-md-6 mb-3">
          <label for="firstName">First name</label>
          <input type="text" class="form-control" id="firstName" [(ngModel)]="firstName" name="firstName" required />
          <div class="invalid-feedback" *ngIf="firstNameAlert==true">
            Valid first name is required.
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <label for="lastName">Last name</label>
          <input type="text" class="form-control" id="lastName" [(ngModel)]="lastName" name="lastName" required />
          <div class="invalid-feedback" *ngIf="lastNameAlert==true">
            Valid last name is required.
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label for="email">Email
          <span class="text-muted">(Optional)</span>
        </label>
        <input type="email" class="form-control" id="email" [(ngModel)]="email" name="email" />
        <div class="invalid-feedback" *ngIf="emailNameAlert==true">
          Please enter a valid email address for shipping updates.
        </div>
      </div>

      <div class="row">
        <div class="col-md-8 b-3">
          <label for="address">Address</label>
          <input type="text" class="form-control" id="address" placeholder="1234 Main St" [(ngModel)]="address1"
            name="address1" required />
          <div class="invalid-feedback" *ngIf="address1NameAlert==true">
            Please enter your shipping address.
          </div>
        </div>
        <div class="col-md-4">
          <label for="type">Address Type</label>
          <select class="custom-select d-block w-100 col-md-12" id="type" required [(ngModel)]="addresstype"
            name="type">
            <option value="0">Options</option>
            <option value="home">Home</option>
            <option value="office">Office</option>
          </select>
          <div class="invalid-feedback" *ngIf="typeNameAlert==true">
            Please select a valid address type.
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label for="address2">Address 2
          <span class="text-muted">(Optional)</span>
        </label>
        <input type="text" class="form-control" id="address2" placeholder="Apartment or suite" [(ngModel)]="address2"
          name="address2" />
        <div class="invalid-feedback" *ngIf="address2NameAlert==true">
          Please enter your shipping address.
        </div>
      </div>

      <div class="row">
        <div class="col-md-5 mb-3">
          <label for="country">Country</label>
          <ng-select [items]="CurrienciesList" [(ngModel)]="this.selectedCountry" disabled bindLabel="title"
            bindValue="id" appendTo="body" name="selectedCountry">
          </ng-select>
          <div class="invalid-feedback" *ngIf="countryNameAlert==true">
            Please select a valid country.
          </div>
        </div>
        <div class="col-md-4 mb-3">
          <label for="state">City</label>
          <select class="custom-select d-block w-100 col-md-12" id="state" [(ngModel)]="state" name="state" required>
            <option value="0">Select City</option>
            <option *ngFor="let subCat of citySelection" value={{subCat.id}}>{{subCat.name}}</option>
          </select>
          <div class="invalid-feedback" *ngIf="stateNameAlert==true">
            Please provide a valid state.
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <label for="zip">Zip</label>
          <input type="text" class="form-control" id="zip" placeholder="Zip Code" [(ngModel)]="zip" name="zip"
            required />
          <div class="invalid-feedback" *ngIf="zipNameAlert==true">
            Zip code required.
          </div>
        </div>
      </div>
      <hr class="mb-4" />
      <button class="btn btn-primary btn-lg btn-block" type="submit">
        Continue to checkout
      </button>
    </form>
  </div>
</div>

<!--   
  <div class="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2 ">
    <div class="mb-4">
      <h2>Confirm order and pay</h2>
      <span>please make the payment, after that you can enjoy all the features and benefits.</span>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card p-3">
          <h6 class="text-uppercase">Payment details</h6>
          <div class="inputbox mt-3"> <input type="text" name="name" class="form-control" required="required"> <span>Name
              on card</span>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="inputbox mt-3 mr-2"> <input type="text" name="name" class="form-control" required="required"> <i
                  class="fa fa-credit-card"></i> <span>Card Number</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="d-flex flex-row">
                <div class="inputbox mt-3 mr-2"> <input type="text" name="name" class="form-control" required="required">
                  <span>Expiry</span>
                </div>
                <div class="inputbox mt-3 mr-2"> <input type="text" name="name" class="form-control" required="required">
                  <span>CVV</span>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4 mb-4">
            <h6 class="text-uppercase">Billing Address</h6>
            <div class="row mt-3">
              <div class="col-md-6">
                <div class="inputbox mt-3 mr-2"> <input type="text" name="name" class="form-control" required="required">
                  <span>Street Address</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="inputbox mt-3 mr-2"> <input type="text" name="name" class="form-control" required="required">
                  <span>City</span>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-6">
                <div class="inputbox mt-3 mr-2"> <input type="text" name="name" class="form-control" required="required">
                  <span>State/Province</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="inputbox mt-3 mr-2"> <input type="text" name="name" class="form-control" required="required">
                  <span>Zip code</span>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="mt-4 mb-4 d-flex justify-content-between">
          <span>Previous step</span>
          <button class="btn btn-success px-3">Pay $840</button>
        </div>
      </div>
    </div>
  </div> -->