<div class="tg-wrapper tg-haslayout">
    <div class="tg-dashboardbanner">
        <h1>Post An Ad</h1>
        <ol class="tg-breadcrumb">
            <li><a (click)="backtoMain()">Go to Home</a></li>
            <li class="tg-active">Add Details</li>
        </ol>
    </div>

    <ul>

    </ul>

    <main id="tg-main" class="tg-main tg-haslayout">
        <!--************************************
					Section Start
			*************************************-->
        <section class="tg-dbsectionspace tg-haslayout">
            <div class="row">
                <!-- <form class="tg-formtheme tg-formdashboard" [formGroup]="postDetailForm" (ngSubmit)="onSubmit()"> -->
                <form class="tg-formtheme tg-formdashboard" #f="ngForm" (ngSubmit)="onSubmit(f)">
                    <fieldset>
                        <div class="tg-postanad">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                                <div class="tg-dashboardbox">
                                    <div class="tg-dashboardboxtitle">
                                        <h2>Ad Post</h2>
                                    </div>
                                    <div class="tg-dashboardholder">
                                        <div class="form-group text-center">
                                            <a href="#" class="tg-btn" data-toggle="modal"
                                                data-target=".tg-categorymodal">Select Category Here</a>
                                        </div>
                                        <div class="form-group">
                                            <ol class="tg-categorysequence">
                                                <span>{{this.categoryTitle +' '}}</span>
                                                <fa-icon [icon]="faArrowRight" *ngIf="this.subCategoryTitle != ''">
                                                </fa-icon>
                                                <span>{{' '+this.subCategoryTitle +' '}}</span>
                                                <fa-icon [icon]="faArrowRight" *ngIf="this.subToFirstTitle != ''">
                                                </fa-icon>
                                                <span>{{' '+this.subToFirstTitle +' '}}</span>
                                                <fa-icon [icon]="faArrowRight" *ngIf="this.subToSecondTitle != ''">
                                                </fa-icon>
                                                <span *ngIf="this.subToSecondTitle != ''">{{' '+
                                                    this.subToSecondTitle}}</span>

                                            </ol>
                                        </div>
                                        <div class="form-group">
                                            <div *ngIf="addCategoryAlert == true" class="alert alert-danger">
                                                <div *ngIf="addCategoryAlert == true">
                                                    Please select Category !
                                                </div>
                                            </div>
                                            <div *ngIf="addSubCatAlert == true" class="alert alert-danger">
                                                <div *ngIf="addSubCatAlert == true">
                                                    Please select Sub-Category !
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="input_id_1">Title</label>
                                            <input type="text" required class="form-control"
                                                placeholder="Enter Ad Title*" id="addTitle" name="addTitle"
                                                [(ngModel)]="addTitle">
                                        </div>
                                        <div class="form-group">
                                            <div *ngIf="addTitleAlert == true" class="alert alert-danger">
                                                <div *ngIf="addTitleAlert == true">
                                                    Name is required.
                                                </div>
                                                <!-- <div *ngIf="addTitle.errors.minlength">
                                                Name must be at least 4 characters long.
                                              </div> -->
                                                <!-- <div *ngIf="name.errors.forbiddenName">
                                                Name cannot be Bob.
                                              </div> -->
                                            </div>
                                        </div>
                                        <div class="form-group tg-priceformgroup">
                                            <div style="width:60%; float:left">
                                                <div>
                                                    <label for="input_id_1">Price</label>
                                                    <input type="text" numbersOnly required class="form-control"
                                                        placeholder="Price*" id="addPrice" name="addPrice"
                                                        [(ngModel)]="addPrice">
                                                </div>
                                                <div class="form-group">
                                                    <div *ngIf="addPriceAlert == true" class="alert alert-danger">
                                                        <div *ngIf="addPriceAlert == true">
                                                            Price is required !
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div style="width:35%;float:left; margin-left: 5px">
                                                <!-- <select ui-select2 style="border:none !important; width: 100%;    margin-top: 0px;" placeholder="Currency" class="js-example-placeholder">
                                                <option></option></select> -->
                                                <label for="input_id_1">Currency</label>
                                                <ng-select [items]="CurrienciesList" bindLabel="title" bindValue="id"
                                                    placeholder="Currency" appendTo="body" id="selectedCurrency"
                                                    name="selectedCurrency" [(ngModel)]="selectedCurrency">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <!-- <textarea id="tg-tinymceeditor" class="tg-tinymceeditor" data-ng-model="vm.Message"></textarea> -->
                                            <!-- <textarea ui-tinymce="vm.tinymceOptions" data-ui-tinymce data-ng-model="vm.selectedAdDetails"></textarea> -->
                                            <editor [init]="{
                                                    height: 500,
                                                    menubar: true,
                                                    plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar:
                                                    'undo redo | formatselect | bold italic backcolor | \
                                                    alignleft aligncenter alignright alignjustify | \
                                                    bullist numlist outdent indent | removeformat | help'
                                                }" id="descriptionArea" name="descriptionArea"
                                                [(ngModel)]="descriptionArea"></editor>
                                            <!-- <p>{{vm.Message}}</p> -->

                                            <!-- <p ng-bind-html="vm.Message"></p> -->
                                        </div>
                                        <label class="tg-fileuploadlabel" for="tg-photogallery">
                                            <span>Drop files anywhere to upload</span>
                                            <span>Or</span>
                                            <span class="tg-btn">Select Files</span>
                                            <span>Maximum upload file size: 500 KB</span>
                                            <!-- <input type="file" class="tg-fileinput" (change)="onSelectFile($event)" name="file" multiple accept="image/*" /> -->
                                            <input id="tg-photogallery" class="tg-fileinput"
                                                (change)="onSelectFile($event)" type="file" name="file" multiple
                                                accept="image/jpeg,image/jpg">
                                        </label>
                                        <div class="tg-horizontalthemescrollbar tg-profilephotogallery">
                                            <div class="col-md-2 productAddfromImages"
                                                *ngFor='let url of imageurls; let i = index'>
                                                <img class="img-fluid" [src]="url.base64String">
                                                <a (click)="removeImage(i)" class="btn btn-xs btn-danger">Remove</a>
                                            </div>

                                            <!-- <ul>
                                                <li>
                                                    <figure>
                                                        <img src="images/thumbnail/img-01.jpg" alt="image description">
                                                        <i class="icon-trash"></i>
                                                    </figure>
                                                </li>
                                                <li>
                                                    <figure class="tg-activephoto">
                                                        <img src="images/thumbnail/img-02.jpg" alt="image description">
                                                        <i class="fa fa-check-square-o"></i>
                                                    </figure>
                                                </li>
                                                <li>
                                                    <figure>
                                                        <img src="images/thumbnail/img-02.jpg" alt="image description">
                                                        <i class="icon-trash"></i>
                                                    </figure>
                                                </li>
                                                <li>
                                                    <figure class="tg-activephoto">
                                                        <img src="images/thumbnail/img-04.jpg" alt="image description">
                                                        <i class="fa fa-check-square-o"></i>
                                                    </figure>
                                                </li>
                                                <li>
                                                    <figure class="tg-activephoto">
                                                        <img src="images/thumbnail/img-05.jpg" alt="image description">
                                                        <i class="fa fa-check-square-o"></i>
                                                    </figure>
                                                </li>
                                                <li>
                                                    <figure>
                                                        <img src="images/thumbnail/img-01.jpg" alt="image description">
                                                        <i class="icon-trash"></i>
                                                    </figure>
                                                </li>
                                                <li>
                                                    <figure class="tg-activephoto">
                                                        <img src="images/thumbnail/img-02.jpg" alt="image description">
                                                        <i class="fa fa-check-square-o"></i>
                                                    </figure>
                                                </li>
                                                <li>
                                                    <figure>
                                                        <img src="images/thumbnail/img-02.jpg" alt="image description">
                                                        <i class="icon-trash"></i>
                                                    </figure>
                                                </li>
                                                <li>
                                                    <figure>
                                                        <img src="images/thumbnail/img-04.jpg" alt="image description">
                                                        <i class="icon-trash"></i>
                                                    </figure>
                                                </li>
                                                <li>
                                                    <figure class="tg-activephoto">
                                                        <img src="images/thumbnail/img-05.jpg" alt="image description">
                                                        <i class="fa fa-check-square-o"></i>
                                                    </figure>
                                                </li>
                                            </ul> -->
                                        </div>

                                        <label class="tg-fileuploadlabel" for="tg-photogallery">
                                            <ng-container>
                                                <!-- <span class="tg-btn">Select Video</span> -->
                                                <span>Drop your video to upload</span>
                                                <!-- <span>Or</span> -->
                                                <span>Maximum video size: 10MB</span>
                                                <i class="fa fa-upload fa-3x" (change)="onSelectVideo($event)" aria-hidden="true"></i>
                                                <input type="file" id="tg-photogallery" accept="mp4"
                                                 multiple (change)="onSelectVideo($event)">
                                            </ng-container>
                                        </label>
                                        <div class="col-sm-10" *ngFor='let url of videoUrls; let i = index'>
                                            <video width="370" height="220" controls disabled="true" #videoPlayer>
                                                <source [src]="url" type="video/mp4" maxFileSize="10">                                                
                                            </video>
                                            <!-- <figure> <a class="btn btn-xs btn-success" (click)="onVideoUpdate()">Update Video </a> </figure> -->
                                            <figure> <a (click)="removeVideo(i)" class="btn btn-xs btn-danger ">Remove</a> </figure>
                                        </div>
                                        <br />

                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5">
                                <!-- <div class="tg-dashboardbox">
                                <div class="tg-dashboardboxtitle">
                                    <h2>Enable Offers/Messages</h2>
                                </div>
                                <div class="tg-dashboardholder">
                                    <div class="tg-checkbox">
                                        <input id="tg-enablemessages" type="checkbox" name="enablemessages" value="on">
                                        <label for="tg-enablemessages">Enable offers/messages option in this Post</label>
                                    </div>
                                </div>
                            </div> -->
                                <div class="tg-dashboardbox tg-contactdetail">
                                    <div class="tg-dashboardboxtitle">
                                        <h2>Ad Details</h2>
                                    </div>
                                    <div class="tg-dashboardholder" id="divCommon">

                                    </div>

                                    <!-- Autos -->
                                    <div class="tg-dashboardholder"
                                        *ngIf="this.selectedCategory.trim().toLowerCase() == '5bf243b1-2be4-4dda-93a0-cf9e06209e0f'"
                                        id="divAutos">
                                        <!-- <div class="form-group" id="divKM" *ngIf="this.selectedSubCategory.trim().toUpperCase() =='79504CCA-41F7-4936-ACF3-6757E9AA7E29' || this.selectedSubCategory.trim().toUpperCase() =='FE1C13DE-9342-4EFB-824B-806DA0B8E3FE' || this.selectedSubCategory.trim().toUpperCase() =='6240A3B2-15B4-49AC-BF96-B30311BAEC60'">
                                            <input type="text "  class="form-control " placeholder="Kilometers " >
                                            <div class="form-group">
                                                <div *ngIf="selectedKmAlert == true"
                                                    class="alert alert-danger">
                                                  <div *ngIf="selectedKmAlert == true">
                                                    Km's are required !
                                                  </div>                                                     
                                                </div>
                                              </div>
                                        </div> -->
                                        <div id="divKmTitle">
                                            <strong>Kilometers Range:</strong>
                                        </div>
                                        <div class="form-group" id="divKMRangee"
                                            *ngIf="this.selectedSubCategory.trim().toUpperCase() =='9C89C78F-B925-4129-A95B-D4D750954AA3' || this.selectedSubCategory.trim().toUpperCase() =='042F8B36-2F6E-4A05-8DF0-2BC49710D67C' || this.selectedSubCategory.trim().toUpperCase() =='79504CCA-41F7-4936-ACF3-6757E9AA7E29' || this.selectedSubCategory.trim().toUpperCase() =='FE1C13DE-9342-4EFB-824B-806DA0B8E3FE' || this.selectedSubCategory.trim().toUpperCase() =='6240A3B2-15B4-49AC-BF96-B30311BAEC60'">
                                            <ng5-slider [(value)]="minkmValue" [(highValue)]="maxkmValue"
                                                [options]="optionsKM"></ng5-slider>
                                        </div>
                                        <!--New Changes Commited -->
                                        <div class="form-group" id="divYears"
                                            *ngIf="this.selectedSubCategory.trim().toUpperCase() =='79504CCA-41F7-4936-ACF3-6757E9AA7E29' || this.selectedSubCategory.trim().toUpperCase() =='FE1C13DE-9342-4EFB-824B-806DA0B8E3FE' || this.selectedSubCategory.trim().toUpperCase() =='6240A3B2-15B4-49AC-BF96-B30311BAEC60'">
                                            <!-- <select style="width: 100%;" [(ngModel)]="selectedYear" aria-placeholder="Select Year" name="ddYears">
                                                <option value="0">Select Year</option>
                                                <option *ngFor="let year of YearsList" value={{year.id}}>{{year.title}}</option>
                                            </select> -->
                                            <ng-select [items]="YearsList" bindLabel="title" placeholder="Select Year"
                                                appendTo="body" id="selectedYear" name="selectedYear"
                                                [(ngModel)]="selectedYear">
                                            </ng-select>
                                            <div class="form-group">
                                                <div *ngIf="selectedYearAlert == true" class="alert alert-danger">
                                                    <div *ngIf="selectedYearAlert == true">
                                                        Year is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group" id="divDoors"
                                            *ngIf="this.selectedSubCategory.trim().toUpperCase() =='79504CCA-41F7-4936-ACF3-6757E9AA7E29' || this.selectedSubCategory.trim().toUpperCase() =='FE1C13DE-9342-4EFB-824B-806DA0B8E3FE'">
                                            <ng-select [items]="DoorsList" bindLabel="title" placeholder="Select Doors"
                                                appendTo="body" id="selectedDoor" name="selectedDoor"
                                                [(ngModel)]="selectedDoor">
                                            </ng-select>
                                            <div class="form-group">
                                                <div *ngIf="selectedDoorAlert == true" class="alert alert-danger">
                                                    <div *ngIf="selectedDoorAlert == true">
                                                        Doors are required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group" id="divColor"
                                            *ngIf="this.selectedSubCategory.trim().toUpperCase() =='79504CCA-41F7-4936-ACF3-6757E9AA7E29' || this.selectedSubCategory.trim().toUpperCase() =='FE1C13DE-9342-4EFB-824B-806DA0B8E3FE' || this.selectedSubCategory.trim().toUpperCase() =='6240A3B2-15B4-49AC-BF96-B30311BAEC60'">
                                            <ng-select [items]="ColorsList" bindLabel="title"
                                                placeholder="Select Colors" appendTo="body" id="selectedColor"
                                                name="selectedColor" [(ngModel)]="selectedColor">
                                            </ng-select>
                                            <div class="form-group">
                                                <div *ngIf="selectedColorAlert == true" class="alert alert-danger">
                                                    <div *ngIf="selectedColorAlert == true">
                                                        Colors is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group" id="divBodyCondition"
                                            *ngIf="this.selectedSubCategory.trim().toUpperCase() =='79504CCA-41F7-4936-ACF3-6757E9AA7E29' || this.selectedSubCategory.trim().toUpperCase() =='FE1C13DE-9342-4EFB-824B-806DA0B8E3FE' || this.selectedSubCategory.trim().toUpperCase() =='6240A3B2-15B4-49AC-BF96-B30311BAEC60'">
                                            <ng-select [items]="BodyConditionList" bindLabel="title"
                                                placeholder="Select Body condition" appendTo="body"
                                                id="selectedBodyCondition" name="selectedBodyCondition"
                                                [(ngModel)]="selectedBodyCondition">
                                            </ng-select>
                                            <div class="form-group">
                                                <div *ngIf="selectedBcAlert == true" class="alert alert-danger">
                                                    <div *ngIf="selectedBcAlert == true">
                                                        Mechnical condition is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group" id="divMacCondition"
                                            *ngIf="this.selectedSubCategory.trim().toUpperCase() =='79504CCA-41F7-4936-ACF3-6757E9AA7E29' || this.selectedSubCategory.trim().toUpperCase() =='FE1C13DE-9342-4EFB-824B-806DA0B8E3FE' || this.selectedSubCategory.trim().toUpperCase() =='6240A3B2-15B4-49AC-BF96-B30311BAEC60'">
                                            <ng-select [items]="MechenicalConditionList" bindLabel="title"
                                                placeholder="Select Mec- condition" appendTo="body"
                                                id="selectedMechenicalCondition" name="selectedMechenicalCondition"
                                                [(ngModel)]="selectedMechenicalCondition">
                                            </ng-select>
                                            <div class="form-group">
                                                <div *ngIf="selectedMcAlert == true" class="alert alert-danger">
                                                    <div *ngIf="selectedMcAlert == true">
                                                        Mechnical condition is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="form-group" id="divCondition" *ngIf="this.selectedSubCategory.trim().toUpperCase() =='042F8B36-2F6E-4A05-8DF0-2BC49710D67C' || this.selectedSubCategory.trim().toUpperCase() =='9C89C78F-B925-4129-A95B-D4D750954AA3'">
                                            <ng-select [items]="BodyConditionList" bindLabel="title" placeholder="Select Body condition" appendTo="body" [(ngModel)]="selectedBodyCondition">
                                            </ng-select>
                                        </div> -->

                                        <div class="form-group" id="divAge"
                                            *ngIf="this.selectedSubCategory.trim().toUpperCase() =='042F8B36-2F6E-4A05-8DF0-2BC49710D67C' || this.selectedSubCategory.trim().toUpperCase() =='9C89C78F-B925-4129-A95B-D4D750954AA3'">
                                            <!-- <ng-select [items]="AgeList" bindLabel="title" placeholder="Select Age" appendTo="body" id="ddlAge" name="ddlAge" ngModel  formControlName="ddlAge"> -->
                                            <ng-select [items]="AutoAgeList" bindLabel="title" placeholder="Select Age"
                                                appendTo="body" id="selectedAutoAge" name="selectedAutoAge"
                                                [(ngModel)]="selectedAutoAge">
                                            </ng-select>
                                            <div class="form-group">
                                                <div *ngIf="selectedAutoAgeAlert == true" class="alert alert-danger">
                                                    <div *ngIf="selectedAutoAgeAlert == true">
                                                        Age is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group" id="divUsage"
                                            *ngIf="this.selectedSubCategory.trim().toUpperCase() =='042F8B36-2F6E-4A05-8DF0-2BC49710D67C' || this.selectedSubCategory.trim().toUpperCase() =='9C89C78F-B925-4129-A95B-D4D750954AA3'">
                                            <ng-select [items]="UsageList" bindLabel="title" placeholder="Select Usage"
                                                appendTo="body" id="selectedAutoUsage" name="selectedAutoUsage"
                                                [(ngModel)]="selectedAutoUsage">
                                            </ng-select>
                                            <div class="form-group">
                                                <div *ngIf="selectedAutoUsageAlert == true" class="alert alert-danger">
                                                    <div *ngIf="selectedAutoUsageAlert == true">
                                                        Usage is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group" id="divTrim"
                                            *ngIf="this.selectedSubCategory.trim().toUpperCase() =='FE1C13DE-9342-4EFB-824B-806DA0B8E3FE' || this.selectedSubCategory.trim().toUpperCase() =='79504CCA-41F7-4936-ACF3-6757E9AA7E29'">
                                            <ng-select [items]="TrimList" bindLabel="title" placeholder="Select Trim"
                                                appendTo="body" id="selectedTrim" name="selectedTrim"
                                                [(ngModel)]="selectedTrim">
                                            </ng-select>
                                            <div class="form-group">
                                                <div *ngIf="selectedTrimAlert == true" class="alert alert-danger">
                                                    <div *ngIf="selectedTrimAlert == true">
                                                        Trim is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group " id="divBodyType "
                                            *ngIf="this.selectedSubCategory.trim().toUpperCase() =='FE1C13DE-9342-4EFB-824B-806DA0B8E3FE' || this.selectedSubCategory.trim().toUpperCase() =='79504CCA-41F7-4936-ACF3-6757E9AA7E29'">
                                            <ng-select [items]="BodyTypeList" bindLabel="title"
                                                placeholder="Select Body Type" appendTo="body" id="selectedBodyType"
                                                name="selectedBodyType" [(ngModel)]="selectedBodyType">
                                            </ng-select>
                                            <div class="form-group">
                                                <div *ngIf="selectedBtAlert == true" class="alert alert-danger">
                                                    <div *ngIf="selectedBtAlert == true">
                                                        Body Type is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group " id="divCylender "
                                            *ngIf="this.selectedSubCategory.trim().toUpperCase() =='FE1C13DE-9342-4EFB-824B-806DA0B8E3FE' || this.selectedSubCategory.trim().toUpperCase() =='79504CCA-41F7-4936-ACF3-6757E9AA7E29'">
                                            <ng-select [items]="CylenderList" bindLabel="title"
                                                placeholder="Select Cylender" appendTo="body" id="selectedCylender"
                                                name="selectedCylender" [(ngModel)]="selectedCylender">
                                            </ng-select>
                                            <div class="form-group">
                                                <div *ngIf="selectedClAlert == true" class="alert alert-danger">
                                                    <div *ngIf="selectedClAlert == true">
                                                        Cylender is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group " id="divTransmission "
                                            *ngIf="this.selectedSubCategory.trim().toUpperCase() =='FE1C13DE-9342-4EFB-824B-806DA0B8E3FE' || this.selectedSubCategory.trim().toUpperCase() =='79504CCA-41F7-4936-ACF3-6757E9AA7E29'">
                                            <ng-select [items]="TransmissionList" bindLabel="title"
                                                placeholder="Select Transmission" appendTo="body"
                                                id="selectedTransmission" name="selectedTransmission"
                                                [(ngModel)]="selectedTransmission">
                                            </ng-select>
                                            <div class="form-group">
                                                <div *ngIf="selectedTransmissionAlert == true"
                                                    class="alert alert-danger">
                                                    <div *ngIf="selectedTransmissionAlert == true">
                                                        Transmission is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group " id="divHP "
                                            *ngIf="this.selectedSubCategory.trim().toUpperCase() =='FE1C13DE-9342-4EFB-824B-806DA0B8E3FE' || this.selectedSubCategory.trim().toUpperCase() =='79504CCA-41F7-4936-ACF3-6757E9AA7E29'">
                                            <ng-select [items]="FuelTypeList" bindLabel="title" placeholder="Select FT"
                                                appendTo="body" id="selectedFuelType" name="selectedFuelType"
                                                [(ngModel)]="selectedFuelType">
                                            </ng-select>
                                            <div class="form-group">
                                                <div *ngIf="selectedFTAlert == true" class="alert alert-danger">
                                                    <div *ngIf="selectedFTAlert == true">
                                                        Fuel Type is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group " id="divFT"
                                            *ngIf="this.selectedSubCategory.trim().toUpperCase() =='FE1C13DE-9342-4EFB-824B-806DA0B8E3FE' || this.selectedSubCategory.trim().toUpperCase() =='79504CCA-41F7-4936-ACF3-6757E9AA7E29'">
                                            <ng-select [items]="HPList" bindLabel="title" placeholder="Select HP"
                                                appendTo="body" id="selectedHPList" name="selectedHPList"
                                                [(ngModel)]="selectedHPList">
                                            </ng-select>
                                            <div class="form-group">
                                                <div *ngIf="selectedHpAlert == true" class="alert alert-danger">
                                                    <div *ngIf="selectedHpAlert == true">
                                                        Horse Power is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group " id="divWarranty "
                                            *ngIf="this.selectedSubCategory.trim().toUpperCase() =='042F8B36-2F6E-4A05-8DF0-2BC49710D67C' || this.selectedSubCategory.trim().toUpperCase() =='79504CCA-41F7-4936-ACF3-6757E9AA7E29' || this.selectedSubCategory.trim().toUpperCase() =='FE1C13DE-9342-4EFB-824B-806DA0B8E3FE' || this.selectedSubCategory.trim().toUpperCase() =='6240A3B2-15B4-49AC-BF96-B30311BAEC60'">
                                            <ng-select [items]="WarrantyList" bindLabel="title"
                                                placeholder="Select Warranty" appendTo="body" id="selectedWarranty"
                                                name="selectedWarranty" [(ngModel)]="selectedWarranty">
                                            </ng-select>
                                            <div class="form-group">
                                                <div *ngIf="selectedWlAlert == true" class="alert alert-danger">
                                                    <div *ngIf="selectedWlAlert == true">
                                                        Warranty List is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group " id="divFacilities">
                                            <!-- facilitied required -->
                                            <label *ngFor="let item of autoFacilitiesNew; let i = index">
                                                <input type="checkbox" name="i" [(ngModel)]="item.id"
                                                    checked="item.enabled">
                                                {{item[i].title}}
                                            </label>
                                            <div class="form-group">
                                                <!-- <div *ngIf="selectedWlAlert == true" class="alert alert-danger">
                                                <div *ngIf="selectedWlAlert == true">
                                                    Warranty List is required !
                                                </div>
                                            </div> -->
                                            </div>
                                        </div>
                                        <!-- <div class="form-group " id="divEngine " *ngIf="this.selectedSubCategory.trim().toUpperCase() =='FE1C13DE-9342-4EFB-824B-806DA0B8E3FE' || this.selectedSubCategory.trim().toUpperCase() =='79504CCA-41F7-4936-ACF3-6757E9AA7E29'">
                                            <ng-select [items]="EngineList" bindLabel="title" placeholder="Select Engine" appendTo="body"  id="selectedEngine" name="selectedEngine" [(ngModel)]="selectedEngine" >
                                            </ng-select>
                                            <div class="form-group">
                                                <div *ngIf="selectedElAlert == true"
                                                    class="alert alert-danger">
                                                  <div *ngIf="selectedElAlert == true">
                                                    Engine List is required !
                                                  </div>                                                     
                                                </div>
                                              </div>
                                        </div> -->
                                        <div class="form-group " id="divBikeEngine "
                                            *ngIf="this.selectedSubCategory.trim().toUpperCase() =='6240A3B2-15B4-49AC-BF96-B30311BAEC60' ">
                                            <ng-select [items]="EngineBikeList" bindLabel="title"
                                                placeholder="Select Engine" appendTo="body"
                                                [(ngModel)]="selectedEngineBike" name="selectedEngineBike"
                                                id="selectedEngineBike">
                                            </ng-select>
                                            <div class="form-group">
                                                <div *ngIf="selectedEbAlert == true" class="alert alert-danger">
                                                    <div *ngIf="selectedEbAlert == true">
                                                        Engine Bike is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Classified -->
                                    <div class="tg-dashboardholder"
                                        *ngIf="this.selectedCategory.trim().toLowerCase() == 'd7b411fb-1c21-4f0a-88f7-34d6b789f67d'"
                                        id="divClassified">
                                        <div class="form-group" id="divAge">
                                            <ng-select [items]="AgeList" bindLabel="title" placeholder="Select Age"
                                                appendTo="body" [(ngModel)]="selectedAge" name="selectedAge"
                                                id="selectedAge">
                                            </ng-select>
                                            <div class="form-group">
                                                <div *ngIf="selectedAgeClassAlert == true" class="alert alert-danger">
                                                    <div *ngIf="selectedAgeClassAlert == true">
                                                        Age is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group" id="divUsage">
                                            <ng-select [items]="UsageList" bindLabel="title" placeholder="Select Usage"
                                                appendTo="body" [(ngModel)]="selectedUsage" name="selectedUsage"
                                                id="selectedUsage">
                                            </ng-select>
                                            <div class="form-group">
                                                <div *ngIf="selectedUsageAlert == true" class="alert alert-danger">
                                                    <div *ngIf="selectedUsageAlert == true">
                                                        Usage is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group" id="divCondition">
                                            <ng-select [items]="ConditionList" bindLabel="title"
                                                placeholder="Select Condition" appendTo="body"
                                                [(ngModel)]="selectedCondition" name="selectedCondition"
                                                id="selectedCondition">
                                            </ng-select>
                                            <div class="form-group">
                                                <div *ngIf="selectedConditionAlert == true" class="alert alert-danger">
                                                    <div *ngIf="selectedConditionAlert == true">
                                                        Condition is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- hiring Jobs -->
                                    <div class="tg-dashboardholder"
                                        *ngIf="this.selectedSubCategory.trim().toUpperCase() == '967CEED7-54C0-488E-8AA8-69E6045E6C05'"
                                        id="divJobsHiring">
                                        <div id="divSalaryTitle ">
                                            <strong>Select Salary Range:</strong>
                                        </div>

                                        <div class="form-group " id="divSalaryRangee ">
                                            <ng5-slider [(value)]="minSalValue" [(highValue)]="maxSalValue"
                                                [options]="optionsSal"></ng5-slider>
                                        </div>
                                        <div id="divAgeTitle ">
                                            <strong>Select Age Range:</strong>
                                        </div>
                                        <div class="form-group " id="divAgeRangee ">
                                            <ng5-slider [(value)]="minAgeValue" [(highValue)]="maxAgeValue"
                                                [options]="optionsAge"></ng5-slider>
                                        </div>
                                        <div class="form-group ">
                                            <strong>Salary:</strong>
                                            <div class="tg-selectgroup">
                                                <span class="tg-radio">
                                                    <input id="tg-sameuser" type="radio" name="showSalary"
                                                        [(ngModel)]="showSalary" value="Show" checked="">
                                                    <label for="tg-sameuser">Show Salary</label>
                                                </span>
                                                <span class="tg-radio">
                                                    <input id="tg-someoneelse" type="radio" name="showSalary"
                                                        [(ngModel)]="showSalary" value="noShow">
                                                    <label for="tg-someoneelse">Don't Show Salary</label>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="form-group ">
                                            <strong>Travel:</strong>
                                            <div class="tg-selectgroup">
                                                <span class="tg-radio">
                                                    <input id="tg-travelRequired" type="radio" name="travelType"
                                                        [(ngModel)]="travelType" value="Required">
                                                    <label for="tg-travelRequired">Required</label>
                                                </span>
                                                <span class="tg-radio">
                                                    <input id="tg-travelNot" type="radio" name="travelType"
                                                        [(ngModel)]="travelType" value="Not Required" checked="">
                                                    <label for="tg-travelNot">Not Required</label>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="form-group " id="divDegree ">
                                            <input type="text " [(ngModel)]="this.selectedDegree " class="form-control "
                                                placeholder="Degree " name="txtDegree">
                                        </div>
                                        <div class="form-group " id="divPositions ">
                                            <ng-select [items]="PositionList" bindLabel="title"
                                                placeholder="Select Position" appendTo="body"
                                                [(ngModel)]="selectedPosition" name="selectedPosition"
                                                id="selectedPosition">
                                            </ng-select>
                                            <div class="form-group">
                                                <div *ngIf="selectedPositionAlert == true" class="alert alert-danger">
                                                    <div *ngIf="selectedPositionAlert == true">
                                                        Position is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group " id="divGender ">
                                            <ng-select [items]="RGenderList" bindLabel="title"
                                                placeholder="Select Gender" appendTo="body"
                                                [(ngModel)]="selectedRGender" name="selectedRGender" id=""
                                                selectedRGender>
                                            </ng-select>
                                            <div class="form-group">
                                                <div *ngIf="selectedGenderAlert == true" class="alert alert-danger">
                                                    <div *ngIf="selectedGenderAlert == true">
                                                        Gender is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- want job -->
                                    <div class="tg-dashboardholder " id="divJobsWanted "
                                        *ngIf="this.selectedSubCategory.trim().toUpperCase() == 'A07D5BE2-BC59-4086-82F5-1E1DF97EC87A'">
                                        <input type="number" numbersOnly [(ngModel)]="selectedCompensation "
                                            class="form-control " placeholder="Compensation " name="txtCompensation">
                                        <!-- <input type="text " name="enterDegree " ng-model="this.selectedCompensation " class="form-control " placeholder="Enter Compensation ... "> -->
                                        <div class="form-group">
                                            <div *ngIf="selectedCompensationAlert== true" class="alert alert-danger">
                                                <div *ngIf="selectedCompensationAlert == true">
                                                    Compensation is required !
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- main Job for both -->
                                    <div class="tg-dashboardholder " id="divJobsCommon "
                                        *ngIf="this.selectedCategory.trim().toLowerCase() == '3c064386-a300-4f90-8e18-f6da9f83aa1c'">
                                        <div class="form-group " id="divExperience ">
                                            <ng-select [items]="RWEList" bindLabel="title"
                                                placeholder="Select Experience" appendTo="body"
                                                [(ngModel)]="selectedRWE" name="selectedRWE" id="selectedRWE">
                                            </ng-select>
                                            <div class="form-group">
                                                <div *ngIf="selectedExperienceAlert == true" class="alert alert-danger">
                                                    <div *ngIf="selectedExperienceAlert == true">
                                                        Experience is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group " id="divEducation ">
                                            <ng-select [items]="REducationList" bindLabel="title"
                                                placeholder="Select Education" appendTo="body"
                                                [(ngModel)]="selectedREducation" name="selectedREducation"
                                                id="selectedREducation">
                                            </ng-select>
                                            <div class="form-group">
                                                <div *ngIf="selectedEducationAlert == true" class="alert alert-danger">
                                                    <div *ngIf="selectedEducationAlert == true">
                                                        Education is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group " id="divCommitment ">
                                            <ng-select [items]="RCommitmentList" bindLabel="title"
                                                placeholder="Select Commitement" appendTo="body"
                                                [(ngModel)]="selectedRCommitment" name="selectedRCommitment"
                                                id="selectedRCommitment">
                                            </ng-select>
                                            <div class="form-group">
                                                <div *ngIf="selectedCommitementAlert == true"
                                                    class="alert alert-danger">
                                                    <div *ngIf="selectedCommitementAlert == true">
                                                        Commitement is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group " id="divShift ">
                                            <ng-select [items]="RShiftList" bindLabel="title" placeholder="Select Shift"
                                                appendTo="body" [(ngModel)]="selectedRShift" name="selectedRShift"
                                                id="selectedRShift">
                                            </ng-select>
                                            <div class="form-group">
                                                <div *ngIf="selectedShiftAlert == true" class="alert alert-danger">
                                                    <div *ngIf="selectedShiftAlert == true">
                                                        Shift is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Property For Rent -->
                                    <div class="tg-dashboardholder " id="divProperty"
                                        *ngIf=" this.selectedCategory.trim().toLowerCase() == '0372dc00-f9cb-4cb5-8b95-901ea8a02efa' || this.selectedCategory.trim().toLowerCase() == '27bbb528-5666-4429-b153-7d6441c0efa1'">
                                        <div class="form-group" id="DateMonth" *ngIf="this.selectedMonthShow == true">
                                            <input type="date" value="30/13/2021" id="selectedMonth"
                                                [(ngModel)]="this.selectedMonth " class="form-control "
                                                placeholder="Select Month " name="txtDate">
                                            <div class="form-group">
                                                <div *ngIf="selectedMonthAlert == true" class="alert alert-danger">
                                                    <div *ngIf="selectedMonthAlert == true">
                                                        Ready date is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group " id="Furnishedratiobtn"
                                            *ngIf="this.furnishedShow == true">
                                            <!-- <strong>Furnished:</strong>
                                            <div class="tg-selectgroup " styel="margin-bottom:10px ">

                                                <span class="tg-radio ">
                                                <div class="tg-selectgroup " styel="width:100%">
                                                    <div style="width: 40%;float: left;">
                                                        <input type="checkbox" id="furnished"  name="furnished" [(ngModel)]="furnishedIsChecked" />
                                                    </div>
                                                    <div style="width: 40%;float: left;">
                                                        <label for="tg-Balcony">Furnished</label>
                                                    </div>
                                                </div>  
                                                </span>
                                            </div> -->
                                            <!-- <strong>Furnished :</strong>
                                            <div class="tg-selectgroup mb-2">
                                                <span class="tg-radio">
                                                    <input id="tg-Furnished" type="radio" name="Furnished"  [(ngModel)]="furnishedIsChecked" value="furnishedShow">
                                                    <label for="tg-Furnished">Furnished</label>
                                                </span>
                                                <span class="tg-radio">
                                                    <input id="tg-NOT" type="radio" name="Furnished" [(ngModel)]="furnishedIsChecked" value="NOT">
                                                    <label for="tg-NOT">Not Furnished</label>
                                                </span>
                                            </div> -->
                                        </div>
                                        <div class="form-group " id="Landratiobtn"
                                            *ngIf=" this.showLanLordOption == true">
                                            <strong>Select:</strong>
                                            <div class="tg-selectgroup mb-2">
                                                <span class="tg-radio">
                                                    <input id="tg-LanLord" type="radio" name="LanLord"
                                                        [(ngModel)]="LanLord" value="LanLord">
                                                    <label for="tg-LanLord">LanLord</label>
                                                </span>
                                                <span class="tg-radio">
                                                    <input id="tg-Agent" type="radio" name="LanLord"
                                                        [(ngModel)]="LanLord" value="Agent">
                                                    <label for="tg-Agent">Agent</label>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="form-group " id="Facilityratiobtn"
                                            *ngIf="this.showBalconyOption == true">
                                            <strong>Facilities :</strong>
                                            <div class="tg-selectgroup " styel="margin-bottom:10px; width:100%">
                                                <div style="float: left;">
                                                    <span class="tg-radio">
                                                        <div class="tg-selectgroup " styel="width:100%">
                                                            <div style="width: 20%;float: left;">
                                                                <input type="checkbox" id="furnished" name="furnished"
                                                                    [(ngModel)]="furnishedIsChecked" />
                                                            </div>
                                                            <div style="width: 40%;float: left;">
                                                                <label for="tg-Balcony">Furnished</label>
                                                            </div>
                                                        </div>
                                                    </span>
                                                    <span class="tg-radio">
                                                        <!-- <input id="tg-Balcony" type="radio" name="Balcony"
                                                            [(ngModel)]="this.Balcony"> -->
                                                        <div class="tg-selectgroup " styel="width:100%">
                                                            <div style="width: 20%;float: left;">
                                                                <input type="checkbox" id="Balcony" name="Balcony"
                                                                    [(ngModel)]="BalconyIsChecked" />
                                                            </div>
                                                            <div style="width: 40%;float: left;">
                                                                <label for="tg-Balcony">Balcony</label>
                                                            </div>
                                                        </div>
                                                    </span>
                                                    <span class="tg-radio ">
                                                        <div class="tg-selectgroup " styel="width:100%">
                                                            <div style="width: 20%;float: left;">
                                                                <input type="checkbox" id="Parking" name="Parking"
                                                                    [(ngModel)]="ParkingIsChecked" />
                                                            </div>
                                                            <div style="width: 40%;float: left;">
                                                                <label for="tg-Parking ">Parking</label>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </div>
                                                <!-- <div style="width: 40%;float: left">
                                                    
                                                </div> -->
                                            </div>
                                        </div>
                                        <div class="form-group " *ngIf="">
                                            <strong>Facilities:</strong>
                                            <!-- <div class="tg-selectgroup " styel="margin-bottom:10px ">
                                                <span class="tg-radio ">
                                                                <input id="tg-travelRequired" type="radio" name="travelRequired" [(ngModel)]="this.travelRequired" ng-model="vm.travelRequiredChk" value="travelRequired" >
                                                                <label for="tg-travelRequired ">Required</label>
                                                            </span>
                                                <span class="tg-radio ">
                                                                <input id="tg-travelNotRequired" type="radio" name="travelRequired" ng-model="vm.travelRequired" value="travelNotRequired" checked>
                                                                <label for="tg-travelNotRequired">Not Required</label>
                                                            </span>
                                            </div> -->
                                        </div>
                                        <div class="form-group" id="divReadyDate" *ngIf="">
                                            <input type="text" [(ngModel)]="this.FacilityReadyDate" class="form-control"
                                                placeholder="Select Ready" name="txtReadyDate">
                                        </div>
                                        <div class="form-group" id="divBuilding"
                                            *ngIf="this.showBuildingOption == true">
                                            <input type="text" [(ngModel)]="this.FacilityBuilding" class="form-control"
                                                placeholder="Enter Building" name="txtBuilding">
                                            <div class="form-group">
                                                <div *ngIf="this.FacilityBuildingAlert == true"
                                                    class="alert alert-danger">
                                                    <div *ngIf="this.FacilityBuildingAlert == true">
                                                        Building is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group" id="divSize" *ngIf="this.showSizeOption == true">
                                            <input type="number" numbersOnly [(ngModel)]="this.FacilitySize" class="form-control"
                                                placeholder="Enter Size (ex-750 sq.m.)" name="txtSize">
                                            <div class="form-group">
                                                <div *ngIf="this.facilitySizeALert == true" class="alert alert-danger">
                                                    <div *ngIf="this.facilitySizeALert == true">
                                                        Size is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group " id="divBeds" *ngIf="this.showBedOptions == true;">
                                            <ng-select [items]="BedsList" bindLabel="title" placeholder="Select Beds"
                                                appendTo="body" [(ngModel)]="selectedBeds" name="ddlBeds"
                                                id="selectedBeds">
                                            </ng-select>
                                            <div class="form-group">
                                                <div *ngIf="selectedBedsAlert == true" class="alert alert-danger">
                                                    <div *ngIf="selectedBedsAlert == true">
                                                        Beds is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group" id="divBaths" *ngIf="this.showBathOptions == true">
                                            <ng-select [items]="BathsList" bindLabel="title" placeholder="Select Bath"
                                                appendTo="body" [(ngModel)]="selectedBaths" name="selectedBaths"
                                                id="selectedBaths">
                                            </ng-select>
                                            <div class="form-group">
                                                <div *ngIf="selectedBathsAlert == true" class="alert alert-danger">
                                                    <div *ngIf="selectedBathsAlert == true">
                                                        Bath Room is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group" id="divDuration" *ngIf="this.showDurationOPtion ==true">
                                            <ng-select [items]="DurationList" bindLabel="title"
                                                placeholder="Select Duration" appendTo="body"
                                                [(ngModel)]="selectedDuration" name="selectedDuration"
                                                id="selectedDuration">
                                            </ng-select>
                                            <div class="form-group">
                                                <div *ngIf="selectedDurationAlert == true" class="alert alert-danger">
                                                    <div *ngIf="selectedDurationAlert == true">
                                                        Duration is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group" id="divRentPaid"
                                            *ngIf="this.showRentPaidOption == true">
                                            <ng-select [items]="PaidList" bindLabel="title"
                                                placeholder="Select Paid Type" appendTo="body"
                                                [(ngModel)]="selectedPaid" name="selectedPaid" id="selectedPaid">
                                            </ng-select>
                                            <div class="form-group">
                                                <div *ngIf="selectedPaidAlert == true" class="alert alert-danger">
                                                    <div *ngIf="selectedPaidAlert == true">
                                                        Paid is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div style="margin-left: 5%;">
                                        <a href="#" style="background-color: #00cc67!important;" class="tg-btn"
                                            data-toggle="modal" data-target=".tg-locationmodal">Location</a>
                                    </div>

                                    <div class="tg-dashboardholder" id="divCommon">
                                        <div class="form-group">
                                            <div>Latitude: {{latitude}}</div>
                                            <div>Longitude: {{longitude}}</div>
                                            <h5>Current Location: {{address}}</h5>
                                            <!-- <input type="text" numbersOnly disabled  class="form-control" placeholder="latitude" id="latitude" name="latitude" [(ngModel)]="latitude"> -->

                                        </div>
                                        <div class="form-group">
                                            <!-- <input type="text" numbersOnly disabled  class="form-control" placeholder="longitude*" id="longitude" name="longitude" [(ngModel)]="longitude"> -->
                                        </div>
                                        <!-- <button class="tg-btn" type="button" (click)="checkData()">Post Ad</button> -->
                                        <button class="tg-btn" type="submit">Submit</button>
                                    </div>
                                </div>
                            </div>                           
                        </div>
                        <div>
                            <!-- <p ng-bind-html="vm.selectedAdDetails "></p> -->
                        </div>
                    </fieldset>
                    <div class="form-group text-center row">

                    </div>

                </form>
            </div>
        </section>
        <!--************************************
					Section End
			*************************************-->
    </main>
    <div class="modal fade tg-thememodal tg-categorymodal " tabindex="-1 " role="dialog ">
        <div class="modal-dialog tg-thememodaldialog " role="document ">
            <div class="modal-content tg-thememodalcontent " style="min-height: 200px; ">
                <div class="tg-title ">
                    <strong style="font-size: 16px; ">Select Category</strong>
                    <!-- <p id="finalSelectionAlert " style="margin-top: 1%;display:none " class="tg-alert alert alert-success fade in ">{{vm.finalSelectionMessage}}</p> -->
                </div>
                <div>
                    <!-- <div ng-click="vm.GetCatByParentID(categoroies,1) " class="col-lg-3 col-md-3 col-sm-3 col-xs-3 btn-default " style="padding: 0px " ng-repeat="categoroies in vm.categories ">
                        <div class="bon1 ">
                            <div style="padding: 10px; ">
                                <div class="center moduleText ">
                                    <i class="{{categoroies.icon}} "></i>
                                     <a> {{categoroies.Cat}}</a>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <select id="mainCategory " [(ngModel)]="selectedCategory" aria-placeholder="Select Category"
                        (change)="getSubCategories(0,$event) " style="width: 100%; " name="ddCategory">
                        <option value="0">All Types</option>
                        <option value="5bf243b1-2be4-4dda-93a0-cf9e06209e0f ">Autos</option>
                        <option value="d7b411fb-1c21-4f0a-88f7-34d6b789f67d ">Classified</option>
                        <option value="3c064386-a300-4f90-8e18-f6da9f83aa1c ">Jobs</option>
                        <option value="0372dc00-f9cb-4cb5-8b95-901ea8a02efa ">Property For Rent</option>
                        <option value="27bbb528-5666-4429-b153-7d6441c0efa1 ">Property For Sale</option>
                        <!-- <option value="c4e1f606-8105-4666-a8bc-c6657730a0df ">Deals</option>
                        <option value="C4E1F606-8105-4666-A8BC-C6657730A0DF ">Products</option>
                        <option value="C4E1F606-8105-4666-A8BC-C6657730A0DF ">Suppliers</option> -->
                    </select>
                </div>
                <div *ngIf="this.levelToShow> 0" style="width: 100%;margin-top: 10px;margin-bottom: 10px;">
                    <div class="tg-title">
                        <strong style="font-size: 16px;">Select Sub Category</strong>
                    </div>
                    <div class=" ">
                        <select style="width: 100%;" [(ngModel)]="selectedSubCategory"
                            (change)="getSubCategories(1,$event)" name="ddSubCategory">
                            <option value="0">All Types</option>
                            <option *ngFor="let subCat of subCategories" value={{subCat.catID}}>{{subCat.catName}}
                            </option>
                        </select>
                    </div>
                </div>
                <div *ngIf="this.levelToShow > 1" style="width: 100%;margin-top: 10px;margin-bottom: 10px;">
                    <div class="tg-title">
                        <strong style="font-size: 16px;">Select Child Category</strong>
                    </div>
                    <div class="">
                        <select style="width: 100%;" [(ngModel)]="selectedSubCatFirstChild"
                            (change)="getSubCategories(2,$event)" name="ddSubCategoryChildOne">
                            <option value="0">All Types</option>
                            <option *ngFor="let subCatFirst of subCatFirstChildList" value={{subCatFirst.catID}}>
                                {{subCatFirst.catName}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="this.levelToShow > 2" style="width: 100%;margin-top: 10px;margin-bottom: 10px;">
                    <div class="tg-title">
                        <strong style="font-size: 16px;">Select Child Category</strong>
                    </div>
                    <div class="">
                        <select style="width: 100%;" [(ngModel)]="selectedSubCatSecondChild"
                            (change)="getSubCategories(3,$event)" name="ddSubCategoryChildTwo">
                            <option value="0">All Types</option>
                            <option *ngFor="let subCatSecond of subCatSecondChildList" value={{subCatSecond.catID}}>
                                {{subCatSecond.catName}}</option>
                        </select>
                    </div>
                </div>

                <div style="margin-top: 50px;">
                    <button data-dismiss="modal" aria-label="Close" type="button" class="tg-btn"
                        style="background-color:#00cc67!important">Close</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade tg-thememodal tg-locationmodal " tabindex="-1 " role="dialogNew">
        <div class="modal-dialog tg-thememodaldialog " role="document ">
            <div class="modal-content tg-thememodalcontent " style="min-height: 200px; ">
                <div class="tg-title ">
                    <strong style="font-size: 16px; ">Select Location</strong>
                    <!-- <p id="finalSelectionAlert " style="margin-top: 1%;display:none " class="tg-alert alert alert-success fade in ">
                        {{vm.finalSelectionMessage}}</p> -->
                </div>
                <div>
                    <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
                        <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
                    </agm-map>

                    <!-- <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" style="">
                        <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true" (dragEnd)="markerDragEnd($event)"></agm-marker>
                        <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
                    </agm-map> -->

                    <h5>Address: {{address}}</h5>
                    <div>Latitude: {{latitude}}</div>
                    <div>Longitude: {{longitude}}</div>

                </div>
                <div style="margin-top: 50px;">
                    <button data-dismiss="modal" aria-label="Close" type="button" class="tg-btn"
                        style="background-color:#00cc67!important">Close</button>
                </div>
            </div>
        </div>
    </div>

</div>