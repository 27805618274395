export class Product {
    proID : number;
    proName : string='';
    proBrandName : string='';
    proDescription : string='';
    proAddedBy : string='';
    proEditedBy : string='';
    proUserID : string='';
    prooComID : string='';
    proCatID : string='';
    proSubCatID : string='';
    proEndCatID : string='';
    proQuantity : number=1;
    proDiscount : number=0;
    DiscountCheck : boolean;
    proTotal : number=0;
    proPrice : string='';
    proPriceUnit : string='';
    proMinQty : string='';
    proMaxSupply : string='';
    proMainImage : string='';
    cat : string='';
    subCat : string='';
    videoBase64: string ='';
    videO_PATH: string = '';
    proImageBase: string='';
}