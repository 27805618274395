import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SigninComponent } from './auth/signin/signin.component';
import { SignupComponent } from './auth/signup/signup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './core/header/header.component';
import { FooterComponent } from './core/footer/footer.component';
import { DropDownDirective } from './shared/dropdown.directive';
import {NumbersOnlyInputDirective} from './shared/directives/NumberOnly.directive';
import { StoreModule } from '@ngrx/store';
import { reducers } from './store/app.state';
import { HomeComponent } from './core/home/home.component';
import { CompanyListComponent } from './company-list/company-list.component';
import { EffectsModule } from '@ngrx/effects';
import { authEffects } from './store/effects/auth.effects';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import {companyService} from '../app/company-list/company-list.service';
import { AdcompanyService } from './shared/services/compnies/CompanyServices';
import { adProductService } from './shared/services/compnies/ProductServices';
import { authInterceptor } from './shared/auth.interceptor';
import { authGuard } from './auth/store/auth-guard.service';
import { ErrorInterceptor } from './shared/error.Interceptor';
import { AngularWebStorageModule } from 'angular-web-storage';
import { ChatBotComponent } from './chat-bot/chat-bot.component';
import { ChatService } from './shared/chat.service';
import { BooksComponent } from './books/books.component';
import { BookService } from './books/book.service';
import { NgChatModule } from 'ng-chat';
import { AddPostingComponent } from './add-posting/add-posting.component';
import { AdPostingService } from './add-posting/adPosting.service';
import { AboutUsComponent } from './about-us/about-us.component';
import { OwlModule } from 'ngx-owl-carousel';
import { addsService } from './shared/services/addServices';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SearchComponent } from './core/search/search.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { searchService } from './shared/services/searchService';
import { AddDetailsComponent } from './add-details/add-details.component';
import { addDetailsService } from './shared/services/addDetails.service';
import { adDealService } from './shared/services/deal/deal-services.service';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
//import { NgxGalleryModule } from 'ngx-gallery';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { NgImageSliderModule } from 'ng-image-slider';
//import { NgImageSliderModule } from 'ng-image-video-gallery';
import { GoogleMapsModule } from "@angular/google-maps";
import { MainloaderComponent } from './core/mainloader/mainloader.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { LoaderService } from './shared/services/loader.service';
import { LoaderInterceptor } from './shared/interceptors/loaderInterceptor.service';
import { TooltipModule } from '@syncfusion/ej2-angular-popups';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotificationService } from './shared/services/notification.service';
import { AddListingComponent } from './add-listing/add-listing.component';
import { openSearchService } from './shared/services/search/openSearch.service';
import { ShareButtonsModule } from '@ngx-share/buttons';
import { ShareModule } from '@ngx-share/core';
import { AccordionModule  } from 'ngx-bootstrap/accordion';
import { Ng5SliderModule } from 'ng5-slider'
import { dataLibraryService } from './shared/services/search/DataLibraries.service';
import { RouterModule } from '@angular/router';
import { searchReducer } from './store/reducers/search.reducers';
import { liveUpdateService } from './shared/liveUpdate/listUpdate.service';
import { notificationService } from './shared/liveUpdate/Notification.service';
import { searchEffects } from './store/effects/search.effects';
import { JwPaginationModule } from 'jw-angular-pagination';
import { NgxPaginationModule } from 'ngx-pagination';
import { HeaderInnerComponent } from './core/header-inner/header-inner.component';
import { FooterInnerComponent } from './core/footer-inner/footer-inner.component';
import { UserHomeComponent } from './core/user-home/user-home.component';
import { UserNewPostComponent } from './user-new-post/user-new-post.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { AgmCoreModule } from '@agm/core';
import { UserCompanyComponent } from './user-company/user-company.component';
import { UserNewCompanyComponent } from './user-new-company/user-new-company.component';
import { UserProductComponent } from './user-product/user-product.component';
import { ProductComponent } from './product/product.component';
import { ShowProductComponent } from './product/show-product/show-product.component';
import { AddEditProductComponent } from './product/add-edit-product/add-edit-product.component';
import { AdsComponent } from './ads/ads.component';
import { AddEditPostComponent } from './ads/add-edit-post/add-edit-post.component';
import { ShowPostComponent } from './ads/show-post/show-post.component';
import { ComDetailsComponent } from './pro-details/com-details.component';
import { UserDealComponent } from './user-deal/user-deal.component';
import { DealComponent } from './deal/deal.component';
import { AddEditDealComponent } from './deal/add-edit-deal/add-edit-deal.component';
import { ShowDealComponent } from './deal/show-deal/show-deal.component';
import { DealDetailsComponent } from './deal-details/deal-details.component';
import { HeaderOuterComponent } from './core/header-outer/header-outer.component';
import { ContectUsComponent } from './contect-us/contect-us.component';
import { UserValidationComponent } from './user-validation/user-validation.component';
import { UserProfileDetailComponent } from './user-profile-detail/user-profile-detail.component';
import { UpdateProfileComponent } from './update-profile/update-profile.component';
import { UserProfileAboutComponent} from  './user-profile-about/user-profile-about.component';
//import { proActionService } from './shared/services/compnies/ProActionServices';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDividerModule} from '@angular/material/divider';
import { UserFriendComponent } from './user-friend/user-friend.component';
import { chattingService } from './shared/liveUpdate/chatting.service';
import { UserChatComponent } from './user-chat/user-chat.component';
import { AddToCartComponent } from './add-to-cart/add-to-cart.component';
import { CartCalculatorComponent } from './cart-calculator/cart-calculator.component';
import { BillingDetailsComponent } from './billing-details/billing-details.component';
import { ShoppingDetailsComponent } from './shopping-details/shopping-details.component';
import { AddToFavoriteComponent } from './add-to-favorite/add-to-favorite.component';
import { PaymentComponent } from './payment/payment.component';
import { RecepitComponent } from './recepit/recepit.component';
//import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
// import {} from 'googlemaps';
@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    SigninComponent,
    SignupComponent,
    HeaderComponent,
    FooterComponent,
    DropDownDirective,
    NumbersOnlyInputDirective,
    HomeComponent,
    CompanyListComponent,
    ChatBotComponent,
    BooksComponent,
    AddPostingComponent,
    AboutUsComponent,    
    SearchComponent, AddDetailsComponent, MainloaderComponent, AddListingComponent, HeaderInnerComponent, FooterInnerComponent, UserHomeComponent, UserNewPostComponent, UserCompanyComponent, UserNewCompanyComponent, UserProductComponent, ProductComponent, ShowProductComponent, AddEditProductComponent, AdsComponent, AddEditPostComponent, ShowPostComponent, ComDetailsComponent, UserDealComponent, DealComponent, AddEditDealComponent, ShowDealComponent, DealDetailsComponent, HeaderOuterComponent, ContectUsComponent, UserValidationComponent, UserProfileDetailComponent, UpdateProfileComponent,UserProfileAboutComponent, UserFriendComponent, UserChatComponent, AddToCartComponent, CartCalculatorComponent, BillingDetailsComponent, ShoppingDetailsComponent, AddToFavoriteComponent, PaymentComponent, RecepitComponent,
  ],
  imports: [
    BrowserModule,
    //NgMultiSelectDropDownModule,
    CommonModule,
    MatDividerModule,
    MatExpansionModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,  
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([authEffects,searchEffects]), 
    EffectsModule.forRoot([authEffects]), 
    AngularWebStorageModule,
    NgChatModule,
     OwlModule,
     FontAwesomeModule,
     AngularMultiSelectModule,
     MalihuScrollbarModule.forRoot()
     ,NgxGalleryModule 
     ,NgImageSliderModule
     ,GoogleMapsModule
     ,NgxUiLoaderModule
     ,TooltipModule
     ,BrowserAnimationsModule
     ,ToastrModule.forRoot()
     ,AccordionModule.forRoot()
     ,Ng5SliderModule
     ,RouterModule
     ,JwPaginationModule
     ,NgxPaginationModule
     ,EditorModule
     ,NgSelectModule
     ,AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAFgM81Qz-SwfTzUsr4F51AgDj0HdN88CQ',
      // apiKey: '',
      libraries: ['places']
    })
     ,ShareModule
     , ShareButtonsModule.withConfig({
      debug: true
    })
  ],
  providers: [AdcompanyService ,adProductService, BookService,AdPostingService, addsService,searchService, addDetailsService,NotificationService,openSearchService,dataLibraryService,    
    ,ChatService,liveUpdateService,notificationService, companyService,LoaderService, adDealService, chattingService
    ,{
    provide : HTTP_INTERCEPTORS,
    useClass : authInterceptor,
    multi:true},
  {
    provide: HTTP_INTERCEPTORS,
    useClass : ErrorInterceptor,
    multi:true
  }
  ,{ provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
],
  bootstrap: [AppComponent]
})
export class AppModule { }
