import { Injectable } from '@angular/core';
import { Observable,BehaviorSubject } from "rxjs";
import { User } from '../core/models/user.model';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromApp from '../store/app.state';
import * as authActions from '../store/actions/auth.actions';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, switchMap, mergeMap } from "rxjs/operators";
import { HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from 'angular-web-storage';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
 
  private baseUrl = environment.baseUrl;  
  token : string ;
 
  private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
 
 
  constructor(private router: Router, private store: Store<fromApp.AppState>,private httpclient:HttpClient,
    public localStor: LocalStorageService )
  {
  };

  testUser: User = {email: 'user@email.com',password: '1234', token: 'sampleToken'};
 
  getToken(): string {
    return localStorage.getItem('token');
  }
 
  isLoggedIn() {
    const token = this.getToken();
    return token != null;
  }
 
  //login(email: string, password: string): Observable<any> {
    loginOld(email: string, password: string) {
   // //debugger;
    if(email == this.testUser.email && password == this.testUser.password) {        
      //observer.next({email: this.testUser.email, token: this.testUser.token});
      this.store.dispatch(new authActions.Login());
      //this.store.dispatch(new authActions.setToken());
    }
    //this is a mocked response to be able to test the example
    // return new Observable((observer) => {
    //   //debugger;
    //   if(email == this.testUser.email && password == this.testUser.password) {        
    //     //observer.next({email: this.testUser.email, token: this.testUser.token});
    //     this.store.dispatch(new authActions.Login());
    //     this.store.dispatch(new authActions.setToken());
    //   } else {
    //     observer.error({error: 'invalid credentials.'});
    //   }
    //   observer.complete();
    // });
    //this would probably make an http post to the server to process the login
    //return this.http.post<User>(url, {email, password});
  }

  LogedOut() {
    //debugger;    
    this.localStor.remove('currentUser');
    //localStorage.removeItem('currentUser');
    this.store.dispatch(new authActions.Logout());    
  }
    
  logIn(email: string, password: string): Observable<any> {
    //debugger;
    //console.log('logInCall');
    const url = this.baseUrl+'token';
    return this.httpclient.post<any>(url, {email, password});
  }

  login(username: string, password: string) {
   ////debugger;
       
        ////debugger;
        //const url = this.baseUrl+'authenticate/token';     
        const url = this.baseUrl+'token';     
        username ='username';
        password = 'secret';
        const httpOptions = {
          headers: new HttpHeaders({
            //'Content-Type':  'application/x-www-form-urlencoded'            
            'Content-Type':'application/json'
          })
        };              
        //const bodyMain ='grant_type=client_credentials&client_id=GSB&client_secret=GSB@123';        
        
        this.httpclient.post<any>(url,JSON.stringify({ username, password }),httpOptions).subscribe(data => {
            ////debugger; 
            //console.log(data);           
        this.token = data.token;
    })
    return this.token;                         
  } 
}