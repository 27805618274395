<app-search></app-search>
<!--************************************
				Main Start
		*************************************-->
<main id="tg-main" class="tg-main tg-haslayout">
    <div class="container">
        <div class="row">
            <div id="tg-content" class="tg-content">
                <div class="tg-loginsignup">
                    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                        <div class="tg-logingarea">
                            <h2>Login Now</h2>
                            <form class="tg-formtheme tg-formloging" (ngSubmit)="onSignIn(f)" #f="ngForm">
                                <fieldset>
                                    <div class="form-gorup">
                                        <!-- <label for="email">Mail</label> -->
                                        <input type="email" id="email" name="email" ngModel class="form-control" placeholder="Username">
                                    </div>
                                    <br>
                                    <div class="form-gorup">
                                        <!-- <label for="pass">Password</label> -->
                                        <input type="password" id="pass" name="pass" ngModel class="form-control" placeholder="Password">
                                    </div>
                                    <div class="form-group">
                                        <div class="tg-checkbox">
                                            <input id="tg-rememberme" type="checkbox" name="rememberme" value="rememberme">
                                            <label for="tg-rememberme">Keep me logged in</label>
                                        </div>
                                        <a class="tg-forgetpassword" data-toggle="modal" data-target="#exampleModal" href="javascript:void(0);">Forgot Password ?</a>
                                    </div>
                                    <div class="form-group">
                                        <label for="tg-rememberme" style="color:red;">{{logInMessage}}</label>
                                    </div>
                                    <hr>
                                    <button class="tg-btn" type="submit">Sign In</button>
                                </fieldset>
                            </form>
                        </div>
                        <div class="tg-texbox">
                            <p><strong>For no one rejects or hates pleasure because it is pleasure.</strong></p>
                            <p>Who of ours should exercise any pleasure unless we take advantage of the consequences of it?</p>
                            <ul>
                                <li>They are aware of who is at fault for the services</li>
                                <li>Forsake soothes the soul of those who are</li>
                                <li>Sedutana encounter</li>
                                <li>Whence all this pleasure was born</li>
                                <li>Cullamcoaris</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8">
                        <div class="tg-videobox">
                            <figure>
                                <img src="../../../assets/images/loginbg.jpg" alt="image description">
                                <a class="tg-btnplayvideo" href="javascript:void(0);"><i class="fa fa-check" aria-hidden="true"></i></a>
                            </figure>
                        </div>
                        <div class="tg-title">
                            <h2>Register Now</h2>
                        </div>
                        <div class="tg-haslayout">
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7">
                                    <form class="tg-formtheme tg-formregister" #f="ngForm" (ngSubmit)="onSubmit(f)">
                                        <fieldset>
                                            <div class="form-group tg-inputwithicon">
                                                <i class="fa fa-user" aria-hidden="true"></i>
                                                <input type="text" name="user" id="user" [(ngModel)]="this.userName" class="form-control" placeholder="Name*">
                                            </div>
                                            <div class="form-group">
                                                <div *ngIf="NameAlert == true" class="alert alert-danger">
                                                    <div *ngIf="NameAlert == true">
                                                        Name is required.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group tg-inputwithicon">
                                                <i class="fa fa-envelope" aria-hidden="true"></i>
                                                <input type="email" name="email" id="email" [(ngModel)]="this.email" class="form-control" placeholder="Email*">
                                            </div>
                                            <div class="form-group tg-inputwithicon">
                                                <!-- <i class="fa fa-envelope" aria-hidden="true"></i> -->
                                                <ng-select [items]="CountryList" bindLabel="title" bindValue="id" placeholder="Select Country"
                                                    appendTo="body" [(ngModel)]="this.Country" id="selectedCountry" name="selectedCountry">
                                                </ng-select>
                                            </div>
                                            <div class="form-group tg-inputwithicon">
                                                <i class="fa fa-phone" aria-hidden="true"></i>
                                                <input type="text" name="phoneNumber" id="phoneNumber" [(ngModel)]="this.phoneNumber" class="form-control" placeholder="Phone Number*">
                                            </div>
                                            <div class="form-group tg-inputwithicon">
                                                <i class="fa fa-lock" aria-hidden="true"></i>
                                                <input type="password" name="userPassword" id="userPassword" [(ngModel)]="this.userPassword" ngModel class="form-control" placeholder="Password*">
                                            </div>
                                            <div class="form-group tg-inputwithicon">
                                                <i class="fa fa-lock" aria-hidden="true"></i>
                                                <input type="password" name="retypePassword" id="retypePassword" [(ngModel)]="this.retypePassword" ngModel class="form-control" placeholder="Retype Password*">
                                            </div>
                                            <div class="form-group">
                                                <div class="tg-checkbox">
                                                    <input id="tg-agree" type="checkbox" name="agree" value="agree">
                                                    <label for="tg-agree">By registering, you accept our <a  routerLink ="/contactUs">Terms &amp; Conditions</a></label>
                                                </div>
                                            </div>
                                            <button class="tg-btn" type="submit" >Register</button>
                                        </fieldset>
                                    </form>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
                                    <ul class="tg-sociallogingsignup">
                                        <li class="tg-googleplus">
                                            <a href="javascript:void(0);">
                                                <i class="fa fa-google-plus">
                                                    <!-- <fa-icon [icon]="faGoogle"></fa-icon> -->
                                                </i>
                                                <span>Sign in with <strong>“Google”</strong></span>
                                            </a>
                                        </li>
                                        <li class="tg-facebook">
                                            <a href="javascript:void(0);">
                                                <i class="fa fa-facebook">
                                                    <!-- <fa-icon [icon]="faFacebook"></fa-icon> -->
                                                </i>
                                                <span>Sign in with <strong>Facebook</strong></span>
                                            </a>
                                        </li>
                                        <li class="tg-linkedin">
                                            <a href="javascript:void(0);">
                                                <i class="fa fa-linkedin">                                                
                                                <!-- <fa-icon [icon]="faLinkedin"></fa-icon> -->
                                            </i>
                                                <span>Sign in with <strong>Linkedin</strong></span>
                                            </a>
                                        </li>
                                        <li class="tg-twitter">
                                            <a href="javascript:void(0);">
                                                <i class="fa fa-twitter">
                                                    <!-- <fa-icon [icon]="faTwitter"></fa-icon> -->
                                                </i>
                                                <span>Sign in with <strong>Twitter</strong></span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
<!--************************************
				Main End
		*************************************-->
        <div class="modal fade" data-backdrop="static" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Forget Your Password</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="panel panel-default">
                              <div class="panel-body">
                                <div class="text-center">
                                  <h3><i class="fa fa-lock fa-4x"></i></h3>
                                  <h2 class="text-center">Forgot Password?</h2>
                                  <p>You can reset your password here.</p>
                                  <div class="panel-body">
                    
                                    <form id="register-form" role="form" autocomplete="off"  (ngSubmit)="onEmailSubmit()">
                    
                                      <div class="form-group">
                                        <div class="input-group">
                                          <span class="input-group-addon"><i class="glyphicon glyphicon-envelope color-blue"></i></span>
                                          <input id="email" [(ngModel)]="this.sendemail" name="email" placeholder="email address" class="form-control"  type="email">
                                        </div>
                                      </div>
                                      <div class="form-group">
                                        <input name="recover-submit" class="btn btn-lg btn-success btn-block" value="Reset Password" type="submit">
                                      </div>
                                      
                                      <input type="hidden" class="hide" name="token" id="token" value=""> 
                                    </form>
                    
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                    </div>
                </div>
                <!-- <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-success">Rest Password</button>
                </div> -->
              </div>
            </div>
          </div>