<!--************************************
				Home Slider Start
		*************************************-->
<app-search></app-search>
<!--************************************
                  Home Slider End
              *************************************-->
<!--************************************
                  Main Start
              *************************************-->
<main id="tg-main" class="tg-main tg-haslayout">
    <!--************************************
                    About Us Start
                *************************************-->
    <section class="tg-sectionspace tg-haslayout">
        <div class="container">
            <div class="row">
                <div class="tg-aboutus">
                    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-6">
                        <div class="tg-textshortcode">
                            <div class="tg-titleshortcode">
                                <h2><span>Welcome!</span>Know more about us</h2>
                            </div>
                            <div class="tg-description">
                                <p>MyKnocks.com is one of the largest and best free classified Ads post websites in the Gulf region UAE, Dubai, India, Pakistan, UK, US, Canada, Australia, China, Japan, KSA, Oman, Kuwait and Qatar are proud to announce their free online ad posting option. For those who wish to sell used cars, Property for Sale and Deals in Dubai, India, Pakistan, UK, US, Canada, Australia, China, Japan, KSA, Oman, Kuwait and Qatar.</p>
                                <p> As the founders of MyKnocks.com understand, it can be challenging to sell used cars, property for sale and Dubai deals. They point out that placing a "For Sale" sign in a vehicle’s window limits the number of people that will see it, and traditional classified ads can be expensive.</p>
                            </div>
                            <div class="tg-btnbox">
                                <a class="tg-btn" href="javascript:void(0);">Meet The Team</a>
                                <a class="tg-btn" href="javascript:void(0);">Sponsors</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-7 col-lg-6">
                        <div class="tg-imgshortcode tg-multiimg">
                            <figure>
                                <div class="tg-imgholder"><img src="../../assets/images/Aboutus/CTO_serv_background.jpg" alt="image description"></div>
                            </figure>
                            <figure>
                                <div class="tg-imgholder"><img src="../../assets/images/Aboutus/istockphoto-1180617882-612x612.jpg" alt="image description"></div>
                            </figure>
                            <figure>
                                <div class="tg-imgholder"><img src="../../assets/images/Aboutus/about-us-banner.png" alt="image description"></div>
                            </figure>
                            <figure>
                                <div class="tg-imgholder"><img src="../../assets/images/Aboutus/1232.webp" alt="image description"></div>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--************************************
                    About Us End
                *************************************-->
    <!--************************************
                    Latest Posted Ads Start
                *************************************-->
    <section class="tg-sectionspace tg-bglight tg-haslayout">
        <div class="container">
            <div class="row">
                <ul class="tg-statistics">
                    <li>
                        <div class="tg-statisticicon"><i class="icon-smile"></i></div>
                        <h3 data-from="0" data-to="246321" data-speed="8000" data-refresh-interval="50">246321</h3>
                        <h4>Happy Customers</h4>
                    </li>
                    <li>
                        <div class="tg-statisticicon"><i class="icon-user"></i></div>
                        <h3 data-from="0" data-to="423156" data-speed="8000" data-refresh-interval="50">423156</h3>
                        <h4>Active Members</h4>
                    </li>
                    <li>
                        <div class="tg-statisticicon"><i class="icon-bookmark"></i></div>
                        <h3 data-from="0" data-to="246321" data-speed="8000" data-refresh-interval="50">246321</h3>
                        <h4>Verified Ads</h4>
                    </li>
                    <li>
                        <div class="tg-statisticicon"><i class="icon-coffee-cup"></i></div>
                        <h3 data-from="0" data-to="74231" data-speed="8000" data-refresh-interval="50">74231</h3>
                        <h4>Cup of Coffee</h4>
                    </li>
                </ul>
            </div>
        </div>
    </section>
    <!--************************************
                    Latest Posted Ads End
                *************************************-->
    <!--************************************
                    News Article Start
                *************************************-->
    <section class="tg-sectionspace tg-haslayout">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-push-2 col-lg-8">
                    <div class="tg-sectionhead tg-sectionheadvtwo">
                        <div class="tg-title">
                            <h2>Why We Are Best</h2>
                        </div>
                        <div class="tg-description">
                            <p>Select from the Largest Online Marketplace in Dubai
                                With over 15 million products to select from, MyKnocks offers its customers the most comprehensive listing of products in the country. Whether you’re looking for electronics, apparel, appliances, or groceries – there is something for everyone.</p>
                        </div>
                    </div>
                </div>
                <div class="tg-features">
                    <li>
                        <div class="tg-feature">
                            <div class="tg-featureicon"><i class="fa fa-check" aria-hidden="true"></i></div>
                            <div class="tg-title">
                                <h3><a href="javascript:void(0);">Eye on Quality</a></h3>
                            </div>
                            <div class="tg-description">
                                <p>This inspired them to allow posting ads on their website at no cost; once the ad is on the user-friendly site</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="tg-feature">
                            <div class="tg-featureicon"><i class="fa fa-check" aria-hidden="true"></i></div>
                            <div class="tg-title">
                                <h3><a href="javascript:void(0);">Protection Guaranteed</a></h3>
                            </div>
                            <div class="tg-description">
                                <p>This inspired them to allow posting ads on their website at no cost; once the ad is on the user-friendly site</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="tg-feature">
                            <div class="tg-featureicon"><i class="fa fa-check" aria-hidden="true"></i></div>
                            <div class="tg-title">
                                <h3><a href="javascript:void(0);">Active Support</a></h3>
                            </div>
                            <div class="tg-description">
                                <p>This inspired them to allow posting ads on their website at no cost; once the ad is on the user-friendly site</p>
                            </div>
                        </div>
                    </li>
                </div>
            </div>
        </div>
    </section>
    <!--************************************
                    News Article End
                *************************************-->
    <!--************************************
                    Quality Services Start
                *************************************-->
    <section class="tg-bglight tg-haslayout">
        <div class="container-fluid">
            <div class="row">
                <div class="tg-qualityservices">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div class="row">
                            <div class="tg-imgshortcode">
                                <figure>
                                    <img src="assets/images/Aboutus/istockphoto-1250152532-612x612.jpg" alt="image description">
                                    <figcaption><a class="tg-btnplayvideo" href="javascript:void"><i class="fa fa-play" aria-hidden="true"></i></a></figcaption>
                                </figure>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div class="row">
                            <div class="tg-textshortcode">
                                <div class="tg-titleshortcode">
                                    <h2>Serving Quality Services</h2>
                                </div>
                                <div class="tg-description">
                                    <p>Enhanced iterative competition, but give this time as low as some great age-old effort. For the purpose of coming to a minimum, who of ours should exercise any employment except to take advantage of the consequences from it.</p>
                                    <p>But the pain in the film is irure to criticize in the pleasure it wants to escape from the cillusolore that none of the chips have to pay. They are the exceptions the blinds crave, they don't pride themselves, they are the ones who abandon their responsibilities in a way that soothes the soul's hardships..</p>
                                    <p>But in order that you may understand whence all this error was born, the pleasure of the saints and the pain of praising them, I will open the whole thing, and those very things which came from that inventor of the truth and as it were the architect of the blessed one.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--************************************
                    Quality Services End
                *************************************-->
    <!--************************************
                    Partners Start
                *************************************-->
    <section class="tg-sectionspace tg-haslayout">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-push-2 col-lg-8">
                    <div class="tg-sectionhead tg-sectionheadvtwo">
                        <div class="tg-title">
                            <h2>Our Team</h2>
                        </div>
                        <div class="tg-description">
                            <p>Enhanced iterative competition, but give this time as low as some great age-old effort. In order for the smallest to come, who can do our exercise, any school of work except to take advantage of those consequences.</p>
                        </div>
                    </div>
                </div>
                <div class="tg-teamclassified">
                    <div class="tg-team">
                        <div class="tg-teamholder">
                            <figure>
                                <a href="javascript:void(0);"><img src="../../assets/images/team/dummy.png" alt="image description"></a>
                                <figcaption>
                                    <ul class="tg-socialicons">
                                        <li class="tg-facebook"><a href="javascript:void(0);"><i class="fa fa-facebook"></i></a></li>
                                        <li class="tg-twitter"><a href="javascript:void(0);"><i class="fa fa-twitter"></i></a></li>
                                        <li class="tg-linkedin"><a href="javascript:void(0);"><i class="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </figcaption>
                            </figure>
                            <div class="tg-teamcontent">
                                <h3><a href="javascript:void(0);">Waqar Chaudhry</a></h3>
                                <h4>Founder &amp; C.E.O</h4>
                            </div>
                        </div>
                    </div>
                    <div class="tg-team">
                        <div class="tg-teamholder">
                            <figure>
                                <a href="javascript:void(0);"><img src="../../assets/images/team/manager_woman-512.webp" alt="image description"></a>
                                <figcaption>
                                    <ul class="tg-socialicons">
                                        <li class="tg-facebook"><a href="javascript:void(0);"><i class="fa fa-facebook"></i></a></li>
                                        <li class="tg-twitter"><a href="javascript:void(0);"><i class="fa fa-twitter"></i></a></li>
                                        <li class="tg-linkedin"><a href="javascript:void(0);"><i class="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </figcaption>
                            </figure>
                            <div class="tg-teamcontent">
                                <h3><a href="javascript:void(0);">Colin Renshaw</a></h3>
                                <h4>Chief Product Officer</h4>
                            </div>
                        </div>
                    </div>
                    <div class="tg-team">
                        <div class="tg-teamholder">
                            <figure>
                                <a href="javascript:void(0);"><img src="assets/images/team/ahmad.png" alt="image description"></a>
                                <figcaption>
                                    <ul class="tg-socialicons">
                                        <li class="tg-facebook"><a href="https://www.facebook.com/profile.php?id=100031631150247" target="_blank"><i class="fa fa-facebook"></i></a></li>
                                        <li class="tg-twitter"><a href="javascript:void(0);"><i class="fa fa-twitter"></i></a></li>
                                        <li class="tg-linkedin"><a href="javascript:void(0);"><i class="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </figcaption>
                            </figure>
                            <div class="tg-teamcontent">
                                <h3><a href="javascript:void(0);">Ali Ahmad</a></h3>
                                <h4>Web Developer</h4>
                            </div>
                        </div>
                    </div>
                    <div class="tg-team">
                        <div class="tg-teamholder">
                            <figure>
                                <a href="javascript:void(0);"><img src="../../assets/images/team/man-avatar-icon-flat-vector-19152370-1.jpg" alt="image description"></a>
                                <figcaption>
                                    <ul class="tg-socialicons">
                                        <li class="tg-facebook"><a href="javascript:void(0);"><i class="fa fa-facebook"></i></a></li>
                                        <li class="tg-twitter"><a href="javascript:void(0);"><i class="fa fa-twitter"></i></a></li>
                                        <li class="tg-linkedin"><a href="javascript:void(0);"><i class="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </figcaption>
                            </figure>
                            <div class="tg-teamcontent">
                                <h3><a href="javascript:void(0);">Cheryle Epperly</a></h3>
                                <h4>Investment Officer</h4>
                            </div>
                        </div>
                    </div>
                    <div class="tg-team">
                        <div class="tg-teamholder">
                            <figure>
                                <a href="javascript:void(0);"><img src="../../assets/images/team/female-img.png" alt="image description"></a>
                                <figcaption>
                                    <ul class="tg-socialicons">
                                        <li class="tg-facebook"><a href="javascript:void(0);"><i class="fa fa-facebook"></i></a></li>
                                        <li class="tg-twitter"><a href="javascript:void(0);"><i class="fa fa-twitter"></i></a></li>
                                        <li class="tg-linkedin"><a href="javascript:void(0);"><i class="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </figcaption>
                            </figure>
                            <div class="tg-teamcontent">
                                <h3><a href="javascript:void(0);">Arnoldo Charpentier</a></h3>
                                <h4>Sr. Creative Designer</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--************************************
                    Partners End
                *************************************-->
</main>
<!--************************************
                  Main End
        *************************************-->
<script>
    /*--------------------------------------
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            COUNTER							
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    --------------------------------------*/
    if ($('.tg-statistics').length > 0) {
        $('.tg-statistics').appear(function() {
            $('.tg-statistics > li > h3').countTo();
        });
    }

    $(document).ready(function() {
        //$('select2-chosen').html("plz");


        // $(".js-example-templating").select2({
        //     // templateResult: formatState,
        //     placeholder: {
        //         id: '-1', // the value of the option
        //         text: 'Select an option'
        //     },
        //     allowClear: true
        // });
        // $(document.body).on("change", ".js-example-templating", function() {
        //     alert(this.value);
        // });

        // $('.js-example-templating').on("select2:selecting", function(e) {
        //     //debugger;
        // });

        function formatState(state) {
            if (!state.id) {
                return state.text;
            }
            var baseUrl = "/user/pages/images/flags";
            var $state = $(
                '<span><img src="' + baseUrl + '/' + state.element.value.toLowerCase() + '.png" class="img-flag" /> ' + state.text + '</span>'
            );
            return $state;
        };
    });
</script>
<style>
    .select2-container--default .select2-selection--single {
        border: none !important;
    }
    
    .select2-container--default .select2-selection--single .select2-selection__clear {
        display: none;
    }
    
    .select2-container--default .select2-selection--single .select2-selection__arrow b {
        display: none;
    }
    
    .select2-container--default .select2-selection--single .select2-selection__rendered {
        text-align: left;
    }
    
    .select2-container .select2-choice {
        text-align: left;
    }
    
    .select2-container {
        width: 100%;
    }
    
    .tg-select:after {
        display: none;
    }
    
    .select2-container .select2-choice {
        border: none;
        background-image: none;
        border-radius: 0%;
    }
    
    .select2-container .select2-choice .select2-arrow {
        display: none;
    }
</style>