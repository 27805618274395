import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { userEndorcement } from '../shared/models/Company/User-About/user_Endorcement';
import { ActionserviceService } from '../shared/services/compnies/actionservice.service';
import { AdcompanyService } from '../shared/services/compnies/CompanyServices';
import { NotificationService } from '../shared/services/notification.service';

@Component({
  selector: 'app-user-profile-detail',
  templateUrl: './user-profile-detail.component.html',
  styleUrls: ['./user-profile-detail.component.css']
})
export class UserProfileDetailComponent implements OnInit {

  constructor(private router: Router,private route: ActivatedRoute,private adcompanyService: AdcompanyService,
    private notifyService: NotificationService,private actions: ActionserviceService,private ngxLoader: NgxUiLoaderService) {}

  ngOnInit(): void {
    const ID = this.route.snapshot.params['userProfileID'];
    var userID = parseInt(ID);
    this.LoadProfileData(userID);
    this.LoadEducationList(userID);
    this.LoadExperienceList(userID);
    this.LoadCertificationList(userID);
    this.LoadMyClasses(userID);
    this.LoadMyTeams(userID);
    this.LoadProjectList(userID);
    this.LoadSuggestFrnds();
    this.LoadSelectedSkills(userID);
  }
  profileList:any;
  education:any;
  experience:any;
  certification:any;
  class:any;
  project:any;
  skills:any;
  team:any;
  SuggestedFrnd:any;
  imageurls = [];
  fileurls = [];
  LoadProfileData(userID) {
    //debugger;
    //this.ngxLoader.start();
    this.adcompanyService.getProfileDetails_2(userID).subscribe(data => {
      this.profileList = data;
      //console.log(this.profileList);
      if (this.profileList.imageName != null) {
        //debugger;
        this.imageurls.push(this.profileList.imageBase64);
        //console.log(this.profileList.imageBase64);
      }
      if (this.profileList.currentCVPath != null) {
        //debugger;
        this.fileurls.push(this.profileList.cvPathBase64);
      }

      // setTimeout(() => {
      //   this.ngxLoader.stop();
  
      // }, 1000);
    });
  }
  LoadEducationList(userID) {
    ////debugger;
    this.adcompanyService.GetEducationList_2(userID).subscribe(data => {
      this.education = data;
      //console.log(this.education);
    });
  }
  LoadExperienceList(userID) {
    // //debugger;
     this.adcompanyService.GetExperienceList_2(userID).subscribe(data => {
       this.experience = data;
       //console.log(this.experience);
     });
   }
   LoadCertificationList(userID) {
    ////debugger;
    this.adcompanyService.GetCertificationList_2(userID).subscribe(data => {
      this.certification = data;
      //console.log(this.certification);
    });
  }
  LoadMyClasses(userID) {
    ////debugger;
    this.adcompanyService.GetClassList_2(userID).subscribe(data => {
      this.class = data;  
    });
  }
  LoadMyTeams(userID) {
    ////debugger;
    this.adcompanyService.GetTeamList_2(userID).subscribe(data => {
      this.team = data; 
    });
  }
  LoadProjectList(userID) {
    // //debugger;
     this.adcompanyService.GetProjectList_2(userID).subscribe(data => {
       this.project = data;
       //console.log(this.project);
     });
   }

   LoadSuggestFrnds() {
    //debugger;
    this.actions.GetSuggestFrnds().subscribe(data => {
      this.SuggestedFrnd = data;
      //console.log(this.SuggestedFrnd);
    });
  }
  
  LoadSelectedSkills(userID){
    this.adcompanyService.GetSelectedSkills_2(userID).subscribe(data => {
      this.skills = data;
      //console.log(this.skills);
    });
  }

  AddEndorcement(skill_ID){
    //debugger;
    const ID = this.route.snapshot.params['userProfileID'];
    var userID = parseInt(ID);
    const data = new userEndorcement();
    data.USE_AddedBy = 0;
    data.USE_AddedDate = "";
    data.USE_UserSkill_ID = skill_ID;
    this.adcompanyService.AddUserEndorcement(data).subscribe(data => {
      if(data != 0){
        this.LoadSelectedSkills(userID);
        this.notifyService.showSuccess('Baravo !!', 'Endorcement Successfully');
      }else{
        this.notifyService.showError('Somthing Wrong', 'You have already Endorcement');
      }
    });   
  }





  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }
}
 