<!-- <link rel="stylesheet" href="/assets/css/dashboard.css"> -->
<style>
    .mega-dropdown {
        position: static !important;
    }

    .mega-dropdown-menu {
        padding: 20px 0px;
        width: 100%;
        box-shadow: none;
        -webkit-box-shadow: none;
    }

    .mega-dropdown-menu>li>ul {
        padding: 0;
        margin: 0;
    }

    .mega-dropdown-menu>li>ul>li {
        list-style: none;
    }

    .mega-dropdown-menu>li>ul>li>a {
        display: block;
        color: #222;
        padding: 3px 5px;
    }

    .mega-dropdown-menu>li ul>li>a:hover,
    .mega-dropdown-menu>li ul>li>a:focus {
        text-decoration: none;
    }

    .mega-dropdown-menu .dropdown-header {
        font-size: 18px;
        color: #00cc67;
        padding: 5px 60px 5px 5px;
        line-height: 30px;
    }

    .mega-dropdown-menu>a {
        text-decoration: none;
    }

    /* top header*/
    .rounded-img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
    }

    .tg-dashboardheader {
        z-index: 999;
        background: #fff;
        padding: 20px 60px;
        position: relative;
        -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.20);
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.20);
    }

    .tg-navigation>ul>li:first-child {
        padding-left: 0;
    }

    .tg-navigation>ul>li:last-child {
        padding-right: 0;
    }

    .tg-themedropdown {
        position: relative;
        display: inline-block;
        vertical-align: middle;
    }

    .tg-notification {
        float: right;
        margin: 0 0 0 10px;
    }

    .tg-btndropdown {
        outline: 0;
        color: #666;
        background: none;
        position: relative;
    }

    .tg-btndropdown i {
        width: 46px;
        height: 46px;
        display: block;
        font-size: 18px;
        overflow: hidden;
        line-height: 44px;
        border-radius: 50%;
        border: 1px solid #666;
    }

    .tg-dashboardheader .tg-btn {
        float: left;
        padding: 0 30px;
    }

    .tg-badge {
        top: 0;
        right: 0;
        color: #fff;
        min-width: 10px;
        padding: 1px 6px;
        font-size: 10px;
        font-weight: 500;
        line-height: 15px;
        text-align: center;
        border-radius: 50%;
        position: absolute;
        background: #f91942;
        white-space: nowrap;
        display: inline-block;
        vertical-align: middle;
    }

    .tg-dropdownmenu {
        border: 0;
        margin: 0;
        right: 0;
        opacity: 0;
        top: 150px;
        left: auto;
        padding: 0;
        float: none;
        width: 330px;
        display: block;
        color: #363b4d;
        font-size: 13px;
        line-height: 20px;
        border-radius: 0;
        background: #fff;
        list-style: none;
        visibility: hidden;
        position: absolute;
        -webkit-box-shadow: none;
        box-shadow: none;
        -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.10);
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.10);
    }

    .tg-dropdownmenu:before {
        width: 0;
        height: 0;
        content: '';
        right: 15px;
        bottom: 100%;
        position: absolute;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #fff;
    }
    .fa-times:hover{
        color: red;
    }
    .tg-dropdownmenu li {
        width: 100%;
        float: left;
        position: relative;
        line-height: inherit;
        list-style-type: none;
        padding: 20px 20px 20px 38px;
    }

    .tg-dropdownmenu li:before {
        top: 29px;
        left: 18px;
        width: 4px;
        height: 4px;
        content: '';
        border-radius: 50%;
        position: absolute;
        background: #f91942;
    }

    a:link {
        text-decoration: none;
    }

    .tg-dropdownmenu li+li {
        border-top: 1px solid #eaeaea;
    }

    .tg-dropdownmenu li:hover {
        background: #f7f7f7;
    }

    .tg-dropdownmenu li p {
        margin: 0;
        line-height: inherit;
    }

    .tg-themedropdown.open .tg-dropdownmenu {
        top: 70px;
        opacity: 1;
        visibility: visible;
    }

    .logoset {
        width: 100px;
        height: 60px;
        margin: auto;
    }
</style>
<header id="tg-header" class="tg-header tg-haslayout">
    <div class="tg-topbar">
        <div class="container-xl">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <strong class="tg-logo" (click)="goMainHome()"><a href="javascript:void(0);"><img class="logoset"
                                src="../../../assets/images/wihoutborder.png" alt="Logo"></a></strong>

                    <div id="tg-navigation" class="collapse navbar-collapse tg-navigation">
                        <ul>
                            <li class="menu-item-has-children"><span class="tg-dropdowarrow"><i
                                        class="fa fa-angle-down"></i></span>
                                <a style="color: white;text-decoration: none;" [routerLink]="['home']">Home </a>
                            </li>
                            <li class="menu-item-has-children current-menu-item"><span class="tg-dropdowarrow"><i
                                        class="fa fa-angle-down"></i></span>
                                <a style="color: white;text-decoration: none;" [routerLink]="['aboutUs']">About us </a>
                            </li>
                            <li class="menu-item-has-children"><span class="tg-dropdowarrow"><i
                                        class="fa fa-angle-down"></i></span>
                                <a style="color: white;text-decoration: none;" [routerLink]="['contactUs']">Contact us
                                </a>
                                <ul class="sub-menu">
                                    <!-- <li><a href="dashboard.html">Dashboard</a></li>
                                        <li><a href="dashboard-myads.html">Dashboard My Ads</a></li>
                                        <li><a href="dashboard-myfavourites.html">Dashboard Favorites</a></li>
                                        <li><a href="dashboard-offermessages.html">Dashboard Offer Message</a></li>
                                        <li><a href="dashboard-payments.html">Dashboard Payment</a></li>
                                        <li><a href="dashboard-postanad.html">Dashboard Post Ad</a></li>
                                        <li><a href="dashboard-privacy-setting.html">Dashboard privacy Setting</a></li>
                                        <li><a href="dashboard-profile-setting.html">Dashboard Profile Setting</a></li> -->
                                </ul>
                            </li>
                            <li class="menu-item-has-children"><span class="tg-dropdowarrow"><i
                                        class="fa fa-angle-down"></i></span>
                                <a style="color: white;text-decoration: none;" href="javascript:void(0);">Helps<span
                                        class="caret"></span></a>
                                <ul class="sub-menu" style="z-index: 99999;">
                                    <!-- <li><a [routerLink]="['aboutUs']">About us</a></li> -->
                                    <li><a style="text-decoration: none;" [routerLink]="['contactUs']">Privacy
                                            Agreement</a></li>
                                    <li><a style="text-decoration: none;" [routerLink]="['contactUs']">Terms &
                                            Conditions</a></li>
                                    <!-- <li class="menu-item-has-children"><span class="tg-dropdowarrow"><i
                                                    class="fa fa-angle-down"></i></span>
                                            <a href="javascript:void(0);">News</a>
                                            <ul class="sub-menu">
                                                <li><a href="newsgrid.html">News grid</a></li>
                                                <li><a href="newslist.html">News list</a></li>
                                                <li><a href="newsdetail.html">News detail</a></li>
                                            </ul>
                                        </li> -->
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <ul class="tg-navcurrency">
                        <li> <a href="javascript:void(0);" data-toggle="modal" data-target="#modalselectcurrency">
                                <i class="fa fa-globe" aria-hidden="true"
                                    style="color: white;text-decoration: none;"></i> country</a>
                        </li>
                    </ul>
                    <div>
                        <ul class="tg-navcurrency">
                            <!-- <li class="tg-facebook"><a href="https://www.facebook.com/MyKnocks/?ref=aymt_homepage_panel" target="_blank"><i class="fa fa-facebook"></i></a></li>
                            <li class="tg-instagram"><a href="https://www.instagram.com/myknocks2015/" target="_blank"><i class="fa fa-instagram"></i></a></li>
                            <li class="tg-twitter"><a href="https://twitter.com/myknocksdeals" target="_blank"><i class="fa fa-twitter"></i></a></li>
                           <li class="tg-linkedin"><a href="https://www.linkedin.com/in/my-knocks-922077105/" target="_blank"><i class="fa fa-linkedin"></i></a></li>
                            <li class="tg-googleplus"><a href="javascript:void(0);"><i class="fa fa-google-plus" target="_blank"></i></a></li>
                            <li class="tg-rss"><a href="javascript:void(0);"><i class="fa fa-rss" target="_blank"></i></a></li> -->
                            <!-- <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"><a
                                        [routerLink]="['home']">Home</a></li>
                                <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"><a
                                        [routerLink]="['aboutUs']">About Us</a></li> -->
                            <!-- <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"><a
                                        [routerLink]="['books']">Books</a></li>
                                <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"><a
                                        [routerLink]="['chat']">Check</a></li>
                                <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"><a
                                        [routerLink]="['adPosting']">Ad Post</a></li>
                                <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"><a
                                        [routerLink]="['details']">Details</a></li>
                                <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"><a
                                        [routerLink]="['search','autos','cars']">Ads-Listing</a></li> -->
                        </ul>
                    </div>
                    <div>
                        <ul class="tg-navcurrency" style="float: right;"
                            *ngIf="(authState | async).athenticated === false">
                            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"><a
                                    [routerLink]="['login']">Register</a></li>
                            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"><a
                                    [routerLink]="['login']">SignIn</a></li>
                        </ul>
                    </div>

                    <div id="userLoggedIn" appDropDown class="dropdown tg-themedropdown tg-userdropdown"
                        *ngIf="(authState | async).athenticated === true">
                        <a class="dropdown-toggle tg-btndropdown" id="tg-adminnav">
                            <!-- <span class="tg-userdp"><img src="assets/images/author/img-01.jpg" alt="image description"></span> -->
                            <span class="tg-userdp"><img *ngIf="imageBase64 != null" [src]="imageBase64"
                                    alt="userImage" />
                                <img src="../../../assets/images/ads/dummyimage.png"
                                    *ngIf="imageBase64 == null" /></span>
                            <span class="tg-name" style="cursor: pointer;color:#f7f7f7">Hi! {{this.userName}}
                                <span class="caret"></span>
                            </span>
                        </a>
                        <ul class="dropdown-menu tg-themedropdownmenu" aria-labelledby="tg-adminnav">
                            <li>
                                <a style="cursor: pointer " (click)="onLogOut() ">
                                    <i class="icon-exit"></i>
                                    <span>SignOut</span>
                                </a>
                            </li>
                            <li><a (click)="goUserHome()">
                                    <i class="icon-layers"></i>
                                    <span>Dashboard</span>
                                </a>
                            </li>
                            <li>
                                <a (click)="goUserProfile()">
                                    <i class="icon-cog"></i>
                                    <span>Profile Settings</span>
                                </a>
                            </li>
                            <!-- <li class="menu-item-has-children">
                                    <a href="javascript:void(0);">
                                        <i class="icon-layers"></i>
                                        <span>My Ads</span>
                                    </a>
                                    <ul>
                                        <li><a href="dashboard-myads.html">All Ads</a></li>
                                        <li><a href="dashboard-myads.html">Featured Ads</a></li>
                                        <li><a href="dashboard-myads.html">Active Ads</a></li>
                                        <li><a href="dashboard-myads.html">Inactive Ads</a></li>
                                        <li><a href="dashboard-myads.html">Sold Ads</a></li>
                                        <li><a href="dashboard-myads.html">Expired Ads</a></li>
                                        <li><a href="dashboard-myads.html">Deleted Ads</a></li>
                                    </ul>
                                </li> -->
                            <li>
                                <a href="javascript:void(0);">
                                    <i class="icon-cart"></i>
                                    <span>Company</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <i class="icon-star"></i>
                                    <span>Help</span>
                                </a>
                            </li>

                        </ul>
                    </div>
                    <div *ngIf="this.token != null">
                        <div class="dropdown tg-themedropdown tg-notification">
                            <button class="tg-btndropdown" id="tg-notificationdropdown3" type="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-envelope" aria-hidden="true" style="color:white"></i>
                                <span class="tg-badge">
                                    {{(messageslength != null) ? messageslength : '0'}}
                                </span>
                            </button>
                            <ul class="dropdown-menu tg-dropdownmenu" aria-labelledby="tg-notificationdropdown3">
                                <div
                                    style="font-weight: bold;background-color: rgb(241, 241, 241);padding: 5px 0px 5px 20px">
                                    Message's
                                    <span (click)="goUserMessages()"
                                        style="float: right;cursor: pointer;padding: 0px 20px 5px 0px;">ViewAll</span>
                                </div>
                                <li *ngFor="let item of this.friendmessages">
                                    <div class="row" (click)="goUserMessages()">
                                        <div class="col-md-3">
                                            <img *ngIf="item.userimagebase64 != null && item.userimagebase64 != ''" [src]="item.userimagebase64"
                                                class="img-fluid rounded-img" />
                                                <img *ngIf="item.userimagebase64 == null || item.userimagebase64 == ''" 
                                                src="/assets/images/no_image.jpg" class="img-fluid rounded-img">
                                        </div>
                                        <div class="col-md-9" style="margin-left:inherit;">
                                            <span style="font-size: 1.7rem;">{{item.sender}} &nbsp;
                                                <i class="fa fa-comments-o" aria-hidden="true"></i></span>
                                            <p style="cursor: pointer;"><span>{{ (item.message.length>25)? (item.message
                                                    | slice:0:25)+'...':(item.message) }}</span> &nbsp; &nbsp;
                                                {{item.datetime | date:'mediumTime'}} &nbsp; <a style="float: right;"
                                                    (click)="goUserMessages()">Reply</a></p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="dropdown tg-themedropdown tg-notification">
                            <button class="tg-btndropdown" id="tg-notificationdropdown2" type="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-bell" aria-hidden="true" style="color:white"></i>
                                <span class="tg-badge">9</span>
                            </button>
                            <ul class="dropdown-menu tg-dropdownmenu" aria-labelledby="tg-notificationdropdown2">
                                <li>
                                    <p>Consectetur adipisicing sedi eiusmod ampore incididunt ut labore et dolore.</p>
                                </li>
                            </ul>
                        </div>
                        <div class="dropdown tg-themedropdown tg-notification">
                            <button class="tg-btndropdown" id="tg-notificationdropdown1" type="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-users" aria-hidden="true" style="color:white"></i>
                                <span class="tg-badge">
                                    {{(frndrequestlenght != null) ? frndrequestlenght : '0'}}
                                </span>
                            </button>
                            <ul class="dropdown-menu tg-dropdownmenu" aria-labelledby="tg-notificationdropdown1">
                                <div
                                    style="font-weight: bold;background-color: rgb(241, 241, 241);padding: 5px 0px 5px 20px">
                                    Friend Request's
                                    <span (click)="goUserFrineds()"
                                        style="float: right;cursor: pointer;padding: 0px 20px 5px 0px;">ViewAll</span>
                                </div>
                                <li *ngFor="let item of this.FrndRequests">
                                    <div class="row">
                                        <div class="col-md-3" (click)="goUserFrineds()">
                                            <img *ngIf="item.imageBase64 != null && item.imageBase64 != ''" [src]="item.imageBase64"
                                                class="img-fluid rounded-img" />
                                                <img *ngIf="item.imageBase64 == null || item.imageBase64 == ''" 
                                                src="/assets/images/no_image.jpg" class="img-fluid rounded-img">
                                        </div>
                                        <div class="col-md-9" style="margin-left: inherit;">
                                            <span style="font-size: 1.7rem;">{{item.userName}} &nbsp;
                                                <i class="fa fa-handshake-o" aria-hidden="true"></i></span>
                                            <p style="cursor: pointer;"><a
                                                    (click)="AcceptReq(item.userProfileID)">Accept</a> | <a
                                                    (click)="RejectReq(item.userProfileID)">Reject</a> &nbsp; <span
                                                    style="float: right;">{{item.datetime | date:'mediumTime'}}</span>
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="dropdown tg-themedropdown tg-notification">
                            <button class="tg-btndropdown" id="tg-notificationdropdown1" type="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-shopping-cart" aria-hidden="true" style="color:white"></i>
                                <span class="tg-badge">
                                    {{ adproductService.getLocalCartProducts().length }}
                                    <!-- {{(frndrequestlenght != null) ? frndrequestlenght : '0'}} -->
                                </span>
                            </button>
                            <ul class="dropdown-menu tg-dropdownmenu" aria-labelledby="tg-notificationdropdown1" >
                                <div
                                    style="font-weight: bold;background-color: rgb(241, 241, 241);padding: 5px 0px 5px 20px">
                                    Add to Cart
                                    <span (click)="goUserCart()"
                                        style="float: right;cursor: pointer;padding: 0px 20px 5px 0px;">ViewAll</span>
                                </div>
                                <li *ngFor="let item of adproductService.getLocalCartProducts()">
                                    <div class="row">
                                        <div class="col-md-3" (click)="goUserCart()">
                                            <img *ngIf="item.proImageBase != null && item.proImageBase != ''" [src]="item.proImageBase"
                                                class="img-fluid rounded-img" />
                                                <img *ngIf="item.proImageBase == null || item.proImageBase == ''" 
                                                src="/assets/images/no_image.jpg" class="img-fluid rounded-img">
                                        </div>
                                        <div class="col-md-9" style="margin-left: inherit;">
                                            <span style="font-size: 1.7rem;">{{item.proName}} &nbsp;
                                                <i (click)="removeCartProduct(item)" class="fa fa-times" aria-hidden="true"></i></span>
                                            <p style="cursor: pointer;"><a>{{item.proPriceUnitName}}</a> | <a>
                                                {{item.proPrice}}</a> &nbsp;
                                                 <!-- <span style="float: right;">{{item.proPriceUnitName}}</span> -->
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="dropdown tg-themedropdown tg-notification">
                            <button class="tg-btndropdown" id="tg-notificationdropdown1" type="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-heart" aria-hidden="true" style="color:white"></i>
                                <span class="tg-badge">
                                    {{adproductService.getFavoriteAds().length + adproductService.getFavoriteCartProducts().length+adproductService.getFavoriteDeals().length}}
                                </span>
                            </button>
                            <ul class="dropdown-menu tg-dropdownmenu" aria-labelledby="tg-notificationdropdown1">
                                <div
                                    style="font-weight: bold;background-color: rgb(241, 241, 241);padding: 5px 0px 5px 20px">
                                    Add to Favorite
                                    <span (click)="goUserCartFavorite()"
                                        style="float: right;cursor: pointer;padding: 0px 20px 5px 0px;">ViewAll</span>
                                </div>
                                <li *ngFor="let item of adproductService.getFavoriteAds(); let i = index">
                                    <div class="row">
                                        <div class="col-md-3" (click)="goUserCartFavorite()">
                                            <img *ngIf="item.addImageBase != null && item.addImageBase != ''" [src]="item.addImageBase"
                                                class="img-fluid rounded-img" />
                                                <img *ngIf="item.addImageBase == null || item.addImageBase == ''" 
                                                src="/assets/images/no_image.jpg" class="img-fluid rounded-img">
                                        </div>
                                        <div class="col-md-9" style="margin-left: inherit;">
                                            <span style="font-size: 1.7rem;">{{item.title}} &nbsp;
                                                <i (click)="removeLocalAds(item)" class="fa fa-times" aria-hidden="true"></i></span>
                                            <p style="cursor: pointer;"><a>{{item.currencyName}}</a> | <a>
                                                {{item.price}}</a> &nbsp;
                                                 <!-- <span style="float: right;">{{item.proPriceUnitName}}</span> -->
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="tg-navigationarea" style="background-color: white; box-shadow: 0 0px 15px rgba(0,0,0,0.2);">
        <div class="container" style="margin-top: 10px;">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                    <a style="background-color: #00cc67;" class="tg-btn" (click)="goUserNewPost()">
                        <i class="icon-bookmark"></i>
                        <span>post an ad</span>
                    </a>
                    <nav id="tg-nav" class="tg-nav" style="padding: 10px;">
                        <div class="navbar-header">
                            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
                                data-target="#tg-navigation" aria-expanded="false">
                                <span class="sr-only">Toggle navigation</span>
                                <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                            </button>
                        </div>

                        <div class="collapse navbar-collapse js-navbar-collapse">
                            <ul class="nav navbar-nav" style="list-style: none;list-style-type: none;">
                                <li class="dropdown mega-dropdown"><span class="tg-dropdowarrow"><i
                                            class="fa fa-angle-down"></i></span>
                                    <a style="text-decoration: none;color: black" href="javascript:void(0);"
                                        class="dropdown-toggle" data-toggle="dropdown">Classified <span
                                            class="caret"></span></a>
                                    <ul class="dropdown-menu mega-dropdown-menu"
                                        style="border-top:5px solid #00cc67;border-bottom:5px solid #00cc67;">
                                        <li class="col-sm-3" style=" list-style-type: none;">
                                            <ul>
                                                <li class="dropdown-header">Accessories</li>
                                                <li><a (click)="ClassifiedsearchSend()"
                                                        href="javascript:void(0);">Clothing</a></li>
                                                <li><a (click)="ClassifiedsearchSend()" href="javascript:void(0);">Baby
                                                        Items</a></li>
                                                <li><a (click)="ClassifiedsearchSend()"
                                                        href="javascript:void(0);">Jewellery</a></li>
                                                <li><a (click)="ClassifiedsearchSend()"
                                                        href="javascript:void(0);">Toys</a></li>
                                                <!-- <li class="divider"></li>
                                                    <li class="dropdown-header">Fonts</li> -->
                                                <li><a (click)="ClassifiedsearchSend()"
                                                        href="javascript:void(0);">Scarves</a></li>
                                                <li><a (click)="ClassifiedsearchSend()"
                                                        href="javascript:void(0);">Wallets</a></li>
                                                <li><a (click)="ClassifiedsearchSend()"
                                                        href="javascript:void(0);">Watches</a></li>
                                            </ul>
                                        </li>
                                        <li class="col-sm-3" style=" list-style-type: none;">
                                            <ul>
                                                <li class="dropdown-header">Home & Garden</li>
                                                <li><a (click)="ClassifiedsearchSend()"
                                                        href="javascript:void(0);">Furniture</a></li>
                                                <li><a (click)="ClassifiedsearchSend()" href="javascript:void(0);">Home
                                                        & Garden</a></li>
                                                <li><a (click)="ClassifiedsearchSend()" href="javascript:void(0);">Home
                                                        Appliances</a></li>
                                                <li><a (click)="ClassifiedsearchSend()"
                                                        href="javascript:void(0);">Sofas</a></li>
                                                <li><a (click)="ClassifiedsearchSend()"
                                                        href="javascript:void(0);">Cupbords</a></li>
                                                <li><a (click)="ClassifiedsearchSend()"
                                                        href="javascript:void(0);">Beds</a></li>
                                            </ul>
                                        </li>
                                        <li class="col-sm-3" style=" list-style-type: none;">
                                            <ul>
                                                <li class="dropdown-header">Electronics</li>
                                                <li><a (click)="ClassifiedsearchSend()"
                                                        href="javascript:void(0);">Mobiles</a></li>
                                                <li><a (click)="ClassifiedsearchSend()"
                                                        href="javascript:void(0);">Music</a></li>
                                                <li><a (click)="ClassifiedsearchSend()"
                                                        href="javascript:void(0);">Networking</a></li>
                                                <li><a (click)="ClassifiedsearchSend()"
                                                        href="javascript:void(0);">PDAs</a></li>
                                                <li><a (click)="ClassifiedsearchSend()"
                                                        href="javascript:void(0);">LCD/LED</a></li>
                                                <li><a (click)="ClassifiedsearchSend()"
                                                        href="javascript:void(0);">TV</a></li>
                                                <li><a (click)="ClassifiedsearchSend()"
                                                        href="javascript:void(0);">Fans</a></li>
                                                <li><a (click)="ClassifiedsearchSend()"
                                                        href="javascript:void(0);">Cameras</a></li>
                                            </ul>
                                        </li>
                                        <li class="col-sm-3" style=" list-style-type: none;">
                                            <ul>
                                                <li class="dropdown-header">Others</li>
                                                <li><a (click)="ClassifiedsearchSend()" href="javascript:void(0);">Baby
                                                        Items</a></li>
                                                <li><a (click)="ClassifiedsearchSend()"
                                                        href="javascript:void(0);">Books</a></li>
                                                <li><a (click)="ClassifiedsearchSend()"
                                                        href="javascript:void(0);">Business</a></li>
                                                <li><a (click)="ClassifiedsearchSend()"
                                                        href="javascript:void(0);">Collecables</a></li>
                                                <li><a (click)="ClassifiedsearchSend()"
                                                        href="javascript:void(0);">Computers</a></li>
                                                <li><a (click)="ClassifiedsearchSend()"
                                                        href="javascript:void(0);">Industries</a></li>
                                                <li><a (click)="ClassifiedsearchSend()"
                                                        href="javascript:void(0);">Clocks</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li class="dropdown mega-dropdown"><span class="tg-dropdowarrow"><i
                                            class="fa fa-angle-down"></i></span>
                                    <a style="text-decoration: none;color: black" href="javascript:void(0);"
                                        class="dropdown-toggle" data-toggle="dropdown">Autos <span
                                            class="caret"></span></a>
                                    <ul class="dropdown-menu mega-dropdown-menu"
                                        style="border-top:5px solid #00cc67;border-bottom:5px solid #00cc67;">
                                        <li class="col-sm-12" style=" list-style-type: none;">
                                            <ul>
                                                <li class="dropdown-header">Autos</li>
                                                <li><a (click)="AutosearchSend()" href="javascript:void(0);">Auto
                                                        Accessories & Parts</a></li>
                                                <li><a (click)="AutosearchSend()" href="javascript:void(0);">Boats</a>
                                                </li>
                                                <li><a (click)="AutosearchSend()" href="javascript:void(0);">Cars</a>
                                                </li>
                                                <li><a (click)="AutosearchSend()" href="javascript:void(0);">Heavy
                                                        Vehycles</a></li>
                                                <!-- <li class="divider"></li>
                                                    <li class="dropdown-header">Fonts</li> -->
                                                <li><a (click)="AutosearchSend()"
                                                        href="javascript:void(0);">Motorcycles</a></li>
                                                <li><a (click)="AutosearchSend()" href="javascript:void(0);">Wallets</a>
                                                </li>
                                                <li><a (click)="AutosearchSend()" href="javascript:void(0);">Watches</a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li class="dropdown mega-dropdown"><span class="tg-dropdowarrow"><i
                                            class="fa fa-angle-down"></i></span>
                                    <a style="text-decoration: none;color: black" href="javascript:void(0);"
                                        class="dropdown-toggle" data-toggle="dropdown">Property <span
                                            class="caret"></span></a>
                                    <ul class="dropdown-menu mega-dropdown-menu"
                                        style="border-top:5px solid #00cc67;border-bottom:5px solid #00cc67;">
                                        <li class="col-sm-3" style=" list-style-type: none;">
                                            <ul>
                                                <li class="dropdown-header">Property for Rent</li>
                                                <li><a (click)="PropertyRentsearchSend()"
                                                        href="javascript:void(0);">Commercial For Rent</a></li>
                                                <li><a (click)="PropertyRentsearchSend()"
                                                        href="javascript:void(0);">Rental Wanted</a></li>
                                                <li><a (click)="PropertyRentsearchSend()"
                                                        href="javascript:void(0);">Residential For Rent</a></li>
                                                <li><a (click)="PropertyRentsearchSend()"
                                                        href="javascript:void(0);">Rooms For Rent</a></li>
                                                <!-- <li class="divider"></li>
                                                    <li class="dropdown-header">Fonts</li> -->
                                                <li><a (click)="PropertyRentsearchSend()"
                                                        href="javascript:void(0);">Rooms Wanted</a></li>
                                                <li><a (click)="PropertyRentsearchSend()"
                                                        href="javascript:void(0);">Short Term (Daily)</a></li>
                                                <li><a (click)="PropertyRentsearchSend()"
                                                        href="javascript:void(0);">Short Term(Monthly)</a></li>
                                            </ul>
                                        </li>
                                        <li class="col-sm-3" style=" list-style-type: none;">
                                            <ul>
                                                <li class="dropdown-header">Property for Sale</li>
                                                <li><a (click)="PropertySalesearchSend()"
                                                        href="javascript:void(0);">Commercial For Sale</a></li>
                                                <li><a (click)="PropertySalesearchSend()"
                                                        href="javascript:void(0);">Land For Sale</a></li>
                                                <li><a (click)="PropertySalesearchSend()"
                                                        href="javascript:void(0);">Multiple For Sale</a></li>
                                                <li><a (click)="PropertySalesearchSend()"
                                                        href="javascript:void(0);">Residential For Sale</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li class="dropdown mega-dropdown"><span class="tg-dropdowarrow"><i
                                            class="fa fa-angle-down"></i></span>
                                    <a style="text-decoration: none;color: black" href="javascript:void(0);"
                                        class="dropdown-toggle" data-toggle="dropdown">Jobs <span
                                            class="caret"></span></a>
                                    <ul class="dropdown-menu mega-dropdown-menu"
                                        style="border-top:5px solid #00cc67;border-bottom:5px solid #00cc67;">
                                        <li class="col-sm-3" style=" list-style-type: none;">
                                            <ul>
                                                <li class="dropdown-header">Jobs</li>
                                                <li><a (click)="JobWantsearchSend()"
                                                        href="javascript:void(0);">Accounting</a></li>
                                                <li><a (click)="JobWantsearchSend()"
                                                        href="javascript:void(0);">Administration</a></li>
                                                <li><a (click)="JobWantsearchSend()"
                                                        href="javascript:void(0);">Advertising</a></li>
                                                <li><a (click)="JobWantsearchSend()"
                                                        href="javascript:void(0);">Airline</a></li>
                                                <!-- <li class="divider"></li>
                                                    <li class="dropdown-header">Fonts</li> -->
                                                <li><a (click)="JobWantsearchSend()"
                                                        href="javascript:void(0);">Architecture</a></li>
                                                <li><a (click)="JobWantsearchSend()" href="javascript:void(0);">Art</a>
                                                </li>
                                                <li><a (click)="JobWantsearchSend()"
                                                        href="javascript:void(0);">Automotive</a></li>
                                                <li><a (click)="JobWantsearchSend()"
                                                        href="javascript:void(0);">Aviation</a></li>
                                            </ul>
                                        </li>
                                        <li class="col-sm-3" style=" list-style-type: none;">
                                            <ul>
                                                <li class="dropdown-header">Jobs</li>
                                                <li><a (click)="JobWantsearchSend()"
                                                        href="javascript:void(0);">Banking</a></li>
                                                <li><a (click)="JobWantsearchSend()"
                                                        href="javascript:void(0);">Beauty</a></li>
                                                <li><a (click)="JobWantsearchSend()"
                                                        href="javascript:void(0);">Constuction</a></li>
                                                <li><a (click)="JobWantsearchSend()"
                                                        href="javascript:void(0);">Consulting</a></li>
                                                <li><a (click)="JobWantsearchSend()" href="javascript:void(0);">Customer
                                                        Service</a></li>
                                                <li><a (click)="JobWantsearchSend()"
                                                        href="javascript:void(0);">Education</a></li>
                                                <li><a (click)="JobWantsearchSend()"
                                                        href="javascript:void(0);">Engineering</a></li>
                                                <li><a (click)="JobWantsearchSend()"
                                                        href="javascript:void(0);">Education</a></li>
                                            </ul>
                                        </li>
                                        <li class="col-sm-3" style=" list-style-type: none;">
                                            <ul>
                                                <li class="dropdown-header">Jobs</li>
                                                <li><a (click)="JobWantsearchSend()"
                                                        href="javascript:void(0);">Fashion</a></li>
                                                <li><a (click)="JobWantsearchSend()"
                                                        href="javascript:void(0);">Finance</a></li>
                                                <li><a (click)="JobWantsearchSend()" href="javascript:void(0);">HR
                                                        Recruitment</a></li>
                                                <li><a (click)="JobWantsearchSend()" href="javascript:void(0);">IT</a>
                                                </li>
                                                <li><a (click)="JobWantsearchSend()" href="javascript:void(0);">Legal
                                                        Services</a></li>
                                                <li><a (click)="JobWantsearchSend()"
                                                        href="javascript:void(0);">Manufacturing</a></li>
                                                <li><a (click)="JobWantsearchSend()"
                                                        href="javascript:void(0);">Marketing</a></li>
                                                <li><a (click)="JobWantsearchSend()" href="javascript:void(0);">Oil Gas
                                                        Energy</a></li>
                                            </ul>
                                        </li>
                                        <li class="col-sm-3" style=" list-style-type: none;">
                                            <ul>
                                                <li class="dropdown-header">Jobs</li>
                                                <li><a (click)="JobWantsearchSend()" href="javascript:void(0);">Online
                                                        Media</a></li>
                                                <li><a (click)="JobWantsearchSend()"
                                                        href="javascript:void(0);">Pharmacuetical</a></li>
                                                <li><a (click)="JobWantsearchSend()"
                                                        href="javascript:void(0);">Resturents</a></li>
                                                <li><a (click)="JobWantsearchSend()"
                                                        href="javascript:void(0);">Sales</a></li>
                                                <li><a (click)="JobWantsearchSend()"
                                                        href="javascript:void(0);">Trasportation</a></li>
                                                <li><a (click)="JobWantsearchSend()"
                                                        href="javascript:void(0);">Warehousing</a></li>
                                                <li><a (click)="JobWantsearchSend()"
                                                        href="javascript:void(0);">Wholesale</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li class="dropdown mega-dropdown"><span class="tg-dropdowarrow"><i
                                            class="fa fa-angle-down"></i></span>
                                    <a style="text-decoration: none;color: black" href="#" class="dropdown-toggle"
                                        data-toggle="dropdown">Supplier's <span class="caret"></span></a>
                                    <ul class="dropdown-menu mega-dropdown-menu"
                                        style="border-top:5px solid #00cc67;border-bottom:5px solid #00cc67;">
                                        <li class="col-sm-6" style=" list-style-type: none;">
                                            <ul>
                                                <li class="dropdown-header">Suppliers</li>
                                                <li class="divider"></li>
                                                <li><a (click)="SuppliersearchSend()" href="javascript:void(0);">Details of the suppliers.</a></li>
                                            </ul>
                                        </li>
                                        <li class="col-sm-6" style=" list-style-type: none;">
                                            <ul>
                                                <li class="dropdown-header">Products</li>
                                                <li class="divider"></li>
                                                <li><a (click)="ProductsearchSend()" href="javascript:void(0);">Check all the products added by
                                                        supplier</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li class="dropdown mega-dropdown"><span class="tg-dropdowarrow"><i
                                            class="fa fa-angle-down"></i></span>
                                    <a style="text-decoration: none;color: black;" href="#" class="dropdown-toggle"
                                        data-toggle="dropdown">Deals <span class="caret"></span></a>
                                    <ul class="dropdown-menu mega-dropdown-menu"
                                        style="border-top:5px solid #00cc67;border-bottom:5px solid #00cc67;">
                                        <li class="col-sm-12" style=" list-style-type: none;">
                                            <ul>
                                                <li class="dropdown-header">Deals</li>
                                                <li><a (click)="DealsearchSend()" href="javascript:void(0);">Bose Headphone</a></li>
                                                <li><a (click)="DealsearchSend()" href="javascript:void(0);">Mont Blank Perfume</a></li>
                                                <li><a (click)="DealsearchSend()" href="javascript:void(0);">Stroller</a></li>
                                                <li><a (click)="DealsearchSend()" href="javascript:void(0);">Gravity Sunglasses</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </nav>

                </div>
            </div>
        </div>
    </div>
</header>