import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { adProductService } from 'src/app/shared/services/compnies/ProductServices';
import {ActivatedRoute} from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Product } from './Product';
import { CategoryDto } from 'src/app/shared/models/category/categoryDTO.model';
import { dataLibraries } from 'src/app/shared/models/category/dataLibraries/dataLibraries.model';
import { openSearchService } from 'src/app/shared/services/search/openSearch.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
//import { productDetails } from 'src/app/shared/models/Company/productDetail';

@Component({
  selector: 'app-show-product',
  templateUrl: './show-product.component.html',
  styleUrls: ['./show-product.component.css']
})
export class ShowProductComponent implements OnInit {
  //Productobj: any;
  //UpdateData !: FormGroup;
  //empObj : Product = new Product();
  
  constructor(public adproductService: adProductService,private formBuilder: FormBuilder,private ngxLoader: NgxUiLoaderService,
    //private productData: productDetails,
    private router: Router,
    //private router: ActivatedRoute
    ) { }

  ProductList: any=[];
    TotalLength:any;
    page:number=1;

  LoadProductData() {
    debugger;
    this.ngxLoader.start();
    this.adproductService.getProductDetails().subscribe((data:any[]) => {
      this.ProductList = data;
      this.TotalLength = data.length;
      setTimeout(() => {
        this.ngxLoader.stop();
  
      }, 1000);
    });   
  }
  
  addToCart(product: Product) {
    debugger
    this.adproductService.addToCart(product);
  }
  onPageSizeChange(): void{
    this.adproductService._pageSizeSubject.next(this.adproductService.pageSize);
  }
  selectedBrand: "All";
  brands = ["All", "Apple", "Realme", "Nokia", "Motorolla"];
  ngOnInit(): void {
    // this.adproductService.pageSizeChanges$
    // .subscribe(newPageSize => {
    //   this.adproductService.page = 1;
    //   this.LoadProductData();
    // });
    this.LoadProductData();
  }

  // editClick(items) {
  //   //this.ActivateAddEditProComp = true;
  //   // this.adproductService.GetProductByID(items);
  //   //this.router.navigate(['/userupdate']);
  //   // this.router.navigate(['/userupdate' + [//console.log(items)]] );
  //   // //console.log(items.proID);
  //   this.pro=items;
  //   // this.UpdateData.controls['proID'].setValue(items.proID);
  //   // this.UpdateData.controls['proName'].setValue(items.proName);
  //   // this.UpdateData.controls['proDescription'].setValue(items.proDescription);
  //   ////console.log(items);

  // }

  // updateProduct() {
  //   this.pro.Obj.proID = this.UpdateData.value.proID;
  //   this.pro.Obj.proName = this.UpdateData.value.proName;
  //   this.pro.Obj.proDescription = this.UpdateData.value.proDescription;

  //   this.adproductService.updateProduct(this.pro.Obj).subscribe(res=>{
  //     //console.log(res);
  //     //this.getAllEmployee();
  //   },err=>{
  //     //console.log(err);
  //   })

  // }

}
