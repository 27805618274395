import { Component, OnInit } from '@angular/core';
import { AppState } from 'src/app/store/app.state';
import * as fromApp from './store/app.state';
import * as fromAuth from './store/reducers/auth.reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tokenName } from '@angular/compiler';
import { LocalStorageService, SessionStorageService } from 'angular-web-storage';

import { ChatAdapter } from 'ng-chat';
import { DemoAdapter } from './shared/demoAdaptor.service';
import { ChatService } from './shared/chat.service';
import { SignalRGroupAdapter } from './shared/signalRGroupAdapter.service';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { openSearchService } from './shared/services/search/openSearch.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {  
  title = 'myTestApp';
  authState : Observable<fromAuth.State>;
  //name = 'NGX-UI-LOADER';
  currentTheme = 'dark-theme';

  fileUploadUrl: string = 'UploadFile';
  userId: string = "offline-demo";
  username: string;
  triggeredEvents = [];

  userMode : string = "0"; // means normal

  userInnerSideBarMode : string = "1"; // means normal

  adapter: ChatAdapter = new DemoAdapter();
  signalRAdapter: SignalRGroupAdapter;

  switchTheme(theme: string): void {
    this.currentTheme = theme;
  }

  onEventTriggered(event: string): void {
    this.triggeredEvents.push(event);
  }

  joinSignalRChatRoom(): void {
    ////debugger;
    const userName = prompt('Please enter a user name:');

    this.signalRAdapter = new SignalRGroupAdapter(userName, this.http);
  }

  constructor(private store: Store<fromApp.AppState>, public localStor: LocalStorageService, public sessionStor: SessionStorageService,private http: HttpClient,
    private ngxLoader: NgxUiLoaderService, private userInterface: openSearchService, private router: Router){
    ////debugger;
    if(this.getToken() == true)
    {
      ////debugger;
      fromAuth.initialState.athenticated = true;
      fromAuth.initialState.token = this.localStor.get('currentUser').replace(/^"(.*)"$/, '$1');           
    }
    else{
     //this.store.dispatch(new autha)
    }  
    
    //debugger;
    let currentRoute : string = window.location.href;
    if(currentRoute.includes("user"))
    {
      this.userMode = "1";
    }
    else
    {
      this.userMode = "0";
    }
  }
  
  getToken(): boolean {
    //this.localStor.set(this.KEY, { a: 1, now: +new Date }, expired, 's');
    //this.localStor.remove(this.KEY);
    if(this.localStor.get('currentUser'))
    {
      return true;      
    }
    return  false;
  }  

  ngOnInit(){ 
    // this.ngxLoader.start();
    // this.http.get(`https://api.npmjs.org/downloads/range/last-year/ngx-ui-loader`).subscribe((res: any) => {
    //   //console.log(res);
    //   this.ngxLoader.stop();
    // });   
    // //debugger;
    // let currentRoute : string = window.location.href;
    // if(currentRoute.includes("user"))
    // {
    //   this.userMode = "1";
    // }
    // else
    // {
    //   this.userMode = "0";
    // }
    // //console.log(this.router.url);
    this.userInterface.currentInterface.subscribe(data => {
      ////debugger;
      //console.log(data);
      if(data.toString()=="1")
      {
        this.userMode  = data.toString();
      }
      else if(data.toString()=="0")
      {
        this.userMode  = '0';
      }
    });


    //for sideBarSettings
    // this.userInterface.currentInnerSideBarInterface.subscribe(data => {
    //   //debugger;
    //   //console.log(data);
    //   if(data.toString()=="1")
    //   {
    //     this.userInnerSideBarMode  = data.toString();
    //   }
    //   else if(data.toString()=="0")
    //   {
    //     this.userInnerSideBarMode  = '0';
    //   }
    // });
  }  
}
