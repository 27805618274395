<div class="container">
    <div class="row" style="margin-top: 150px;">
        <aside class="col-lg-8">
            <div class="container-xl center">
                <table class="table table-striped table-hover">
                    <thead>
                        <tr style="background: #00cc67;color: white;">
                            <th scope="col">Product</th>
                            <th scope="col" width="120">Quantity </th>
                            <th scope="col" width="120">Unit Price</th>
                            <th scope="col" width="120">Price</th>
                            <th scope="col" class="text-center d-none d-md-block">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let product of cartProducts | paginate: { itemsPerPage: 10, currentPage: page,totalItems:TotalLength }">
                            <td>
                                <figure class="itemside align-items-center">
                                    <div class="aside">
                                        <img *ngIf="product.proImageBase != null" [src]="product.proImageBase" class="img-sm" />
                                        <img *ngIf="product.proImageBase == null" src="../../assets/images/ads/no_image.jpg" class="img-sm" />
                                    </div>
                                    <figcaption class="info"> <a href="javascript:void(0)" class="title text-dark"
                                            data-abc="true">{{product.proName}}</a>
                                        <p class="text-muted small">Brand: {{product.proBrandName}}</p>
                                    </figcaption>
                                </figure>
                            </td>
                            <td> 
                               <input type="number" class="form-control" (change)="changeQuantity(product,product.proQuantity)" [(ngModel)]="product.proQuantity" name="quantity">
                                <!-- <input type="number" class="form-control" value="{{product.proQuantity}}" > -->
                            </td>
                            <td>
                                <div class="price-wrap"> <var class="price">{{product.proPrice}}</var> <small
                                        class="text-muted">&nbsp;{{product.proPriceUnitName}}</small> </div>
                            </td>
                            <td>
                                <div class="price-wrap"> <var class="price">{{product.proPrice * product.proQuantity}}</var> <small
                                        class="text-muted">&nbsp;{{product.proPriceUnitName}}</small> </div>
                            </td>
                            <td class="text-center d-none d-md-block"> 
                                <!-- <a data-original-title="Save to Wishlist" href="javascript:void(0)" class="btn btn-light" data-toggle="tooltip"
                                    data-abc="true" (click)="addFavourite(product)"> <i class="fa fa-heart"></i></a> -->
                                <a class="btn btn-light" data-abc="true" (click)="removeCartProduct(product)">
                                    Remove <i class="fa fa-trash" aria-hidden="true"></i></a>
                            </td>
                        </tr>
                        <tr *ngIf="!cartProducts || cartProducts.length==0">
                            <td colspan="6">No data found</td>
                        </tr>
                    </tbody>
                </table>
                <pagination-controls class="my-pagination text-center" (pageChange)="page = $event">
                </pagination-controls>
            </div>
        </aside>
        <aside class="col-lg-4">
            <div class="card">
                <div class="card-body">
                    <h4 class="d-flex justify-content-between align-items-center mb-3">
                        <span class="text-muted">Your cart</span>
                        <span class="badge badge-primary badge-pill" style="float: right;background: #00cc67;">{{ cartProducts.length }}</span>
                    </h4>
                    <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <h5 style="text-align: center;">Order Summary</h5>
                        <table class="table table-striped table-hover">
                            <thead>
                                <th style="text-align: center;">Name</th>
                                <th style="text-align: center;">Quantity</th>
                                <th style="text-align: center;">Price</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let product of cartProducts">
                                    <td>{{product.proName}}</td>
                                    <td>{{product.proQuantity}}</td>
                                    <td>{{product.proPrice * product.proQuantity}} {{product.proPriceUnitName}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <!-- <dl class="dlist-align" *ngFor="let product of cartProducts">
                            <dt>{{product.proName}}</dt> &nbsp;
                            <dt>{{product.proQuantity}}</dt>
                            <dd class="text-right ml-3">{{product.proPrice * product.proQuantity}} {{product.proPriceUnitName}}</dd>
                        </dl>                        -->
                    </li>
                    <hr />
                    <dl class="dlist-align">
                        <dt>Total price:</dt>
                        <dd class="text-right ml-3" >{{ totalValue }}</dd>
                    </dl>
                    <dl class="dlist-align">
                        <dt>Delivery Fee:</dt>
                        <dd class="text-right text-danger ml-3">+ $ 0.00</dd>
                    </dl>
                    <dl class="dlist-align">
                        <dt>Total:</dt>
                        <dd class="text-right text-dark b ml-3"><strong>${{ grandTotal }}</strong></dd>
                    </dl>
                    <hr> <a href="javascript:void(0)" routerLink="/usershopping" class="btn btn-out btn-primary tn-square btn-main" data-abc="true"> Make Purchase
                    </a> <a href="javascript:void(0)" (click)="backtoMain()" class="btn btn-out btn-success btn-square btn-main mt-2" data-abc="true">Continue
                        Shopping</a>
                </div>
            </div>
        </aside>
        <!-- <app-cart-calculator [products]="cartProducts"></app-cart-calculator> -->
    </div>
</div>

