import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgOption } from '@ng-select/ng-select';
import { LocalStorageService } from 'angular-web-storage';
import { dataLibrary } from '../shared/models/category/dataLibraries/dataLibrary.model';
import { adProductService } from '../shared/services/compnies/ProductServices';
import { NotificationService } from '../shared/services/notification.service';
import { dataLibraryService } from '../shared/services/search/DataLibraries.service';
import { ShippingAddress } from '../user-product/ShippingAddress';

@Component({
  selector: 'app-shopping-details',
  templateUrl: './shopping-details.component.html',
  styleUrls: ['./shopping-details.component.css']
})
export class ShoppingDetailsComponent implements OnInit {
  firstName:string;
  lastName:string;
  email:string;
  address1:string;
  address2:string;
  country:string;
  state:string; 
  zip:string;
  addresstype:string;
  firstNameAlert :boolean;
  lastNameAlert :boolean;
  emailNameAlert:boolean;
  address1NameAlert:boolean;
  address2NameAlert:boolean;
  countryNameAlert :boolean;
  stateNameAlert :boolean;
  zipNameAlert :boolean;
  typeNameAlert:boolean;
  AddressList1:ShippingAddress;
  AddressList2:ShippingAddress;
  selectedCountry = '0';
  selectedCity = '0';
  cityName:any;
  TotalLength:any;
  citySelection : dataLibrary[]=[];
  AddressID:any;

  constructor(private adproductService: adProductService,private router: Router, private dlService : dataLibraryService,
    private notifyService: NotificationService,public localStor: LocalStorageService) {
    if(this.localStor.get('currentCountry'))
    {
      this.selectedCountry =this.localStor.get('currentCountry');
    }
    if(this.localStor.get('currentCity'))
    {
      this.selectedCity =this.localStor.get('currentCity');
    }
    this.getCities();
     }

     CurrienciesList: NgOption[]=[
     { id:"0", title: "Select Curriencies" },
     { id:"1", title: "United Arab Emirates"},
     { id:"2", title: "Pakistan"},
     { id:"3", title: "Saudi Arabia"},
     { id:"4", title: "KUWAIT"},
     { id:"5", title: "Oman"},
     { id:"7", title: "india"},
     { id:"8", title: "United Kingdom"},
     { id:"9", title: "United States"},
     { id:"10", title: "China"},
     { id:"11", title: "Japan"},
     { id:"12", title: "Canada"}
    ]
    
  ngOnInit(): void {
    this.showPop1();
    this.showPop2();
  }

  onSubmit(form: NgForm){
    debugger
    var result = this.validateForm();
    if (result === true) {
    const obj = new ShippingAddress();
    obj.ID = this.AddressID;
    obj.firstName = this.firstName;
    obj.lastName = this.lastName;
    obj.address = this.address1;
    obj.description = this.address2;
    obj.email = this.email;
    obj.countryID = this.selectedCountry;
    obj.state = this.state;
    obj.zip = this.zip;
    obj.addresstype = this.addresstype;
    this.adproductService.ShippingAddress(obj).subscribe((data:ShippingAddress) => {
      this.AddressID = data;
      if(data != null && data != undefined){
        console.log(data)
        this.notifyService.showSuccess('You Address is Approved !', 'Successfully');
        this.showPop1();
        this.showPop2();
       // this.router.navigate(['/usershopping']);
      }
    });   
  }else{
    this.notifyService.showError('Provide the missing data !', 'Somthing Wrong');
  }  
  }
  HomeShippingAddress(){
    const val = new ShippingAddress();
    val.addresstype = "home";
    this.adproductService.GetShippingAddress(val).subscribe((address:ShippingAddress) =>{
    this.firstName = address.firstName;
    this.lastName = address.lastName;
    this.address1 = address.address;
    this.address2 = address.description;
    this.email = address.email;
    this.country = address.countryID;
    this.state = address.state;
    this.zip = address.zip;
    this.addresstype = address.addresstype;
    });
  }
  
  OfficeShippingAddress(){
    const val = new ShippingAddress();
    val.addresstype = "office";
    this.adproductService.GetShippingAddress(val).subscribe((address:ShippingAddress) =>{
      //console.log(address)
    this.firstName = address.firstName;
    this.lastName = address.lastName;
    this.address1 = address.address;
    this.address2 = address.description;
    this.email = address.email;
    this.country = address.countryID;
    this.state = address.state;
    this.zip = address.zip;
    this.addresstype = address.addresstype;
    });
  }

  showPop1(){
    const val = new ShippingAddress();
    val.addresstype = "home";
    this.adproductService.GetShippingAddress(val).subscribe((address:ShippingAddress) =>{      
      this.AddressList1 = address;
    });
  }
  showPop2(){
    const val = new ShippingAddress();
    val.addresstype = "office";
    this.adproductService.GetShippingAddress(val).subscribe((address:ShippingAddress) =>{      
      this.AddressList2 = address;
    });
  }

  getCities(){
    //debugger;
    this.dlService.getCitiesByCountry(this.selectedCountry)
        .subscribe((data:dataLibrary[]) => {
          this.citySelection = data;
          this.cityName = data;
          this.TotalLength = data.length;
          //console.log(this.selectedCountry);
        });
  }
  
  validateForm() {
    //debugger;
    var result = true;
    if (typeof this.firstName == 'undefined' || !this.firstName || this.firstName == "0") {
      this.firstNameAlert = true;
      result = false;
    }
    else if (typeof this.lastName == 'undefined' || !this.lastName || this.lastName == "0") {
      this.firstNameAlert = false;
      this.lastNameAlert = true;
      result = false;
    }
    else if (typeof this.email == 'undefined' || !this.email || this.email == "0") {
      this.firstNameAlert = false;
      this.lastNameAlert = false;
      this.emailNameAlert = true;
      result = false;
    }
    else if (typeof this.address1 == 'undefined' || !this.address1 || this.address1 == "0") {
      this.firstNameAlert = false;
      this.lastNameAlert = false;
      this.emailNameAlert = false;
      this.address1NameAlert = true;
      result = false;
    }
    else if (typeof this.addresstype == 'undefined' || !this.addresstype || this.addresstype == "0") {
      this.firstNameAlert = false;
      this.lastNameAlert = false;
      this.emailNameAlert = false;
      this.address1NameAlert = false;
      this.typeNameAlert = true;
      result = false;
    }
    else if (typeof this.address2 == 'undefined' || !this.address2 || this.address2 == "0") {
      this.firstNameAlert = false;
      this.lastNameAlert = false;
      this.emailNameAlert = false;
      this.address1NameAlert = false;
      this.typeNameAlert = false;
      this.address2NameAlert = true;
      result = false;
    }
    else if (typeof this.selectedCountry == 'undefined' || !this.selectedCountry || this.selectedCountry == "0") {
      this.firstNameAlert = false;
      this.lastNameAlert = false;
      this.emailNameAlert = false;
      this.address1NameAlert = false;
      this.typeNameAlert = false;
      this.address2NameAlert = false;
      this.countryNameAlert = true;
      result = false;
    }
    else if (typeof this.state == 'undefined' || !this.state || this.state == "0") {
      this.firstNameAlert = false;
      this.lastNameAlert = false;
      this.emailNameAlert = false;
      this.address1NameAlert = false;
      this.typeNameAlert = false;
      this.address2NameAlert = false;
      this.countryNameAlert = false;
      this.stateNameAlert = true;
      result = false;
    }
    else if (typeof this.zip == 'undefined' || !this.zip || this.zip == "0") {
      this.firstNameAlert = false;
      this.lastNameAlert = false;
      this.emailNameAlert = false;
      this.address1NameAlert = false;
      this.typeNameAlert = false;
      this.address2NameAlert = false;
      this.countryNameAlert = false;
      this.stateNameAlert = false;
      this.zipNameAlert = true;
      result = false;
    }
    else {
      this.firstNameAlert = false;
      this.lastNameAlert = false;
      this.emailNameAlert = false;
      this.address1NameAlert = false;
      this.typeNameAlert = false;
      this.address2NameAlert = false;
      this.countryNameAlert = false;
      this.stateNameAlert = false;
      this.zipNameAlert = false;
    }
    return result;
  }
  
}
