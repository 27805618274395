<!-- <div class="container">
            <form >
                <div class="form-group">
                    <label for="exampleInputEmail1">ID :</label>
                    <input type="text " [(ngModel)]="proDetails[0].proID" class="form-control" name="id">               
                </div>
                <div class="form-group">
                    <label >Name :</label>
                    <input type="text " [(ngModel)]="proDetails[0].proName" class="form-control " name="name">
                </div>
                <div class="form-group">
                    <label for="exampleInputPassword2">Description :</label>
                    <input type="text"  name="desc" class="form-control" [(ngModel)]="proDetails[0].proDescription">
                </div>
            </form>
                <button class="tg-btn" type="submit">Update</button>
    </div> -->

<div class="tg-wrapper tg-haslayout">
    <div class="tg-dashboardbanner">
        <h1>Edit Product</h1>
        <ol class="tg-breadcrumb">
            <li><a style="cursor: pointer;" routerLink="/userHome">Go to Dashboard</a></li>
            <li class="tg-active">Edit Product</li>
        </ol>
    </div>

    <main id="tg-main" class="tg-main tg-haslayout">
        <section class="tg-dbsectionspace tg-haslayout">
            <div class="row">
                <!-- <form class="tg-formtheme tg-formdashboard" [formGroup]="updateData" (ngSubmit)="onSubmit()"> -->
                <form class="tg-formtheme tg-formdashboard" [formGroup]="updateData">
                    <fieldset>
                        <div class="tg-postanad">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                                <div class="tg-dashboardbox">
                                    <div class="tg-dashboardboxtitle">
                                        <h2>Edit Product</h2>
                                    </div>
                                    <div class="tg-dashboardholder">
                                        <!-- <div class="form-group text-center">
                                            <a href="#" class="tg-btn" data-toggle="modal" data-target=".tg-categorymodal">Select Category Here</a>
                                        </div> -->
                                        <div class="form-group">
                                            <ol class="tg-categorysequence">
                                                <h5 style="color: #00cc67;">𝗦𝗲𝗹𝗲𝗰𝘁𝗲𝗱 𝗖𝗮𝘁𝗲𝗴𝗼𝗿𝗶𝗲𝘀</h5>
                                                <span>{{this.categoryTitle +' '}}</span>
                                                <fa-icon [icon]="faArrowRight" *ngIf="this.subCategoryTitle != ''">
                                                </fa-icon>
                                                <span>{{' '+this.subCategoryTitle +' '}}</span>
                                                <fa-icon [icon]="faArrowRight" *ngIf="this.subToFirstTitle != ''">
                                                </fa-icon>
                                                <span>{{' '+this.subToFirstTitle +' '}}</span>
                                                <fa-icon [icon]="faArrowRight" *ngIf="this.subToSecondTitle != ''">
                                                </fa-icon>
                                                <span *ngIf="this.subToSecondTitle != ''">{{' '+
                                                    this.subToSecondTitle}}</span>

                                            </ol>
                                        </div>
                                        <!-- Input type text -->
                                        <div class="form-group">
                                            <label for="input_id_1">Title:</label>
                                            <input type="text" formControlName="proName" [(ngModel)]="proName" class="form-control" name="input_id_0" id="input_id_0" placeholder="Name">
                                        </div><br/>
                                        <div class="form-group">
                                            <div style="width:20%; float:left">
                                                <div>
                                                    <label for="input_id_1">Actual Price</label>
                                                    <input type="number" formControlName="proPrice" [(ngModel)]="proPrice" class="form-control" name="input_id_1" id="input_id_1" placeholder="placeholder" required>
                                                </div>
                                            </div>
                                            <div style="width:10%; float:left;margin-left: 5px">
                                                <label for="input_id_1">Discount<span class="tg-checkbox">
                                                    <input id="tg-enablemessages1"  formControlName="DiscountCheck" type="checkbox" name="birth"
                                                    [(ngModel)]="DiscountCheck">
                                                    <label for="tg-enablemessages1"></label>
                                                </span></label>
                                            </div>
                                            <div style="width:30%; float:left;margin-left: 5px" *ngIf="DiscountCheck == false" >
                                                <div>
                                                    <label for="disPrice">Discount Price</label>
                                                    <input type="number" formControlName="proDiscount" required class="form-control" [disabled]="isDisable"
                                                        placeholder="Discount Price " id="disPrice" name="disPrice"
                                                        [(ngModel)]="proDiscount">
                                                </div>
                                            </div>

                                            <div style="width:35%;float:left; margin-left: 5px">
                                                <label for="input_id_1">Currency</label>
                                                <ng-select [items]="CurrienciesList" formControlName="proPriceUnit" [(ngModel)]="proPriceUnit" bindLabel="title" bindValue="id"  appendTo="body" name="selectedCurrency">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <editor [init]="{
                                                        height: 500,
                                                        menubar: true,
                                                        plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                        ],
                                                        toolbar:
                                                        'undo redo | formatselect | bold italic backcolor | \
                                                        alignleft aligncenter alignright alignjustify | \
                                                        bullist numlist outdent indent | removeformat | help'
                                                    }" id="descriptionArea" name="descriptionArea" [(ngModel)]="proDescription" 
                                                    formControlName="proDescription"></editor>
                                        </div>
                                        <!-- <form #f="ngForm" (ngSubmit)="onSubmit(f)"> -->
                                        <label class="tg-fileuploadlabel" for="tg-photogallery">
                                                <span>Drop files anywhere to upload</span>
                                                <span>Or</span>
                                                <span class="tg-btn">Select Photo</span>
                                                <span>Maximum upload file size: 500 KB</span>
                                                <input id="tg-photogallery" class="tg-fileinput"
                                                    (change)="onSelectFile($event)" type="file" name="file" multiple
                                                    accept="image/jpeg,image/jpg">
                                            </label>
                                        <div class="tg-horizontalthemescrollbar tg-profilephotogallery">
                                            <div class="col-md-2 productAddfromImages" *ngFor='let url of imageurls; let i = index'>
                                                <img class="img-fluid" [src]="url">
                                                <a (click)="removeImage(i)" class="btn btn-xs btn-danger mt-1">Remove</a>
                                            </div>
                                        </div>

                                        <button class="tg-btn mt-2" (click)="onImagesUpdate()">Update Images</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5">
                                <div class="tg-dashboardbox tg-contactdetail">
                                    <div class="tg-dashboardboxtitle">
                                        <h2>Product Detail</h2>
                                    </div>
                                    <div class="tg-dashboardholder" id="divCommon">
                                        <div class="form-group">
                                            <label for="input_id_1">Min-Qty:</label>
                                            <input type="number" formControlName="proMinQty" [(ngModel)]="proMinQty"  class="form-control" name="input_id_1" id="input_id_1" placeholder="placeholder" required>
                                        </div>
                                        <div class="form-group">
                                            <label for="input_id_1">Max-Supply:</label>
                                            <input type="number" formControlName="proMaxSupply" [(ngModel)]="proMaxSupply" class="form-control" name="input_id_2" id="input_id_1" placeholder="placeholder" required>
                                        </div>
                                        <label class="tg-fileuploadlabel" for="tg-photogallery">
                                            <ng-container>
                                                <!-- <span class="tg-btn">Select Video</span> -->
                                                <span>Drop your video to upload</span>
                                                <!-- <span>Or</span> -->
                                                <span>Maximum video size: 10MB</span>
                                                <i class="fa fa-upload fa-3x" (change)="onSelectVideo($event)" aria-hidden="true"></i>
                                                <input type="file" id="tg-photogallery" accept="mp4"
                                                (change)="onSelectVideo($event)">
                                            </ng-container>
                                        </label>
                                        <div class="col-sm-10" *ngFor='let url of videoUrls; let i = index'>
                                            <video width="370" height="220" controls disabled="true" #videoPlayer>
                                                <source [src]="url" type="video/mp4" maxFileSize="10">                                                
                                            </video>
                                            <figure> <a class="btn btn-xs btn-success" (click)="onVideoUpdate()">Update Video </a> </figure>
                                          <!-- <figure> <a (click)="removeVideo()" class="btn btn-xs btn-danger ">Remove</a> </figure> -->
                                        </div>
                                        <br>
                                        <button class="tg-btn mt-2" (click)="updateDetails()">Update</button>
                                    </div>
                                </div>
                                <!-- <form #f="ngForm" (ngSubmit)="onSubmit(f)">
                                <div class="tg-dashboardbox tg-contactdetail">
                                    <div class="tg-dashboardboxtitle">
                                        <h2>Image Update 🔔</h2>
                                    </div>
                                    <div class="tg-dashboardholder" id="divCommon">
                                        <div class="form-group">
                                            <label class="tg-fileuploadlabel" for="tg-photogallery">
                                                <span>Drop files anywhere to upload</span>
                                                <span>Or</span>
                                                <span class="tg-btn">Select Files</span>
                                                <span>Maximum upload file size: 500 KB</span>
                                                <input id="tg-photogallery" class="tg-fileinput"
                                                    (change)="onSelectFile($event)" type="file" name="file" multiple
                                                    accept="image/jpeg,image/jpg">
                                            </label>
                                            <div class="tg-horizontalthemescrollbar tg-profilephotogallery">
                                                <div class="col-md-2 productAddfromImages" *ngFor='let url of imageurls; let i = index'>
                                                    <img class="img-fluid" [src]="url.base64String">
                                                    <a (click)="removeImage(i)" class="btn btn-xs btn-danger">Remove</a>
                                                </div>
                                            </div>
                                        </div> <button class="tg-btn mt-2 float-right" type="submit">Image</button>
                                    </div>
                                </div>
                            </form> -->
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </section>
    </main>
    <div class="modal fade tg-thememodal tg-categorymodal " tabindex="-1 " role="dialog ">
        <div class="modal-dialog tg-thememodaldialog " role="document ">
            <div class="modal-content tg-thememodalcontent " style="min-height: 200px; ">
                <div class="tg-title ">
                    <strong style="font-size: 16px; ">Select Category</strong>
                    <!-- <p id="finalSelectionAlert " style="margin-top: 1%;display:none " class="tg-alert alert alert-success fade in ">{{vm.finalSelectionMessage}}</p> -->
                </div>
                <div>
                    <select id="mainCategory " [(ngModel)]="selectedCategory" aria-placeholder="Select Category" (change)="getSubCategories(0,$event) " style="width: 100%; " name="ddCategory">
                        <option value="0">All Types</option>
                        <option value="c4e1f606-8105-4666-a8bc-c6657730a0df ">Deals</option>
                        <option value="C4E1F606-8105-4666-A8BC-C6657730A0DF ">Products</option>
                        <option value="C4E1F606-8105-4666-A8BC-C6657730A0DF ">Suppliers</option>
                    </select>
                </div>
                <div *ngIf="this.levelToShow> 0" style="width: 100%;margin-top: 10px;margin-bottom: 10px;">
                    <div class="tg-title">
                        <strong style="font-size: 16px;">Select Sub Category</strong>
                    </div>
                    <div class=" ">
                        <select style="width: 100%;" [(ngModel)]="selectedSubCategory" (change)="getSubCategories(1,$event)" name="ddSubCategory">
                            <option value="0">All Types</option>
                            <option *ngFor="let subCat of subCategories" value={{subCat.catID}}>{{subCat.catName}}
                            </option>
                        </select>
                    </div>
                </div>
                <div *ngIf="this.levelToShow > 1" style="width: 100%;margin-top: 10px;margin-bottom: 10px;">
                    <div class="tg-title">
                        <strong style="font-size: 16px;">Select Child Category</strong>
                    </div>
                    <div class="">
                        <select style="width: 100%;" [(ngModel)]="selectedSubCatFirstChild" (change)="getSubCategories(2,$event)" name="ddSubCategoryChildOne">
                            <option value="0">All Types</option>
                            <option *ngFor="let subCatFirst of subCatFirstChildList" value={{subCatFirst.catID}}>
                                {{subCatFirst.catName}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="this.levelToShow > 2" style="width: 100%;margin-top: 10px;margin-bottom: 10px;">
                    <div class="tg-title">
                        <strong style="font-size: 16px;">Select Child Category</strong>
                    </div>
                    <div class="">
                        <select style="width: 100%;" [(ngModel)]="selectedSubCatSecondChild" (change)="getSubCategories(3,$event)" name="ddSubCategoryChildTwo">
                            <option value="0">All Types</option>
                            <option *ngFor="let subCatSecond of subCatSecondChildList" value={{subCatSecond.catID}}>
                                {{subCatSecond.catName}}</option>
                        </select>
                    </div>
                </div>

                <div style="margin-top: 50px;">
                    <button data-dismiss="modal" aria-label="Close" type="button" class="tg-btn" style="background-color:#00cc67!important">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- <div class="container">
        <form [formGroup]="updateData">
            <div class="form-group">
                <label for="exampleInputEmail1">ID</label>
                <input type="text" class="form-control" formControlName="proID" name="id">
            </div>
            <div class="form-group">
                <label for="exampleInputPassword1">Name</label>
                <input type="text" class="form-control" formControlName="proName" name="name">
            </div>
            <div class="form-group">
                <label for="exampleInputPass">Price</label>
                <input type="text" class="form-control" formControlName="proPrice" name="price">
            </div>
            <div class="form-group">
                <label for="exampleInputPass">Description</label>
                <input type="text" class="form-control" formControlName="proDescription" name="Description">
            </div>
            <button (click)="updateDetails()" class="tg-btn">Update</button>
        </form>
    </div> -->