<div class="tg-wrapper tg-haslayout">
    <div class="tg-dashboardbanner">
        <h1>Post An Ad</h1>
        <ol class="tg-breadcrumb">
            <li><a (click)="backtoMain()">Go to Home</a></li>
            <li class="tg-active">Ad Details</li>
        </ol>
    </div>

    <ul>

    </ul>

    <main id="tg-main" class="tg-main tg-haslayout">
        <!--************************************
					Section Start
			*************************************-->
        <section class="tg-dbsectionspace tg-haslayout">
            <div class="row">
                <!-- <form class="tg-formtheme tg-formdashboard" [formGroup]="postDetailForm" (ngSubmit)="onSubmit()"> -->
                <form class="tg-formtheme tg-formdashboard" #f="ngForm" (ngSubmit)="onSubmit(f)">
                    <fieldset>
                        <div class="tg-postanad">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                                <div class="tg-dashboardbox">
                                    <div class="tg-dashboardboxtitle">
                                        <h2>Add Product</h2>
                                    </div>
                                    <div class="tg-dashboardholder">
                                        <div class="form-group text-center">
                                            <a href="#" class="tg-btn" data-toggle="modal"
                                                data-target=".tg-categorymodal">Select Category Here</a>
                                        </div>
                                        <div class="form-group">
                                            <ol class="tg-categorysequence">
                                                <span>{{this.categoryTitle +' '}}</span>
                                                <fa-icon [icon]="faArrowRight" *ngIf="this.subCategoryTitle != ''">
                                                </fa-icon>
                                                <span>{{' '+this.subCategoryTitle +' '}}</span>
                                                <fa-icon [icon]="faArrowRight" *ngIf="this.subToFirstTitle != ''">
                                                </fa-icon>
                                                <span>{{' '+this.subToFirstTitle +' '}}</span>
                                                <fa-icon [icon]="faArrowRight" *ngIf="this.subToSecondTitle != ''">
                                                </fa-icon>
                                                <span *ngIf="this.subToSecondTitle != ''">{{' '+
                                                    this.subToSecondTitle}}</span>

                                            </ol>
                                        </div>
                                        <div class="form-group">
                                            <div *ngIf="addCategoryAlert == true" class="alert alert-danger">
                                                <div *ngIf="addCategoryAlert == true">
                                                    Please select Category !
                                                </div>
                                            </div>
                                            <div *ngIf="addSubCatAlert == true" class="alert alert-danger">
                                                <div *ngIf="addSubCatAlert == true">
                                                    Please select Sub-Category !
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="input_id_1">Product Name</label>
                                            <input type="text" required class="form-control"
                                                placeholder="Enter Product Name" id="Name" name="Name"
                                                [(ngModel)]="this.Name">
                                        </div>
                                        <div class="form-group">
                                            <div *ngIf="ProNameAlert == true" class="alert alert-danger">
                                                <div *ngIf="ProNameAlert == true">
                                                    Name is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div style="width:20%; float:left">
                                                <div>
                                                    <label for="input_id_1">Actual Price</label>
                                                    <input type="text" numbersOnly required class="form-control"
                                                        placeholder="Price" id="Price" name="Price"
                                                        [(ngModel)]="this.Price">
                                                </div>
                                                <div class="form-group">
                                                    <div *ngIf="ProPriceAlert == true" class="alert alert-danger">
                                                        <div *ngIf="ProPriceAlert == true">
                                                            Price is required !
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="width:10%; float:left;margin-left: 5px">
                                                <label for="input_id_1">Discount  <span class="tg-checkbox">
                                                    <input id="tg-enablemessages1" type="checkbox" name="birth"
                                                    [(ngModel)]="discountChk" value="0">
                                                    <label for="tg-enablemessages1"></label>
                                                </span></label>
                                            </div>
                                            <div style="width:30%; float:left;margin-left: 5px">
                                                <div *ngIf="discountChk == false">                                                    
                                                    <label for="input_id_1">Discount Price</label>
                                                        <input type="number" numbersOnly required class="form-control" 
                                                            placeholder="Discount Price" id="disPrice" name="disPrice"
                                                            [(ngModel)]="this.DiscountPrice">
                                                </div>
                                                <!-- <div class="form-group">
                                                    <div *ngIf="ProPriceAlert == true" class="alert alert-danger">
                                                        <div *ngIf="ProPriceAlert == true">
                                                            Price is required !
                                                        </div>
                                                    </div>
                                                </div> -->
                                            </div>

                                            <div style="width:35%;float:left; margin-left: 5px">
                                                <label for="input_id_1">Currency</label>
                                                <ng-select [items]="CurrienciesList" bindLabel="title" bindValue="id"
                                                    placeholder="Currency" appendTo="body" id="selectedCurrency"
                                                    name="selectedCurrency" [(ngModel)]="selectedCurrencyID">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <editor [init]="{
                                                    height: 500,
                                                    menubar: true,
                                                    plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar:
                                                    'undo redo | formatselect | bold italic backcolor | \
                                                    alignleft aligncenter alignright alignjustify | \
                                                    bullist numlist outdent indent | removeformat | help'
                                                }" id="descriptionArea" name="descriptionArea"
                                                [(ngModel)]="descriptionArea"></editor>

                                        </div>
                                        <label class="tg-fileuploadlabel" for="tg-photogallery">
                                            <span>Drop files anywhere to upload</span>
                                            <span>Or</span>
                                            <span class="tg-btn">Select Files</span>
                                            <span>Maximum upload file size: 500 KB</span>
                                            <!-- <input type="file" class="tg-fileinput" (change)="onSelectFile($event)" name="file" multiple accept="image/*" /> -->
                                            <input id="tg-photogallery" class="tg-fileinput"
                                                (change)="onSelectFile($event)" type="file" name="file" multiple
                                                accept="image/jpeg,image/jpg">
                                        </label>
                                        <div class="tg-horizontalthemescrollbar tg-profilephotogallery">
                                            <div class="col-md-2 productAddfromImages"
                                                *ngFor='let url of imageurls; let i = index'>
                                                <img class="img-fluid" [src]="url">
                                                <a (click)="removeImage(i)"
                                                    class="btn btn-xs btn-danger mt-2">Remove</a>
                                            </div>
                                        </div>

                                        <!-- <div class="tg-fileuploadlabel" for="tg-photogallery">
                                            <ng-container>
                                                <input type="file" id="tg-photogallery" accept="mp4"
                                                 multiple (change)="onSelectVideo($event)">
                                            </ng-container>
                                        </div>
                                        <div class="col-sm-10" *ngFor='let urls of videoUrls; let i = index'>
                                            <video width="270" height="220" controls disabled="true" #videoPlayer>
                                                <source [src]="urls" type="video/mp4">                                                
                                            </video>
                                          <figure> <a (click)="removeVideo(i)" class="btn btn-xs btn-danger ">Remove</a> </figure>
                                        </div> -->

                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5">
                                <div class="tg-dashboardbox tg-contactdetail">
                                    <div class="tg-dashboardboxtitle">
                                        <h2>Products Details</h2>
                                    </div>
                                    <div class="tg-dashboardholder" id="divCommon">
                                        <div class="form-group">
                                            <label for="input_id_1">Brand Name</label>
                                            <input type="text" class="form-control" placeholder="Brand Name ♣"
                                                id="BrandName" name="BrandName" [(ngModel)]="this.BrandName">
                                        </div>
                                        <div class="form-group">
                                            <div *ngIf="ProBrandAlert == true" class="alert alert-danger">
                                                <div *ngIf="ProBrandAlert == true">
                                                    Brand Name is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <!-- <ng5-slider [(value)]="minkmValue" [(highValue)]="maxkmValue" [(ngModel)]="this.MinQty" [options]="optionsKM"></ng5-slider>
                                             -->
                                            <label for="input_id_1">Min Qty:</label>
                                            <input type="number" numbersOnly required class="form-control"
                                                placeholder="Min Quantity" id="MinQty" name="MinQty"
                                                [(ngModel)]="this.MinQty">

                                        </div>
                                        <div class="form-group">
                                            <div *ngIf="ProMinAlert == true" class="alert alert-danger">
                                                <div *ngIf="ProMinAlert == true">
                                                    Min Qty is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="input_id_1">Max Supply:</label>
                                            <input type="number" numbersOnly required class="form-control"
                                                placeholder="Max Supply" id="MaxSupply" name="MaxSupply"
                                                [(ngModel)]="this.MaxSupply">
                                        </div>
                                        <div class="form-group">
                                            <div *ngIf="ProMaxAlert == true" class="alert alert-danger">
                                                <div *ngIf="ProMaxAlert == true">
                                                    Max Supply is required.
                                                </div>
                                            </div>
                                        </div>
                                        <label class="tg-fileuploadlabel" for="tg-photogallery">
                                            <ng-container>
                                                <!-- <span class="tg-btn">Select Video</span> -->
                                                <span>Drop your video to upload</span>
                                                <!-- <span>Or</span> -->
                                                <span>Maximum video size: 10MB</span>
                                                <i class="fa fa-upload fa-3x" (change)="onSelectVideo($event)" aria-hidden="true"></i>
                                                <input type="file" id="tg-photogallery" accept="mp4"
                                                 multiple (change)="onSelectVideo($event)">
                                            </ng-container>
                                        </label>
                                        <div class="col-sm-10" *ngFor='let url of videoUrls; let i = index'>
                                            <video width="370" height="220" controls disabled="true" #videoPlayer>
                                                <source [src]="url" type="video/mp4" maxFileSize="10">                                                
                                            </video>
                                            <!-- <figure> <a class="btn btn-xs btn-success" (click)="onVideoUpdate()">Update Video </a> </figure> -->
                                            <figure> <a (click)="removeVideo(i)" class="btn btn-xs btn-danger ">Remove</a> </figure>
                                        </div>
                                        <br />
                                        <button *ngIf="companyList || companyList.length ==0 " class="tg-btn mt-3" type="submit" >Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </section>
        <!--************************************
					Section End
			*************************************-->
    </main>
    <div class="modal fade tg-thememodal tg-categorymodal " tabindex="-1 " role="dialog ">
        <div class="modal-dialog tg-thememodaldialog " role="document ">
            <div class="modal-content tg-thememodalcontent " style="min-height: 200px; ">
                <div class="tg-title ">
                    <strong style="font-size: 16px; ">Select Category</strong>
                </div>
                <div>
                    <!-- <div ng-click="vm.GetCatByParentID(categoroies,1) " class="col-lg-3 col-md-3 col-sm-3 col-xs-3 btn-default " style="padding: 0px " ng-repeat="categoroies in vm.categories ">
                        <div class="bon1 ">
                            <div style="padding: 10px; ">
                                <div class="center moduleText ">
                                    <i class="{{categoroies.icon}} "></i>
                                     <a> {{categoroies.Cat}}</a>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <select id="mainCategory " [(ngModel)]="selectedCategory" aria-placeholder="Select Category"
                        (change)="getSubCategories(0,$event) " style="width: 100%; " name="ddCategory">
                        <option value="0">All Types</option>
                        <!-- <option value="c4e1f606-8105-4666-a8bc-c6657730a0df ">Deals</option> -->
                        <option value="C4E1F606-8105-4666-A8BC-C6657730A0DF ">Products</option>
                        <option value="C4E1F606-8105-4666-A8BC-C6657730A0DF ">Suppliers</option>
                    </select>
                </div>
                <div *ngIf="this.levelToShow> 0" style="width: 100%;margin-top: 10px;margin-bottom: 10px;">
                    <div class="tg-title">
                        <strong style="font-size: 16px;">Select Sub Category</strong>
                    </div>
                    <div class=" ">
                        <select style="width: 100%;" [(ngModel)]="selectedSubCategory"
                            (change)="getSubCategories(1,$event)" name="ddSubCategory">
                            <option value="0">All Types</option>
                            <option *ngFor="let subCat of subCategories" value={{subCat.catID}}>{{subCat.catName}}
                            </option>
                        </select>
                    </div>
                </div>
                <div *ngIf="this.levelToShow > 1" style="width: 100%;margin-top: 10px;margin-bottom: 10px;">
                    <div class="tg-title">
                        <strong style="font-size: 16px;">Select Child Category</strong>
                    </div>
                    <div class="">
                        <select style="width: 100%;" [(ngModel)]="selectedSubCatFirstChild"
                            (change)="getSubCategories(2,$event)" name="ddSubCategoryChildOne">
                            <option value="0">All Types</option>
                            <option *ngFor="let subCatFirst of subCatFirstChildList" value={{subCatFirst.catID}}>
                                {{subCatFirst.catName}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="this.levelToShow > 2" style="width: 100%;margin-top: 10px;margin-bottom: 10px;">
                    <div class="tg-title">
                        <strong style="font-size: 16px;">Select Child Category</strong>
                    </div>
                    <div class="">
                        <select style="width: 100%;" [(ngModel)]="selectedSubCatSecondChild"
                            (change)="getSubCategories(3,$event)" name="ddSubCategoryChildTwo">
                            <option value="0">All Types</option>
                            <option *ngFor="let subCatSecond of subCatSecondChildList" value={{subCatSecond.catID}}>
                                {{subCatSecond.catName}}</option>
                        </select>
                    </div>
                </div>

                <div style="margin-top: 50px;">
                    <button data-dismiss="modal" aria-label="Close" type="button" class="tg-btn"
                        style="background-color:#00cc67!important">Close</button>
                </div>
            </div>
        </div>
    </div>

</div>