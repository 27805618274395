import { Component, OnInit, NgZone } from '@angular/core';
import { addDetailsService } from '../shared/services/addDetails.service';
import { addDetailsDTO } from '../shared/models/Adds/addDetails.model';
import { addDetailsParams } from '../shared/models/requestsModel/addDetailsParams.model';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faLaptop } from '@fortawesome/free-solid-svg-icons';
// import {   } from '@fortawesome/free-regular-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

import { faFacebook, faPinterest, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';

import { NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { NgxGalleryImage } from '@kolkov/ngx-gallery';
import { NgxGalleryAnimation } from '@kolkov/ngx-gallery';
import { GoogleMap, MapInfoWindow, MapMarker } from "@angular/google-maps";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { adds } from '../shared/models/Adds/adds.model';
import { addsService } from '../shared/services/addServices';
import { AdComments } from '../shared/models/comments/comments.model';
import { Comments } from '../shared/models/comments/comment.model';
import { Subject, Subscription, BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';
import * as fromApp from '../store/app.state';
import * as fromAuth from '../store/reducers/auth.reducers';
import { Observable } from 'rxjs';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { NotificationService } from '../shared/services/notification.service';
import { ToastrService } from 'ngx-toastr';
import { likeDislikeDetails } from '../shared/models/Adds/likeDislikeDetails.model';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { galleryImagesDTO } from '../shared/models/Adds/galleryImage.model';
import { liveUpdateService } from '../shared/liveUpdate/listUpdate.service';
import * as jwt_decode from "jwt-decode";
import { LocalStorageService } from 'angular-web-storage';
import { VideoObject } from '../shared/models/Adds/VideoObject.model';
import { ViewCount } from '../shared/models/likesDislikes/ViewCount';
import { openSearchService } from '../shared/services/search/openSearch.service';
import { allSearchFilters } from '../shared/models/requestsModel/search/searchInput.model';
import { environment } from 'src/environments/environment';
import { stringify } from 'querystring';
//import { ShareButtons } from '@ngx-share/buttons';

@Component({
  selector: 'app-add-details',
  templateUrl: './add-details.component.html',
  styleUrls: ['./add-details.component.css']
})
export class AddDetailsComponent implements OnInit {

  private baseUrl = environment.baseUrl;

  latitude;
  longitude;
  zoom: number = 14;
  address: string;
  private geoCoder;

  addId: string;
  addedBy: number;
  category: string;
  addDetails: addDetailsDTO;
  topAddsByUser: adds[];
  //Array<String> = [];
  galleryImagesList: Array<galleryImagesDTO> = [];
  imageObject: Array<VideoObject> = [];
  topAdds: adds[];
  private result: number;
  commentDescription = '';
  addsNewComments: Comments[];

  // fbIcon = faFacebookSquare;
  addLikes: number;
  addDisLikes: number;
  showPhone: boolean = false;
  update = new BehaviorSubject<boolean>(false);
  updateLike = new BehaviorSubject<boolean>(false);
  updateDislike = new BehaviorSubject<boolean>(false);

  //commentsChanged = new Subject<Comments[]>();
  //private readonly commentsChangedNew = new Subject();

  faThumbsUp = faThumbsUp;
  faThumbsDown = faThumbsDown;
  faEye = faEye;
  faGlobe = faGlobe;
  faMobile = faLaptop;

  faFacebook = faFacebook;
  faGoogle = faGoogle;
  faLinkedin = faLinkedin;
  faTwitter = faTwitter;
  faPinterest = faPinterest;
  faWhatsapp = faWhatsapp;

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  subscription: Subscription;

  // public scrollbarOptions = { axis: 'yx', theme: 'minimal-dark' };
  public scrollbarOptions = { axis: 'y', theme: 'light' };
  // public darkScrollbarOptions = { axis: 'y', theme: 'dark' };
  authState: Observable<fromAuth.State>;
  commentsObject: Comments;
  adsDetails: addDetailsDTO;
  loginuserID: string;

  constructor(private addDetailsService: addDetailsService, private operSearch: openSearchService, private ngxLoader: NgxUiLoaderService, private addService: addsService
    , private store: Store<fromApp.AppState>, private mScrollbarService: MalihuScrollbarService, private notifyService: NotificationService,
    private toasterService: ToastrService, private route: ActivatedRoute, private router: Router, private userInterface: openSearchService,
    private liveUpdate: liveUpdateService, private _ngZone: NgZone, private storage: LocalStorageService,
    public localStor: LocalStorageService) { }

  ngOnInit(): void {
    this.authState = this.store.select('auth');

    let token = this.storage.get('currentUser');
    let tokenInfo = this.getDecodedAccessToken(token); // decode token 

    if (tokenInfo != null) {
      this.loginuserID = tokenInfo.nameid;
    }

    this.route.params.subscribe((params: Params) => {
      this.addId = params['id'];
      this.category = params['category'];
    })


    this.getCommentsByAdd();
    this.update.subscribe(update => update === true ? this.getCommentsByAdd() : '');
    this.updateLike.subscribe(update => update === true ? this.getAddLikeDislikeCount(1, "direct") : '');
    this.updateDislike.subscribe(update => update === true ? this.getAddLikeDislikeCount(2, "direct") : '');

    this.liveUpdate.commentReceived.subscribe((comment: Comments) => {
      this.commentsObject = comment;
      // //debugger;     
      this.addsNewComments.splice(0, 0, this.commentsObject);
    });
    // //debugger;
    this.liveUpdate.likeReceived.subscribe((likeDetails: likeDislikeDetails) => {
      // //debugger;
      if (likeDetails.actionType == 1) {
        this.getAddLikeDislikeCount(1, "update");
      }
      else if (likeDetails.actionType == 2) {
        this.getAddLikeDislikeCount(2, "update");
      }

    });

    this.GetAddDetailsByID();

    this.GetTopAddsByUser();
    this.getTopAdds();

    this.galleryOptions = [
      {
        width: '766px',
        height: '470px',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide,
        zoomInIcon: 'fa fa-search-plus',
        zoomOutIcon: 'fa fa-search-minus',
        rotateLeftIcon: 'fa fa-undo',
        rotateRightIcon: 'fa fa-repeat',
        imageSwipe: true
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 10,
        thumbnailsMargin: 10,
        thumbnailMargin: 10,
        imageSwipe: true
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: true,
        previewZoom: true,
        imageSwipe: true,
        previewRotate: true
      }
    ];

    this.galleryImages = [
      {
        small: 'assets/images/AdsImages/test/1-big.jpeg',
        medium: 'assets/images/AdsImages/test/1-medium.jpeg',
        big: 'assets/images/AdsImages/test/1-big.jpeg'
      },
      {
        small: 'assets/images/AdsImages/test/2-small.jpeg',
        medium: 'assets/images/AdsImages/test/2-medium.jpeg',
        big: 'assets/images/AdsImages/test/2-big.jpeg'
      },
      {
        small: 'assets/images/AdsImages/test/5-small.jpeg',
        medium: 'assets/images/AdsImages/test/5-medium.jpeg',
        big: 'assets/images/AdsImages/test/5-big.jpeg'
      }
    ];
    this.counters();
  }


  // getToken(): boolean {
  //   if (this.localStor.get('currentUser')) {
  //     return true;
  //   }
  //   return false;
  // }


  counters() {
    //debugger;
    const viewDetail = new ViewCount();
    viewDetail.addID = this.addId;
    // viewDetail.userID = this.addDetails[0].addedBy;
    viewDetail.userID = this.addedBy;
    this.addService.submitview(viewDetail)
  }

  GetAddDetailsByID() {
    debugger;
    this.ngxLoader.start();
    const requestData = new addDetailsParams();
    requestData.catName = this.category;
    requestData.addID = this.addId;
    //requestData.addID ="B7A1A6D3-D488-4281-8801-D8E890D99787";  
    this.addDetailsService.GetAddDetailsByID(requestData)
      .subscribe((data: addDetailsDTO) => {
      //  console.log(data);
        this.addDetails = data; /// this.adsDetails = data;
        this.latitude = parseFloat(this.addDetails[0].latitude);//parseInt(this.addDetails[0].latitude);
        this.longitude = parseFloat(this.addDetails[0].longitude);//parseInt(this.addDetails[0].longitude);
        this.addedBy = this.addDetails[0].addedBy;
        //console.log(this.addedBy)
        this.addLikes = this.addDetails[0].likeCount;
        this.addDisLikes = this.addDetails[0].dislikeCount;
        //debugger;
        if (this.addDetails[0].videO_PATH != null) {
          if (this.addDetails[0].videO_PATH != "") {
            var p = {
              small: this.addDetails[0].videoBase64,
              medium: this.addDetails[0].videoBase64, big: this.addDetails[0].videoBase64
            };
            //var p = this.addDetails[0].videoBase64;
            this.galleryImagesList.push(p);
          }
        }
        if (this.addDetails[0].adImagesData != null) {
          //for(let ingrediant of this.addDetails[0].adImages)adImagesData
          //debugger;
          for (let ingrediant of this.addDetails[0].adImagesData) {
            //var p = {small:ingrediant.photoPath, medium:ingrediant.photoPath,big:ingrediant.photoPath};
            var p = { small: ingrediant.imageBase64, medium: ingrediant.imageBase64, big: ingrediant.imageBase64 };
            this.galleryImagesList.push(p);
          }
        }

        setTimeout(() => {
          this.ngxLoader.stop();

        }, 3000);
        //vm.GetAdsByUserID('8');      
        ////console.log(this.topFifteenAdds);     
      });
  }

  ProfileView(userID) {
    this.userInterface.setInterface("1");
    this.router.navigate(['/userprofile/detail/', userID], { relativeTo: this.route });
    // let url = this.router.createUrlTree(['/userprofile/detail/',userID])
    // window.open(url.toString(), '_blank')
  }

  GetTopAddsByUser() {
    // //debugger;
    const requestData = new addDetailsParams();
    this.addDetailsService.GetTopAddsByUser(this.addId)
      .subscribe((data: adds[]) => {
        //console.log(data);
        this.topAddsByUser = data;
        //vm.GetAdsByUserID('8');      
        ////console.log(this.topFifteenAdds);     
      });
  }
  // getTopAdds() {
  //   this.addService.getTopAddsByChoice_OLD(4)
  //     .subscribe((data: adds[]) => {
  //       this.topAdds = data;
  //     });
  // }

  getTopAdds() {
    // var category ="664F16CF-C8A3-4990-ABF4-66E1606094B0";
    this.addDetailsService.getTopAdsByCategory((this.addId))
      .subscribe((data) => {
        //this.topAdds = data;
        this.topAdds = data;
      });
  }

  showPhones:any
  showPhoneNumber2(items) {
    //debugger;
    if(items){
      if (this.showPhone == false) {
        this.showPhones = items;
      }
      else {
        this.showPhone = true;
      }
  }
  }

  backtoMain() {
    this.userInterface.setInterface("0");
    this.router.navigate(['/home'], { relativeTo: this.route });
  }
  submitComment() {
    // //debugger; 
    //first do the validation   
    const commentDetails = new AdComments();
    commentDetails.postID = this.addId;
    //commentDetails.postID = "B7A1A6D3-D488-4281-8801-D8E890D99787";
    commentDetails.commentDesc = this.commentDescription;
    let token = this.storage.get('currentUser');
    let tokenInfo = this.getDecodedAccessToken(token); // decode token
    //debugger;
    ////console.log(tokenInfo);
    // commentDetails.addedBy =  parseInt(tokenInfo.nameid);      
    ////console.log(commentDetails);
    //this.result = this.addService.submitAddComment(commentDetails); 
    let abc: number;
    const x = this.addService.submitAddComment(commentDetails)
      .subscribe((value: number) => {
        abc = value;
        this.update.next(true);
      });
    //let x= this.addService.submitAddComment(commentDetails); 

    // x.subscribe((value: number)=>{
    //   abc = value;
    //   this.update.next(true);
    // })     
    //console.log("abc");
    this.commentDescription = "";

    // this.addService.submitAddComment(commentDetails).pipe(
    //   switchMap(() => this.addService.getAddComments('B7A1A6D3-D488-4281-8801-D8E890D99787'))
    // )
    // .subscribe((data: Comments[]) => this.addDetails.adComments=data);
    commentDetails.addedBy = parseInt(tokenInfo.nameid);
    this.liveUpdate.sendChatMessage(commentDetails);
    this.toasterService.success('Comment submitted successfully', 'Comment', {
      closeButton: true,
      progressBar: true,
      tapToDismiss: true
    });
  }

  likeDislikePost(action: number) {
   // debugger;
    const likeDislike = new likeDislikeDetails();
    likeDislike.actionType = action;
    likeDislike.postLikePostID = this.addId;
    likeDislike.userID = parseInt(this.loginuserID);
    //likeDislike.postLikePostID = "B7A1A6D3-D488-4281-8801-D8E890D99787";     

    const x = this.addService.submitLikeDislike(likeDislike);
    //this.addService.submitLikeDislike(likeDislike); 
    //x.subscribe(()=>this.updateLike.next(true));
    if (action == 1) {
      // //debugger;
      x.subscribe(() => this.updateLike.next(true));
    }
    else if (action == 2) {
      // //debugger;
      x.subscribe(() => this.updateDislike.next(true));
    }
    this.liveUpdate.sendLikeDisliek(likeDislike);
    //this.notifyService.showSuccess('Post like successfully !', 'Like');    
    // this.toasterService.success('Post like successfully', 'Like', {
    //   closeButton: true,
    //   progressBar:true,
    //   tapToDismiss:true
    // });
  }

  getCommentsByAdd() {
    // this.addsNewComments = this.commentsChangedNew.pipe(
    //   startWith(''),
    //   concatMap(()=> {
    //     return this.addService.getAddComments('B7A1A6D3-D488-4281-8801-D8E890D99787'); // this will be your http get request
    //   }),
    // )

    //this.addService.getAddComments('B7A1A6D3-D488-4281-8801-D8E890D99787')
    this.addService.getAddComments(this.addId)
      .subscribe((data: Comments[]) => {
        this.addsNewComments = data;
      });
  }

  getAddLikeDislikeCount(action: number, callingPlace: string) {
    //debugger;
    const likeDislike = new likeDislikeDetails();
    likeDislike.actionType = action;
    likeDislike.postLikePostID = this.addId;
    likeDislike.userID = parseInt(this.loginuserID);
    //likeDislike.postLikePostID = "B7A1A6D3-D488-4281-8801-D8E890D99787"; 

    this.addService.getAddLikeDislikeCount(likeDislike)
      .subscribe((data: number) => {
        //debugger;
        if (action == 1) {
          if (callingPlace == "direct") {
            if (this.addLikes < data) {
              this.toasterService.success('Your like submitted successfully', 'Like', {
                closeButton: true,
                progressBar: true,
                tapToDismiss: true
              });
            }
            else {
              this.toasterService.success('Your like removed successfully', 'Like', {
                closeButton: true,
                progressBar: true,
                tapToDismiss: true
              });

            }
          }
          setTimeout(() => {
            this.addLikes = data;
          }, 1000);
        }
        else if (action == 2) {
          if (callingPlace == "direct") {
            if (this.addDisLikes < data) {
              this.toasterService.success('Your dislike submitted successfully', 'Like', {
                closeButton: true,
                progressBar: true,
                tapToDismiss: true
              });
            }
            else {
              this.toasterService.success('Your dislike removed successfully', 'Like', {
                closeButton: true,
                progressBar: true,
                tapToDismiss: true
              });
            }
          }
          setTimeout(() => {
            this.addDisLikes = data;
          }, 1000);
        }

      });
  }

  setComments(commentsRecieved: Comments[]) {
    ////debugger;
    // this.addDetails.adComments = this.addsNewComments;
    // this.commentsChanged.next(this.addDetails.adComments.slice());
    ////console.log(this.recipes);
  }
  showPhoneNumber() {
    if (this.showPhone == false) {
      this.showPhone = true;
    }
    else {
      this.showPhone = false;
    }

  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }

  onMoreProducts(cat, addID) {
    debugger;
    let url = this.router.createUrlTree(['/details/', cat, addID])
    window.open(url.toString(), '_blank')
  }

  shareData: {
    url: string;
    description?: string;
    tags?: string;
  } = {
      url: 'https://knocks.myknocks.com/details/' + this.route.snapshot.params['category'] + '/' + this.route.snapshot.params['id'],
      //url: 'https://knocks.myknocks.com', 
      description: 'MyKnocks',
      tags: 'Ali Ahamd',
    };

  url: string;
  test() {
    this.url = this.baseUrl + 'details/' + this.route.snapshot.params['category'] + '/' + this.route.snapshot.params['id'];
    alert(this.url);
  }

  Facebook: { title: string; link: string }[] = [
    {
      title: 'fb',
      link: `https://www.facebook.com/sharer.php?u=${this.shareData?.url}`,
    }
  ]
  Twitter: { title: string; link: string }[] = [
    {
      title: 'twitter',
      link: `https://twitter.com/intent/tweet?url=${this.shareData?.url}&text=${this.shareData?.description}&hashtags=${this.shareData?.tags}`,
    }
  ]
  Pinterest: { title: string; link: string }[] = [
    {
      title: 'pinterest',
      link: `http://pinterest.com/pin/create/link/?url=${this.shareData?.url}`,
    }
  ]
  Whatsapp: { title: string; link: string }[] = [
    {
      title: 'whatsapp',
      link: `https://wa.me?text=${this.shareData?.url}`,
    }
  ]


  goSearch() {
    //debugger;
    const requestData = new allSearchFilters();

    let catName: string;
    let subCatName: string;
    let catID: string;
    let subCatID: string;
    catName = "Items";
    catID = "3c064386-a300-4f90-8e18-f6da9f83aa1c";
    subCatName = "?q=sad&_keyori=ss&from=input&spm=a2a0e.pdp.search.go";
    subCatID = "967CEED7-54C0-488E-8AA8-69E6045E6C05";

    requestData.CatID = this.addDetails[0].catID;
    requestData.subCatID = this.addDetails[0].subCatID;
    requestData.levelOfSearch = "2";
    requestData.searchWord = "";
    var newCatName = catName.replace(/ /g, '-');
    var newSubCatName = subCatName.replace(/ /g, '-');
    this.operSearch.setData(requestData);
    this.router.navigate(['/search', newCatName, newSubCatName]);
  }

}
