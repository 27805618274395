import { Component, OnInit } from '@angular/core';
import { openSearchService } from 'src/app/shared/services/search/openSearch.service';
import { Router, ActivatedRoute } from "@angular/router";
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { AdcompanyService } from 'src/app/shared/services/compnies/CompanyServices';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { adProductService } from 'src/app/shared/services/compnies/ProductServices';
import { addDetailsService } from 'src/app/shared/services/addDetails.service';
import { adDealService } from 'src/app/shared/services/deal/deal-services.service';

@Component({
  selector: 'app-user-home',
  templateUrl: './user-home.component.html',
  styleUrls: ['./user-home.component.css']
})
export class UserHomeComponent implements OnInit {

  public scrollbarOptions = { axis: 'y', theme: 'light' };
  List: any;
  PostList: any=[];
  ProductList: any=[];
  DealList: any=[];
  TotalLength:any;
  page:number=1;

  constructor( private dealService: adDealService,private adService: addDetailsService,private adcompanyService: AdcompanyService,private userInterface: openSearchService,private router: Router, private route:ActivatedRoute
    ,private mScrollbarService: MalihuScrollbarService,private ngxLoader: NgxUiLoaderService,public adproductService: adProductService) { }

  ngOnInit(): void {
    this.LoadTotalCount();
    this.LoadPostData();
    this.LoadProductData();
    this.LoadDealList();
  }

backtoMain()
{
  this.userInterface.setInterface("0");
  this.router.navigate(['../home'],{relativeTo : this.route });
}

LoadTotalCount() {
  //debugger;
  this.ngxLoader.start();
  this.adcompanyService.getTotalByUser().subscribe(data => {
    this.List = data;
    setTimeout(() => {
      this.ngxLoader.stop();
     }, 1000); 
  });
}

LoadPostData() {
  //debugger;
 // this.ngxLoader.start(); 
  this.adService.getadsDetails().subscribe((data:any[]) => {
    this.PostList = data;
    // setTimeout(() => {
    //   this.ngxLoader.stop();
    //  }, 0); 
    this.TotalLength = data.length;
  });
}
LoadProductData() {
  this.adproductService.getProductDetails().subscribe((data:any[]) => {
    this.ProductList = data;
  });
}
LoadDealList() {
  this.dealService.getDealList().subscribe((data:any[]) => {
    this.DealList = data;
  });
}

}
