import { Injectable } from '@angular/core';
import {createEffect, Actions,ofType} from '@ngrx/effects';
import * as searchActions from '../actions/search.action';
import { of, from, pipe, Observable } from 'rxjs';
import { map, switchMap, mergeMap,catchError } from "rxjs/operators";
import { dispatch } from 'rxjs/internal/observable/pairs';
import { Action } from 'rxjs/internal/scheduler/Action';

import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from 'angular-web-storage';


@Injectable()
export class searchEffects{    
    private baseUrl = environment.baseUrl;      
    private url = this.baseUrl+'token';    
    private bodyHeader =  JSON.stringify({ username:'mario', password :'secret' });                 
    private httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/x-www-form-urlencoded'            
          })
        };                      
    // @Effect()    
    // setSearchParams = this.actions$.pipe(ofType(searchActions.SearchActionTypes.SETSEARCH),map(
    //     (action: searchActions.trySearch)=>{
    //         //debugger;
    //         //console.log('insideSearchEffectsParam');
    //         //console.log(action.payload);
    //         return action.payload;            
    //     })
    //     // , switchMap((searchData : {
    //     //     category: string , 
    //     //     subCategory: string,
    //     //     firstLevel: string,
    //     //     secondLevel: string,
    //     //     thirdLevel: string
    //     // })=>
    //     // {
    //     //     //debugger;
    //     //     //console.log('insideSearchEffectsParam');
    //     //     //console.log(searchData);
    //     //     return pipe(mergeMap(val => of(`${val}World!`)))            
    //     // })        
               
    //     );              
//   @Effect()        
//    searchSet = this.actions$.pipe(        
//        ofType(searchActions.SearchActionTypes.SETSEARCH)
//     ,map((action: searchActions.trySearch)=>{      
//         //debugger;
//         //console.log('insideSearchE');
//         //console.log(action.payload);
//         // return {
//         //         type: searchActions.SearchActionTypes.SETSEARCH,
//         //         payload : action.payload
//         //     } 
//         return action.payload;       
//     })      
//     ,switchMap(action => this.httpclient.post<any>(this.url,this.bodyHeader, {
//             headers: { 'Content-Type':  'application/json' }
//           }).pipe(map(response => {
//             //debugger;
//             //console.log('insideSearchE2');             
//               return {
//                 type: searchActions.SearchActionTypes.SETSEARCH,
//                 payload : response.token
//               }             
//             })
//           ))
//          );
    constructor(private actions$:Actions,private httpclient:HttpClient, public localStor: LocalStorageService,){
    } 
}