import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Product } from '../product/show-product/Product';
import { adProductService } from '../shared/services/compnies/ProductServices';
import { openSearchService } from '../shared/services/search/openSearch.service';

@Component({
  selector: 'app-cart-calculator',
  templateUrl: './cart-calculator.component.html',
  styleUrls: ['./cart-calculator.component.css']
})
export class CartCalculatorComponent implements OnInit, OnChanges {
  @Input() products: Product[];

  totalValue = 0;
  Total=0;
  grandTotal=0;
  constructor(public adproductService: adProductService,private userInterface: openSearchService,private router: Router,private route:ActivatedRoute) {}

  ngOnChanges(changes: SimpleChanges) {
    const dataChanges: SimpleChange = changes.products;
    debugger
    const products: Product[] = dataChanges.currentValue;
    products.forEach((product) => {    
      this.Total = parseInt(product.proPrice) * product.proQuantity; 
      this.totalValue += parseInt(product.proPrice);
      var productTotalPrice = parseInt(product.proPrice) + this.totalValue;
      this.grandTotal += productTotalPrice;
    });
   // this.getItemTotalresult()
  }
  
  //   public totalPrice: number = 0;  
  //   public totalQty: number = 0;  
  //   public GrandtotalPrice: number = 0;    
  //   public totalItem: number = 0; 
  // getItemTotalresult() {  
  //   debugger
  //   this.totalPrice = 0;  
  //   this.totalQty = 0;  
  //   this.GrandtotalPrice = 0;  
  //   var count: number = 0;  
  //   this.totalItem = this.adproductService.getLocalCartProducts().length;
  //   for (count = 0; count < this.adproductService.getLocalCartProducts().length; count++) {  
  //       this.totalPrice += parseInt(this.adproductService.getLocalCartProducts()[count].proPrice);  
  //       this.totalQty += (this.adproductService.getLocalCartProducts()[count].proQuantity);  
  //       this.GrandtotalPrice += parseInt(this.adproductService.getLocalCartProducts()[count].proPrice) * this.adproductService.getLocalCartProducts()[count].proQuantity;  
  //   }   
  // }

  ngOnInit(): void {
  }

  backtoMain()
  {
    this.userInterface.setInterface("0");
    this.router.navigate(['../home'],{relativeTo : this.route });
  }
}
