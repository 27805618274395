import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { userprofile } from '../shared/models/Company/userprofile';
import { userprofileimg } from '../shared/models/Company/userprofileimage';
import { AdcompanyService } from '../shared/services/compnies/CompanyServices';
import { NotificationService } from '../shared/services/notification.service';
import { openSearchService } from '../shared/services/search/openSearch.service';
@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.css']
})
export class UpdateProfileComponent implements OnInit {

  constructor(private userInterface: openSearchService, private router: Router, private route: ActivatedRoute,
    private adcompanyService: AdcompanyService, private notifyService: NotificationService,private ngxLoader: NgxUiLoaderService) { }

  GenderList: any;
  Nationality: any;
  Status: any;
  Religion: any;
  Carrier: any;
  ngOnInit(): void {
    this.fillDropDowns();
    this.LoadProfileData();
  }

  profileList: any;
  oldPassword;
  userPassword;
  confirmPassword;
  userMiddleName:string;
  imageurls = [];
  fileurls = [];
  url = [];
  imageurlsNew = [];
  base64String: string;
  public showPassword: boolean;
  public showPasswordOnPress: boolean;

  LoadProfileData() {
    //debugger;
    this.ngxLoader.start();
    this.adcompanyService.getProfileDetails().subscribe(data => {
      this.profileList = data;
    //  this.userMiddleName = this.profileList[0].userMiddleName;
      //console.log(data);
      if (this.profileList.imageName != null) {
       // debugger;
        this.imageurls.push(this.profileList.imageBase64);
        console.log(this.profileList.imageBase64);
      }
      if (this.profileList.currentCVPath != null) {
       // debugger;
        this.fileurls.push(this.profileList.cvPathBase64);
        // //console.log(this.profileList.cvPathBase64);
      }
      setTimeout(() => {
        this.ngxLoader.stop();
  
      }, 1000);
    });
  }

  changepassword() {
    const profile = new userprofile();
    profile.oldPassword = this.oldPassword;
    profile.UserPassword = this.userPassword;
    profile.ConfirmPassword = this.confirmPassword;
    debugger;
    //this.profileList[0].userPassword;
    debugger
    if (profile.oldPassword != null) {
      if (profile.UserPassword != null && profile.ConfirmPassword != null) {
        if (profile.oldPassword != profile.UserPassword) {
          if (profile.UserPassword == profile.ConfirmPassword) {
            this.adcompanyService.UpdateProfilePass(profile).subscribe(data => {
              ////console.log(data);
              if (data != 0) {
                this.notifyService.showSuccess('Bravo!', 'Password Updated successfully !!');
              } else {
                this.notifyService.showError('Provide True Old Password !', 'Wrong Password');
              }
            });
          }
          else {
            this.notifyService.showError('Provide same Password !', 'Missing Data');
          }
        } else {
          this.notifyService.showError('New password must be different than old password !!', 'ERROR');
        }
      }
      else {
        this.notifyService.showError('Provide New & Confirm Password !', 'Missing Data');
      }
    }
    else {
      this.notifyService.showError('Provide Old Password !', 'Missing Data');
    }
  }

  mobilePrivate: any;
  onSubmit(form: NgForm) {
    debugger;
    //var number = this.mobilePrivate;
    const profile = new userprofile();
    profile.MakePrivate = this.profileList.makePrivate;
    profile.MobilePrivate = this.profileList.mobilePrivate;
    profile.DOBPrivate = this.profileList.dobPrivate;
    profile.UserMiddleName = this.profileList.userMiddleName;
    profile.UserLastName = this.profileList.userLastName;
    profile.UserFatherName = this.profileList.userFatherName;
    profile.MobileNumber = this.profileList.mobileNumber;
    profile.CurrentCompany = this.profileList.currentCompany;
    profile.CurrentCarrierLevel = this.profileList.currentCarrierLevel;
    profile.GenderID = this.profileList.genderID;
    profile.Nationality = this.profileList.nationality;
    profile.Status = this.profileList.status;
    profile.ReligionID = this.profileList.religionID;
    profile.CarrierLevel = this.profileList.carrierLevel;
    profile.CurrentCVPath = "";
    profile.DOB = this.profileList.dob;
    profile.Address = this.profileList.address;
    profile.CurrentCoverLetter = this.profileList.currentCoverLetter;
    debugger;
    var counter = 1;
    if (this.fileurls.length > 0) {
      this.fileurls.forEach(element => {
        profile.CurrentCVPath = element;
      })
    }
    debugger;
    this.adcompanyService.UpdateProfileDetails(profile).subscribe(data => {
      if(data != 0){
     //   this.notifyService.showSuccess('Bravo!', 'Profile Updated successfully !!');  
        this.successNotification();
      } 
    });
  }

  successNotification() {
    Swal.fire({
      position: 'top-center',
      icon: 'success',
      title: 'Profile Update Successfully',
      showConfirmButton: false,
      timer: 1000
    })
  }
  errorNotification() {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong!',
      footer: '<a href="">Provide the missing data ‼️‼️</a>'
    })
  }
  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  onImagesUpdate() {
    debugger;
    const profile = new userprofileimg();
    profile.userID = this.profileList.userID;
    ////console.log(this.companyID);
    var photos = [];
    debugger
    var counter = 1;
    if (this.imageurls.length > 0) {
      this.imageurls.forEach(element => {
        profile.ImageName = element;
      })
    }
    debugger;
    //console.log(profile);
    this.adcompanyService.updateProfileImage(profile).subscribe(data => {
      this.notifyService.showSuccess('Bravo!', 'Image Updated successfully !!');
    });
    //this.successNotification();
  }

  backtoMain() {
    this.userInterface.setInterface("0");
    this.router.navigate(['../home'], { relativeTo: this.route });
  }
  removeImage(i) {
    this.imageurls.splice(i, 1);
    this.imageurlsNew.splice(i, 1);
  }

  onSelectCV(event) {
    debugger;
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      var totalFiles = this.fileurls.length + filesAmount;

      if (totalFiles > 1) {
        alert("File limit execede!");
      }
      else {
        for (let i = 0; i < filesAmount; i++) {
          var reader = new FileReader();
          reader.onload = (event: any) => {
            // this.imageurls.push({ base64String: event.target.result});
            this.fileurls.push(event.target.result);
            //this.imageurlsNew.push({base64String: event.target.result});
            //this.imageurlsNew.push(event.target.result);
          }
          reader.readAsDataURL(event.target.files[i]);
        }
      }
    }
  }

  onSelectImagess(event) {
    debugger;
    // if (event.target.files && event.target.files[0]) {
    //   var filesAmount = event.target.files.length;
    //   var totalFiles = this.imageurls.length + filesAmount;

    //   if (totalFiles > 1) {
    //     alert("Image limit execede !!");
    //   }
    //   else {
    //     for (let i = 0; i < filesAmount; i++) {
    //       var reader = new FileReader();
    //       reader.onload = (event: any) => {
    //         this.imageurls.push(event.target.result);
    //       }
    //       reader.readAsDataURL(event.target.files[i]);
    //     }
    //   }
    // }
    for (let i = 0; i < this.imageurls.length; i++) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.imageurls[0] = event.target.result;
      }
      reader.readAsDataURL(event.target.files[i]);
    }
  }

  fillDropDowns() {
    this.GenderList = [
      { id: 0, title: "Select Gender" },
      { id: 1, title: "Male" },
      { id: 2, title: "Female" },
      { id: 3, title: "No Prefernce" }
    ],
      this.Nationality = [
        { id: 0, title: "Select Nationality" },
        { id: 1, title: "UAE" },
        { id: 2, title: "Pakistan" },
        { id: 3, title: "Saudi Arabia" },
        { id: 4, title: "KUWAIT" },
        { id: 5, title: "Oman" },
        { id: 7, title: "india" },
        { id: 8, title: "United Kingdom" },
        { id: 9, title: "United States" },
        { id: 10, title: "China" },
        { id: 11, title: "Japan" },
        { id: 12, title: "Canada" }
      ],
      this.Status = [
        { id: 0, title: "Select Status" },
        { id: 1, title: "Single" },
        { id: 2, title: "Married" },
        { id: 3, title: "Divorced" },
        { id: 4, title: "Widow" },
        { id: 5, title: "Complicated" },
        { id: 6, title: "Engaged" },
        { id: 7, title: "No Specified" }
      ],
      this.Religion = [
        { id: 0, title: "Select Religion" },
        { id: 1, title: "Muslim" },
        { id: 2, title: "Hindu" },
        { id: 3, title: "Christian" },
        { id: 4, title: "No Specified" },
      ],
      this.Carrier = [
        { id: 0, title: "Select Carrier" },
        { id: 1, title: "Bigenners" }
      ]
  }

  myuserview(){
    debugger;
    var userProfileID = this.profileList.userProfileID
    this.router.navigate(['/userprofile/detail',userProfileID], { relativeTo: this.route });
  }
}
