<div class="tg-wrapper tg-haslayout">
    <div class="tg-dashboardbanner">
        <h1>Dashboard</h1>
        <ol class="tg-breadcrumb">
            <li><a (click)="backtoMain()">Main</a></li>
            <li class="tg-active">Dashboard</li>
        </ol>
    </div>

    <!-- <ul>
        <li><a style="background-color: #00cc67;" class="tg-btn" (click)="backtoMain()">Go to Mian</a></li>
    </ul> -->

    <!--************************************
				Main Start
		*************************************-->
    <main id="tg-main" class="tg-main tg-haslayout">
        <!--************************************
					Dashboard Alerts Start
			*************************************-->
        <div class="tg-dbsectionspace tg-haslayout tg-alertexamples">
            <!-- <div class="tg-alert alert alert-info fade in">
                <p><strong>info: </strong> Consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <div class="tg-anchors">
                    <a class="tg-btndoaction" href="#">Do Action Now</a>
                    <a href="#" class="close" data-dismiss="alert">&times;</a>
                </div>
            </div>
            <div class="tg-alert alert alert-warning fade in">
                <p><strong>Warning:</strong> Nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                <div class="tg-anchors">
                    <a class="tg-btndoaction" href="#">Do Action Now</a>
                    <a href="#" class="close" data-dismiss="alert">&times;</a>
                </div>
            </div>
            <div class="tg-alert alert alert-danger fade in">
                <p><strong>Danger: </strong>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim aborum.</p>
                <div class="tg-anchors">
                    <a class="tg-btndoaction" href="#">Do Action Now</a>
                    <a href="#" class="close" data-dismiss="alert">&times;</a>
                </div>
            </div> -->
            <div class="tg-alert alert alert-success fade in">
                <p><strong>Success:</strong> Consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <div class="tg-anchors">
                    <a class="tg-btndoaction" href="#">Do Action Now</a>
                    <a href="#" class="close" data-dismiss="alert">&times;</a>
                </div>
            </div>
        </div>
        <!--************************************
					Dashboard Alerts End
			*************************************-->
        <!--************************************
					Statistics Start
			*************************************-->
        <section class="tg-dbsectionspace tg-haslayout tg-statistics">
            <div class="row">
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3">
                    <div class="tg-dashboardbox tg-statistic">
                        <figure><img src="../../../assets/images/icons/ads.png" alt="image description"></figure>
                        <div class="tg-contentbox">
                            <h2>{{List[0].adsCount}}</h2>
                            <h3>Total Ads Posted</h3>
                            <a class="tg-btnviewdetail fa fa-angle-right" routerLink="/user/adslist">View Detail</a>
                        </div>
                    </div>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3">
                    <div class="tg-dashboardbox tg-statistic">
                        <figure><img src="../../../assets/images/icons/received.png" alt="image description"></figure>
                        <div class="tg-contentbox">
                            <h2>{{List[0].productCount}}</h2>
                            <h3>Total Products Posted</h3>
                            <a class="tg-btnviewdetail fa fa-angle-right" routerLink="/user/productlist">View Detail</a>
                        </div>
                    </div>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3">
                    <div class="tg-dashboardbox tg-statistic">
                        <figure><img src="../../../assets/images/icons/hot-deal.png" alt="image description"></figure>
                        <div class="tg-contentbox">
                            <h2>{{List[0].dealCount}}</h2>
                            <h3>Total Deals Posted</h3>
                            <a class="tg-btnviewdetail fa fa-angle-right" routerLink="/user/deallist">View Detail</a>
                        </div>
                    </div>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3">
                    <div class="tg-dashboardbox tg-statistic">
                        <span class="tg-badge">7</span>
                        <figure><img src="../../../assets/images/icons/notification.png" alt="image description"></figure>
                        <div class="tg-contentbox">
                            <h2>4570</h2>
                            <h3>Offers / Messages</h3>
                            <a class="tg-btnviewdetail fa fa-angle-right" href="javascript:void(0);">View Detail</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--************************************
					Statistics End
			*************************************-->
            <section class="tg-dbsectionspace tg-haslayout">
                <div class="row">
                    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 tg-lgcolwidthhalf">
                        <div class="tg-dashboardbox">
                            <div class="tg-dashboardboxtitle">
                                <h2>Ads <i class="fa fa-get-pocket" aria-hidden="true"></i></h2>
                            </div>
                            <div class="tg-dashboardholder tg-approvedads tg-verticalscrollbar tg-dashboardscrollbar" malihu-scrollbar [scrollbarOptions]="scrollbarOptions">
                                <ul class="tg-approvedads">
                                    <li *ngFor="let items of PostList | paginate: { itemsPerPage: 10, currentPage: page,totalItems:TotalLength }">
                                        <figure [routerLink]="['/details',items.cat,items.addID]">
                                            <a href="javascript:void(0);">
                                                <img class="fav_imgs" *ngIf="items.addImageBase != null" [src]="items.addImageBase"  src="../../assets/images/ads/thumbnail/img-01.jpg" alt="image description">
                                                <img class="fav_imgs" *ngIf="items.addImageBase == null" src="../../assets/images/ads/thumbnail/img-01.jpg" alt="image description">
                                            </a>
                                        </figure>
                                        <div class="tg-adcontent">
                                            <span class="tg-adverified">Verified Ad</span>
                                            <h3>{{items.title}}</h3>
                                            <time datetime="2017-08-08">{{items.datePosted}}</time>
                                        </div>
                                    </li>
                                    <!-- <li>
                                        <figure>
                                            <a href="javascript:void(0);"><img src="../../assets/images/ads/thumbnail/img-04.jpg" alt="image description"></a>
                                        </figure>
                                        <div class="tg-adcontent">
                                            <span class="tg-adverified">Verified Ad</span>
                                            <h3>18 - 55 used macro lense for sale</h3>
                                            <time datetime="2017-08-08">01 Day Ago</time>
                                        </div>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 tg-lgcolwidthhalf">
                        <div class="tg-dashboardbox">
                            <div class="tg-dashboardboxtitle">
                                <h2>Products <i class="fa fa-get-pocket" aria-hidden="true"></i></h2>
                            </div>
                            <div class="tg-dashboardholder tg-approvedads tg-verticalscrollbar tg-dashboardscrollbar" malihu-scrollbar [scrollbarOptions]="scrollbarOptions">
                                <ul class="tg-approvedads">
                                    <li *ngFor="let items of ProductList">
                                        <figure [routerLink]="['/product/details',items.proID]">
                                            <a href="javascript:void(0);">
                                                <img class="fav_imgs" *ngIf="items.proImageBase != null" [src]="items.proImageBase"  src="../../assets/images/ads/thumbnail/img-01.jpg" alt="image description">
                                                <img class="fav_imgs" *ngIf="items.proImageBase == null" src="../../assets/images/ads/thumbnail/img-01.jpg" alt="image description">
                                            </a>
                                        </figure>
                                        <div class="tg-adcontent">
                                            <span class="tg-adverified">Verified Ad</span>
                                            <h3>{{items.proName}}</h3>
                                            <time datetime="2017-08-08">{{items.proAddedDate}}</time>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 tg-lgcolwidthhalf">
                        <div class="tg-dashboardbox">
                            <div class="tg-dashboardboxtitle">
                                <h2>Deals <i class="fa fa-get-pocket" aria-hidden="true"></i></h2>
                            </div>
                            <div class="tg-dashboardholder tg-myactivitylog tg-verticalscrollbar tg-dashboardscrollbar" malihu-scrollbar [scrollbarOptions]="scrollbarOptions">
                                <ul class="tg-activitylog">
                                    <li *ngFor="let items of DealList">
                                        <h3 style="cursor: pointer;" [routerLink]="['/deal/details',items.dealID]">{{items.name}}</h3>
                                        <time datetime="2017-08-08">{{items.addedDate}}</time>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        <!--***************-->
        <!-- <section class="tg-dbsectionspace tg-haslayout">
            <div class="row">
                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 tg-lgcolwidthhalf">
                    <div class="tg-dashboardbox">
                        <div class="tg-dashboardboxtitle">
                            <h2>My Activity Log</h2>
                        </div>
                        <div class="tg-dashboardholder tg-myactivitylog tg-verticalscrollbar tg-dashboardscrollbar" malihu-scrollbar [scrollbarOptions]="scrollbarOptions">
                            <ul class="tg-activitylog">
                                <li>
                                    <h3>You Logged In Via Mobile</h3>
                                    <time datetime="2017-08-08">02 Minutes Ago</time>
                                </li>
                                <li>
                                    <h3>You Liked <a href="javascript:void(0);">Samsung Galaxy S5</a></h3>
                                    <time datetime="2017-08-08">01 Day Ago</time>
                                </li>
                                <li>
                                    <h3>You Updated Your Profile Photo</h3>
                                    <time datetime="2017-08-08">01 Day Ago</time>
                                </li>
                                <li>
                                    <h3>You Posted A Job - Carpenter Required</h3>
                                    <time datetime="2017-08-08">03 Days Ago</time>
                                </li>
                                <li>
                                    <h3>You Subscribed <a href="javascript:void(0);">Basic Package</a></h3>
                                    <time datetime="2017-08-08">03 Days Ago</time>
                                </li>
                                <li>
                                    <h3>You Logged In Via Mobile</h3>
                                    <time datetime="2017-08-08">02 Minutes Ago</time>
                                </li>
                                <li>
                                    <h3>You Liked <a href="javascript:void(0);">Samsung Galaxy S5</a></h3>
                                    <time datetime="2017-08-08">01 Day Ago</time>
                                </li>
                                <li>
                                    <h3>You Updated Your Profile Photo</h3>
                                    <time datetime="2017-08-08">01 Day Ago</time>
                                </li>
                                <li>
                                    <h3>You Posted A Job - Carpenter Required</h3>
                                    <time datetime="2017-08-08">03 Days Ago</time>
                                </li>
                                <li>
                                    <h3>You Subscribed <a href="javascript:void(0);">Basic Package</a></h3>
                                    <time datetime="2017-08-08">03 Days Ago</time>
                                </li>
                                <li>
                                    <h3>You Logged In Via Mobile</h3>
                                    <time datetime="2017-08-08">02 Minutes Ago</time>
                                </li>
                                <li>
                                    <h3>You Liked <a href="javascript:void(0);">Samsung Galaxy S5</a></h3>
                                    <time datetime="2017-08-08">01 Day Ago</time>
                                </li>
                                <li>
                                    <h3>You Updated Your Profile Photo</h3>
                                    <time datetime="2017-08-08">01 Day Ago</time>
                                </li>
                                <li>
                                    <h3>You Posted A Job - Carpenter Required</h3>
                                    <time datetime="2017-08-08">03 Days Ago</time>
                                </li>
                                <li>
                                    <h3>You Subscribed <a href="javascript:void(0);">Basic Package</a></h3>
                                    <time datetime="2017-08-08">03 Days Ago</time>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 tg-lgcolwidthhalf">
                    <div class="tg-dashboardbox">
                        <div class="tg-dashboardboxtitle">
                            <h2>My Favorite Ads</h2>
                        </div>
                        <div class="tg-dashboardholder tg-approvedads tg-verticalscrollbar tg-dashboardscrollbar" malihu-scrollbar [scrollbarOptions]="scrollbarOptions">
                            <ul class="tg-approvedads">
                                <li>
                                    <figure>
                                        <a href="javascript:void(0);"><img src="../../assets/images/ads/thumbnail/img-01.jpg" alt="image description"></a>
                                    </figure>
                                    <div class="tg-adcontent">
                                        <span class="tg-adverified">Verified Ad</span>
                                        <h3>Brand new lenovo laptop i5 for sale</h3>
                                        <time datetime="2017-08-08">01 Day Ago</time>
                                    </div>
                                </li>
                                <li>
                                    <figure>
                                        <a href="javascript:void(0);"><img src="../../assets/images/ads/thumbnail/img-02.jpg" alt="image description"></a>
                                    </figure>
                                    <div class="tg-adcontent">
                                        <span class="tg-adverified">Verified Ad</span>
                                        <h3>Samsung galaxy s5 for sale</h3>
                                        <time datetime="2017-08-08">01 Day Ago</time>
                                    </div>
                                </li>
                                <li>
                                    <figure>
                                        <a href="javascript:void(0);"><img src="../../assets/images/ads/thumbnail/img-03.jpg" alt="image description"></a>
                                    </figure>
                                    <div class="tg-adcontent">
                                        <span class="tg-adverified">Verified Ad</span>
                                        <h3>Honda civic for sale urgent</h3>
                                        <time datetime="2017-08-08">01 Day Ago</time>
                                    </div>
                                </li>
                                <li>
                                    <figure>
                                        <a href="javascript:void(0);"><img src="../../assets/images/ads/thumbnail/img-04.jpg" alt="image description"></a>
                                    </figure>
                                    <div class="tg-adcontent">
                                        <span class="tg-adverified">Verified Ad</span>
                                        <h3>18 - 55 used macro lense for sale</h3>
                                        <time datetime="2017-08-08">01 Day Ago</time>
                                    </div>
                                </li>
                                <li>
                                    <figure>
                                        <a href="javascript:void(0);"><img src="../../assets/images/ads/thumbnail/img-01.jpg" alt="image description"></a>
                                    </figure>
                                    <div class="tg-adcontent">
                                        <span class="tg-adverified">Verified Ad</span>
                                        <h3>Brand new lenovo laptop i5 for sale</h3>
                                        <time datetime="2017-08-08">01 Day Ago</time>
                                    </div>
                                </li>
                                <li>
                                    <figure>
                                        <a href="javascript:void(0);"><img src="../../assets/images/ads/thumbnail/img-02.jpg" alt="image description"></a>
                                    </figure>
                                    <div class="tg-adcontent">
                                        <span class="tg-adverified">Verified Ad</span>
                                        <h3>Samsung galaxy s5 for sale</h3>
                                        <time datetime="2017-08-08">01 Day Ago</time>
                                    </div>
                                </li>
                                <li>
                                    <figure>
                                        <a href="javascript:void(0);"><img src="../../assets/images/ads/thumbnail/img-03.jpg" alt="image description"></a>
                                    </figure>
                                    <div class="tg-adcontent">
                                        <span class="tg-adverified">Verified Ad</span>
                                        <h3>Honda civic for sale urgent</h3>
                                        <time datetime="2017-08-08">01 Day Ago</time>
                                    </div>
                                </li>
                                <li>
                                    <figure>
                                        <a href="javascript:void(0);"><img src="../../assets/images/ads/thumbnail/img-04.jpg" alt="image description"></a>
                                    </figure>
                                    <div class="tg-adcontent">
                                        <span class="tg-adverified">Verified Ad</span>
                                        <h3>18 - 55 used macro lense for sale</h3>
                                        <time datetime="2017-08-08">01 Day Ago</time>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 tg-lgcolwidthhalf">
                    <div class="tg-dashboardbox">
                        <div class="tg-dashboardboxtitle">
                            <h2>Total Views</h2>
                            <form class="tg-formtheme tg-formthemesearch">
                                <fieldset>
                                    <span class="tg-select">
											<select>
												<option>Select Ad</option>
												<option>Select Ad</option>
												<option>Select Ad</option>
											</select>
										</span>
                                </fieldset>
                            </form>
                        </div>
                        <div class="tg-dashboardholder tg-totalviews">
                            <div id="tg-viewchart" class="tg-viewchart"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->
        <!--******************-->
        <!--****************-->
        <section class="tg-dbsectionspace tg-haslayout">
            <div class="row">
                <!--************************************
							Offers/Messages Start
					*************************************-->
                <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8 tg-lgcolwidthfull">
                    <div class="tg-dashboardbox">
                        <div class="tg-dashboardboxtitle">
                            <h2>My Favorite Items</h2>
                        </div>
                        <div class="tg-dashboardholder tg-offersmessages">
                            <ul>
                                <li>
                                    <div class="tg-verticalscrollbar tg-dashboardscrollbar" malihu-scrollbar [scrollbarOptions]="scrollbarOptions">
                                        <div class="tg-ad tg-dotnotification"  *ngFor="let items of  adproductService.getFavoriteAds()" >
                                            <figure [routerLink]="['/details',items.cat,items.add_ID]">
                                                <img class="fav_imgs" *ngIf="items.addImageBase != null  && items.addImageBase != ''" [src]="items.addImageBase" alt="image description">
                                                <img class="fav_imgs" *ngIf="items.addImageBase == null  || items.addImageBase == ''" src="../../assets/images/ads/thumbnail/img-01.jpg" alt="image description">
                                            </figure>
                                            <h3>{{items.title}}</h3>
                                        </div>
                                        <!-- <div class="tg-ad">
                                            <figure><img src="../../assets/images/ads/thumbnail/img-07.jpg" alt="image description"></figure>
                                            <h3>Original ei phone magnet charger</h3>
                                        </div> -->
                                    </div>
                                </li>
                                <li>
                                    <div class="tg-offerers tg-verticalscrollbar tg-dashboardscrollbar" malihu-scrollbar [scrollbarOptions]="scrollbarOptions">
                                        <div class="tg-offerer tg-dotnotification" *ngFor="let items of  adproductService.getFavoriteCartProducts()">
                                            <figure [routerLink]="['/product/details',items.proID]">
                                                <img class="fav_imgs" *ngIf="items.proImageBase != null  && items.proImageBase != ''" [src]="items.proImageBase" alt="image description">
                                                <img class="fav_imgs" *ngIf="items.proImageBase == null  || items.proImageBase == ''" src="../../assets/images/ads/thumbnail/img-01.jpg" alt="image description">
                                            </figure>
                                            <h3>{{items.proName}}</h3>
                                        </div>
                                        <!-- <div class="tg-offerer">
                                            <figure><img src="../../assets/images/author/img-15.jpg" alt="image description"></figure>
                                            <h3>Susanne Foran</h3>
                                        </div> -->
                                    </div>
                                </li>
                                <li>
                                    <div class="tg-chatarea">
                                        <div class="tg-messages tg-verticalscrollbar tg-dashboardscrollbar" malihu-scrollbar [scrollbarOptions]="scrollbarOptions">
                                            <div class="tg-offerermessage">
                                                <figure><img src="../../assets/images/author/img-16.jpg" alt="image description"></figure>
                                                <div class="tg-description">
                                                    <p>Consectetur adipisicing elit sei do eiusmod tempor incididunt labore et dolore.</p>
                                                    <div class="clearfix"></div>
                                                    <time datetime="2017-08-08">January 12th, 2011</time>
                                                </div>
                                            </div>
                                            <div class="tg-memessage tg-readmessage">
                                                <figure><img src="../../assets/images/author/img-17.jpg" alt="image description"></figure>
                                                <div class="tg-description">
                                                    <p>Eiusmod tempor incididunt labore et dolore magna aliqiu enim ad minim veniam qiuisru exercitation ullamco laborisen nisi ut aliquip exea.</p>
                                                    <div class="clearfix"></div>
                                                    <p><a href="https://themeforest.net" target="_blank">https://themeforest.net</a></p>
                                                    <div class="clearfix"></div>
                                                    <p>It that ok?</p>
                                                    <div class="clearfix"></div>
                                                    <time datetime="2017-08-08">Jun 28, 2017 09:30</time>
                                                    <div class="clearfix"></div>
                                                </div>
                                            </div>
                                            <div class="tg-offerermessage">
                                                <figure><img src="../../assets/images/author/img-16.jpg" alt="image description"></figure>
                                                <div class="tg-description">
                                                    <div class="clearfix"></div>
                                                    <p>Consectetur adipisicing elit sei do eiusmod tempor incididunt labore et dolore.</p>
                                                    <div class="clearfix"></div>
                                                    <time datetime="2017-08-08">January 12th, 2011</time>
                                                    <div class="clearfix"></div>
                                                </div>
                                            </div>
                                            <div class="tg-memessage tg-readmessage">
                                                <figure><img src="../../assets/images/author/img-17.jpg" alt="image description"></figure>
                                                <div class="tg-description">
                                                    <div class="clearfix"></div>
                                                    <p>Eiusmod tempor incididunt labore et dolore magna aliqiu enim ad minim veniam qiuisru exercitation ullamco laborisen nisi ut aliquip exea.</p>
                                                    <div class="clearfix"></div>
                                                    <p><a href="https://themeforest.net" target="_blank">https://themeforest.net</a></p>
                                                    <div class="clearfix"></div>
                                                    <p>It that ok?</p>
                                                    <div class="clearfix"></div>
                                                    <time datetime="2017-08-08">Jun 28, 2017 09:30</time>
                                                    <div class="clearfix"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tg-replaybox">
                                            <textarea class="form-control" name="reply" placeholder="Type Here &amp; Press Enter"></textarea>
                                            <div class="tg-iconbox">
                                                <i class="icon-thumbs-up"></i>
                                                <i class="icon-thumbs-down"></i>
                                                <i class="icon-smile"></i>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!--************************************
							Offers/Messages End
					*************************************-->
                <!--************************************
							Todo Task List Start
					*************************************-->
                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 tg-lgcolwidthfull">
                    <div class="tg-dashboardbox">
                        <div class="tg-dashboardboxtitle">
                            <h2>Todo Task List</h2>
                        </div>
                        <div class="tg-verticalscrollbar tg-dashboardscrollbar tg-todotasklist" malihu-scrollbar [scrollbarOptions]="scrollbarOptions">
                            <ul>
                                <li>
                                    <div class="tg-checkbox">
                                        <input id="tg-todoone" type="checkbox" name="todolist" value="one">
                                        <label for="tg-todoone">Consectetur adipisicing elit sed do eiusmod lokatns eima tempor incididunt ut labore</label>
                                    </div>
                                </li>
                                <li>
                                    <div class="tg-checkbox">
                                        <input id="tg-todotwo" type="checkbox" name="todolist" value="two">
                                        <label for="tg-todotwo">Ut enim ad minim veniam quis nostrud sheisam</label>
                                    </div>
                                </li>
                                <li>
                                    <div class="tg-checkbox">
                                        <input id="tg-todothree" type="checkbox" name="todolist" value="three" checked>
                                        <label for="tg-todothree">Exercitation ullamco laboris lokamna</label>
                                    </div>
                                </li>
                                <li>
                                    <div class="tg-checkbox">
                                        <input id="tg-todofour" type="checkbox" name="todolist" value="four">
                                        <label for="tg-todofour">Aisi ut aliquip ex ea commodo consequat</label>
                                    </div>
                                </li>
                                <li>
                                    <div class="tg-checkbox">
                                        <input id="tg-todofive" type="checkbox" name="todolist" value="five">
                                        <label for="tg-todofive">Duis aute irure dolor sola to hokama</label>
                                    </div>
                                </li>
                                <li>
                                    <div class="tg-checkbox">
                                        <input id="tg-todosix" type="checkbox" name="todolist" value="six" checked>
                                        <label for="tg-todosix">Reprehenderit in voluptate velit esse cillum</label>
                                    </div>
                                </li>
                                <li>
                                    <div class="tg-checkbox">
                                        <input id="tg-todoseven" type="checkbox" name="todolist" value="seven">
                                        <label for="tg-todoseven">Excepteur sint occaecat lokstanshie</label>
                                    </div>
                                </li>
                                <li>
                                    <div class="tg-checkbox">
                                        <input id="tg-todoeight" type="checkbox" name="todolist" value="eight">
                                        <label for="tg-todoeight">Cupidatat non proident sunt in culpa milake na shiet</label>
                                    </div>
                                </li>
                                <li>
                                    <div class="tg-checkbox">
                                        <input id="tg-todonine" type="checkbox" name="todolist" value="nine">
                                        <label for="tg-todonine">Consectetur adipisicing elit sed do eiusmod lokatns eima tempor incididunt ut labore</label>
                                    </div>
                                </li>
                                <li>
                                    <div class="tg-checkbox">
                                        <input id="tg-todoten" type="checkbox" name="todolist" value="ten">
                                        <label for="tg-todoten">Ut enim ad minim veniam quis nostrud sheisam</label>
                                    </div>
                                </li>
                                <li>
                                    <div class="tg-checkbox">
                                        <input id="tg-todoeleven" type="checkbox" name="todolist" value="eleven">
                                        <label for="tg-todoeleven">Exercitation ullamco laboris lokamna</label>
                                    </div>
                                </li>
                                <li>
                                    <div class="tg-checkbox">
                                        <input id="tg-todotwelve" type="checkbox" name="todolist" value="twelve">
                                        <label for="tg-todotwelve">Aisi ut aliquip ex ea commodo consequat</label>
                                    </div>
                                </li>
                                <li>
                                    <div class="tg-checkbox">
                                        <input id="tg-todothirteen" type="checkbox" name="todolist" value="thirteen">
                                        <label for="tg-todothirteen">Duis aute irure dolor sola to hokama</label>
                                    </div>
                                </li>
                                <li>
                                    <div class="tg-checkbox">
                                        <input id="tg-todofourteen" type="checkbox" name="todolist" value="fourteen">
                                        <label for="tg-todofourteen">Reprehenderit in voluptate velit esse cillum</label>
                                    </div>
                                </li>
                                <li>
                                    <div class="tg-checkbox">
                                        <input id="tg-todofifteen" type="checkbox" name="todolist" value="fifteen">
                                        <label for="tg-todofifteen">Excepteur sint occaecat lokstanshie</label>
                                    </div>
                                </li>
                                <li>
                                    <div class="tg-checkbox">
                                        <input id="tg-todosixteen" type="checkbox" name="todolist" value="sixteen">
                                        <label for="tg-todosixteen">Cupidatat non proident sunt in culpa milake na shiet</label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!--************************************
							Todo Task List End
					*************************************-->
            </div>
        </section>
        <!--************************************
					Section End
			*************************************-->
    </main>
    <!--************************************
				Main End
		*************************************-->

</div>