import { EventEmitter, Injectable } from '@angular/core';
import { Subject, Observable, pipe, of, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {  HttpHeaders } from '@angular/common/http';
import { map,catchError, retry , tap } from 'rxjs/operators';
import { error } from 'protractor';
import { companyRequest } from '../../models/Company/companyRequest';
import { companyDetails } from '../../models/Company/companyDetails';
import { Data } from '@angular/router';
import { companyRequestImage } from '../../models/Company/companyRequestImage';
import { userprofile } from '../../models/Company/userprofile';
import { userprofileimg } from '../../models/Company/userprofileimage';
import { validateParams } from '../../models/Company/uservalidation';
import { usereducation } from '../../models/Company/User-About/usereducation';
import { userexperience } from '../../models/Company/User-About/userexperience';
import { usercertification } from '../../models/Company/User-About/usercertification';
import { userproject } from '../../models/Company/User-About/userproject';
import { userskill } from '../../models/Company/User-About/userskill';
import { alluserlist } from '../../models/Company/User-About/userwebskill';
import { Li_skill } from '../../models/Company/User-About/LI_Skills';
import { skillObj } from '../../models/Company/User-About/skillObj';
import { userteam } from '../../models/Company/User-About/userteam';
import { teamcollegues } from '../../models/Company/User-About/getTeamCollegues';
import { userclass } from '../../models/Company/User-About/userclass';
import { userEndorcement } from '../../models/Company/User-About/user_Endorcement';


@Injectable()
export class AdcompanyService{
private baseUrl = environment.baseUrl;  
    private httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/x-www-form-urlencoded'         
        })
      };     
private result:number;
private resultLike:string;
  team: teamcollegues;
constructor(private httpclient:HttpClient){}


  getCompanyDetails(){
    return this.httpclient.get<any>(this.baseUrl+'services/Company/companyDetailsByUser');
  }

  getProfileDetails(){
    return this.httpclient.get<any>(this.baseUrl+'services/User/LoginUser');
  }
  getProfileDetails_2(userID : number):Observable<any[]>{
    debugger;
    return this.httpclient.get<any>(this.baseUrl+'services/User/LoginUser?userID='+userID,this.httpOptions);
  }

  getValidationUser(params:validateParams){  
      return  this.httpclient.post<any>(this.baseUrl+'services/User/ValidateUser',JSON.stringify(params),this.httpOptions)
      .pipe(map((response : any)=> {
        return response;
        }),
        catchError(error =>{
          return throwError('seomething went wrong');
        })
    );                        
  }

  getTotalByUser(){
    return this.httpclient.get<any>(this.baseUrl+'services/total/all');
  }
  
  UpdateProfilePass(profile:userprofile){  
    debugger;
    return this.httpclient.post<number>(this.baseUrl+'services/userupdate/LoginUserpassword',JSON.stringify(profile),this.httpOptions)      
  }
  UpdateProfileDetails(profile:userprofile){  
    debugger;
    return this.httpclient.post<number>(this.baseUrl+'services/userupdate/LoginUser',JSON.stringify(profile),this.httpOptions)              
  }
  submitCompanyDetails(companyDetails:companyRequest){  
    debugger;
    return this.httpclient.post<number>(this.baseUrl+'services/Company/adCompany',JSON.stringify(companyDetails),this.httpOptions)             
  }
  updateCompanyImage(companyDetails:companyRequestImage){  
    debugger;
    return this.httpclient.post<number>(this.baseUrl+'services/Company/updateimage',JSON.stringify(companyDetails),this.httpOptions)              
  }

  updateProfileImage(profile:userprofileimg){  
    debugger;
    return this.httpclient.post<number>(this.baseUrl+'services/userprofile/updateimage',JSON.stringify(profile),this.httpOptions)             
  }

  ///////////////////////////  User About ////////////////////////////

  // USER EDUCATION
  GetSingleEducation(eduID : number){  
    debugger;
    return  this.httpclient.get<any>(this.baseUrl+'services/user/geteducationById?eduID='+eduID,this.httpOptions)
    .pipe(map((response : any)=> {
      return response;
      }),
      catchError(error =>{
        return throwError('seomething went wrong');
      })
      );                        
  }
  DELETESingleEducation(eduID : number){  
   // debugger;
    return  this.httpclient.get<any>(this.baseUrl+'services/user/delete/geteducationById?eduID='+eduID,this.httpOptions)
    .pipe(map((response : any)=> {
      return response;
      }),
      catchError(error =>{
        return throwError('seomething went wrong');
      })
      );                        
  }
  GetEducationList(){
    return this.httpclient.get<any>(this.baseUrl+'services/user/geteducation');
  }
  GetEducationList_2(userID : number):Observable<any[]>{
    debugger;
    return this.httpclient.get<any>(this.baseUrl+'services/user/geteducation?userID='+userID,this.httpOptions);
  }

  UpdateEducation(edu:usereducation){  
   // debugger;
    return this.httpclient.post<number>(this.baseUrl+'services/user/addeducation',JSON.stringify(edu),this.httpOptions)          
  }

  // USER EXPERIENCE
  GetSingleExperience(expID : number){  
    debugger;
    return  this.httpclient.get<any>(this.baseUrl+'services/user/getexperienceById?expID='+expID,this.httpOptions)
    .pipe(map((response : any)=> {
      return response;
      }),
      catchError(error =>{
        return throwError('seomething went wrong');
      })
      );                        
  }
  DELETESingleExperience(expID : number){  
    debugger;
    return  this.httpclient.get<any>(this.baseUrl+'services/user/delete/getexperienceById?expID='+expID,this.httpOptions)
    .pipe(map((response : any)=> {
      return response;
      }),
      catchError(error =>{
        return throwError('seomething went wrong');
      })
      );                        
  }
  GetExperienceList(){
    return this.httpclient.get<any>(this.baseUrl+'services/user/getexperience');
  }
  GetExperienceList_2(userID : number):Observable<any[]>{
    debugger;
    return this.httpclient.get<any>(this.baseUrl+'services/user/getexperience?userID='+userID,this.httpOptions);
  }
  UpdateExperience(edu:userexperience){  
    debugger;
   return this.httpclient.post<number>(this.baseUrl+'services/user/addexperience',JSON.stringify(edu),this.httpOptions)
  }

   // USER CERTIFICATION
   GetSingleCertification(certID : number){  
    debugger;
    return  this.httpclient.get<any>(this.baseUrl+'services/user/getcertificationById?certID='+certID,this.httpOptions)
    .pipe(map((response : any)=> {
      return response;
      }),
      catchError(error =>{
        return throwError('seomething went wrong');
      })
      );                        
  }
  DELETESingleCertification(certID : number){  
    debugger;
    return  this.httpclient.get<any>(this.baseUrl+'services/user/delete/getcertificationById?certID='+certID,this.httpOptions)
    .pipe(map((response : any)=> {
      return response;
      }),
      catchError(error =>{
        return throwError('seomething went wrong');
      })
      );                        
  }
  GetCertificationList(){
    return this.httpclient.get<any>(this.baseUrl+'services/user/getcertification');
  }
  GetCertificationList_2(userID : number):Observable<any[]>{
    debugger;
    return this.httpclient.get<any>(this.baseUrl+'services/user/getcertification?userID='+userID,this.httpOptions);
  }
  UpdateCertification(data:usercertification){  
    debugger;
    return this.httpclient.post<number>(this.baseUrl+'services/user/addcertification',JSON.stringify(data),this.httpOptions)     
  }

   // USER PROJECT
   GetSingleProject(projID : number){  
    debugger;
    return  this.httpclient.get<any>(this.baseUrl+'services/user/getprojectById?projID='+projID,this.httpOptions)
    .pipe(map((response : any)=> {
      return response;
      }),
      catchError(error =>{
        return throwError('seomething went wrong');
      })
      );                        
  }
  DELETESingleProject(projID : number){  
    debugger;
    return  this.httpclient.get<any>(this.baseUrl+'services/user/delete/getprojectById?projID='+projID,this.httpOptions)
    .pipe(map((response : any)=> {
      return response;
      }),
      catchError(error =>{
        return throwError('seomething went wrong');
      })
      );                        
  }
  GetProjectList(){
    return this.httpclient.get<any>(this.baseUrl+'services/user/getproject');
  }
  GetProjectList_2(userID : number):Observable<any[]>{
    debugger;
    return this.httpclient.get<any>(this.baseUrl+'services/user/getproject?userID='+userID,this.httpOptions);
  }
  GetSelectedSkills(){
    return this.httpclient.get<any>(this.baseUrl+'services/user/selectedskills');
  }
  GetSelectedSkills_2(userID : number):Observable<any[]>{
    debugger;
    return this.httpclient.get<any>(this.baseUrl+'services/user/selectedskills?userID='+userID,this.httpOptions);
  }
  UpdateProject(data:userproject){  
    debugger;
   return this.httpclient.post<number>(this.baseUrl+'services/user/addproject',JSON.stringify(data),this.httpOptions)
  }
  // CLASS & TEAM MEMBERS
  GetTeamList(){
    return this.httpclient.get<any>(this.baseUrl+'services/user/getteam',this.httpOptions);
  }
  GetTeamList_2(userID : number):Observable<any[]>{
    debugger;
    return this.httpclient.get<any>(this.baseUrl+'services/user/getteam?userID='+userID,this.httpOptions);
  }
  GetClassList(){
    return this.httpclient.get<any>(this.baseUrl+'services/user/getclass',this.httpOptions);
  }
  GetClassList_2(userID : number):Observable<any[]>{
    debugger;
    return this.httpclient.get<any>(this.baseUrl+'services/user/getclass?userID='+userID,this.httpOptions);
  }
  GetSingleMember(collegueID : number){  
    debugger;
    return  this.httpclient.get<any>(this.baseUrl+'services/user/getteamById?collegueID='+collegueID,this.httpOptions)
    .pipe(map((response : any)=> {
      return response;
      }),
      catchError(error =>{
        return throwError('seomething went wrong');
      })
      );                        
  }
  UpdateTeam(data:userteam){  
    debugger;
    return this.httpclient.post<number>(this.baseUrl+'services/user/addteam',JSON.stringify(data),this.httpOptions)
  }
  UpdateClass(data:userclass){  
    debugger;
    return this.httpclient.post<number>(this.baseUrl+'services/user/addclass',JSON.stringify(data),this.httpOptions)
  }

  //USER SKILLS
  GetSkillsList(parentId:number){
    return  this.httpclient.get<any>(this.baseUrl+'services/user/getparentbyid?parentId='+parentId,this.httpOptions)
    .pipe(map((response : any)=> {
      return response;
      }),
      catchError(error =>{
        return throwError('seomething went wrong');
      })
      );    
  }

  UpdateWebSkills(data:skillObj){  
    debugger;
    this.httpclient.post<number>(this.baseUrl+'services/user/webskill',JSON.stringify(data),this.httpOptions).subscribe(data => {
      debugger; 
      //console.log(data);           
  return this.result = data;
    })               
  }
  GetUserSkills(){
    return this.httpclient.get<any>(this.baseUrl+'services/user/selectedskills');
  }
  AddUserEndorcement(data:userEndorcement){  
    debugger;
    return this.httpclient.post<number>(this.baseUrl+'services/user/postendorcement',JSON.stringify(data),this.httpOptions)              
  }

}