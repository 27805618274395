import { Component, EventEmitter, Output, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "../../auth/auth.service";
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';
import * as fromApp from '../../store/app.state';
import * as fromAuth from '../../store/reducers/auth.reducers';
import { Observable } from 'rxjs';
import { openSearchService } from 'src/app/shared/services/search/openSearch.service';
import jwt_decode from 'jwt-decode';
import { AdcompanyService } from "src/app/shared/services/compnies/CompanyServices";
import { notificationService } from "src/app/shared/liveUpdate/Notification.service";
import { NotificationService } from "src/app/shared/services/notification.service";
import { postnotify } from "src/app/shared/models/postnotify.model";
import { Comments } from "src/app/shared/models/comments/comment.model";
import { liveUpdateService } from "src/app/shared/liveUpdate/listUpdate.service";
import { chattingService } from "src/app/shared/liveUpdate/chatting.service";
import { commentNotify } from "src/app/shared/models/Notifications/commentNotification.model";
import { HttpClient } from '@angular/common/http';
import { notifyMessage } from "src/app/shared/models/notifyMessage";
import { ActionserviceService } from "src/app/shared/services/compnies/actionservice.service";
import { Actionbtn } from "src/app/shared/models/Company/User-About/Action_btn";
import { allSearchFilters } from "src/app/shared/models/requestsModel/search/searchInput.model";
import { adProductService } from "src/app/shared/services/compnies/ProductServices";
import { Product } from "src/app/product/show-product/Product";
import { adds } from "src/app/shared/models/Adds/adds.model";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  @Output() featuresSelected = new EventEmitter<string>();
  authState: Observable<fromAuth.State>;

  userName: string;
  token: string;
  email: string;
  userId: string;
  ImageName: string;
  UserImage: any;
  imageBase64: any;
  imageUrl: any;
  chatServiceAdapter: chattingService;
  itemArray: any = [];
  FrndRequests: any;
  frndrequestlenght: number;
  friendmessages: any[];
  messageslength: number;

  constructor(private liveNotification: notificationService, private operSearch: openSearchService,private commentService: liveUpdateService, private notifyService: NotificationService, private adcompanyService: AdcompanyService, private authSer: AuthenticationService, private router: Router, private route: ActivatedRoute
    , private store: Store<fromApp.AppState>, private userInterface: openSearchService,private actions: ActionserviceService
    , private httpclient: HttpClient,public adproductService: adProductService
    , private userNotifyService: chattingService) {
  };
  onSelect(feature: string) {
    this.featuresSelected.emit(feature);
  }
  onLogIn() {
    // this.authSer.login();       
  }
  
  ngOnInit() {
   //debugger;
    this.liveNotification.NotificationReceived.subscribe((adds: postnotify) => {
      // //debugger;
      this.showNotifyAdd(adds);
    });

    //debugger;
    this.commentService.commentReceived.subscribe((comment: Comments) => {
      //debugger;
      //this.showNotifyComments(comment);
    });

    this.LoadFriendRequests();
    this.LoadMessageNotify();

    // this.commentService.ChatMessageReceived.subscribe((result: notifyMessage) => {
    //   //debugger;
    //   this.showNotifyMessage(result);
    // });

    this.authState = this.store.select('auth');

    this.store.select('auth').subscribe(data =>
      this.token = data.token
    )
    debugger;
    const tokenInfo = this.getDecodedAccessToken(this.token); // decode token
    if (tokenInfo != null) {

      this.email = tokenInfo.email; // get token expiration dateTime
      this.userName = tokenInfo.given_name;
      this.userId = tokenInfo.nameid;
      this.ImageName = tokenInfo.unique_name;
      //   //console.log(this.ImageName);
      //   //console.log(this.userName);
      //   //console.log(this.email);
      //   //console.log(tokenInfo);
      //debugger;
      this.userNotifyService.CommentNotificationReceived.subscribe((adds: commentNotify) => {
        //debugger;
        if (this.userId == adds.receiverID) {
          this.showCommentNotifyAdd(adds);
        }
      });
      // this.userNotifyService.ChatNotificationReceived.subscribe((result: notifyMessage) => {
      //   //debugger;
      //   alert("Notify")
      //   this.updateArray(result);
      // });
    }
    else {

    }
    this.LoadCompanyData();
  }
  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  removeCartProduct(product: Product) {
    this.adproductService.removeLocalCartProduct(product);
  }
  removeLocalAds(items:adds){
    this.adproductService.removeLocalAds(items);
  }

  updateArray(result) {
    this.itemArray.items[result.recevierID].message = result.message
  }

  goUserNewPost() {
    this.userInterface.setInterface("1");
    this.router.navigate(['../user/adsposting'], { relativeTo: this.route });
  }

  onLogOut() {
    this.authSer.LogedOut();
    this.router.navigate(['../home'], { relativeTo: this.route });
  }
  goMainHome() {
    this.userInterface.setInterface("0");
    this.router.navigate(['../home'], { relativeTo: this.route });
  }

  goUserHome() {
    this.userInterface.setInterface("1");
    this.router.navigate(['../userHome'], { relativeTo: this.route });
  }
  goUserProfile() {
    this.userInterface.setInterface("1");
    this.router.navigate(['../userprofile'], { relativeTo: this.route });
  }
  goUserMessages() {
    this.userInterface.setInterface("1");
    this.router.navigate(['../userchat'], { relativeTo: this.route });
  }
  goUserFrineds() {
    this.userInterface.setInterface("1");
    this.router.navigate(['../userprofile/friend'], { relativeTo: this.route });
  }
  goUserCart() {
    this.userInterface.setInterface("1");
    this.router.navigate(['../usercart'], { relativeTo: this.route });
  }
  goUserCartFavorite() {
    this.userInterface.setInterface("1");
    this.router.navigate(['../user/favorite/products'], { relativeTo: this.route });
  }
  onSaveData() {
    //  this.dataStorageService.storeRecipies().subscribe(
    //      (response : Response) => {
    //          //console.log(response)
    //      }
    //  );
  }
  onGetData() {
    // this.dataStorageService.getRecipies();
  }
  onSignUP() {

  }

  showNotifyAdd(add: postnotify) {
    //debugger;
    this.notifyService.showSuccessWithHtml(
      '<div style="width:100%; float:left;">' +
      '<div> ' +
      '<a href="/' + add.URL + '" target="_blank"><img src="' + add.userImage + '"  class="circularImage" style="width: 50px!important;height: 50px!important;border-radius: 50%!important;overflow: hidden;"   alt="..."></a>' +
      //'<span> Ali Ahamd </span>'+
      '</div>' +
      '<div style="width:75%">' +
      '<div style="width:100%; float:left;">' +
      '<div style="width:50%"><span>' + add.userName + '</span></div>' +
      '<div style="width:50%"> <span>' + add.datetime + '</span></div>' +
      '</div>' +
      '<div style="width:100%; float:left;"><span>' + add.addTitle + '</span></div>' +
      ' </div>', '')
  }

  showCommentNotifyAdd(comment: commentNotify) {
    //debugger;
    this.notifyService.showSuccessWithHtml(
      '<div style="width:100%; float:left;">' +
      '<div style="width:25%"> ' +
      '<a href="/' + comment.linkURL + '" target="_blank"><img src="' + comment.userImage + '"  class="circularImage" alt="..."></a>' +
      //'<span> Ali Ahamd </span>'+
      '</div>' +
      '<div style="width:75%">' +
      '<div style="width:100%; float:left;">' +
      '<div style="width:50%"><span>' + comment.userName + '</span></div>' +
      '<div style="width:50%"> <span>' + comment.datetime + '</span></div>' +
      '</div>' +
      '<div style="width:100%; float:left;"><span>' + comment.addTitle + '</span></div>' +
      ' </div>', '')

  }

  showNotifyComments(comment: Comments) {
    //debugger;
    // //console.log(adds.addTitle + "NOtiFy");
    // var username = adds.userName;
    // var userimage = adds.userName;
    // var name = adds.addTitle;
    this.notifyService.showSuccess(' Bravo! ', 'Comment successful');
    // //console.log(userimage);
  }
  // showNotifyMessage(result: notifyMessage) {
  //   //debugger;
  //   this.notifyService.showSuccess(' Bravo! ','ChatMessage send successfull');
  // }  

  LoadCompanyData() {
    //debugger;
    this.adcompanyService.getProfileDetails().subscribe(data => {
      this.UserImage = data;
      this.imageBase64 = data.imageBase64;
      ////console.log(this.imageBase64);
      //console.log(this.UserImage);
    });
  }

  LoadFriendRequests(){
    this.actions.GetFrndsRequest().subscribe(data => {
      this.FrndRequests = data;
      ////console.log(this.FrndRequests)
    });
  }

  AcceptReq(frndID){
    // //debugger;
     const data = new Actionbtn();
     data.mode = "Accept"
     data.userID = frndID;
     this.actions.AcceptFriendRequest(data).subscribe(data => {
       this.LoadFriendRequests();
       this.notifyService.showSuccess('Baravo !!', 'Accept Successfully');
     });     
   }
   RejectReq(frndID){
     var result = confirm("want to Reject ??")
     if (result) {
     const data = new Actionbtn();
     data.mode = "Reject"
     data.userID = frndID;
     this.actions.AcceptFriendRequest(data).subscribe(data => {
       this.LoadFriendRequests();
       this.notifyService.showSuccess('Baravo !!', 'Reject Successfully');
     });        
     }
   }

  LoadMessageNotify(){
    var userProfileID = 0;
    this.actions.From_FriendMessages(userProfileID).subscribe((result) => {
      this.friendmessages = result;
      this.messageslength = result.length;
      // this.todayWithPipe = this.pipe.transform(Date.now(), 'dd/MM/yyyy');
    });
  }





  
  ClassifiedsearchSend(){
    //debugger;
    const requestData = new allSearchFilters();
    
    let catName : string;
    let subCatName : string ;
    let catID: string ;
    let subCatID: string ;     

    catName = "Items"; 
    catID = "d7b411fb-1c21-4f0a-88f7-34d6b789f67d";
    subCatName ="?q=sad&_keyori=ss&from=input&spm=a2a0e.Classified.search.go";
    subCatID= "0";

    requestData.CatID =catID;
    requestData.subCatID =subCatID;
    requestData.levelOfSearch = "1";
    requestData.searchWord = "";
    var newCatName = catName.replace(/ /g,'-');
    var newSubCatName = subCatName.replace(/ /g,'-');
    this.operSearch.setData(requestData); 

    let url = this.router.createUrlTree(['/search',newCatName,newSubCatName])
    window.open(url.toString(), '_blank')
  }
  AutosearchSend()
  {
    debugger;
    const requestData = new allSearchFilters();
    
    let catName : string;
    let subCatName : string ;
    let catID: string ;
    let subCatID: string ;  

    catName = "Autos"; 
    catID = "5bf243b1-2be4-4dda-93a0-cf9e06209e0f";
    subCatName ="q=sad&_keyori=ss&from=input&spm=a2a0e.Autos.search.go";
    subCatID= "79504CCA-41F7-4936-ACF3-6757E9AA7E29";

    requestData.CatID =catID;
    requestData.subCatID =subCatID;
    requestData.levelOfSearch = "1";
    requestData.searchWord = "";
    var newCatName = catName.replace(/ /g,'-');
    var newSubCatName = subCatName.replace(/ /g,'-');
    this.operSearch.setData(requestData); 
    let url = this.router.createUrlTree(['/search',newCatName,newSubCatName])
    window.open(url.toString(), '_blank')
  }
  PropertyRentsearchSend()
  {
    //debugger;
    const requestData = new allSearchFilters();
    
    let catName : string;
    let subCatName : string ;
    let catID: string ;
    let subCatID: string ;
    catName = "Property For Rent"; 
    catID = "0372dc00-f9cb-4cb5-8b95-901ea8a02efa";
    subCatName ="q=sad&_keyori=ss&from=input&spm=a2a0e.Property.search.go";
    subCatID= "E49D23FB-F5E0-43A7-9489-FC3D52F1D9EC";
    requestData.CatID =catID;
    requestData.subCatID =subCatID;
    requestData.levelOfSearch = "1";
    requestData.searchWord = "";
    var newCatName = catName.replace(/ /g,'-');
    var newSubCatName = subCatName.replace(/ /g,'-'); 
    this.operSearch.setData(requestData);
    let url = this.router.createUrlTree(['/search',newCatName,newSubCatName])
    window.open(url.toString(), '_blank')
  }
  PropertySalesearchSend()
  {
    //debugger;
    const requestData = new allSearchFilters();
    
    let catName : string;
    let subCatName : string ;
    let catID: string ;
    let subCatID: string ;
    catName = "Property For Sale"; 
    catID = "27bbb528-5666-4429-b153-7d6441c0efa1";
    subCatName ="q=sad&_keyori=ss&from=input&spm=a2a0e.Property.search.go";
    subCatID= "AD708A18-5BEA-40BC-966B-C22383A8ADAA";
    requestData.CatID =catID;
    requestData.subCatID =subCatID;
    requestData.levelOfSearch = "1";
    requestData.searchWord = "";
    var newCatName = catName.replace(/ /g,'-');
    var newSubCatName = subCatName.replace(/ /g,'-'); 
    this.operSearch.setData(requestData);
    let url = this.router.createUrlTree(['/search',newCatName,newSubCatName])
    window.open(url.toString(), '_blank')
  }
  JobWantsearchSend()
  {
    //debugger;
    const requestData = new allSearchFilters();
    
    let catName : string;
    let subCatName : string ;
    let catID: string ;
    let subCatID: string ;
    catName = "Job"; 
    catID = "3c064386-a300-4f90-8e18-f6da9f83aa1c";
    subCatName ="q=sad&_keyori=ss&from=input&spm=a2a0e.Job.search.go";
    subCatID= "A07D5BE2-BC59-4086-82F5-1E1DF97EC87A";     

    requestData.CatID =catID;
    requestData.subCatID =subCatID;
    requestData.levelOfSearch = "2";
    requestData.searchWord = "";
    var newCatName = catName.replace(/ /g,'-');
    var newSubCatName = subCatName.replace(/ /g,'-'); 
    this.operSearch.setData(requestData);
    let url = this.router.createUrlTree(['/search',newCatName,newSubCatName])
    window.open(url.toString(), '_blank')
  }
  JobHiresearchSend()
  {
    //debugger;
    const requestData = new allSearchFilters();
    
    let catName : string;
    let subCatName : string ;
    let catID: string ;
    let subCatID: string ;
    catName = "Job"; 
    catID = "3c064386-a300-4f90-8e18-f6da9f83aa1c";
    subCatName ="I hiring Job";
    subCatID= "967CEED7-54C0-488E-8AA8-69E6045E6C05";        

    requestData.CatID =catID;
    requestData.subCatID =subCatID;
    requestData.levelOfSearch = "2";
    requestData.searchWord = "";
    var newCatName = catName.replace(/ /g,'-');
    var newSubCatName = subCatName.replace(/ /g,'-'); 
    this.operSearch.setData(requestData);
    let url = this.router.createUrlTree(['/search',newCatName,newSubCatName])
    window.open(url.toString(), '_blank')
  }

  SuppliersearchSend()
  {
    //debugger;
    const requestData = new allSearchFilters();
    
    let catName : string;
    let subCatName : string ;
    let catID: string ;
    let subCatID: string ;
    catName = "Supplier"; 
    catID = "c4e1f606-8105-4666-A8BC-C6657730A0DF";
    subCatName ="q=sad&_keyori=ss&from=input&spm=a2a0e.Supplier.search.go";
    subCatID= "F3F3582D-2BF4-4D92-90D3-0F93C0426F1F";        

    requestData.CatID =catID;
    requestData.subCatID =subCatID;
    requestData.levelOfSearch = "2";
    requestData.searchWord = "";
    var newCatName = catName.replace(/ /g,'-');
    var newSubCatName = subCatName.replace(/ /g,'-'); 
    this.operSearch.setData(requestData);
    let url = this.router.createUrlTree(['/search',newCatName,newSubCatName])
    window.open(url.toString(), '_blank')
  }

  ProductsearchSend()
  {
    //debugger;
    const requestData = new allSearchFilters();
    
    let catName : string;
    let subCatName : string ;
    let catID: string ;
    let subCatID: string ;
    catName = "Products"; 
    catID = "C4E1F606-8105-4666-A8BC-C6657730A0DF";
    subCatName ="q=sad&_keyori=ss&from=input&spm=a2a0e.Product.search.go";
    subCatID= "F3F3582D-2BF4-4D92-90D3-0F93C0426F1F";        

    requestData.CatID =catID;
    requestData.subCatID =subCatID;
    requestData.levelOfSearch = "2";
    requestData.searchWord = "";
    var newCatName = catName.replace(/ /g,'-');
    var newSubCatName = subCatName.replace(/ /g,'-'); 
    this.operSearch.setData(requestData);
    let url = this.router.createUrlTree(['/search',newCatName,newSubCatName])
    window.open(url.toString(), '_blank')
  }

  DealsearchSend()
  {
    //debugger;
    const requestData = new allSearchFilters();
    
    let catName : string;
    let subCatName : string ;
    let catID: string ;
    let subCatID: string ;
    catName = "Deals"; 
    catID = "c4e1f606-8105-4666-a8bc-c6657730a0df";
    subCatName ="q=sad&_keyori=ss&from=input&spm=a2a0e.Deal.search.go";
    subCatID= "F3F3582D-2BF4-4D92-90D3-0F93C0426F1F";        

    requestData.CatID =catID;
    requestData.subCatID =subCatID;
    requestData.levelOfSearch = "2";
    requestData.searchWord = "";
    var newCatName = catName.replace(/ /g,'-');
    var newSubCatName = subCatName.replace(/ /g,'-'); 
    this.operSearch.setData(requestData);
    let url = this.router.createUrlTree(['/search',newCatName,newSubCatName])
    window.open(url.toString(), '_blank')
  }
  
}
