<!-- <link rel="stylesheet" href="/assets/css/dashboard.css">
<link rel="stylesheet" href="/assets/css/dbresponsive.css"> -->
<!--************************************
				Header Start
        *************************************-->
<link rel="stylesheet" href="/assets/css/dashboard.css">
<link rel="stylesheet" href="/assets/css/dbresponsive.css">
<div id="tg-wrapper" [ngClass]="(status) ? 'tg-wrapper tg-haslayout tg-openmenu ' : 'tg-wrapper tg-haslayout '">
    <header id="tg-dashboardheader" class="tg-dashboardheader tg-haslayout">
        <nav id="tg-nav" class="tg-nav">
            <div class="navbar-header">
                <button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
                    data-target="#tg-navigation" aria-expanded="false">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
            </div>
            <div id="tg-navigation" class="collapse navbar-collapse tg-navigation">
                <ul>
                    <li class="menu-item-has-children">
                        <a (click)="backtoMain()">Home</a>
                        <ul class="sub-menu">
                            <!-- <li><a (click)="redirectPage('home');">Home</a></li>
                        <li><a (click)="redirectPage('adListing');">Ad-Listing</a></li>
                        <li><a (click)="redirectPage('adDetails');">Ad-Details</a></li>
                        <li><a (click)="redirectPage('logInSignUp');">LogInSignUp</a></li>
                        <li><a (click)="redirectPage('errorPage');">errorPage</a></li>
                        <li><a (click)="redirectPage('aboutUs');">aboutUs</a></li>
                        <li><a (click)="redirectPage('contactUs');">contactUs</a></li> -->
                        </ul>
                    </li>
                    <li class="menu-item-has-children">
                        <a routerLink="/userHome">Dashboard</a>
                        <!-- <ul class="sub-menu">
                            <li><a href="javascript:void(0)">Dashboard</a></li>
                            <li><a href="dashboard-myads.html">Dashboard My Ads</a></li>
                            <li><a href="dashboard-myfavourites.html">Dashboard Favorites</a></li>
                            <li><a href="dashboard-offermessages.html">Dashboard Offer Message</a></li>
                            <li><a href="dashboard-payments.html">Dashboard Payment</a></li>
                            <li><a href="dashboard-postanad.html">Dashboard Post Ad</a></li>
                            <li><a href="dashboard-privacy-setting.html">Dashboard privacy Setting</a></li>
                            <li><a href="dashboard-profile-setting.html">Dashboard Profile Setting</a></li>
                        </ul> -->
                    </li>
                    <li class="menu-item-has-children current-menu-item">
                        <a href="javascript:void(0);">Setting's</a>
                        <ul class="sub-menu">
                            <li><a routerLink="/userprofile">Profile</a></li>
                            <li><a routerLink="/userprofile/about">About me</a></li>
                            <li><a  (click)="myuserview()">Preview</a></li>
                            <li><a routerLink="/userprofile/friend">Friends</a></li>
                            <li><a routerLink="/userchat">Message</a></li>
                        </ul> 
                    </li>
                    <!-- <li class="menu-item-has-children">
                        <a href="javascript:void(0);">Pages</a>
                        <ul class="sub-menu">
                            <li><a href="aboutus.html">About</a></li>
                            <li><a href="contactus.html">Contact Us</a></li>
                            <li class="menu-item-has-children">
                                <a href="javascript:void(0);">News</a>
                                <ul class="sub-menu">
                                    <li><a href="newsgrid.html">News grid</a></li>
                                    <li><a href="newslist.html">News list</a></li>
                                    <li><a href="newsdetail.html">News detail</a></li>
                                </ul>
                            </li>
                            <li><a href="404error.html">404 Error</a></li>
                            <li><a href="comingsoon.html">Coming Soon</a></li>
                            <li><a href="packages.html">Packages</a></li>
                            <li><a href="loginsignup.html">Login/Register</a></li>
                        </ul>
                    </li> -->
                </ul>
            </div>
        </nav>
        <!-- <div class="tg-rghtbox">
            <div class="navigationss">
                <a class="buttonss" (click)="onLogOut()" style="cursor: pointer;">
                    <img [src]="imageBase64" alt="usre image" *ngIf="imageBase64 != null" /><img
                        *ngIf="imageBase64 == null" src="assets/images/ads/dummyimage.png" alt="user image ">
                    <div class="logoutss">LogOut</div>
                </a>
            </div>
        </div> -->

        <div class="tg-rghtbox">
            <a class="tg-btn" (click)="goUserNewPost()">
                <i class="fa fa-bookmark"></i>
                <span>Post An Ad</span>
            </a>
            <div class="dropdown tg-themedropdown tg-notification">
                <button class="tg-btndropdown" data-toggle="dropdown" (click)="onLogOut()" 
                    aria-haspopup="true" aria-expanded="false">
                    <i class="fa fa-sign-out" aria-hidden="true"></i>
                </button>
            </div>
            <div class="dropdown tg-themedropdown tg-notification">
                <button class="tg-btndropdown" id="tg-notificationdropdown3" type="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <i class="fa fa-envelope" aria-hidden="true"></i>
                    <span class="tg-badge">
                        {{(messageslength != null) ? messageslength : '0'}}
                    </span>
                </button>
                <ul class="dropdown-menu tg-dropdownmenu" aria-labelledby="tg-notificationdropdown3">                    
                    <div style="font-weight: bold;background-color: rgb(241, 241, 241);padding: 5px 0px 5px 20px">
                        Message's
                        <span routerLink="/userchat" style="float: right;cursor: pointer;padding: 0px 20px 5px 0px;">ViewAll</span>
                    </div>
                    <li *ngFor="let item of this.friendmessages">
                        <!-- <a routerLink="/userchat">
                            <img *ngIf="item.userimagebase64 != null" [src]="item.userimagebase64"
                            class="img-fluid rounded-img" /> &nbsp; <span style="font-size: 1.7rem;">{{item.sender}} &nbsp; <i class="fa fa-handshake-o" aria-hidden="true"></i>
                            </span>
                        </a> -->
                        <div class="row" routerLink="/userchat">
                            <div class="col-md-3">
                                <img *ngIf="item.userimagebase64 != null && item.userimagebase64 != ''" [src]="item.userimagebase64" class="img-fluid rounded-img" />
                                <img *ngIf="item.userimagebase64 == null || item.userimagebase64 == ''" src="/assets/images/no_image.jpg" class="img-fluid rounded-img" />
                            </div>
                            <div class="col-md-9" style="margin-left:inherit;">
                                <span style="font-size: 1.7rem;">{{item.sender}} &nbsp;
                                    <i class="fa fa-comments-o" aria-hidden="true"></i></span>
                                 <p style="cursor: pointer;"><span>{{ (item.message.length>25)? (item.message | slice:0:25)+'...':(item.message) }}</span> &nbsp; &nbsp; {{item.datetime | date:'mediumTime'}}  &nbsp; <a style="float: right;" routerLink="/userchat">Reply</a></p>   
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="dropdown tg-themedropdown tg-notification">
                <button class="tg-btndropdown" id="tg-notificationdropdown2" type="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <i class="fa fa-bell" aria-hidden="true"></i>
                    <span class="tg-badge">9</span>
                </button>
                <ul class="dropdown-menu tg-dropdownmenu" aria-labelledby="tg-notificationdropdown2">
                    <li>
                        <p>Consectetur adipisicing sedi eiusmod ampore incididunt ut labore et dolore.</p>
                    </li>
                </ul>
            </div>
            <div class="dropdown tg-themedropdown tg-notification">
                <button class="tg-btndropdown" id="tg-notificationdropdown1" type="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <i class="fa fa-users" aria-hidden="true"></i>
                    <span class="tg-badge">
                        {{(frndrequestlenght != null) ? frndrequestlenght : '0'}}
                    </span>
                </button>
                <ul class="dropdown-menu tg-dropdownmenu" aria-labelledby="tg-notificationdropdown1">
                    <div style="font-weight: bold;background-color: rgb(241, 241, 241);padding: 5px 0px 5px 20px">
                        Friend Request's
                        <span routerLink="/userprofile/friend" style="float: right;cursor: pointer;padding: 0px 20px 5px 0px;">ViewAll</span>
                    </div>
                    <li *ngFor="let item of this.FrndRequests">
                        <div class="row">
                            <div class="col-md-3" routerLink="/userprofile/friend">
                                <img *ngIf="item.imageBase64 != null && item.imageBase64 != ''" [src]="item.imageBase64" class="img-fluid rounded-img" />
                                <img *ngIf="item.imageBase64 == null || item.imageBase64 == ''" src="/assets/images/no_image.jpg" class="img-fluid rounded-img" />
                            </div>
                            <div class="col-md-9" style="margin-left: inherit;">
                                <span style="font-size: 1.7rem;">{{item.userName}} &nbsp;
                                    <i class="fa fa-handshake-o" aria-hidden="true"></i></span>
                                 <p style="cursor: pointer;"><a (click)="AcceptReq(item.userProfileID)">Accept</a> | <a (click)="RejectReq(item.userProfileID)">Reject</a> &nbsp; <span style="float: right;">{{item.datetime | date:'mediumTime'}}</span> </p>   
                            </div>
                        </div>
                    </li>
                    <!-- <li *ngFor="let item of this.SendFrndRequests">
                        <a routerLink="/userprofile/friend"><img *ngIf="item.imageBase64 == null" src="../../assets/images/ads/dummyimage.png"
                            class="img-fluid rounded-img" /> 
                            <img *ngIf="item.imageBase64 != null" [src]="item.imageBase64"
                            class="img-fluid rounded-img" /> &nbsp; <span style="font-size: 1.7rem;">{{item.userName}} &nbsp; <i class="fa fa-retweet" aria-hidden="true"></i>
                            </span>
                        </a>
                    </li> -->
                </ul>
            </div>
            <div class="dropdown tg-themedropdown tg-notification">
                <button class="tg-btndropdown" id="tg-notificationdropdown1" type="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                <span class="tg-badge">
                    {{ adproductService.getLocalCartProducts().length }}
                </span>
            </button>
            <ul class="dropdown-menu tg-dropdownmenu" aria-labelledby="tg-notificationdropdown1">
                <div
                    style="font-weight: bold;background-color: rgb(241, 241, 241);padding: 5px 0px 5px 20px">
                    Add to Cart
                    <span (click)="goUserCart()"
                        style="float: right;cursor: pointer;padding: 0px 20px 5px 0px;">ViewAll</span>
                </div>
                <li *ngFor="let item of adproductService.getLocalCartProducts()">
                    <div class="row">
                        <div class="col-md-3" (click)="goUserCart()">
                            <img *ngIf="item.proImageBase != null && item.proImageBase != ''" [src]="item.proImageBase"
                                class="img-fluid rounded-img" />
                                <img *ngIf="item.proImageBase == null || item.proImageBase == ''" 
                                src="/assets/images/no_image.jpg" class="img-fluid rounded-img" />
                        </div>
                        <div class="col-md-9" style="margin-left: inherit;">
                            <span style="font-size: 1.7rem;">{{item.proName}} &nbsp;
                                <i (click)="removeCartProduct(item)" class="fa fa-times" aria-hidden="true"></i></span>
                            <p style="cursor: pointer;"><a>{{item.proPriceUnitName}}</a> | <a>
                                {{item.proPrice}}</a> &nbsp;
                                 <!-- <span style="float: right;">{{item.proPriceUnitName}}</span> -->
                            </p>
                        </div>
                    </div>
                </li>
            </ul>
            </div>
            <div class="dropdown tg-themedropdown tg-notification">
                <button class="tg-btndropdown" id="tg-notificationdropdown1" type="button"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="fa fa-heart" aria-hidden="true"></i>
                    <span class="tg-badge">
                        {{adproductService.getFavoriteAds().length + adproductService.getFavoriteCartProducts().length+adproductService.getFavoriteDeals().length}}
                    </span>
                </button>
                <ul class="dropdown-menu tg-dropdownmenu" aria-labelledby="tg-notificationdropdown1">
                    <div
                        style="font-weight: bold;background-color: rgb(241, 241, 241);padding: 5px 0px 5px 20px">
                        Add to Favorite
                        <span (click)="goUserCartFavorite()"
                            style="float: right;cursor: pointer;padding: 0px 20px 5px 0px;">ViewAll</span>
                    </div>
                    <li *ngFor="let item of adproductService.getFavoriteAds()">
                        <div class="row">
                            <div class="col-md-3" (click)="goUserCartFavorite()">
                                <img *ngIf="item.addImageBase != null && item.addImageBase != ''" [src]="item.addImageBase"
                                    class="img-fluid rounded-img" />
                                    <img *ngIf="item.addImageBase == null || item.addImageBase == ''" 
                                    src="/assets/images/no_image.jpg" class="img-fluid rounded-img">
                            </div>
                            <div class="col-md-9" style="margin-left: inherit;">
                                <span style="font-size: 1.7rem;">{{item.title}} &nbsp;
                                    <i (click)="removeLocalAds(item)" class="fa fa-times" aria-hidden="true"></i></span>
                                <p style="cursor: pointer;"><a>{{item.currencyName}}</a> | <a>
                                    {{item.price}}</a> &nbsp;
                                     <!-- <span style="float: right;">{{item.proPriceUnitName}}</span> -->
                                </p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div id="tg-sidebarwrapper" class="tg-sidebarwrapper">
            <!-- <div id="tg-sidebarwrapper" [ngClass]="(status) ? 'tg-openmenu tg-sidebarwrapper' : 'tg-sidebarwrapper'"> -->
            <span id="tg-btnmenutoggle" class="tg-btnmenutoggle" (click)="clickEvent()" style="margin-left: -15px;">
                <i class=" fa fa-angle-left "></i>
                <svg xmlns="http://www.w3.org/2000/svg " width="20 " height="67 " viewBox="0 0 20 67 ">
                    <!-- <metadata>
                    ?xpacket begin="﻿ " id="W5M0MpCehiHzreSzNTczkc9d "?
                        <x:xmpmeta xmlns:x="adobe:ns:meta/ " x:xmptk="Adobe XMP Core 5.6-c138 79.159824, 2016/09/14-01:09:01 ">
                            <rdf:rdf xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns# ">
                                <rdf:description rdf:about=" "></rdf:description>
                            </rdf:rdf>
                        </x:xmpmeta>
                    ?xpacket end="w "?
                </metadata> -->
                    <path id="bg " class="cls-1 "
                        d="M20,27.652V39.4C20,52.007,0,54.728,0,67.265,0,106.515.026-39.528,0-.216-0.008,12.32,20,15.042,20,27.652Z ">
                    </path>
                </svg>
            </span>
            <div id="tg-verticalscrollbar " class="tg-verticalscrollbar " malihu-scrollbar
                [scrollbarOptions]="scrollbarOptions ">
                <strong class="tg-logo "><a href="javascript:void(0); "><img src="../../../assets/images/apps-02.png"
                            alt="Customer Logo "></a></strong>
                <div class="tg-user ">
                    <figure>
                        <span class="tg-bolticon "><i class="fa fa-bolt "></i></span>
                        <a href="javascript:void(0); ">
                            <img [src]="imageBase64" width="50px" alt="image description "
                                *ngIf="imageBase64 != null" /><img *ngIf="imageBase64 == null"
                                src="assets/images/ads/dummyimage.png" width="50px" alt="image description ">
                        </a>
                    </figure>
                    <div class="tg-usercontent ">
                        <h3>{{this.userName}}</h3>
                        <h4>Administrator</h4>
                    </div>
                    <!-- <a class="tg-btnedit " href="javascript:void(0); "><i class="fa fa-pencil "></i></a> -->
                </div>
                <nav id="tg-navdashboard " class="tg-navdashboard ">
                    <ul>
                        <li class="tg-active ">
                            <a href="javascript:void(0); ">
                                <i class="fa fa-bar-chart" aria-hidden="true"></i>
                                <span routerLink="/userHome"> Dashboard </span>
                            </a>
                        </li>
                        <li class="menu-item-has-children">
                            <a href="javascript:void(0); " (click)="selectMyCom()">
                                <!-- <i class="fa fa-list" (click)="selectMyCom()"></i> -->
                                <i class="fa fa-building" aria-hidden="true"></i>
                                <span>My Company</span>
                            </a>
                            <ul class="sub-menu " *ngIf="myCom === true" style="display: block;">
                                <li *ngIf="!companyList || companyList.length ==0 "><a
                                        routerLink="/user/adcompany">Create Comapny</a></li>
                                <li *ngIf="companyList || companyList.length !=0 "><a routerLink="/usercompany">Update
                                        Comapny</a></li>
                            </ul>
                        </li>
                        <li class="menu-item-has-children">
                            <a href="javascript:void(0); " (click)="selectMyPro()">
                                <i class="fa fa-shopping-bag"></i>
                                <span>Comapny Products</span>
                            </a>
                            <ul class="sub-menu " *ngIf="myPro === true" style="display: block;">
                                <li *ngIf="companyList.length !=0"><a routerLink="/user/addproduct">Add Products</a>
                                </li>
                                <li *ngIf="companyList.length !=0"><a routerLink="/user/productlist">Products List</a>
                                </li>
                            </ul>
                        </li>
                        <li class="menu-item-has-children">
                            <a href="javascript:void(0); " (click)="selectMyDeal()">
                                <i class="fa fa-handshake-o" aria-hidden="true"></i>
                                <span>Deals</span>
                            </a>
                            <ul class="sub-menu " *ngIf="myDeal === true" style="display: block;">
                                <li *ngIf="companyList.length !=0"><a routerLink="/user/adddeal">Add Deal</a></li>
                                <li *ngIf="companyList.length !=0"><a routerLink="/user/deallist">Deal List</a></li>
                            </ul>
                        </li>
                        <li class="menu-item-has-children">
                            <a href="javascript:void(0); " (click)="selectMyAdds()">
                                <!-- <i class="fa fa-list" (click)="selectMyAdds()"></i> -->
                                <i class="fa fa-list" aria-hidden="true"></i>
                                <span>My Ads</span>
                            </a>
                            <ul class="sub-menu " *ngIf="myAdds === true" style="display: block;">
                                <li><a routerLink="/user/adsposting">Add Ads</a></li>
                                <li><a routerLink="/user/adslist">Ads List</a></li>
                            </ul>
                        </li>
                        <!-- <li class="menu-item-has-children">
                            <a href="javascript:void(0); ">
                                <i class="fa fa-list" (click)="selectMyAdds()"></i>
                                <span>My Ads</span>
                            </a>
                            <ul class="sub-menu " *ngIf="myAdds === true" style="display: block;">
                                <li><a routerLink="/userads">All Ads</a></li>
                                <li><a href="dashboard-myads.html ">Featured Ads</a></li>
                                <li><a href="dashboard-myads.html ">Active Ads</a></li>
                                <li><a href="dashboard-myads.html ">Inactive Ads</a></li>
                                <li><a href="dashboard-myads.html ">Sold Ads</a></li>
                                <li><a href="dashboard-myads.html ">Expired Ads</a></li>
                                <li><a href="dashboard-myads.html ">Deleted Ads</a></li>
                            </ul>
                        </li> -->

                        <li>
                            <a routerLink="/userprofile">
                                <i class="fa fa-cog "></i>
                                <span>Profile Settings</span>
                            </a>
                        </li>
                        <li class="menu-item-has-children ">
                            <a routerLink="/userchat" href="javascript:void(0); ">
                                <!-- <i class=" fa fa-envelope "></i> -->
                                <i class="fa fa-comments-o" aria-hidden="true"></i>
                                <span>Messages</span>
                            </a>
                            <!-- <ul class="sub-menu ">
                                <li><a href="javascript:void(0);">Offer Received</a></li>
                                <li><a href="javascript:void(0);">Offer Sent</a></li>
                                <li><a href="javascript:void(0);">Trash</a></li>
                            </ul> -->
                        </li>
                        <li>
                            <a href="javascript:void(0); " routerLink="/usercart">
                                <i class=" fa fa-cart-plus "></i>
                                <span>Payments</span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0); " routerLink="/user/favorite/products">
                                <i class="fa fa-heart"></i>
                                <span>My Favourites</span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0); ">
                                <i class=" fa fa-star "></i>
                                <span>Privacy Settings</span>
                            </a>
                        </li>
                        <li>
                            <a (click)="onLogOut()">
                                <i class="fa fa-sign-out" aria-hidden="true"></i>
                                <span>Logout</span>
                            </a>
                        </li>
                    </ul>
                </nav>
                <div class="tg-socialandappicons ">
                    <ul class="tg-socialicons ">
                        <li class="tg-facebook"><a href="https://www.facebook.com/MyKnocks/?ref=aymt_homepage_panel" target="_blank"><i class="fa fa-facebook"></i></a></li>
                        <li class="tg-instagram"><a href="https://www.instagram.com/myknocks2015/" target="_blank"><i class="fa fa-instagram"></i></a></li>
                        <li class="tg-twitter"><a href="https://twitter.com/myknocksdeals" target="_blank"><i class="fa fa-twitter"></i></a></li>
                       <li class="tg-linkedin"><a href="https://www.linkedin.com/in/my-knocks-922077105/" target="_blank"><i class="fa fa-linkedin"></i></a></li>
                    </ul>
                    <ul class="tg-appstoreicons ">
                        <li>
                            <a href="javascript:void "><img src="assets/images/icons/app-01.png "
                                    alt="image description "></a>
                        </li>
                        <li>
                            <a href="javascript:void "><img src="assets/images/icons/app-02.png "
                                    alt="image description "></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </header>
</div>
<!-- <script>
    /*--------------------------------------
                             DASHBOARD MENU					
                            --------------------------------------*/
    // if ($('#tg-btnmenutoggle').length > 0) {
    //     $("#tg-btnmenutoggle ").on('click', function(event) {
    //         event.preventDefault();
    //         $('#tg-wrapper').toggleClass('tg-openmenu');
    //         $('body').toggleClass('tg-noscroll');
    //         $('.tg-navdashboard ul.sub-menu').hide();
    //     });
    // }
    if (jQuery('.tg-verticalscrollbar').length > 0) {
        //debugger;
        var _tg_verticalscrollbar = jQuery('.tg-verticalscrollbar');
        _tg_verticalscrollbar.mCustomScrollbar({
            axis: "y ",
        });
    }
</script> -->
<!--************************************
          Header End
      *************************************-->