<div class="tg-wrapper tg-haslayout">
    <div class="tg-dashboardbanner">
        <h1>Company</h1>
        <ol class="tg-breadcrumb">
            <li><a (click)="backtoMain()">Go to Home</a></li>
            <li class="tg-active">Company Details</li>
        </ol>
    </div>

    <ul>

    </ul>
    <main id="tg-main" class="tg-main tg-haslayout">
        <!--************************************
                      Section Start
              *************************************-->
        <section class="tg-dbsectionspace tg-haslayout">
            <div class="row">
                <form class="tg-formtheme tg-formdashboard" #f="ngForm" (ngSubmit)="onSubmit(f)">
                    <fieldset>
                        <div class="tg-postanad container ">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div class="tg-dashboardbox">
                                    <div class="tg-dashboardboxtitle">
                                        <h2>Create Company</h2>
                                    </div>
                                    <div class="tg-dashboardholder">
                                        <div class="form-group">
                                            <input type="text" required class="form-control" [(ngModel)]="this.comName" placeholder="Company Name" id="comName" name="comName">
                                            <div class="form-group">
                                                <div *ngIf="comNameAlert == true" class="alert alert-danger">
                                                    <div *ngIf="comNameAlert == true">
                                                        Company Name is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <input type="text" required class="form-control" [(ngModel)]="this.comIndustry" placeholder="Enter Industry" id="selectedIndustry" name="selectedIndustry">
                                            <div class="form-group">
                                                <div *ngIf="comIndustryAlert == true" class="alert alert-danger">
                                                    <div *ngIf="comIndustryAlert == true">
                                                        Industry is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <input type="number" numbersOnly required class="form-control" [(ngModel)]="this.comSize" placeholder="Enter Size" id="selectedSize" name="selectedSize">
                                            <div class="form-group">
                                                <div *ngIf="comSizeAlert == true" class="alert alert-danger">
                                                    <div *ngIf="comSizeAlert == true">
                                                        Size is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group " id="divCountry">
                                            <ng-select [items]="CountryList" bindLabel="title" bindValue="id" placeholder="Select Country"
                                                appendTo="body" [(ngModel)]="this.comCountry" id="selectedCountry" name="selectedCountry">
                                            </ng-select>
                                            <!-- <div class="form-group">
                                                <div *ngIf="selectedCountryAlert == true" class="alert alert-danger">
                                                    <div *ngIf="selectedCountryAlert == true">
                                                        Country is required !
                                                    </div>
                                                </div>
                                            </div> -->
                                        </div>
                                        <div class="form-group">
                                            <input type="email" required class="form-control" [(ngModel)]="this.comEmail" placeholder="Enter Email" id="comEmail" name="comEmail">
                                            <div class="form-group">
                                                <div *ngIf="comEmailAlert == true" class="alert alert-danger">
                                                    <div *ngIf="comEmailAlert == true">
                                                        Email is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <input type="text" required class="form-control" [(ngModel)]="this.comNumber" placeholder="Enter Phone" id="comNumber" name="comNumber">
                                            <div class="form-group">
                                                <div *ngIf="comNumberAlert == true" class="alert alert-danger">
                                                    <div *ngIf="comNumberAlert == true">
                                                        Phone Number is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <input type="text" required class="form-control" [(ngModel)]="this.comLicence" placeholder="Enter Licence" id="comLicence" name="comLicence">
                                            <div class="form-group">
                                                <div *ngIf="comLicenceAlert == true" class="alert alert-danger">
                                                    <div *ngIf="comLicenceAlert == true">
                                                        Licence is required !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <input type="text" required class="form-control" [(ngModel)]="this.comWebsite" placeholder="Enter Website" id="comWebsite" name="comWebsite">
                                        </div>
                                        <div class="form-group">
                                            <input type="text" required class="form-control" [(ngModel)]="this.comAddress" placeholder="Enter Address" id="selectedAddress" name="selectedAddress">
                                        </div>
                                        <div class="form-group">
                                            <!-- <input type="text" required class="form-control" [(ngModel)]="this.comDescription" placeholder="Enter Description" id="selectedDescription" name="selectedDescription">
                                            -->
                                            <div class="form-group">
                                                <editor [init]="{
                                                            height: 500,
                                                            menubar: true,
                                                            plugins: [
                                                            'advlist autolink lists link image charmap print preview anchor',
                                                            'searchreplace visualblocks code fullscreen',
                                                            'insertdatetime media table paste code help wordcount'
                                                            ],
                                                            toolbar:
                                                            'undo redo | formatselect | bold italic backcolor | \
                                                            alignleft aligncenter alignright alignjustify | \
                                                            bullist numlist outdent indent | removeformat | help'
                                                        }" id="selectedDescription" name="selectedDescription" [(ngModel)]="this.comDescription"></editor>
                                            </div>
                                        </div>

                                        <!-- <label class="tg-fileuploadlabel" for="tg-photogallery">
                                        <span>Drop files anywhere to upload</span>
                                        <span>Or</span>
                                        <span class="tg-btn">Select Files</span>
                                        <span>Maximum upload file size: 500 KB</span>
                                        <input id="tg-photogallery" class="tg-fileinput" (change)="onSelectFile($event)" type="file"
                                          name="file" multiple accept="image/jpeg,image/jpg">
                                      </label>
                                        <div class="tg-horizontalthemescrollbar tg-profilephotogallery">
                                            <div class="col-md-2 productAddfromImages" *ngFor='let url of imageurls; let i = index'>
                                                <img class="img-fluid" [src]="url.base64String">
                                                <a (click)="removeImage(i)" class="btn btn-xs btn-danger">Remove</a>
                                            </div>
                                        </div> -->
                                        <div class="tg-dashboardholder">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="tg-horizontalthemescrollbar tg-profilephotogallery">
                                                        <div class="col-md-8 productAddfromImages" *ngFor='let url of imageurls; let i = index'>
                                                            <img class="img-fluid" [src]="url">
                                                            <a (click)="removeImage(i)" class="btn btn-xs btn-danger mt-2">Remove</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-8">
                                                    <label class="tg-fileuploadlabel" for="tg-photogallery">
                                                    <span>Drop files anywhere to upload</span>
                                                    <span>Or</span>
                                                    <span class="tg-btn">Select Photo</span>
                                                    <span>Maximum upload file size: 500 KB</span>
                                                    <input id="tg-photogallery" class="tg-fileinput"
                                                        (change)="onSelectFile($event)" type="file" name="file" multiple
                                                        accept="image/jpeg,image/jpg">
                                                </label></div>
                                            </div>

                                        </div>
                                        <button class="tg-btn" type="submit" *ngIf="!companyList || companyList.length ==0 ">Create</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </section>
    </main>
</div>